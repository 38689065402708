import {ShadowTheme} from "../../ShadowTemplate";
import React, {useState} from "react";
import {Button, Card, CardContent, Stack, Typography} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CodeSheetDialog from "./SheetDownloadDialog";
import {hot} from "react-hot-loader";

const CodeSheetShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <CodeSheet {...props}/>
    </ShadowTheme>;
};

const CodeSheet = ({clientId}) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    return <>
        <Card sx={{mt: 4}}>
            <CardContent sx={{p: 3, pr: 6}}>
                <Stack spacing={2} alignItems={'flex-start'}>
                    <Typography>2. Classificação de despesas</Typography>
                    <Typography fontWeight={600}>Faça o download da planilha de códigos ERP abaixo:</Typography>
                    <Button
                        variant={'outlined'}
                        onClick={handleDialogOpen}
                        endIcon={<DownloadIcon/>}>
                        Baixar planilha de códigos do ERP
                    </Button>
                    <Typography textAlign={'justify'}>
                        Para que você possa concluir a integração com sucesso, precisamos que você copie os códigos da
                        planilha de código ERP e cole na classificação de despesas dentro do Espresso.
                    </Typography>
                    <Typography textAlign={'justify'}>
                        Para facilitar, também incluímos a planilha para download na barra de topo da página, clicando
                        no ícone<MoreVertIcon/> a opção de download da planilha ficará visível. Você pode baixá-la agora
                        e
                        fazer a integração imediatamente, ou baixar para fazer posteriormente.
                    </Typography>
                    <Typography>
                        Fique à vontade para baixar a planilha quando for mais conveniente para você.
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
        <CodeSheetDialog open={dialogOpen} onClose={handleDialogClose} clientId={clientId}/>
    </>
}

export default hot(module)(CodeSheetShadow)