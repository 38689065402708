/* istanbul ignore file */

import React from "react";
import { parserCurrencyBRLValue, parserDate } from "../../shared/helpers";
import {
  parserSwapCardNumber,
  parserSwapCardObject,
  renderCardStatus,
} from "./parser/swapCard";
import { parserIssueCardsObject } from "./parser/swapIssueCard";
import {
  parserSectionTittles,
  parserSwapAccountObjectList,
} from "./parser/swapAccount";
import {
  parserActivityObject,
  renderActivityStatus,
} from "./parser/activities";
import {
  parserPmaObject,
  renderStatementItemStatus,
} from "./parser/pmaStatement";

import QuickSearch from "../../shared/QuickSearch";
import SelectTab from "../../shared/SelectTab";
import ListWithModalDetail from "../../shared/lists/WithModalDetail";
import Pagination from "../../shared/Pagination";
import Loading from "../../shared/Loading";
import CardsFilter from "./CardsFilter";
import FilterVisibilityTrigger from "./FilterVisibilityTrigger";
import PmaSummary from "./PmaSummary";
import PmaRetryService from "./pma_retry/PmaRetryService";
import { showSnackbar } from "../../shared/jquery_wrapper";
import {
  bistro_accounts_chargebacks_path,
  sync_bistro_accounts_activity_path,
} from "../../../routes";
import ShadowComponent from "../../shared/ShadowComponent";
import { Box, Button, Link } from "@mui/material";

class ManageAccount extends React.Component {
  state = {
    select: this.props.select ?? "activities",
    filter: {
      search: "",
      status: "",
    },
    showFilter: false,
    forcePageOne: false,
    list: [],
    perPage: 20,
    loading: true,
  };

  componentDidMount() {
    this.setState({ forcePageOne: !this.state.forcePageOne, loading: true });
  }

  render() {
    return (
      <div className="manage-account">
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Gestão da Conta Digital Espresso</h1>
          <Link href={bistro_accounts_chargebacks_path()}>
            <Button
              style={{
                backgroundColor: "#2E2F35",
                color: "white",
                height: "40px",
              }}
            >
              Consulta chargeback
            </Button>
          </Link>
        </Box>

        <SelectTab
          list={[
            {
              testName: "activities",
              label: "Transações",
              href: "activities",
              onClick: () => this.handleTab("activities"),
              active: this.state.select === "activities",
            },
            {
              testName: "swap-cards",
              label: "Cartões",
              href: "swap-cards",
              onClick: () => this.handleTab("swap_cards"),
              active: this.state.select === "swap_cards",
            },
            {
              testName: "swap-accounts",
              label: "Contas",
              href: "swap-accounts",
              onClick: () => this.handleTab("swap_accounts"),
              active: this.state.select === "swap_accounts",
            },
            {
              testName: "issue-cards",
              label: "Envio de Cartões",
              href: "issue-cards",
              onClick: () => this.handleTab("issue_cards"),
              active: this.state.select === "issue_cards",
            },
            {
              testName: "pma",
              label: "Extrato PMA",
              href: "pma",
              onClick: () => this.handleTab("pma"),
              active: this.state.select === "pma",
            },
            {
              testName: "funds_in",
              label: "Recargas",
              href: "funds_in",
              onClick: () => this.handleTab("funds_in", false),
              active: this.state.select === "funds_in",
            },
          ]}
          customClass={"d-flex justify-start tab-manage-account"}
          disabled={this.state.loading}
        />
        {this.state.select !== "funds_in" && (
          <QuickSearch
            placeholder={
              this.state.select === "swap_accounts"
                ? "Empresa/E-mail/Razão Social/CNPJ..."
                : "Empresa/E-mail/Final do cartão..."
            }
            value={this.state.filter.search}
            onChange={(e) => {
              this.setState({ filter: { search: e.target.value } });
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                this.setState({
                  forcePageOne: !this.state.forcePageOne,
                  loading: true,
                });
              }
            }}
          />
        )}

        <Loading show={this.state.loading} />

        {this.state.select === "swap_cards" && (
          <React.Fragment>
            <FilterVisibilityTrigger
              onClick={() =>
                this.setState({
                  ...this.state,
                  showFilter: !this.state.showFilter,
                })
              }
            />
            <CardsFilter
              showFilter={this.state.showFilter}
              value={this.state.filter.status}
              onChange={(e) => {
                this.setState({
                  filter: { status: e.target.value },
                });
              }}
              onFilterClick={() => {
                this.setState({
                  forcePageOne: !this.state.forcePageOne,
                  loading: true,
                });
              }}
              onClearClick={() => {
                this.setState({
                  filter: { status: "" },
                  forcePageOne: !this.state.forcePageOne,
                  loading: true,
                });
              }}
            />
          </React.Fragment>
        )}

        {this.state.select === "pma" && (
          <PmaSummary data={this.state.pmaStatementSummary} />
        )}

        {this.state.select === "funds_in" && (
          <ShadowComponent
            rootId={"funds_in_statement"}
            path={"bistro/accounts/funds_in/FundsInStatement"}
            props={{ csrfToken: this.props.csrf_token }}
          />
        )}

        {this.state.select !== "funds_in" && (
          <div className="collection white list-container">
            {this[`render_${this.state.select}`]()}

            <Loading show={this.state.loading} />
            <Pagination
              url={this.props[`${this.state.select}_endpoint`]}
              perPage={this.state.perPage}
              forcePageOne={this.state.forcePageOne}
              params={{
                filter: this.state.filter,
                per_page: this.state.perPage,
              }}
              setExternalLoading={(state) => this.setState({ loading: state })}
              setExternalList={(list, others) =>
                this.setState({ loading: false, list })
              }
            />
          </div>
        )}
      </div>
    );
  }

  render_swap_cards() {
    return (
      <ListWithModalDetail
        modalTittle={"Cartões"}
        header={[
          { title: "Empresa", width: "30%" },
          { title: "Usuário associado", width: "30%" },
          { title: "Cartão", width: "20%" },
          { title: "Saldo", width: "10%" },
          { title: "Status", width: "10%" },
        ]}
        list={this.state.list.map((e) => [
          e.company,
          e.user_name,
          parserSwapCardNumber(e.card_number, e.card_type),
          e.balance,
          renderCardStatus(
            e.status,
            e.card_integrated_status,
            e.color_status,
            e.activation_error
          ),
        ])}
        listDetail={this.state.list.map((e) => parserSwapCardObject(e))}
        listColor={this.state.list.map((e) => "left-border " + e.card_type)}
      />
    );
  }

  render_issue_cards() {
    return (
      <ListWithModalDetail
        modalTittle={"Envio de Cartões"}
        header={[
          { title: "Empresa", width: "30%" },
          { title: "Número de Cartões", width: "20%" },
          { title: "Data de Solicitação", width: "20%" },
          { title: "Código de Rastreio", width: "20%" },
          { title: "Status", width: "10%" },
        ]}
        list={this.state.list.map((e) => [
          e.name,
          e.quantity,
          e.created_at,
          e.tracking_code,
          e.status,
        ])}
        listDetail={this.state.list.map((e) => parserIssueCardsObject(e))}
      />
    );
  }

  render_activities() {
    return (
      <ListWithModalDetail
        modalTittle={"Transações"}
        header={[
          { title: "Fornecedor", width: "10%" },
          { title: "Empresa", width: "30%" },
          { title: "Cartão", width: "15%" },
          { title: "Valor", width: "10%" },
          { title: "Data", width: "10%" },
          { title: "Status", width: "10%" },
          { title: "Tipo", width: "25%" },
        ]}
        list={this.state.list.map((e) => [
          e.baas_provider,
          e.company_name,
          e.card_number,
          e.cost,
          e.date_performed_at,
          renderActivityStatus(e.status_color, e.status_name),
          e.activity_type,
        ])}
        listDetail={this.state.list.map((e) => parserActivityObject(e))}
        modalActionList={this.state.list.map((e) => e.syncer)}
        modalAction={(index) => this.handleSyncActivity(index)}
        modalButtonLabel={"Re-sincronizar"}
        listColor={this.state.list.map(
          (e) => "left-border " + e.baas_provider.toLowerCase()
        )}
      />
    );
  }

  render_swap_accounts() {
    return (
      <ListWithModalDetail
        modalTittle="Contas"
        header={[
          { title: "Empresa", width: "45%" },
          { title: "Data de solicitação de abertura", width: "20%" },
          { title: "Saldo total", width: "20%" },
          { title: "Status", width: "15%" },
        ]}
        list={this.state.list.map((e) => [
          e.company_name,
          parserDate(e.created_at),
          parserCurrencyBRLValue(e.total_balance),
          e.kyc_status,
        ])}
        sectionsListDetail={this.state.list.map((e) =>
          parserSwapAccountObjectList(e)
        )}
        sectionsListTittles={this.state.list.map((e) =>
          parserSectionTittles(e)
        )}
        modalButtonLabel={"Editar"}
        modalActionList={this.state.list.map(
          (e) => e.kyc_status !== "approved"
        )}
        modalAction={(index) => this.handleEditAccount(index)}
      />
    );
  }

  render_pma() {
    return (
      <>
        <ListWithModalDetail
          modalTittle="Extrato PMA"
          header={[
            { title: "Empresa", width: "35%" },
            { title: "Valor", width: "15%" },
            { title: "Data", width: "15%" },
            { title: "Hora", width: "10%" },
            { title: "Tipo", width: "15%" },
            { title: "Status", width: "30%" },
          ]}
          list={this.state.list.map((e) => [
            e.company_name,
            e.cost,
            e.date_performed_at,
            e.time_performed_at,
            e.operation_type,
            renderStatementItemStatus(e.status_name),
          ])}
          listDetail={this.state.list.map((e) => parserPmaObject(e))}
          modalButtonLabel={"Tentar Novamente"}
          modalActionList={this.state.list.map((e) => !e.completed)}
          modalAction={(index) => this.handlePmaRetry(index)}
        />
      </>
    );
  }

  handleTab(tab, loading = true) {
    this.setState({
      list: [],
      select: tab,
      forcePageOne: !this.state.forcePageOne,
      loading: loading,
    });
  }

  handleEditAccount(index) {
    window.location.assign(
      this.props.edit_legal_infos_endpoint.replace(
        "company_id",
        this.state.list[index].company_id
      )
    );
  }

  handlePmaRetry(index) {
    let payment_id = this.state.list[index].payment_id;

    PmaRetryService.retryPayment(payment_id)
      .then((data) => {
        showSnackbar({
          content:
            "A retentativa foi realizada com sucesso! Por favor, aguarde enquanto processamos sua solicitação.",
          style: "success",
          timeout: 3000,
        });
      })
      .catch((error) => {
        showSnackbar({
          content: "Houve uma falha ao retentar. Tente novamente mais tarde.",
          style: "error",
          timeout: 3000,
        });
      });
  }

  handleSyncActivity(index) {
    let activity_id = this.state.list[index].id;

    $.ajax({
      type: "POST",
      url: sync_bistro_accounts_activity_path(activity_id),
    })
      .then((data) => {
        showSnackbar({
          content: "Transação sincronizada",
          style: "success",
          timeout: 3000,
        });
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possivel sincronizar a transação",
          style: "error",
          timeout: 3000,
        });
      });
  }
}

ManageAccount.defaultProps = {
  swap_cards_endpoint: "",
  swap_accounts_endpoint: "",
  activities_endpoint: "",
  issue_cards_endpoint: "",
  pma_endpoint: "",
  edit_legal_infos_endpoint: "",
};

export default ManageAccount;
