import {Button, Card, CardActions, CardContent, CardMedia, Chip, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import {edit_bistro_lightbox_path} from "../../../../routes";
import React from "react";

const rolesLabel = new Map([['User', 'Colaboradores'], ['Financial', 'Financeiro'], ['Admin', 'Admin']]);

const LightboxItem = ({lightbox, onPreview, onDelete}) => {
    const lightboxThumb = () => {
        let steps = [...lightbox.steps];
        steps.sort((a, b) => a.order - b.order);
        return steps[0].imageUrl
    }

    return <Card>
        <CardMedia component='img' src={lightboxThumb()} height={200}/>
        <CardContent>
            <Stack direction={'row'} spacing={1}>
                {lightbox.roles.map((role, index) => (
                    <Chip key={index} size={'small'} label={rolesLabel.get(role)} variant={'filled'}/>
                ))}
            </Stack>
            <Typography gutterBottom variant="h6" component="div">
                {lightbox.identifier}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                Expira em {dayjs(lightbox.expiredAt).format('DD/MM/YYYY')}
            </Typography>
        </CardContent>
        <CardActions disableSpacing>
            <Button size="small" href={edit_bistro_lightbox_path(lightbox.id)}>Editar</Button>
            <Button size="small" onClick={onPreview}>Visualizar</Button>
            <Button size="small" sx={{marginLeft: 'auto'}} onClick={onDelete}>Excluir</Button>
        </CardActions>
    </Card>
}

export default LightboxItem;