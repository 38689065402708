import React from "react";

class FilterVisibilityTrigger extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col s12">
          <div className="right-align">
            <a
              test-name="toggle-filter"
              className="btn-flat"
              onClick={this.props.onClick}
            >
              <i className="mdi mdi-filter-variant left" />
              FILTRAR VISUALIZAÇÃO
            </a>
          </div>
        </div>
      </div>
    );
  }
}

FilterVisibilityTrigger.defaultProps = {
  onClick: Function
};

export default FilterVisibilityTrigger;
