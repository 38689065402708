import EspressoLogo from "../../../../assets/images/logo_espresso_appbar.svg";
import * as React from "react";
import PlatformUpdatesMenu from "./platform_updates/PlatformUpdatesMenu";
import ProfileMenu from "./profile_menu/ProfileMenu";
import GeneralSearchField from "./general_search/GeneralSearchField";
import UpgradeButton from "./upgrade_button/UpgradeButton";
import MenuIcon from "@mui/icons-material/Menu";
import {AppBar, Box, IconButton, Toolbar, useMediaQuery} from "@mui/material";
import { admin_home_path } from "../../../routes";
import TwoFactorButton from "./two_factor_button/TwoFactorButton";

const espressoLogo = (
    <EspressoLogo fill={'#fff'} style={{ height: 28 }} />
);

const EspressoAppbar = (props) => {
    const {
        copilotMode,
        profileProps,
        lastSeenUpdate,
        onClickMenu,
        isAdmin,
        planLevel,
        isFinancial,
        twoFactorEnabled
    } = props;

    const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const smallScreen = useMediaQuery((theme) => theme.breakpoints.up('sm'));
    const adminOrFinancial = (isAdmin || isFinancial) && !copilotMode;

    return <AppBar
            color={"surfaceLight12"}
            elevation={2}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                displayPrint: 'none'
            }}>
        <Toolbar>
            <IconButton data-testid={'appbar-menu'} color="inherit" edge="start" sx={{mr: 2}} onClick={onClickMenu}>
                <MenuIcon/>
            </IconButton>

            {adminOrFinancial ? (
                <a href={admin_home_path()} data-testid="logo-with-link">{espressoLogo}</a>
            ) : (
                espressoLogo
            )}

            <UpgradeButton smallScreen={smallScreen} planLevel={planLevel} isShowButton={(isFinancial || isAdmin)} />
            <Box sx={{flexGrow: 1}}/>
            {largeScreen && isAdmin && !copilotMode && <GeneralSearchField/>}
            <PlatformUpdatesMenu lastSeenAt={lastSeenUpdate}/>
            {!twoFactorEnabled && !copilotMode && <TwoFactorButton employeeId={profileProps.user.employeeId}/>}
            <ProfileMenu copilotMode={copilotMode} {...profileProps}/>
        </Toolbar>
    </AppBar>
}

export default EspressoAppbar;