import React from "react";
import { AppBar, Box, Toolbar } from "@mui/material";
import EspressoLogo from "../../../../assets/images/logo_espresso_appbar.svg";
import BlockedAppBarMenu from "./BlockedAppBarMenu";

const BlockedAppBar = () => {
  return <AppBar
    color={'surfaceLight12'}
    elevation={2}
    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, displayPrint: 'none' }}
    data-testid={'blocked-appbar'}
  >
    <Toolbar>
      <EspressoLogo fill={'#fff'} style={{ height: 28 }}/>
      <Box flexGrow={1}/>
      <BlockedAppBarMenu/>
    </Toolbar>
  </AppBar>
}

export default BlockedAppBar;