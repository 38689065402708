import { Button, CardHeader, Dialog, DialogActions, DialogContent, Divider,
         LinearProgress, ListItemButton, ListItemText, Pagination, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { budgetableTypeName, fetchBudgetablesBy } from "./budgets_service";
import BudgetableField from "./BudgetableField";

const SelectBudgetableModal = ({ open, onSelect, onClose, type }) => {
  const [pagesCount, setPagesCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [name, setName] = useState('');

  const Triggers = {
    page: 'page',
    searchName: 'searchName'
  }

  const initialTriggers = {
    [Triggers.page]: 1,
    [Triggers.searchName]: '',
  }

  const [triggers, setTriggers] = useState(initialTriggers);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setTriggers({
        ...triggers,
        [Triggers.page]: 1,
        [Triggers.searchName]: event.target.value,
      });
    }
  }

  const handleName = (event) => {
    setName(event.target.value);
  }

  const handlePageChange = (_, value) => {
    setTriggers({
      ...triggers,
      [Triggers.page]: value,
    });
  }

  const fetchBudgetables = async () => {
    setLoading(true);
    const body = await fetchBudgetablesBy(triggers.page, triggers.searchName, type);
    if (body != null) {
      setData(body.data);
      setPagesCount(body.pages);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchBudgetables();
  }, [triggers]);

  return <>
    <Dialog
      open={open}
      maxWidth={"md"}
      fullWidth={true}
    >
      <CardHeader
        title={`Orçamento por ${budgetableTypeName(type)}`}
        titleTypographyProps={{ sx: { mb: 2 } }}
        subheader={"Escolha o projeto que deve ser vinculado a esse orçamento (os membros do projeto serão automaticamente vinculados ao orçamento)"}
      />
      <DialogContent>
        <BudgetableField
          sx={{ mb: 4 }}
          value={name}
          onChange={handleName}
          disabled={loading}
          onKeyDown={handleKeyDown}
          placeholder={`Busque pelo ${budgetableTypeName(type)}`}
        />
        {
          data.map((budgetable) => (
            <Stack key={budgetable.id}>
              <ListItemButton
                selected={selected?.id == budgetable.id}
                onClick={() => setSelected(budgetable)}
              >
                <ListItemText primary={budgetable.name}/>
              </ListItemButton>
              <Divider/>
            </Stack>
          ))
        }
        {
          pagesCount > 0 &&
          <Stack
            alignItems={"end"}
            sx={{ mt: 2 }}
          >
            <Pagination
              shape="rounded"
              color="primary"
              count={pagesCount}
              page={triggers.page}
              onChange={handlePageChange}
              data-testid={"pagination"}
            />
          </Stack>
        }
        { loading && <LinearProgress sx={{ mt: 4 }}/> }
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={onClose}
          data-testid={"cancel-button"}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => onSelect(selected)}
          disabled={!selected}
          data-testid={"select-button"}
        >
          Selecionar
        </Button>
      </DialogActions>
    </Dialog>
  </>
}

export default SelectBudgetableModal;