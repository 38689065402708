import React from "react";
import FeatureGroup from "../FeatureGroup";
import { showSnackbar } from "../../../shared/jquery_wrapper";

class SecurityFeatureGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onUpdate = (feature, activate, error) => {     
    if (error) {
      showSnackbar({
        content: "Não foi possível ativar a funcionalidade.",
        style: "alert",
        timeout: 5000,
      });
    } else {
      let state = activate == true ? 'ativada' : 'desativada';
      showSnackbar({
        content: `Funcionalidade ${state}!`,
        style: "alert",
        timeout: 3000,
      });
    };
  };

  render() {
    return (
      <FeatureGroup
        groupName={this.props.groupName}
        featuresEndpoint={this.props.featuresEndpoint}
        featureGroup={this.props.featureGroup}
        ref={this.group}
        onUpdate={(feature, activate, error) => this.onUpdate(feature, activate, error)}
        ignoreAvailability={this.props.ignoreAvailability}
        hidePlanWhenActive={this.props.hidePlanWhenActive}
        styleCard={this.props.styleCard}
      />
    );
  }
}

SecurityFeatureGroup.defaultProps = {
  featuresEndpoint: "",
  featureGroup: 4,
  ignoreAvailability: false,
  hidePlanWhenActive: true,
};

export default SecurityFeatureGroup;