import React from "react";

import classNames from "classnames";

class Stage extends React.Component {
  render() {
    return (
      <div className="d-flex justify-between align-center stage">
        {this.props.list.map((item, idx, arr) => (
          <React.Fragment key={idx}>
            {idx !== 0 && (
              <div
                className="divisor"
                style={{
                  width: `${(100 / (arr.length * 2 - 1)) * 0.6}%`,
                }}
              />
            )}
            <div className="d-flex align-center">
              {arr[idx + 1]?.active ? (
                item.selectStage && this.props.edit ? (
                  <i className="mdi mdi-pencil check-icon" />
                ) : (
                  <i className="mdi mdi-checkbox-marked-circle check-icon" />
                )
              ) : (
                <div
                  className={classNames(
                    "stage-icon",
                    "d-flex",
                    "justify-center",
                    "align-center",
                    { active: item?.active }
                  )}
                >
                  <div>{idx + 1}</div>
                </div>
              )}
              <div
                className={classNames("stage-description", {
                  inative: !item?.active && !arr[idx + 1]?.active,
                })}
              >
                {item.label}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

Stage.defaultProps = {
  list: [],
  edit: false,
};

export default Stage;
