import React, { useEffect, useState } from "react";
import classNames from "classnames";

import Card from "./Card";
import Loading from "../../shared/Loading";
import { showSnackbar } from "../../shared/jquery_wrapper";

const CardSensitiveData = ({
  mockCard,
  showCard,
  virtual,
  endpoint,
  virtual_card_icon,
  physical_card_icon,
  mastercard_logo,
  espresso_card_logo,
  external_show,
  sub_account_id,
  card_id,
}) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [mockSensitiveData, setMockSensitiveData] = useState({
    number: "",
    expireDate: "",
    cvv: "",
  });

  useEffect(() => {
    setShow(showCard);
  }, [showCard]);

  const handleShow = (show) => {
    setLoading(true);
    external_show(show);
    setShow(show);

    if (show) {
      if (mockCard) {
        setMockSensitiveData({
          number: "1111 2222 3333 4444",
          expireDate: "07/2050",
          cvv: "123",
        });
        setLoading(false);
      } else {
        $.ajax({
          type: "GET",
          dataType: "json",
          url: endpoint
            .replace("sub_account_id", sub_account_id)
            .replace("id", card_id),
        }).then((response) => {
          swap({ token: response.token });

          setLoading(false);
        });
      }
    }
  };

  const handleCopy = () => {
    const element = document.getElementById("card-number");
    const value = element?.firstChild.nodeValue;

    navigator.clipboard?.writeText(value);

    showSnackbar({ content: "Número do cartão copiado!", style: "notice" });
  };

  return (
    <React.Fragment>
      <div
        className={classNames("d-flex align-center card-sensitive-data", {
          hide: show,
        })}
      >
        <img
          className="card-icon"
          src={virtual ? virtual_card_icon : physical_card_icon}
        />
        {virtual && (
          <button
            test-name="show-card-info"
            className="d-flex align-center pointer infos-handle-button"
            onClick={() => handleShow(true)}
          >
            <p>Visualizar informações</p>
            <i className="mdi mdi-eye-off icon-purple icon-background d-flex align-center justify-center" />
          </button>
        )}
      </div>
      <div
        test-name="show-card-container"
        className={classNames(
          "d-flex align-center justify-between card-sensitive-data show-infos",
          {
            hide: !show,
          }
        )}
      >
        <Card
          mastercard_logo={mastercard_logo}
          espresso_card_logo={espresso_card_logo}
        />

        {loading && (
          <div className="loading">
            <h5>Carregando os dados do cartão</h5>
            <Loading show={loading} />
          </div>
        )}
        <div
          id="card-data"
          className={classNames(
            "d-flex align-center justify-between flex-wrap card-data",
            {
              hide: loading,
            }
          )}
        >
          <div className="sensitive-field">
            <label className="black-high">Número do cartão</label>
            <div className="d-flex">
              <p className="black-high sensitive-info" id="card-number">
                {mockSensitiveData.number}
              </p>
              <i
                test-name="copy-button"
                className="mdi mdi-content-copy copy-icon pointer"
                onClick={() => handleCopy()}
              />
            </div>
          </div>
          <div className="sensitive-field">
            <label className="black-high">Validade</label>
            <p className="black-high sensitive-info" id="card-expiry-date">
              {mockSensitiveData.expireDate}
            </p>
          </div>
          <div className="sensitive-field cvv">
            <label className="black-high">CVC</label>
            <p className="black-high sensitive-info" id="card-cvv">
              {mockSensitiveData.cvv}
            </p>
          </div>
          <div className="sensitive-field">
            <label className="black-high">Bandeira</label>
            <p className="black-high sensitive-info">Mastercard</p>
          </div>
        </div>

        <button
          test-name="hide-card-info"
          className="d-flex align-center pointer infos-handle-button hide-infos"
          onClick={() => handleShow(false)}
        >
          <p>Ocultar informações</p>
          <i className="mdi mdi-eye icon-purple icon-background d-flex align-center justify-center" />
        </button>
      </div>
    </React.Fragment>
  );
};

export default CardSensitiveData;
