import {
    parserCNPJ,
    parserPhoneWithoutCountryCode,
} from "../../../shared/helpers";

function createCardField(label, description) {
    return { label, description };
}

function parsePhoneNumber(phoneNumber) {
    return parserPhoneWithoutCountryCode(phoneNumber);
}

export function parserIssueCardsObject(requestCard) {
    return [
        createCardField('Razão Social', requestCard.name),
        createCardField('CNPJ', parserCNPJ(requestCard.cnpj)),
        createCardField('Rua', requestCard.street),
        createCardField('Complemento', requestCard.complement),
        createCardField('Bairro', requestCard.neighborhood),
        createCardField('Estado', requestCard.state),
        createCardField('Email do Responsável', requestCard.email),
        createCardField('Telefone do Responsável', parsePhoneNumber(requestCard.phone_number)),
    ];
}
