import React, {useCallback, useState} from "react";

import Dialog from "@mui/material/Dialog";
import {Alert, Button, CircularProgress} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CheckIcon from "@mui/icons-material/Check";

export const AsyncConfirmationDialog = (props) => {
    const {
        open,
        onClose,
        confirmAction,
        afterConfirm,
        title,
        content,
        confirmLabel,
        disableWith,
        confirmWith,
        destructive = false,
    } = props;

    const [confirming, setConfirming] = useState(false);
    const [succeed, setSucceed] = useState(false);
    const [error, setError] = useState('');

    const onConfirm = useCallback(async () => {
        setConfirming(true);
        const error = await confirmAction();
        setConfirming(false);
        setError(error);
        setSucceed(!error);
        afterConfirm();
    }, [confirmAction]);

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {content}
            {error && (
                <Alert sx={{mt: 2}} severity={'error'}>{error}</Alert>
            )}
        </DialogContent>
        <DialogActions>
            <Button
                sx={{order: destructive ? 1 : 0}}
                disabled={confirming}
                color={'disabled'}
                onClick={onClose}>
                Fechar
            </Button>
            <Button
                data-testid={'dialog-confirm-button'}
                sx={{order: destructive ? 0 : 1}}
                disabled={confirming}
                onClick={!succeed && onConfirm}
                startIcon={confirming ? (
                    <CircularProgress size={24} color={'disabled'}/>
                ) : succeed ? (
                    <CheckIcon/>
                ) : null}>
                {confirming ? (
                    disableWith
                ) : succeed ? (
                    confirmWith
                ) : (
                    confirmLabel
                )}
            </Button>
        </DialogActions>
    </Dialog>
}