import React from "react";
import FaqDrawer from "../../../shared/faq/FaqDrawer";

const data = [
  {
    question: "O que é o Duplo Fator de Autenticação?",
    answer: "O Duplo Fator de Autenticação, ou 2FA, é uma camada extra de segurança para garantir um acesso seguro à sua conta Espresso. Além de digitar a sua senha, você precisa fornecer uma segunda verificação ao realizar o login, que pode ser um código enviado para o seu e-mail ou um código fornecido por um aplicativo de autenticação."
  },
  {
    question: "Quais são os benefícios do Duplo Fator de Autenticação?",
    answer: "O Duplo Fator de Autenticação (2FA) oferece vários benefícios importantes, entre eles: maior segurança de acesso e utilização da plataforma, reduz o  risco de fraudes e protege seus dados de acesso de terceiros."
  },
  {
    question: "Como funciona o Duplo Fator de Autenticação?",
    answer: "O Duplo Fator de Autenticação (2FA) funciona combinando dois métodos de verificação para garantir que seja realmente você quem está tentando acessar a conta:\nPrimeiro fator: Você insere sua senha normalmente.\nSegundo fator: Um código único é enviado ao seu e-mail, ou gerado por um aplicativo autenticador. Você precisa inserir esse código para acessar sua conta.\nIsso adiciona uma camada extra de proteção, pois mesmo que alguém descubra sua senha, ainda precisará do segundo fator para entrar."
  },
  {
    question: "O que são aplicativos autenticadores?",
    answer: "Aplicativos autenticadores são aplicativos que geram códigos temporários para autenticação de dois fatores (2FA). Esses códigos são usados como o segundo passo para verificar sua identidade ao acessar uma conta. Exemplos populares incluem Google Authenticator, Authy e Microsoft Authenticator.\nApós vincular o aplicativo à sua conta, ele irá gerar códigos de 6 dígitos que mudam a cada 30 segundos. Você usará esses códigos junto com sua senha para uma segurança extra."
  },
  {
    question: "Como ativar a autenticação de dois fatores?",
    answer: "Vá às configurações de perfil do seu aplicativo Espresso, clique em Senhas e segurança e selecione Duplo Fator de Autenticação.\nEm seguida, você poderá escolher se prefere utilizar um aplicativo autenticador para geração de token ou se prefere receber o token via e-mail cadastrado na plataforma."
  },
  {
    question: "\"Código inválido\": o que devo fazer?",
    answer: "Se você usa Aplicativo Autenticador:\nSe você informou o código correto gerado pelo aplicativo autenticador, mas a plataforma retornou a mensagem de \"Código inválido\", tente as seguintes ações:\nVerifique a data e hora do seu celular:\nCertifique-se de que a data e hora do seu celular estão configuradas para atualização automática. Isso é crucial para que o aplicativo de autenticação funcione corretamente e o código gerado seja validado.\nAtualize o aplicativo de autenticação:\nSe os códigos no aplicativo não forem atualizados automaticamente, feche o aplicativo e abra-o novamente. Isso ajuda a carregar as novas informações e a gerar um código válido.\nSe você usa o e-mail como segundo fator de autenticação:\nVerifique se o e-mail cadastrado na plataforma é válido. Se você não recebeu o código, confira as pastas de spam ou lixo eletrônico. Se ainda assim não localizar, acione o administrador da sua empresa."
  },
  {
    question: "Não tenho mais acesso ao código de autenticação. O que devo fazer?",
    answer: "Entre em contato com o suporte ao cliente através do email suporte@espressoapp.com.br para recuperar o acesso à sua conta. Você pode precisar responder a perguntas de segurança ou fornecer documentos para validação da sua identidade."
  },
  {
    question: "Consigo acessar a conta quando não estiver com acesso ao aplicativo autenticador?",
    answer: "Sim, desde que ao realizar a configuração do Duplo Fator de Autenticação (2FA) você tenha salvo em local seguro os códigos de backup.\nA plataforma disponibiliza alguns códigos de backup, que devem ser mantidos em um local seguro. Utilize um deles para fazer o seu login normalmente."
  }
]

const TwoFactorFaqDrawer = ({ open, onClose }) => {
  return <FaqDrawer
    open={open}
    onClose={onClose}
    data={data}
    subtitle={"Entenda melhor o Duplo Fator de Autenticação."}
  />
}

export default TwoFactorFaqDrawer;