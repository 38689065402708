import * as request from "@rails/request.js";
import {
    activate_admin_erp_integration_path,
    admin_erp_integration_path,
    admin_erp_integrations_path,
    deactivate_admin_erp_integration_path
} from "../../../routes";

export const updateConnection = async (erp, credentials) => {
    const response = await request.post(admin_erp_integrations_path(), {
        body: JSON.stringify({erp, credentials})
    })
    return response.ok
}

export const getConnection = async (erp) => {
    const response = await request.get(
        admin_erp_integration_path(erp),
        {responseKind: 'json'}
    )
    return response.json
}

export const deactivateConnection = async (erp) => {
    const response = await request.post(deactivate_admin_erp_integration_path(erp))
    return response.ok
}

export const activateConnection = async (erp) => {
    const response = await request.post(activate_admin_erp_integration_path(erp))
    return response.ok
}