import * as React from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import dayjs from "dayjs";
import {
    Badge,
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    IconButton,
    ListItem,
    ListItemText,
    Popover,
    Typography
} from "@mui/material";
import {getPlatformUpdates, updatePlatformUpdates} from "./platformUpdatesDataSource";

export default function PlatformUpdatesMenu({lastSeenAt}) {
    const [updates, setUpdates] = React.useState([]);
    const [lastSeen, setLastSeen] = React.useState(lastSeenAt ? dayjs(lastSeenAt * 1000) : null);
    const [updatesTarget, setUpdatesTarget] = React.useState(null);
    const open = Boolean(updatesTarget);
    const newUpdates = React.useMemo(
        () => {
            return updates.filter((update) => lastSeen == null || dayjs(update.createdAt).isAfter(lastSeen))
        },
        [updates, lastSeen]
    )

    const handleUpdatesOpen = (event) => {
        updatePlatformUpdates();
        setLastSeen(dayjs())
        setUpdatesTarget(event.currentTarget);
    };

    const handleCloseUpdatesMenu = () => {
        setUpdatesTarget(null);
    };

    React.useEffect(() => {
        getPlatformUpdates().then((data) => setUpdates(data))
    }, []);

    return <Box sx={{flexGrow: 0}}>
        <IconButton sx={{mx: 2}} color={"inherit"} onClick={handleUpdatesOpen}>
            <Badge
                color={"pink"}
                badgeContent={newUpdates.length}>
                {newUpdates.length > 0 ? <NotificationsIcon/> : <NotificationsNoneIcon/>}
            </Badge>
        </IconButton>
        <Popover
            anchorEl={updatesTarget}
            open={open}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            onClose={handleCloseUpdatesMenu}>
            {updates.length === 0 && (
                <ListItem><ListItemText>Não há novas notificações</ListItemText></ListItem>
            )}
            {updates.map((update, idx) => (
                <Card key={idx} sx={{maxWidth: 360}}>
                    <CardActionArea onClick={handleCloseUpdatesMenu} href={update.redirectUrl}>
                        {update.bannerUrl && (
                            <CardMedia component="img" image={update.bannerUrl}/>
                        )}
                        <CardContent>
                            <Typography variant={"caption"}>
                                {dayjs(update.createdAt).format('DD/MM/YYYY')}
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div">
                                {update.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {update.body}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </Popover>
    </Box>
}