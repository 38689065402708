import React, { useState } from 'react';
import EspressoTemplate from "../../EspressoTemplate";
import Planned from "./Planned";
import Immediate from "./Immediate";
import History from "./History";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { ShadowTheme } from "../../ShadowTemplate";

const PasswordExpiration = (props) => {
  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <Container sx={{ mt: 4, mb: 4, marginTop: '80px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Planned {...props} />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Immediate {...props} />
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Container sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <History {...props} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </ShadowTheme>
  );
};

export default PasswordExpiration;