import {Alert, AlertTitle, Button} from "@mui/material";
import React from "react";

export const OmieUserConfigSteps = ({webhooksUrl}) => {
    return <>
        <p><b>Passo 1: </b>Acessar o link de configuração</p>
        <ul>
            <li>Link: <a href="https://developer.omie.com.br/my-apps/"
                         target="_blank">https://developer.omie.com.br/my-apps/</a></li>
        </ul>
        <p><b>Passo 2: </b>Clique na conta que deseja configurar e adicione um novo Webhook</p>
        <ul>
            <li>Selecione a conta que deseja configurar o aplicativo.</li>
            <li>
                Ao selecionar a conta da empresa você precisar clicar em “Adicionar Novo Webhook”.
            </li>
        </ul>
        <p><b>Passo 3: </b>Insira o link no campo “Endpoint”</p>
        <ul>
            <li>Link: <a
                href={webhooksUrl}
                target="_blank">{webhooksUrl}</a>
            </li>
        </ul>
        <p><b>Passo 4: </b>Selecionar os campos abaixo referentes à integração</p>
        <ul>
            <li>Cliente Fornecedor Alterado</li>
            <li>Cliente Fornecedor Incluído</li>
            <li>Cliente Fornecedor Excluído</li>
        </ul>
        <p><b>Passo 5: </b>Salvar configuração</p>
        <ul>
            <li>
                Após inserir o link de “Endpoint” e selecionar os campos referentes á integração,
                clique em “Salvar”.
            </li>
        </ul>
        <Alert sx={{mt: 2}} severity="info">
            <AlertTitle>
                Depois de salvo, é preciso retornar para a tela inicial.
            </AlertTitle>
            Assim que clicar na conta da empresa novamente, o Webhook configurado estará
            visível.
        </Alert>
        <p>
            <b>Passo 6: </b>
            Cadastrar e inserir tag do Espresso dentro do ERP, se você não tem nenhuma tag
            cadastrada veja o passo a passo completo clicando abaixo.
        </p>
        <Alert
            severity="warning"
            action={
                <Button
                    size="small"
                    variant={'outlined'}
                    href={'https://ajuda.omie.com.br/pt-BR/articles/6858986-cadastrando-uma-tag-de-clientes-e-fornecedores'}
                    target={'_blank'}
                    color={'inherit'}>
                    CADASTRAR TAG
                </Button>
            }>
            <AlertTitle>É necessário cadastrar e inserir uma tag no ERP</AlertTitle>
            Clique aqui para acessar o guia com o passo a passo.
        </Alert>
    </>
}