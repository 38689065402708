import React, { useState } from "react";

import { showSnackbar } from "../../../shared/jquery_wrapper";
import {
  parserCurrencyBRLValue,
  parserValueWithBrlCurrency,
} from "../../../shared/helpers";

import {
  Box,
  Button,
  CardActions,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";

import { admin_account_banking_apis_balance_index_path } from "../../../../routes";

import EditIcon from "@mui/icons-material/Edit";

const BalanceAdjust = ({ accountBalance }) => {
  const [openBalance, setOpenBalance] = useState(false);
  const [balanceValue, setBalanceValue] = useState("");

  const handleChangeBalance = () => {
    const value = Number(
      balanceValue.replace("R$", "").replace(/\./gm, "").replace(",", ".")
    );

    $.ajax({
      type: "POST",
      url: admin_account_banking_apis_balance_index_path(),
      data: { balance: value },
    }).then((r) => {
      handleClose();
      showSnackbar({
        content: "O saldo será transferido automaticamente em breve",
        style: "notice",
      });
    });
  };

  const handleClose = () => setOpenBalance(false);

  return (
    <Box>
      <Button
        variant="text"
        color="primary"
        size="medium"
        sx={{ color: "rgba(0, 0, 0, 0.6)" }}
        onClick={() => setOpenBalance(true)}
      >
        <EditIcon sx={{ marginRight: "10px" }} />
        definir saldo
      </Button>
      {/* modal */}
      <Dialog open={openBalance} onClose={handleClose} maxWidth={"lg"}>
        <DialogContent style={{ width: "964px" }}>
          <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>
            Ajustar saldo
          </Typography>
          <Typography sx={{ fontSize: "18px", marginBottom: "10px" }}>
            Confirme o valor do novo saldo
          </Typography>
          <TextField
            label={"Novo saldo alocado"}
            placeholder="R$ 0,00"
            sx={{ width: "620px" }}
            value={balanceValue}
            onChange={(e) =>
              setBalanceValue(parserValueWithBrlCurrency(e.target.value))
            }
          />
          <Box sx={{ display: "flex", marginTop: "10px" }}>
            <Typography>Saldo disponível na conta digital:</Typography>
            <Typography sx={{ color: "#00CBA8" }}>
              {parserCurrencyBRLValue(accountBalance)}
            </Typography>
          </Box>
        </DialogContent>
        <CardActions sx={{ justifyContent: "flex-end", padding: "20px" }}>
          <Button
            sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}
            onClick={handleClose}
          >
            fechar
          </Button>
          <Button onClick={handleChangeBalance}>salvar</Button>
        </CardActions>
      </Dialog>
    </Box>
  );
};

export default BalanceAdjust;
