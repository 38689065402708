import React, { useEffect, useState } from "react";

import { showSnackbar } from "../../../shared/jquery_wrapper";
import { parserCurrencyBRLValue, parserDate } from "../../../shared/helpers";

import { admin_account_banking_apis_card_path } from "../../../../routes";

import {
  Box,
  Dialog,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import espresso_card_logo from "../../../../../assets/images/admin/account/espresso_card_logo.png";
import mastercard_logo from "../../../../../assets/images/admin/account/mastercard_logo.png";
import virtual_card_icon from "../../../../../assets/images/admin/account/virtual_card_icon.png";

const CardDetail = ({
  openCardDetail,
  setOpenCardDetail,
  cardDetailSelected,
}) => {
  const [showCard, setShowCard] = useState(false);
  const [loadingSensitiveInfos, setLoadingSensitiveInfos] = useState(false);
  const [sensitiveInfos, setSensitiveInfos] = useState({
    number: "",
    expiry: "",
    cvc: "",
  });

  useEffect(() => {
    if (showCard) {
      getSensitiveInfos();
    }
  }, [showCard]);

  useEffect(() => {
    if (!openCardDetail) {
      setShowCard(false);
    }
  }, [openCardDetail]);

  const getSensitiveInfos = () => {
    setLoadingSensitiveInfos(true);

    $.ajax({
      type: "GET",
      url: admin_account_banking_apis_card_path(cardDetailSelected.id),
    })
      .then((r) => {
        setSensitiveInfos({ number: r.pan, expiry: r.expiry, cvc: r.cvv });
        setLoadingSensitiveInfos(false);
      })
      .catch((e) => {
        showSnackbar({
          content: "Não foi possivel buscar dados do cartão!",
          style: "alert",
        });
      });
  };

  const handleCopy = () => {
    navigator.clipboard?.writeText(sensitiveInfos.number);

    showSnackbar({ content: "Número do cartão copiado!", style: "notice" });
  };

  const parserStatus = (status, formated = true) => {
    switch (status) {
      case "pending":
        return "Pendente";
      case "active":
        return "Ativo";
      case "blocked":
        return "Bloqueado";
      case "canceled":
        return "Cancelado";
      default:
        return status;
    }
  };

  const handleOpenCardDetail = () => setOpenCardDetail(false);

  return (
    <Dialog
      open={openCardDetail}
      onClose={handleOpenCardDetail}
      maxWidth={"lg"}
    >
      <DialogContent style={{ width: "964px", padding: 0 }}>
        <Box
          id="header"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            borderBottom: "solid 1px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Typography sx={{ fontSize: "20px" }}>
            {cardDetailSelected.holder}
          </Typography>
          <CloseIcon
            sx={{ cursor: "pointer" }}
            data-testid="close-icon"
            onClick={handleOpenCardDetail}
          />
        </Box>

        <Box id="content" sx={{ padding: "20px" }}>
          <Box
            id="general-information"
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <Typography sx={{ fontSize: "14px" }}>
                Limite disponível no cartão
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {parserCurrencyBRLValue(cardDetailSelected.limit)}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>Identificador</Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {cardDetailSelected.custom_id}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>Data início</Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {parserDate(cardDetailSelected.start_date)}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>Data fim</Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {parserDate(cardDetailSelected.end_date)}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>MCC</Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {cardDetailSelected.allowed_mcc}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "14px" }}>Status</Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {parserStatus(cardDetailSelected.status)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ margin: "20px 0", display: "flex", alignItems: "center" }}>
            <Box sx={{ display: "flex" }}>
              {showCard ? (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {/* card */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      backgroundColor: "#CCB3AA",
                      backgroundImage:
                        "linear-gradient(90deg, #E1D5D1, #CCB3AA)",
                      padding: "16px",
                      height: "159px",
                      minWidth: "251px",
                      borderRadius: "12px",
                    }}
                  >
                    <Box>
                      <img src={espresso_card_logo} />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <img src={mastercard_logo} />
                    </Box>
                  </Box>
                  {loadingSensitiveInfos ? (
                    <LinearProgress
                      sx={{ width: "391px", marginLeft: "10px" }}
                    />
                  ) : (
                    <Box
                      sx={{
                        marginLeft: "10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "395px",
                      }}
                    >
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          Número do cartão
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography>{sensitiveInfos.number}</Typography>
                          <ContentCopyIcon
                            data-testid="copy-icon"
                            sx={{
                              fontSize: "16px",
                              marginLeft: "5px",
                              cursor: "pointer",
                            }}
                            onClick={handleCopy}
                          />
                        </Box>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          Validade
                        </Typography>
                        <Typography>{sensitiveInfos.expiry}</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>CVC</Typography>
                        <Typography>{sensitiveInfos.cvc}</Typography>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          Bandeira
                        </Typography>
                        <Typography>Mastercard</Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <img src={virtual_card_icon} />
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "1px #E4DBEC solid",
                borderRadius: "100px",
                padding: "8px 18px",
                marginLeft: "10px",
                cursor: "pointer",
              }}
              onClick={() => setShowCard(!showCard)}
            >
              <Typography sx={{ fontSize: "14px", marginRight: "10px" }}>
                {showCard ? "Ocultar" : "Visualizar"} informações
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  padding: "8px",
                  backgroundColor: "rgba(240, 235, 245, 1)",
                  borderRadius: "100px",
                  color: "#3D0079",
                }}
              >
                {showCard ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </Box>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CardDetail;
