import classNames from "classnames";
import React from "react";

class AvatarAndDetail extends React.Component {
  state = {};
  render() {
    return (
      <div className="col s12 avatar-and-detail">
        <div
          className={`${
            this.props.list.length > 0 && "collection"
          } z-depth-1 white`}
          style={{ padding: `${this.props.hasTableheader && "20px"}` }}
        >
          <table className="table">
            {this.props.hasTableheader && (
              <thead>
                <tr className="collection-item border-bottom">
                  {this.props.hasAvatar && <th></th>}
                  <th>{this.props.headers.first}</th>
                  {this.props.hasSubAccount && <th>Subconta</th>}
                  {this.props.hasName && <th>Nome do Cartão</th>}
                  {this.props.hasUser && <th>Usuário</th>}
                  {this.props.hasCard && <th>Cartão</th>}
                  <th>{this.props.headers.last}</th>
                  {this.props.hasReceipt && <th>Comprovante</th>}
                </tr>
              </thead>
            )}
            <tbody>
              {this.props.list.map((item, idx) => (
                <tr
                  test-name={`collection-item-${item?.id}`}
                  key={idx}
                  className="collection-item"
                  onClick={() =>
                    window.location.assign(
                      this.props.baseLinkToDetail + item?.id
                    )
                  }
                  style={{
                    cursor:
                      this.props.baseLinkToDetail !== "#"
                        ? "pointer"
                        : "default",
                  }}
                >
                  {this.props.hasAvatar && (
                    <td className="d-flex justify-center">
                      {item?.avatar ? (
                        <img
                          className="avatar d-flex justify-center align-center circle"
                          src={item.avatar}
                        />
                      ) : (
                        <i
                          style={{ color: `${item.color_icon || ""}` }}
                          className={`avatar d-flex justify-center align-center circle mdi ${
                            item?.icon || "mdi-credit-card-off-outline"
                          } `}
                        />
                      )}
                    </td>
                  )}
                  <td
                    style={{
                      width: `${this.widthDescription()}`,
                    }}
                  >
                    <span className={`${item?.canceled ? "cancel" : ""}`}>
                      {item?.title}
                    </span>
                    <div className="d-flex">
                      {item?.description.length > 0 && (
                        <p
                          className={`${
                            item?.canceled ? "cancel-through" : ""
                          }`}
                        >
                          {item?.description}
                        </p>
                      )}
                      {item.tag && (
                        <p
                          style={{ color: `${item?.color_tag || ""}` }}
                          className={`${
                            item?.b_color_tag || "grey lighten-1"
                          } tag`}
                        >
                          {item.tag}
                        </p>
                      )}
                    </div>
                  </td>
                  {this.props.hasSubAccount && (
                    <td style={{ width: "25%" }}>{item?.sub_account}</td>
                  )}
                  {this.props.hasName && (
                    <td style={{ width: "25%" }}>{item?.name}</td>
                  )}
                  {this.props.hasUser && (
                    <td style={{ width: "25%" }}>{item?.user_name}</td>
                  )}
                  {this.props.hasCard && (
                    <td style={{ width: "25%" }}>{item?.card}</td>
                  )}
                  <td
                    style={{ width: this.props.hasReceipt && "25%" }}
                    className={classNames({ cancel: item?.canceled })}
                  >
                    {item?.secondary}
                  </td>
                  {this.props.hasReceipt && (
                    <td className="d-flex justify-center receipt">
                      {item?.receipt && (
                        <a href={item.receipt} target="blank">
                          <i className="mdi mdi-download" />
                        </a>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  widthDescription() {
    if (this.props.hasName && this.props.hasCard && this.props.hasUser) {
      return "29%";
    } else if (
      (this.props.hasUser && this.props.hasSubAccount) ||
      (this.props.hasName && this.props.hasCard)
    ) {
      return "37%";
    } else if (
      (this.props.hasUser && !this.props.hasSubAccount) ||
      (!this.props.hasUser && this.props.hasSubAccount)
    ) {
      return "60%";
    } else {
      return "87%";
    }
  }
}

AvatarAndDetail.defaultProps = {
  headers: { first: "Descrição", last: "Data" },
  hasTableheader: false,
  list: [],
  baseLinkToDetail: "#",
  hasAvatar: true,
  hasUser: false,
  hasSubAccount: false,
  hasName: false,
  hasCard: false,
  hasReceipt: false,
};

export default AvatarAndDetail;
