import React from "react";
import {Card, Typography} from "@mui/material";
import {admin_account_merchants_path} from "../../../../routes";
import HomeSubscriptionsCardContent from "./HomeSubscriptionsCardContent";
import HomeEmptyCardContent from "../common/HomeEmptyCardContent";
import BusinessIcon from '@mui/icons-material/Business';

const HomeSubscriptionsEmptyCardContent = () => {
    return <HomeEmptyCardContent
        icon={<BusinessIcon color="primary"/>}
        title={"Cadastre fornecedores"}
        subtitle={"Quer essa facilidade para a sua rotina de trabalho?"}
        buttonText={"CADASTRE AGORA"}
        buttonProps={{href: admin_account_merchants_path()}}/>
}

const HomeSubscriptionsCard = (props) => {
    return (
        <Card sx={{p: 2.0}} data-testid="home-subscriptions">
            <Typography variant="h6" color={"primary"}>Fornecedores</Typography>
            {props.emptyState ? <HomeSubscriptionsEmptyCardContent/> : <HomeSubscriptionsCardContent {...props}/>}
        </Card>
    );
}

export default HomeSubscriptionsCard;