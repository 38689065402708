import React, {useEffect, useMemo, useState} from "react";
import {OnboardingTrigger} from "./OnboardingTrigger";
import {OnboardingDrawer} from "./OnboardingDrawer";
import {ShadowTheme} from "../ShadowTemplate";
import {hot} from "react-hot-loader";
import {completeOnboarding, getOnboarding, ignoreOnboarding, skipStep} from "./onboarding_service";
import OnboardingStepModal from "./OnboardingStepModal";
import { openModal } from "../../shared/jquery_wrapper";

const OnboardingManagerShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <OnboardingManager {...props}/>
    </ShadowTheme>;
};

const OnboardingManager = ({identifier, csrfToken, top, planLevel}) => {
    const [open, setOpen] = useState(false);
    const [onboarding, setOnboarding] = useState(null);
    const [justCompleted, setJustCompleted] = useState(false);
    const [stepOpen, setStepOpen] = useState(null);

    const getCurrentStep = () => {
        if (!onboarding) return;
        return onboarding.steps
            .sort((a, b) => a.order - b.order)
            .find((step) => !onboarding.completedSteps.includes(step.id))?.id;
    }

    const currentStep = useMemo(() => getCurrentStep(), [onboarding]);

    const handleTrigger = () => setOpen(true);
    const handleClose = async () => {
        if (justCompleted) {
            await handleComplete();
        }
        setOpen(false);
    }

    const handleComplete = async () => {
        setJustCompleted(false);
        const data = await completeOnboarding(csrfToken, onboarding.id);
        updateOnboarding(data);
    }

    const handleIgnore = async () => {
        setOpen(false);
        const data = await ignoreOnboarding(csrfToken, onboarding.id);
        updateOnboarding(data);
    }

    const handleStep = async (step) => {
        setOpen(false);
        setStepOpen(step);
    }

    const handleCloseModal = async () => {
        setOpen(true);
        setStepOpen(null);
        if (!onboarding.completed) await refreshOnboarding();
    }

    const handleSkip = async (step) => {
        setStepOpen(null);
        setOpen(true);
        const data = await skipStep(csrfToken, onboarding.id, step);
        updateOnboarding(data);
    }

    const updateOnboarding = (data) => {
        if (!data) return;

        setOnboarding(data);
    }

    const loadOnboarding = () => {
        getOnboarding(identifier).then((data) => {
            updateOnboarding(data);
            if (data) setOpen(data.ignored ? false : !data.completed);
        });
    }

    const refreshOnboarding = async () => {
        const data = await getOnboarding(identifier);
        updateOnboarding(data);
    }

    const checkCompleted = () => {
        if (onboarding && !onboarding.completed && !currentStep) {
            setJustCompleted(true);
        }
    }

    const isStepCompleted = (step) => {
        return onboarding.completed || onboarding.completedSteps.includes(step.id);
    }
    
    const handleUpgrade = () => {
        setStepOpen(null);
        openModal('#upgrade');
    }

    useEffect(() => loadOnboarding(), []);
    useEffect(() => checkCompleted(), [onboarding, currentStep]);

    return <>
        {onboarding && <OnboardingTrigger top={top} onClick={handleTrigger}/>}
        {onboarding && <OnboardingDrawer
            open={open}
            currentStep={currentStep}
            onClose={handleClose}
            onOpenStep={handleStep}
            onboarding={onboarding}
            onComplete={handleComplete}
            completed={justCompleted}
            onIgnore={handleIgnore}/>}
        {stepOpen && <OnboardingStepModal
            open={!!stepOpen}
            step={stepOpen}
            completed={isStepCompleted(stepOpen)}
            onClose={handleCloseModal}
            onSkip={() => handleSkip(stepOpen.identifier)}
            planLevel={planLevel}
            onUpgrade={handleUpgrade}/>}
    </>
}

export default hot(module)(OnboardingManagerShadow)

