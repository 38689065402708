import {Button, Card, CardActions, CardContent, CardHeader, Chip, Typography} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import dayjs from "dayjs";
import {admin_erp_integration_template_events_path} from "../../../../../routes";

export const TemplateItem = ({erp, integration, template, templateType, onActivate}) => {
    return <Card>
        <CardHeader title={templateType.label}/>
        <CardContent sx={{py: 0}}>
            <Typography variant={'body1'} color={template?.failureCount && 'error'}>
                {templateTitle(integration, template, templateType)}
            </Typography>
            <Typography variant={'body2'} color={'gray'}>
                {templateSubtitle(template, templateType)}
            </Typography>
        </CardContent>
        <CardActions sx={{p: 2, justifyContent: 'flex-end'}}>
            {template?.active === false && (
                <Button
                    sx={{mr: 1}}
                    size={'small'}
                    disabled={integration.status !== 'connected'}
                    variant={'outlined'}
                    onClick={onActivate}>
                    Reativar
                </Button>
            )}
            {template ? (
                <Button
                    variant={'contained'}
                    size={'small'}
                    href={admin_erp_integration_template_events_path(erp, templateType.identifier)}>
                    Acessar template
                </Button>
            ) : templateType.available ? (
                <Button
                    size={'small'}
                    variant={'contained'}
                    disabled={integration.status !== 'connected'}
                    href={admin_erp_integration_template_events_path(erp, templateType.identifier)}>
                    Configurar
                </Button>
            ) : (
                <Chip label={'Em breve'} icon={<LockIcon/>}/>
            )}

        </CardActions>
    </Card>
}

const templateTitle = (integration, template, type) => {
    if (integration.status !== 'connected') return 'A integração está inativa'
    if (template?.active === false) return 'O template foi desativado'
    if (template?.failureCount) return 'Atenção! Encontramos problemas na integração'
    if (!!template) return `Template de ${type.label} configurado`
    if (type.available) return `Configure o template de ${type.label}`
    return 'O novo template está chegando'
}

const templateSubtitle = (template, type) => {
    if (template?.active === false) {
        const deactivatedAt = dayjs(template.deactivatedAt).format('DD/MM/YYYY[ às ]HH:mm')
        return `Foi desativado em ${deactivatedAt}`
    }
    if (!!template) {
        const updatedAt = dayjs(template.lastUpdateAt).format('DD/MM/YYYY[ às ]HH:mm')
        return `Ultima atualização realizada em ${updatedAt}`
    }
    if (type.available) return 'Clique em configurar, defina os parâmetros e salve para ativar.'
    return 'Fique atento, em breve estará disponível para facilitar ainda mais o seu trabalho.'
}