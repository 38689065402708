import React, {useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    Stack,
    TextField
} from "@mui/material";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import dayjs from "dayjs";

export const LightboxFormInfo = ({onNext, initialIdentifier, initialRoles, initialExpiredAt}) => {
    const [identifier, setIdentifier] = useState(initialIdentifier);
    const [identifierError, setIdentifierError] = useState(null);

    const [roles, setRoles] = useState(initialRoles);
    const [rolesError, setRolesError] = useState(null);

    const [expiredAt, setExpiredAt] = useState(initialExpiredAt)
    const [expireAtError, setExpireAtError] = useState(null);

    const validateIdentifier = () => {
        if (identifier.trim().length === 0) {
            setIdentifierError('O título não pode ficar vazio');
            return false;
        }
        setIdentifierError(null);
        return true;
    }

    const validateRoles = () => {
        if (roles.length === 0) {
            setRolesError('Escolha pelo menos um grupo de usuários');
            return false;
        }
        setRolesError(null);
        return true;
    }
    const validateExpiredAt = () => {
        if (!expiredAt.isAfter(dayjs(), 'day')) {
            setExpireAtError('A data de expiração deve ser maior que a data atual')
            return false;
        }
        setExpireAtError(null);
        return true;
    }

    const validateFields = () => !!(validateIdentifier() * validateRoles() * validateExpiredAt());

    const handleName = (event) => {
        setIdentifierError(null);
        setIdentifier(event.target.value);
    }

    const handleRoles = (event) => {
        setRolesError(null);
        if (event.target.checked) {
            setRoles([...roles, event.target.value]);
        } else {
            setRoles(roles.filter(role => role !== event.target.value))
        }
    };

    const handleExpireAt = (expireAt) => {
        setExpireAtError(null);
        setExpiredAt(expireAt);
    }

    const handleNext = () => {
        if (!validateFields()) return;
        onNext(identifier, roles, expiredAt);
    }

    return <>
        <Card>
            <CardContent sx={{p: 3}}>
                <Stack>
                    <LightboxRolesForm roles={roles} error={rolesError} onChange={handleRoles}/>
                    <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    required
                                    data-testid={'lightbox-info-title-field'}
                                    name={'lightbox[identifier]'}
                                    label="Título"
                                    placeholder={'Insira um título para o lightbox'}
                                    error={!!identifierError}
                                    helperText={identifierError}
                                    variant="outlined"
                                    value={identifier}
                                    onChange={handleName}/>
                            </FormControl>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                            <FormControl fullWidth>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disablePast
                                        disableHighlightToday
                                        minDate={dayjs().add(1, 'day')}
                                        label="Data de expiração"
                                        format={'DD/MM/YYYY'}
                                        value={expiredAt}
                                        onChange={handleExpireAt}
                                        slotProps={{
                                            textField: {name: 'lightbox[expired_at]', helperText: expireAtError}
                                        }}/>
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>
            </CardContent>
        </Card>
        <Box sx={{my: 2}}>
            <div>
                <Button
                    data-testid={'lightbox-info-continue-button'}
                    variant="contained"
                    onClick={handleNext}
                    sx={{mt: 1, mr: 1}}>
                    Continuar
                </Button>
            </div>
        </Box>
    </>
}

const LightboxRolesForm = ({roles, onChange, error}) => {
    return <FormControl sx={{mb: 2}} component="fieldset" variant="standard" required error={!!error}>
        <FormLabel>Público alvo</FormLabel>
        <FormGroup row>
            <FormControlLabel
                label="Colaboradores"
                control={
                    <Checkbox
                        data-testid={'lightbox-info-check-users'}
                        name={'lightbox[roles][]'}
                        value={'User'}
                        checked={roles.includes('User')}
                        onChange={onChange}/>
                }/>
            <FormControlLabel
                label="Financeiro"
                control={
                    <Checkbox
                        data-testid={'lightbox-info-check-financial'}
                        name={'lightbox[roles][]'}
                        value={'Financial'}
                        checked={roles.includes('Financial')}
                        onChange={onChange}/>
                }/>
            <FormControlLabel
                label="Administradores"
                control={
                    <Checkbox
                        data-testid={'lightbox-info-check-admins'}
                        name={'lightbox[roles][]'}
                        value={'Admin'}
                        checked={roles.includes('Admin')}
                        onChange={onChange}/>
                }/>
        </FormGroup>
        {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
}