import React from "react";

import AccountStatementWithFilters from "./account/StatementWithFilters";
import Balance from "./shared/Balance";
import Card from "./shared/lists/Card";
import CompanyList from "./bistro/CompanyList";
import DistanceFeatureGroup from "./admin/features/distance/DistanceFeatureGroup";
import ReportApprove from "./reports/ReportApprove";
import SubAccountsList from "./sub_accounts/List";
import SubAccountStatementWithFilters from "./sub_accounts/StatementWithFilters";
import PasswordInput from "./shared/PasswordInput";
import BistroFeatures from "./bistro/features/BistroFeatures";
import BistroCompanyFeatures from "./bistro/companies/features/BistroCompanyFeatures";
import BistroManageAccount from "./bistro/accounts/ManageAccount";
import StrongPassword from "./shared/StrongPassword";
import SubmitReportModal from "./admin/expenses/SubmitReportModal";
import AccountNewWeb from "./account/NewWeb";
import ExpensePolicyFeatureGroup from "./admin/features/expense_policy/ExpensePolicyFeatureGroup";
import BaristaIAPolicyFeatureGroup from "./admin/features/barista_ia/BaristaIAPolicyFeatureGroup";
import CardsAndMerchants from "./account/parsers/CardsAndMerchants";
import SubscriptionStatement from "./account/lists/SubscriptionStatement";
import SubscriptionForm from "./account/forms/Subscription";
import ApprovalFeatureGroup from "./admin/features/approval/ApprovalFeatureGroup";
import CreateAccount from "./bistro/accounts/CreateAccount";

class Index extends React.Component {
  state = {
    default: <></>,
    admin_distance_feature: <DistanceFeatureGroup {...this.props} />,
    admin_expense_policy: <ExpensePolicyFeatureGroup {...this.props} />,
    admin_approval_feature: <ApprovalFeatureGroup {...this.props} />,
    admin_barista_ia_feature: <BaristaIAPolicyFeatureGroup {...this.props} />,
    account_statement: <AccountStatementWithFilters {...this.props} />,
    bistro_companies: <CompanyList {...this.props} />,
    bistro_company_features: <BistroCompanyFeatures {...this.props} />,
    bistro_features: <BistroFeatures {...this.props} />,
    bistro_manage_account: <BistroManageAccount {...this.props} />,
    submit_report: <SubmitReportModal {...this.props} />,
    report_approve: <ReportApprove {...this.props} />,
    sub_account_balance: <Balance {...this.props} />,
    sub_account_cards: <Card {...this.props} />,
    sub_account_list: <SubAccountsList {...this.props} />,
    sub_account_statement: <SubAccountStatementWithFilters {...this.props} />,
    password_input: <PasswordInput {...this.props} />,
    strong_password: <StrongPassword {...this.props} />,
    account_new_web: <AccountNewWeb {...this.props} />,
    subscription_cards_and_merchants: <CardsAndMerchants {...this.props} />,
    subscription_statement: <SubscriptionStatement {...this.props} />,
    subscription_form: <SubscriptionForm {...this.props} />,
    create_account: <CreateAccount {...this.props} />,
  };

  render() {
    return this.state[this.props.component];
  }
}

Index.defaultProps = {
  component: "default",
};

export default Index;
