import React from "react";

import { showSnackbar } from "../../../shared/jquery_wrapper";
import FeatureGroup from "../FeatureGroup";

export const APPROVAL_GROUP_ID = 5;

class ApprovalFeatureGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onUpdate = (feature, activate, error) => {      
    if (error) {
      showSnackbar({
        content: "Não foi possível ativar a funcionalidade.",
        style: "alert",
        timeout: 5000,
      });
    } else {
      let state = activate == true ? 'ativada' : 'desativada';
      showSnackbar({
        content: `Aprovação automática ${state}!`,
        style: "alert",
        timeout: 3000,
      });
    };
  };

  render() {
    return (
      <FeatureGroup
        groupName={this.props.groupName}
        featuresEndpoint={this.props.featuresEndpoint}
        featureGroup={this.props.featureGroup}
        ref={this.group}
        onUpdate={(feature, activate, error) => this.onUpdate(feature, activate, error)}
        ignoreAvailability={this.props.ignoreAvailability}
        hidePlanWhenActive={this.props.hidePlanWhenActive}
        styleCard={this.props.styleCard}
      />
    );
  }
}

ApprovalFeatureGroup.defaultProps = {
  featuresEndpoint: "",
  featureGroup: APPROVAL_GROUP_ID,
  ignoreAvailability: false,
  hidePlanWhenActive: true,
  groupName: "",
  styleCard: true
};

export default ApprovalFeatureGroup;