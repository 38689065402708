import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";

const DeleteLightboxDialog = ({open, onCancel, onConfirm}) => {
    const [deleting, setDeleting] = useState(false);

    const handleConfirm = async () => {
        setDeleting(true);
        await onConfirm();
        setDeleting(false);
    }

    return <Dialog open={open} onClose={onCancel}>
        <DialogTitle>Excluir</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Tem certeza que deseja excluir esse lightbox? Essa operação não pode ser revertida
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button disabled={deleting} onClick={onCancel}>Cancelar</Button>
            <Button disabled={deleting} onClick={handleConfirm} autoFocus>
                {deleting ? 'Excluindo...' : 'Excluir'}
            </Button>
        </DialogActions>
    </Dialog>
}

export default DeleteLightboxDialog;