import React, { useReducer, useState } from "react";
import { ShadowTheme } from "../../../admin/ShadowTemplate";
import { hot } from "react-hot-loader";
import CNPJTextMask from "../../../shared/masks/CNPJInputMask";
import PhoneInputMask from "../../../shared/masks/PhoneInputMask";
import NewStrongPasswordField, { isValidStrongPassword } from "../../../shared/inputs/NewStrongPasswordField";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {
    CreateBistroCompanyDto,
    CreateBistroCompanyDtos,
    CreateBistroCompanyUserDto,
    createBistroCompany
} from "./create_service";


const CreateBistroCompanyShadow = (props) => {
    return (
        <ShadowTheme rootSelector={props.rootSelector}>
            <CreateBistroCompany
                csrfToken={props.csrfToken}
                plans={props.plans}
                status={props.status}
            />
        </ShadowTheme>
    );
}

const CreateBistroCompany = ({ csrfToken, plans, status }) => {
    const Inputs = {
        companyName: 'companyName',
        companyTest: 'companyTest',
        companyDocument: 'companyDocument',
        companyLevel: 'companyLevel',
        companyStatus: 'companyStatus',
        companyDomain: 'companyDomain',
        companySegment: 'companySegment',
        companyCity: 'companyCity',
        companyState: 'companyState',
        userName: 'userName',
        userEmail: 'userEmail',
        userPhone: 'userPhone',
        userPassword: 'userPassword'
    }

    const initialState = {
        [Inputs.companyName]: '',
        [Inputs.companyTest]: true,
        [Inputs.companyDocument]: '',
        [Inputs.companyLevel]: plans[1].value,
        [Inputs.companyStatus]: status[1].value,
        [Inputs.companyDomain]: '',
        [Inputs.companySegment]: '',
        [Inputs.companyCity]: '',
        [Inputs.companyState]: '',
        [Inputs.userName]: '',
        [Inputs.userEmail]: '',
        [Inputs.userPhone]: '',
        [Inputs.userPassword]: '',
    }

    const reducer = (state, action) => {
        return { ...state, [action.type]: action.value };
    }

    const dispatchValueOn = (value, input) => {
        dispatch({ type: input, value: value });
    }

    const [state, dispatch] = useReducer(reducer, initialState);
    
    const [loading, setLoading] = useState(false);
    const [userPasswordError, setUserPasswordError] = useState(false);

    const handleCompanyName = (event) => dispatchValueOn(event.target.value, Inputs.companyName);
    const handleCompanyTest = (event) => dispatchValueOn(event.target.checked, Inputs.companyTest);
    const handleCompanyDocument = (event) => dispatchValueOn(event.target.value, Inputs.companyDocument);
    const handleCompanyLevel = (event) => dispatchValueOn(event.target.value, Inputs.companyLevel);
    const handleCompanyStatus = (event) => dispatchValueOn(event.target.value, Inputs.companyStatus);
    const handleCompanyDomain = (event) => dispatchValueOn(event.target.value, Inputs.companyDomain);
    const handleCompanySegment = (event) => dispatchValueOn(event.target.value, Inputs.companySegment);
    const handleCompanyCity = (event) => dispatchValueOn(event.target.value, Inputs.companyCity);
    const handleCompanyState = (event) => dispatchValueOn(event.target.value, Inputs.companyState);
    
    const handleUserName = (event) => dispatchValueOn(event.target.value, Inputs.userName);
    const handleUserEmail = (event) => dispatchValueOn(event.target.value, Inputs.userEmail);
    const handleUserPhone = (event) => dispatchValueOn(event.target.value, Inputs.userPhone);
    const handleUserPassword = (event) => dispatchValueOn(event.target.value, Inputs.userPassword);

    const validateFields = () => {
        let valid = true;
        if (!(isValidStrongPassword(state.userPassword))) {
            setUserPasswordError(true);
            valid = false;
        } else {
            setUserPasswordError(false);
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!validateFields()) return false;
        const company = new CreateBistroCompanyDto({ ...state });
        const user = new CreateBistroCompanyUserDto({ ...state });
        const dtos = new CreateBistroCompanyDtos(company, user);
        setLoading(true);
        await createBistroCompany(csrfToken, dtos);
        setLoading(false);
    }

    return (
        <Card>
            <CardHeader
                title="Nova empresa"
                titleTypographyProps={{ sx: { mb: 0.5 } }}
                subheader="Informações da empresa e primeiro usuário"/>
            <form onSubmit={handleSubmit}>
                <CardContent sx={{ pt: 1 }}>
                    <Grid container spacing={2} alignItems={"center"}>
                        <Grid item md={10} xs={8}>
                            <TextField
                                required
                                fullWidth
                                label="Nome da empresa"
                                placeholder="Insira o nome da empresa"
                                InputLabelProps={{ shrink: true }}
                                value={state.companyName}
                                onChange={handleCompanyName}
                                data-testid={'company-name-field'}/>
                        </Grid>
                        <Grid item md={2} xs={4}>
                            <FormControlLabel
                                label="Empresa de teste"
                                control={
                                    <Checkbox
                                        defaultChecked
                                        value={state.companyTest}
                                        onChange={handleCompanyTest}
                                        data-testid="test-company-checkbox"
                                    />
                                }
                            />
                        </Grid>
                        <Grid item md={4} xs={6}>
                            <TextField
                                fullWidth
                                label="CNPJ (opcional)"
                                placeholder="Insira o CNPJ da empresa"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputComponent: CNPJTextMask }}
                                value={state.companyDocument}
                                onChange={handleCompanyDocument}
                                data-testid={'company-document-field'}/>
                        </Grid>
                        <Grid item md={4} xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="company-plan-label">Plano</InputLabel>
                                <Select
                                    label="Plano"
                                    labelId="company-plan-label"
                                    value={state.companyLevel}
                                    onChange={handleCompanyLevel}>
                                    {plans.map((plan, index) => 
                                        <MenuItem key={index} value={plan.value}>
                                            {plan.name}
                                        </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={4} xs={3}>
                            <FormControl fullWidth>
                                <InputLabel id="company-status-label">Assinatura</InputLabel>
                                <Select
                                    label="Assinatura"
                                    labelId="company-status-label"
                                    value={state.companyStatus}
                                    onChange={handleCompanyStatus}>
                                    {status.map((state, index) => 
                                        <MenuItem key={index} value={state.value}>
                                            {state.name}
                                        </MenuItem>)}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <TextField
                                fullWidth
                                label="Domínio"
                                placeholder="Insira o domínio da empresa"
                                InputLabelProps={{ shrink: true }}
                                value={state.companyDomain}
                                onChange={handleCompanyDomain}
                                data-testid={'company-domain-field'}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <TextField
                                fullWidth
                                label="Segmento"
                                placeholder="Insira o segmento da empresa"
                                InputLabelProps={{ shrink: true }}
                                value={state.companySegment}
                                onChange={handleCompanySegment}
                                data-testid={'company-segment-field'}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <TextField
                                fullWidth
                                label="Cidade"
                                placeholder="Insira a cidade da empresa"
                                InputLabelProps={{ shrink: true }}
                                value={state.companyCity}
                                onChange={handleCompanyCity}
                                data-testid={'company-city-field'}/>
                        </Grid>
                        <Grid item md={3} xs={6}>
                            <TextField
                                fullWidth
                                label="Estado"
                                placeholder="Insira o estado da empresa"
                                InputLabelProps={{ shrink: true }}
                                value={state.companyState}
                                onChange={handleCompanyState}
                                data-testid={'company-state-field'}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <Divider sx={{ my: 0.5 }}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField
                                required
                                fullWidth
                                label="Nome do usuário"
                                placeholder="Insira o nome completo"
                                InputLabelProps={{ shrink: true }}
                                value={state.userName}
                                onChange={handleUserName}
                                data-testid={'user-name-field'}/>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <TextField
                                required
                                fullWidth
                                label="Email do usuário"
                                placeholder="Insira o email do usuário"
                                InputLabelProps={{ shrink: true }}
                                value={state.userEmail}
                                onChange={handleUserEmail}
                                data-testid={'user-email-field'}/>
                        </Grid>
                        <Grid item md={6} xs={6}>
                            <TextField
                                fullWidth
                                label="Telefone do usuário"
                                placeholder="Insira o telefone com DDD"
                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputComponent: PhoneInputMask }}
                                value={state.userPhone}
                                onChange={handleUserPhone}
                                data-testid={'user-phone-field'}/>
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <NewStrongPasswordField
                                value={state.userPassword}
                                error={userPasswordError}
                                onChange={handleUserPassword}/>
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions sx={{ mx: 1, mb: 2, justifyContent: "flex-end" }}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={loading}
                        data-testid="submit-button"
                    >
                        CRIAR NOVA EMPRESA
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
}

export default hot(module)(CreateBistroCompanyShadow);