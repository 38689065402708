import React from "react";

class Loading extends React.Component {
  state = {};
  render() {
    return (
      <div
        className={`progress ${!this.props.show && "transparent"}`}
        style={{ margin: "0.5rem 0" }}
      >
        {this.props.show && <div className="indeterminate"></div>}
      </div>
    );
  }
}

Loading.defaultProps = {
  show: false,
};

export default Loading;
