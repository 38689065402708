import React from "react";
import FeatureRepository from "./feature_repository";
import FeatureItem from "./FeatureItem";
import {initTooltips} from "../../shared/jquery_wrapper";
import classNames from "classnames";

class FeatureGroup extends React.Component {
    constructor(props) {
        super(props);
        this.repository = new FeatureRepository(this.props.featuresEndpoint)
        this.state = {
            features: [],
        }
    }

    async componentDidMount() {
        await this.loadFeatures()
    }

    async loadFeatures() {
        try {
            let response = await this.repository.getFeatures(this.props.featureGroup)
            this.setState({...this.state, features: response.data});
            initTooltips();
        } catch (error) {
            if (error.status === 401) {
                window.location.assign("/");
            }
        }
    }

    async updateFeature(feature, activate, newMetadata) {
        try {
            let response = await this.repository.updateFeature(this.props.featureGroup, feature, activate, newMetadata)
            this.setState({...this.state, features: response.data});
            initTooltips();
            this.props.onUpdate(feature, activate)
        } catch (error) {
            this.props.onUpdate(feature, activate, error)
        }
    }

    async handleSwitch(feature, checked) {
        if (this.props.onChangeSwitch) {
            this.props.onChangeSwitch(feature, checked)
        } else {
            await this.updateFeature(feature, checked)
        }
    }

    renderGroupHeader(visible) {
        if (visible) {
            return (
                <h2>
                    {this.props.groupName}
                    {this.state.features.some((feature) => feature.attributes.new_feature) && <span test-name='new_feature' className="new-badge-pink">TEM NOVIDADE NO ESPRESSO</span>}
                </h2>
            );
        }
    }

    render() {
        return (<div>
            {this.renderGroupHeader(this.props.groupName !== "")}
            <ul className={classNames({
                "collection design-system": this.props.styleCard,
                "border_light_pink": this.props.baristaIA
            })}>
                {this.state.features.map((feature, idx) => {
                    return <FeatureItem
                        key={idx}
                        feature={feature}
                        onChangeSwitch={(feature, checked) => this.handleSwitch(feature, checked)}
                        customDescription={this.props.customDescription}
                        ignoreAvailability={this.props.ignoreAvailability}
                        hidePlanWhenActive={this.props.hidePlanWhenActive}
                        baristaIA={this.props.baristaIA}
                    />
                })}
            </ul>
        </div>)
    }
}

FeatureGroup.defaultProps = {
    featuresEndpoint: "",
    featureGroup: 0,
    groupName: "",
    ignoreAvailability: false,
    hidePlanWhenActive: true,
    styleCard: true,
    baristaIA: false,
    onUpdate: () => {
    },
};

export default FeatureGroup;
