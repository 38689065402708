import React from 'react';
import Button from '@mui/material/Button';
import { openModal } from '../../../shared/jquery_wrapper';

const UpgradeButton = ({ smallScreen, planLevel, isShowButton }) => {
  const isUpgraded = () => {
    return planLevel < 4;
  };

  return (
    <>
      {smallScreen && isShowButton && isUpgraded() && (
        <Button
          size="small"
          variant="contained"
          onClick={() => openModal('#upgrade')}
          sx={{ ml: 3 }}
        >
          UPGRADE
        </Button>
      )}
    </>
  );
};

export default UpgradeButton;
