import {Grid, Typography} from "@mui/material";
import React from "react";
import TextField from "@mui/material/TextField";

export const OmieUserConfigForm = ({tag, onChangeTag, disabled, failed}) => {
    return <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12}>
            <Typography variant={'h6'}>
                Informe a tag de clientes e fornecedores cadastrada no ERP
            </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
            <TextField
                fullWidth
                value={tag}
                label={'Tag'}
                error={failed}
                disabled={disabled}
                placeholder={'ESPRESSO'}
                onChange={(e) => onChangeTag(e.target.value)}
                helperText={failed ? 'Verifique os dados e tente novamente' : ''}/>
        </Grid>
    </Grid>
}