import React from "react";

class FilterMultiSelect extends React.Component {
  state = { selecteds: [] };

  componentDidMount() {
    this.setState({ selecteds: this.props.selecteds });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selecteds !== prevProps.selecteds &&
      this.props.selecteds !== this.state.selecteds
    ) {
      this.setState({ selecteds: this.props.selecteds });
    }
  }

  render() {
    return (
      <div
        className="modal modal-filter modal-fixed-footer open"
        id="modal-base-filter"
      >
        <div className="modal-content">
          <h4>Selecionar {this.props.title}</h4>
          <p className="subtitle">Marque para refinar o filtro</p>
          <div className="row generic-list-checkbox js-form-filter-generic-list">
            <div className="col s12 js-filter-items">
              {this.props.list.map((el, idx) => (
                <p className="item" key={idx}>
                  <label>
                    <input
                      test-name={`checkbox-option-${el.id}`}
                      type="checkbox"
                      multiple="multiple"
                      className="filled-in js-checkbox-filter"
                      value={el.id}
                      checked={this.state.selecteds.includes(el.id)}
                      onChange={(e) => this.handleSelect(el.id)}
                    />
                    <span>
                      <span>{el.label}</span>
                    </span>
                  </label>
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="modal-form-buttons">
            <div className="group-buttons right">
              <a
                test-name={"cancel-and-close"}
                className="modal-action btn-flat grey-text modal-close"
              >
                Cancelar
              </a>
              <a
                test-name="select-filters"
                className="modal-action btn-flat modal-close"
                onClick={() => this.props.handleSelect(this.state.selecteds)}
              >
                Selecionar
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleSelect(selected) {
    let list = [...this.state.selecteds];
    const idx = list.findIndex((e) => e === selected);

    if (list.includes(selected)) {
      list.splice(idx, 1);
      this.setState({ ...this.state, selecteds: list });
    } else {
      list.push(selected);
      this.setState({ ...this.state, selecteds: list });
    }
  }
}

FilterMultiSelect.defaultProps = {
  title: "",
  list: [],
  selecteds: [],
  handleSelect: Function,
};

export default FilterMultiSelect;
