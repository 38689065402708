import Toolbar from "@mui/material/Toolbar";
import {alpha} from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import {Button, CardHeader, Stack, useMediaQuery} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import * as React from "react";
import PropTypes from "prop-types";
import FilterListIcon from "@mui/icons-material/FilterList";

export const EspressoTableToolbar = (props) => {
    const {
        title,
        subtitle,
        selectedCount,
        actions = [],
        onAction,
        filterable,
        showFilter,
        onToggleFilter
    } = props;

    const selectedBg = {
        bgcolor: (theme) => alpha(
            theme.palette.primary.main,
            theme.palette.action.activatedOpacity
        ),
    }
    return (
        <Toolbar
            disableGutters
            sx={{pl: 2, pr: 1, ...(selectedCount > 0 && selectedBg)}}>
            {selectedCount > 0 ? (
                <SelectedTitle count={selectedCount}/>
            ) : (
                <DefaultTitle title={title} subtitle={subtitle}/>
            )}
            <Stack direction={'row'} spacing={2}>
                {selectedCount > 0 && (
                    [...actions.map((action, index) => (
                        <ToolbarButton
                            key={`action-${index}`}
                            actionId={action.id}
                            label={action.label}
                            icon={action.icon}
                            tooltip={action.tooltip}
                            width={action.width}
                            onClick={onAction(action.onClick)}/>
                    ))]
                )}
                {filterable && <ToolbarButton
                    actionId={'toggle-filter'}
                    width={160}
                    onClick={onToggleFilter}
                    variant={'text'}
                    label={showFilter ? 'Ocultar filtros' : 'Filtrar'}
                    icon={<FilterListIcon/>}/>}
            </Stack>

        </Toolbar>
    );
}

const DefaultTitle = ({title, subtitle}) => {
    return <CardHeader
        title={title}
        subheader={subtitle}
        sx={{px: 0, flex: '1 1 100%'}}/>
}

const SelectedTitle = ({count}) => {
    return <Typography
        data-testid={'table-selected-title'}
        sx={{flex: '1 1 100%'}}
        variant="subtitle1"
        component="div">
        {count} selecionados
    </Typography>
}

const ToolbarButton = ({actionId, label, icon, onClick, tooltip, width, variant = 'contained'}) => {
    const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
    return <Tooltip title={tooltip}>
        {largeScreen ? (
            <Button
                data-testid={`toolbar-button-${actionId}`}
                onClick={onClick}
                sx={{width: width}}
                variant={variant}
                startIcon={icon}>
                {label}
            </Button>
        ) : (
            <IconButton
                data-testid={`toolbar-button-${actionId}`}
                onClick={onClick}
                color={'primary'}>
                {icon}
            </IconButton>
        )}
    </Tooltip>
}

EspressoTableToolbar.propTypes = {
    title: PropTypes.string,
    selectedCount: PropTypes.number,
    actions: PropTypes.arrayOf(PropTypes.object),
    onAction: PropTypes.func,
    showFilter: PropTypes.bool,
    onToggleFilter: PropTypes.func,
}

ToolbarButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
    width: PropTypes.number,
}
