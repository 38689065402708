import {createTheme} from "@mui/material/styles";

export const espressoTheme = createTheme({
    palette: {
        primary: {
            main: '#3D0079',
            gradient: 'linear-gradient(180deg, #0F001E 0%, #1A0034 50%, #3D0079 100%)'
        },
        disabled: {
            main: '#616161',
        },
        disabledLight: {
            main: '#E1E1E1'
        },
        primary90: {
            main: '#D9A9FF',
        },
        secondary: {
            main: '#00CBA8',
            contrastText: '#3D0079',
        },
        surfaceLight5: {
            main: 'color-mix(in srgb, #3D0079 5%, white)'
        },
        surfaceLight12: {
            main: '#E4DBEC'
        },
        surfaceLight8: {
            main: '#F0EBF5'
        },
        surfaceLight9: {
            main: '#FAF8FB'
        },
        pink: {
            main: "#ff4081",
            dark: "#ef677e",
            light: "#f88ab0",
            contrastText: '#fff',
        },
        warning: {
            main: "#EF6C00"
        },
        error: {
            main: "#D32F2F"
        },
        success: {
            main: "#2E7D32"
        },
        info: {
            main: "#0288D1"
        },
        alert: {
            success: {
                main: "#1E4620"
            },
            error: {
                main: "#5F2120",
                background: "#FDEDED"
            },
            warning: {
                main: "#663C00",
                background: "#FFF4E5"
            },
            info: {
                main: "#014361",
                background: "#E5F6FD"
            }
        },
        status: {
            success: {
                main: "#00BE6E"
            },
            warning: {
                main: "#FFC30E"
            },
            error: {
                main: "#E69A9C"
            }
        }
    },
    typography: {
        fontFamily: "Lato, sans-serif",
        overline: {
            fontSize: '0.625rem'
        },
        subtitleGray: {
            color: '#646465',
            fontSize: '0.875rem'
        }
    },
    components: {
        MuiStack: {
            defaultProps: {
                useFlexGap: true,
            }
        },
        MuiButton: {
            styleOverrides: {
                startIcon: {
                    marginTop: -1
                },
                endIcon: {
                    marginTop: -1
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                icon: {
                    alignItems: 'center'
                },
                action: {
                    alignItems: 'center'
                }
            }
        },
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    height: 56,
                    minHeight: 56
                },
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                         backgroundColor: '#F0EBF5'
                    }
                }
            }
        },
        MuiDayCalendar: {
            styleOverrides: {
                weekDayLabel: {
                    visibility: 'hidden',
                    position: 'relative',
                    '&::before': {
                        visibility: 'visible',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                    },
                    '&:nth-of-type(1)::before': {
                        content: '"D"', // Domingo
                    },
                    '&:nth-of-type(2)::before': {
                        content: '"S"', // Segunda-feira
                    },
                    '&:nth-of-type(3)::before': {
                        content: '"T"', // Terça-feira
                    },
                    '&:nth-of-type(4)::before': {
                        content: '"Q"', // Quarta-feira
                    },
                    '&:nth-of-type(5)::before': {
                        content: '"Q"', // Quinta-feira
                    },
                    '&:nth-of-type(6)::before': {
                        content: '"S"', // Sexta-feira
                    },
                    '&:nth-of-type(7)::before': {
                        content: '"S"', // Sábado
                    },
                },
            },
        },
    }
});