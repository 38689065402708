import React from "react";

import classNames from "classnames";

class PasswordInput extends React.Component {
  state = { toggle: false, value: "" };

  render() {
    return (
      <div className="form-design-on-surfaces password_input">
        <div className={`input-field ${this.props.classField}`}>
          <input
            className={classNames(this.props.inputClass)}
            id={this.props.id}
            name={this.props.id}
            type={this.state.toggle ? "text" : "password"}
            required={this.props.required}
            placeholder={this.props.active && this.props.placeholder}
            test-name="password-input"
            onChange={(evt) => {
              this.setState({ ...this.state, value: evt.target.value });
              this.props.onChange(evt);
            }}
            value={this.props.value}
            autoComplete={"off"}
          />
          <label
            htmlFor={this.props.id}
            className={classNames(this.props.labelClass, {
              active:
                this.props.active ||
                (!this.props.active && this.state.value.length > 0),
            })}
          >
            {this.props.label}
          </label>
          <span
            className="field-icon toggle-password"
            test-name="password-toggle"
            onClick={() => {
              this.setState({ ...this.state, toggle: !this.state.toggle });
            }}
          >
            <span
              test-name="password-span"
              className={`mdi mdi-eye${this.state.toggle ? "" : "-off"}`}
            />
          </span>
        </div>
      </div>
    );
  }
}

PasswordInput.defaultProps = {
  required: false,
  placeholder: "",
  id: "",
  label: "",
  active: true,
  classField: "",
  onChange: () => {},
  labelClass: "",
  inputClass: "",
};

export default PasswordInput;
