import React, {useEffect, useMemo, useState} from "react";
import {ShadowTheme} from "../../../admin/ShadowTemplate";
import {
    Button,
    DialogContentText,
    FormControl, FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@mui/material";
import {hot} from "react-hot-loader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

const labels = {
    'tags': {name: 'Grupo de tags', button: 'Adicionar grupo'},
    'custom_fields': {name: 'Campo personalizado', button: 'Adicionar campo'}
}

const AddClassificationButton = ({rootSelector, classification, groups, active}) => {
    const [open, setOpen] = useState(false);
    const [activeIds, setActiveIds] = useState(active);
    const [inputId, setInputId] = useState(0);

    const availableGroups = useMemo(() => {
        return groups.filter(group => group.status === 1 && !activeIds.includes(group.id));
    }, [groups, activeIds]);

    useEffect(() => {
        document.addEventListener(`${classification}_changed`, handleChange);
        return () => window.removeEventListener(`${classification}_changed`, handleChange);
    }, []);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => {
        setInputId(0);
        setOpen(false);
    }

    const handleAdd = () => {
        const groupIds = [...activeIds, parseInt(inputId)];
        document.dispatchEvent(new CustomEvent(`add_${classification}`, {detail: groupIds}));
        handleClose();
    }

    const handleChange = (event) => setActiveIds(event.detail);

    return <ShadowTheme rootSelector={rootSelector}>
        <Button
            variant={'outlined'}
            onClick={handleClickOpen}>
            {labels[classification].button}
        </Button>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{`Adicionar ${labels[classification].name.toLowerCase()}`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`Selecione abaixo o ${labels[classification].name.toLowerCase()} que deseja adicionar`}
                </DialogContentText>
                <FormControl fullWidth required margin={'normal'}>
                    <InputLabel>{labels[classification].name}</InputLabel>
                    <Select
                        disabled={availableGroups.length === 0}
                        label={labels[classification].name}
                        value={inputId}
                        onChange={(event) => setInputId(event.target.value)}
                        variant={'outlined'}>
                        {availableGroups.length > 0 && (
                            <MenuItem disabled value={0}>Selecione</MenuItem>
                        )}
                        {availableGroups.length === 0 && (
                            <MenuItem disabled value={0}>
                                {`Sem ${labels[classification].name.toLowerCase()} disponível`}
                            </MenuItem>
                        )}
                        {availableGroups.map(group => (
                            <MenuItem key={group.id} value={group.id}>
                                {`${group.name} - ${group.code || 'Sem código'}`}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={handleAdd} disabled={inputId === 0}>Adicionar</Button>
            </DialogActions>
        </Dialog>
    </ShadowTheme>
}

export default hot(module)(AddClassificationButton)