import React, { useEffect, useState } from "react";
import Confirm from "./common/Confirm";
import SelectionSummary from "./common/SelectionSummary";
import ExpenseModal from "./common/ExpenseModal";
import UserSelectionModal from "./common/UserSelectionModal";
import TransactionsTable from "./common/TransactionsTable";
import { Snackbar } from '@mui/material';
import { update_status_admin_card_transactions_path } from '../../../routes';
import { ShadowTheme } from "../ShadowTemplate";

const Unarchived = (props) => {
  const [page, setPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [selectedTransactions, setSelectedTransactions] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const [dialogContent, setDialogContent] = React.useState(null);
  const [openExpenseModal, setOpenExpenseModal] = useState(false);
  const [openUserSelectionModal, setOpenUserSelectionModal] = useState(false);
  const [transaction, setTransaction] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenDialog = (id, title, content) => {
    setOpenDialog(true);
    setSelectedTransaction(id);
    setDialogTitle(title);
    setDialogContent(content);
  };

  const handleDisplaySnackbar = (message) => {
    setOpenSnackbar(true);
    setSnackbarMessage(message);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTransaction(null);
  };

  const handleCloseExpenseModal = () => {
    setOpenExpenseModal(false);
  };

  const handleOpenUserSelectionModal = (id) => {
    setOpenUserSelectionModal(true);
    setSelectedTransaction(id);
  };

  const handleCloseUserSelectionModal = () => {
    setOpenUserSelectionModal(false);
  }

  const handleCheckboxChange = (itemId) => {
    setSelectedTransactions((prevSelectedItems) => {
      if (prevSelectedItems.includes(itemId)) {
        return prevSelectedItems.filter((id) => id !== itemId);
      } else {
        return [...prevSelectedItems, itemId];
      }
    });
  };

  const selectAllTransactions = () => {
    setSelectAll(!selectAll);

    const allItemIds = rows
      .filter((row) => !row.proven)
      .map((row) => row.id);

    setSelectedTransactions(selectAll ? [] : allItemIds);
  };

  const handleRowClick = (row) => {
    setOpenExpenseModal(true);
    setTransaction(row);
  };

  const fetchTransactions = async () => {
    try {
      let url = props.url + `&page=${page + 1}`;

      const response = await fetch(url, {
        headers: {
          "Accept": "application/json"
        }
      });

      const data = await response.json();
      setRows(data.transactions);
      setTotalRows(data.total_count);
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(`Ocorreu um erro ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const archive = async () => {
    let values;

    if (selectedTransaction !== null) {
      values = { ids: [selectedTransaction] }
    } else {
      values = { ids: selectedTransactions }
    }

    try {
      const response = await fetch(update_status_admin_card_transactions_path({status: 'archive'}), {
        method: 'POST',
        headers: {
          "X-CSRF-Token": props.csrfToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(values)
      });

      if (response.ok) {
        await fetchTransactions();

        setSelectedTransactions([]);
        setSelectAll(false);
        setOpenDialog(false);
        setOpenSnackbar(true);
        setSnackbarMessage("Transação arquivada com sucesso.");
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("Não foi possível arquivar a transação.");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(`Ocorreu um erroa ${error.message}`);
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [page]);

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <React.Fragment>
        {selectedTransactions.length > 0 && (
          <SelectionSummary
            actionTitle="Arquivar"
            title="Deseja arquivar as transações selecionadas?"
            description="A transação não precisará ser comprovada e será movida para a lista de arquivados."
            totalSelectedTransactions={selectedTransactions.length}
            handleOpenDialog={handleOpenDialog}
            setSelectedTransactions={setSelectedTransactions}
            setSelectAll={setSelectAll}
          />
        )}

        <TransactionsTable
          rows={rows}
          totalRows={totalRows}
          page={page}
          type="unarchived"
          loading={loading}
          selectAll={selectAll}
          selectAllTransactions={selectAllTransactions}
          selectedTransactions={selectedTransactions}
          handleCheckboxChange={handleCheckboxChange}
          handleRowClick={handleRowClick}
          handleOpenDialog={handleOpenDialog}
          handleOpenUserSelectionModal={handleOpenUserSelectionModal}
          handleChangePage={handleChangePage}
        />

        <Confirm
          open={openDialog}
          title={dialogTitle}
          content={dialogContent}
          onConfirm={archive}
          onCancel={handleCloseDialog}
        />

        <ExpenseModal
          open={openExpenseModal}
          title="Transação"
          transaction={transaction}
          onClose={handleCloseExpenseModal}
        />

        <UserSelectionModal
          open={openUserSelectionModal}
          transaction={selectedTransaction}
          onClose={handleCloseUserSelectionModal}
          handleDisplaySnackbar={handleDisplaySnackbar}
          updateTransactions={fetchTransactions}
          csrfToken={props.csrfToken}
        />

        <Snackbar
          data-testid="snackbar"
          open={openSnackbar}
          message={snackbarMessage}
          anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
        />
      </React.Fragment>
    </ShadowTheme>
  );
};

export default Unarchived;