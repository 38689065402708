import React, { useEffect, useState } from "react";

import SelectTab from "../../shared/SelectTab";
import SubscriptionCards from "../lists/SubscriptionCards";
import SubscriptionMerchants from "../lists/SubscriptionMerchants";

const CardsAndMerchants = (props) => {
  const [tab, setTab] = useState(
    props.hasCards && props.has_activities ? "merchant" : "cards"
  );

  useEffect(() => {
    setTab(props.hasCards && props.has_activities ? "merchant" : "cards");
  }, [props.hasCards]);

  return (
    <React.Fragment>
      <SelectTab
        forceColorActive={!props.hasCards}
        disabled={!props.hasCards}
        list={[
          {
            testName: "merchant",
            active: tab === "merchant",
            onClick: () => setTab("merchant"),
            label: "fornecedores",
            href: "merchant",
          },
          {
            testName: "cards",
            active: tab === "cards",
            onClick: () => setTab("cards"),
            label: "cartões",
            href: "cards",
          },
        ]}
      />

      <div className="collection z-depth-1">
        {tab === "merchant" ? (
          <SubscriptionMerchants {...props} />
        ) : (
          <SubscriptionCards {...props} />
        )}
      </div>
    </React.Fragment>
  );
};

export default CardsAndMerchants;
