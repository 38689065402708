import React from "react";
import { IMaskInput } from "react-imask";

const PhoneInputMask = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask={["(00) [0000-0000", "(00) 00000-0000"]}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props.name, value } })}
            overwrite
        />
    );
});

export default PhoneInputMask;