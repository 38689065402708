import classNames from "classnames";
import React from "react";
import MaskedInput from "react-text-mask";

import { initSingleDatePickerMaxToday } from "../jquery_wrapper";
class Masked extends React.Component {
  componentDidMount() {
    $(".js-value-mask").maskMoney({
      thousands: ".",
      decimal: ",",
      allowZero: true,
    });
  }

  render() {
    return (
      <div
        className="form-design-on-surfaces masked-input"
        style={{ width: `${this.props.size}%` }}
      >
        <div
          className={classNames("input-field", {
            error:
              (this.props.value.length > 0 && this.props.error) ||
              this.props.forceError,
          })}
        >
          {this.props.mask ? (
            <MaskedInput
              test-name={this.props.test}
              guide={false}
              placeholder={this.props.placeholder}
              mask={this.props.mask}
              onChange={this.props.onChange}
              value={this.props.value}
            />
          ) : (
            <React.Fragment>
              {this.props.type === "date" && (
                <input
                  test-name="auxiliar-datepicker"
                  className="auxiliar-datepicker hide"
                  type="text"
                  onClick={this.props.onChange}
                />
              )}
              <input
                className={classNames(this.props.className, {
                  "single-datepicker-max-today": this.props.type === "date",
                })}
                test-name={this.props.test}
                type={this.props.type === "date" ? "text" : this.props.type}
                placeholder={this.props.placeholder}
                onBlur={this.props.onBlur}
                onChange={
                  this.props.type === "date"
                    ? this.handleChange
                    : this.props.onChange
                }
                onFocus={
                  this.props.type === "date"
                    ? initSingleDatePickerMaxToday
                    : this.handleChange
                }
                value={this.props.value}
                disabled={this.props.disabled}
              />
            </React.Fragment>
          )}
          <label className="active">
            {this.props.label}
            {this.props.required && " *"}
          </label>
        </div>
      </div>
    );
  }

  handleChange() {}
}

Masked.defaultProps = {
  test: "",
  size: 100,
  placeholder: "",
  label: "",
  mask: undefined,
  required: false,
  type: "text",
  error: false,
  forceError: false,
  value: "",
  disabled: false,
  onChange: Function,
  onBlur: Function,
  className: "",
};

export default Masked;
