import React, {useState} from "react";
import {ErpTemplateConfig} from "../ErpTemplateConfig";
import {SankhyaUserConfigSteps} from "./SankhyaUserConfigSteps";
import {SankhyaUserConfigForm} from "./SankhyaUserConfigForm";
import {updateTemplate} from "../../erp_template_connector";

export const SankhyaUserConfig = ({template}) => {
    const [field, setField] = useState(template?.settings?.field ?? '')
    const [frequency, setFrequency] = useState(template?.frequency ?? 'daily')

    const changeData = ({field, frequency}) => {
        setField(field)
        setFrequency(frequency)
    }

    return <ErpTemplateConfig
        erp={'sankhya'}
        title={'Configuração de usuários'}
        validConfig={field.length > 0}
        onConfigure={() => updateTemplate('sankhya', 'user', {field}, frequency)}
        steps={<SankhyaUserConfigSteps/>}
        form={(loading, failed) => (
            <SankhyaUserConfigForm
                data={{field, frequency}}
                onChangeData={changeData}
                failed={failed}
                disabled={loading}/>
        )}/>
}
