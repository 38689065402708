import React from "react";
import { ShadowTheme } from "../../../admin/ShadowTemplate";

import ModalCancelAccount from "./ModalCancelAccount";

// last import
import { hot } from "react-hot-loader";

const Account = (props) => {
  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <ModalCancelAccount {...props} />
    </ShadowTheme>
  );
};

export default hot(module)(Account);
