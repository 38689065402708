import React from "react";

import { initModals, closeModal } from "../jquery_wrapper";

import CardInfos from "../CardInfos";

class Details extends React.Component {
  componentDidMount() {
    initModals();
  }

  render() {
    return (
      <div id="modal-detail" className="modal modal-fixed modal-detail">
        <div className="modal-content">
          <h5 className="tittle">
            <i className="mdi mdi-file-document icon" />
            {this.props.tittle}
          </h5>
          {this.props.details.length > 0 && (
            <CardInfos items={this.props.details} border moreSpace />
          )}

          {this.props.sectionsList.map((e, i) => (
            <React.Fragment key={i}>
              <h5>{this.props.sectionsTittles[i]}</h5>
              <CardInfos items={e} border moreSpace />
            </React.Fragment>
          ))}
        </div>
        <div className="modal-footer">
          {this.props.actionButton && (
            <button
              test-name="action-button"
              className="btn action-button"
              onClick={this.props.buttonOnClick}
            >
              {this.props.buttonLabel}
            </button>
          )}
          <button test-name="close-modal-button" className="btn modal-close">
            Fechar
          </button>
        </div>
      </div>
    );
  }
}

Details.defaultProps = {
  details: [],
  sectionsList: [],
  sectionsTittles: [],
  tittle: "",
  actionButton: false,
  buttonOnClick: Function,
  buttonLabel: "",
};

export default Details;
