import React, {useMemo, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    Stack,
    TextField,
    Typography
} from "@mui/material";

export const LightboxFormButtons = ({onSave, onBack, onPreview, initialButtons, editing}) => {
    const [primaryLabel, setPrimaryLabel] = useState(initialButtons?.primary?.label ?? '');
    const [primaryUrl, setPrimaryUrl] = useState(initialButtons?.primary?.url ?? '');
    const [primaryError, setPrimaryError] = useState(null);

    const [secondaryLabel, setSecondaryLabel] = useState(initialButtons?.secondary?.label ?? '');
    const [secondaryUrl, setSecondaryUrl] = useState(initialButtons?.secondary?.url ?? '');
    const [secondaryError, setSecondaryError] = useState(null);

    const [saving, setSaving] = useState(false);

    const prepareButtons = () => ({
        primary: {label: primaryLabel, url: primaryUrl},
        secondary: {label: secondaryLabel, url: secondaryUrl}
    })

    const handlePreview = () => {
        if (!validateFields()) return;
        onPreview(prepareButtons());
    }
    const handleSave = async () => {
        if (!validateFields()) return;
        setSaving(true);
        await onSave(prepareButtons());
        setSaving(false);
    }

    const validatePrimary = () => {
        if (primaryLabel.trim().length === 0 || primaryUrl.trim().length === 0) {
            setPrimaryError('Ambos campos Título e URL devem ser preenchidos');
            return false;
        }
        setPrimaryError(null);
        return true;
    }

    const validateSecondary = () => {
        const secondaryLabelEmpty = secondaryLabel.trim().length === 0;
        const secondaryUrlEmpty = secondaryUrl.trim().length === 0;
        if (secondaryLabelEmpty !== secondaryUrlEmpty) {
            setSecondaryError('Ambos campos Título e URL devem ser preenchidos ou deixados em branco');
            return false;
        }
        setSecondaryError(null);
        return true;
    }

    const validateFields = () => !!(validatePrimary() * validateSecondary());

    const handlePrimaryLabel = (event) => setPrimaryLabel(event.target.value);
    const handlePrimaryUrl = (event) => setPrimaryUrl(event.target.value);
    const handleSecondaryLabel = (event) => setSecondaryLabel(event.target.value);
    const handleSecondaryUrl = (event) => setSecondaryUrl(event.target.value);

    const confirmLabel = useMemo(() => {
        if (editing && saving) return 'Atualizando...';
        if (editing) return 'Atualizar';
        if (saving) return 'Criando...';
        return 'Criar';
    }, [saving, editing]);

    return <>
        <Card>
            <CardContent>
                <Stack>
                    <LightboxFormPrimaryButton
                        error={primaryError}
                        label={primaryLabel}
                        url={primaryUrl}
                        onChangeLabel={handlePrimaryLabel}
                        onChangeUrl={handlePrimaryUrl}/>
                    <LightboxFormSecondaryButton
                        error={secondaryError}
                        label={secondaryLabel}
                        url={secondaryUrl}
                        onChangeLabel={handleSecondaryLabel}
                        onChangeUrl={handleSecondaryUrl}/>
                </Stack>
            </CardContent>
        </Card>
        <Box sx={{my: 2}}>
            <div>
                <Button
                    data-testid={'lightbox-save-button'}
                    disabled={saving}
                    variant="contained"
                    onClick={handleSave}
                    sx={{mt: 1, mr: 1}}>
                    {confirmLabel}
                </Button>
                <Button
                    data-testid={'lightbox-preview-button'}
                    disabled={saving}
                    variant="outlined"
                    onClick={handlePreview}
                    sx={{mt: 1, mr: 1}}>
                    {'Visualizar'}
                </Button>
                <Button
                    data-testid={'lightbox-steps-back-button'}
                    disabled={saving}
                    onClick={onBack}
                    sx={{mt: 1, mr: 1}}>
                    Voltar
                </Button>
            </div>
        </Box>
    </>
}

const LightboxFormPrimaryButton = ({error, label, url, onChangeLabel, onChangeUrl}) => {
    return <FormControl required component="fieldset" variant="standard" error={!!error}>
        <FormLabel component="legend">Botão principal</FormLabel>
        <FormGroup sx={{mt: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField
                            data-testid={'lightbox-primary-title-field'}
                            name={'lightbox[buttons][primary][label]'}
                            label="Título"
                            variant="outlined"
                            value={label}
                            required
                            onChange={onChangeLabel}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField
                            data-testid={'lightbox-primary-url-field'}
                            name={'lightbox[buttons][primary][url]'}
                            label="URL"
                            variant="outlined"
                            value={url}
                            required
                            onChange={onChangeUrl}/>
                    </FormControl>
                </Grid>
            </Grid>
        </FormGroup>
        {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
}

const LightboxFormSecondaryButton = ({error, label, url, onChangeLabel, onChangeUrl}) => {
    return <FormControl sx={{mt: 2}} component="fieldset" variant="standard" error={!!error}>
        <FormLabel component="legend">
            Botão secundário
            <Typography variant={'caption'}>
                (deixe em branco se quiser apenas 1 botão)
            </Typography>
        </FormLabel>
        <FormGroup sx={{mt: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField
                            data-testid={'lightbox-secondary-title-field'}
                            name={'lightbox[buttons][secondary][label]'}
                            label="Título"
                            variant="outlined"
                            value={label}
                            onChange={onChangeLabel}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <TextField
                            data-testid={'lightbox-secondary-url-field'}
                            name={'lightbox[buttons][secondary][url]'}
                            label="URL"
                            variant="outlined"
                            value={url}
                            onChange={onChangeUrl}/>
                    </FormControl>
                </Grid>
            </Grid>
        </FormGroup>
        {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
}