import React from "react";
import { DialogTitle, Grid, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const TwoFactorConfigHeader = ({ title, onClose, canClose }) => {
  return <Grid mb={-2} pr={2} container alignItems={"center"}>
    <Grid item xs={11}>
      <DialogTitle>
        { title }
      </DialogTitle>
    </Grid>
    <Grid item xs={1} display={"flex"} justifyContent={"flex-end"}>
      <IconButton
        disabled={!canClose}
        onClick={onClose}
        data-testid="close-config-dialog"
      >
        <CloseIcon/>
      </IconButton>
    </Grid>
  </Grid>
}

export default TwoFactorConfigHeader;