import React, { useEffect, useState } from "react";
import { Typography, Alert, AlertTitle  } from "@mui/material";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#00CBA880',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const BalanceProgressFeedback = ({ progress, total, alertMessage, alertSeverity, showLinearProgress, showProgressMessage, description, alertTitle, action }) => {
  const [currentProgress, setCurrentProgress] = useState(0);

  const calculateProgressPercentage = (progress) => {
    if (!progress) return 0;

    return Math.min((progress / total) * 100, 100);
  };

  useEffect(() => {
    setCurrentProgress((prevProgress) => Math.max(prevProgress || 0, progress || 0));
  }, [progress]);

  return (
    <>
      {description && <Typography mb='26px' sx={{ fontSize: 16 }} gutterBottom>
        {description}
      </Typography>}

      {showLinearProgress && <CustomLinearProgress sx={{marginBottom: '26px'}}
        variant="determinate" value={calculateProgressPercentage(currentProgress)} />}

      {!showLinearProgress && alertSeverity && <Alert sx={{marginBottom: 1}} severity={alertSeverity} action={action}>
        <AlertTitle>{alertTitle}</AlertTitle>
        {alertMessage}
      </Alert>}

      {showProgressMessage && <Typography sx={{ fontSize: 16 }} gutterBottom>
        Processado: {currentProgress} / {total}
      </Typography>}
    </>
  );
};

export default BalanceProgressFeedback;
