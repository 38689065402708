import React from "react";

const CreateAccount = ({ open_process_status, swap_account_endpoint }) => {
  const labelOpenButton = () => {
    if (!open_process_status) {
      return "abrir conta swap";
    } else if (open_process_status === "approved") {
      return "Conta swap aberta!";
    } else {
      return "Editar dados conta swap";
    }
  };

  return (
    <div className="card">
      <div className="card-content">
        <h4>Abertura de Conta Swap</h4>
        <p>
          Aqui é possivel realizar a abertura da conta digital para o cliente,
          será necessário preencher os dados da empresa e do sócios.
        </p>
        <p>
          Ao ser aprovada a conta será associada automáticamente a conta da
          empresa.
        </p>
      </div>

      <div className="card-action">
        <div className="d-flex justify-between">
          <button
            test-name="open-btn"
            className="btn"
            disabled={open_process_status === "approved"}
            onClick={() => window.location.assign(swap_account_endpoint)}
          >
            {labelOpenButton()}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
