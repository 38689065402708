import {
  pma_statement_retry_bistro_accounts_path,
} from "../../../../routes";

const PmaRetryService = {
  retryPayment: (payment_id) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: pma_statement_retry_bistro_accounts_path(),
        data: { payment_id }
      })
      .done(data => resolve(data))
      .fail((jqXHR, textStatus, errorThrown) => reject({ jqXHR, textStatus, errorThrown }));
    });
  }
};

export default PmaRetryService;
