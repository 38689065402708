import React from "react";
import InputDateRangePicker from "./InputDateRangePicker";
import ModalFilterMultiSelect from "./modals/FilterMultiSelect";

import SearchField from "./SearchField";

class Filter extends React.Component {
  state = {
    period: "",
    search: "",
    selects: {},
    showModal: false,
    modalReference: "",
  };

  render() {
    return (
      <div id="filter-default" className="selection-filter-card">
        <ModalFilterMultiSelect
          title={this.props.filters[this.state.modalReference]?.label}
          list={this.props.filters[this.state.modalReference]?.list || []}
          selecteds={this.state.selects[this.state.modalReference] || []}
          handleSelect={(list) =>
            this.setState({
              ...this.state,
              selects: {
                ...this.state.selects,
                [this.state.modalReference]: list,
              },
            })
          }
        />
        <div className="card-panel">
          <h2>Defina os filtros</h2>
          <form className="form-design-on-surfaces">
            <div className="row">
              {this.props.showPeriodFilter && (
                <div className="col s12 input-field m4">
                  <InputDateRangePicker
                    period={this.state.period}
                    onChange={(value) =>
                      this.setState({ ...this.state, period: value })
                    }
                  />
                </div>
              )}
              <SearchField
                placeholder={this.props.searchPlaceholder}
                value={this.state.search}
                onChange={(e) => {
                  this.setState({ ...this.state, search: e.target.value });
                  this.props.searchAfterEachChar &&
                    this.props.onFilter({
                      ...this.state,
                      search: e.target.value,
                    });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    this.props.onFilter(this.state);
                  }
                }}
                searchInput={false}
                onSurfaces={false}
                customClass={
                  "s12 " + (this.props.showPeriodFilter ? "m8" : "m12")
                }
              />
            </div>
            <div className="chips-filter">{this.renderMoreFilters()}</div>
            <div className="component-organizer">
              <div className="separator" />
            </div>
            <div className="right-align">
              <a
                test-name="clear-filters"
                className="btn-flat grey-text"
                onClick={() => this.clearFilters()}
              >
                LIMPAR FILTROS
              </a>
              <div
                test-name="apply-filters"
                className="btn"
                onClick={() => this.props.onFilter(this.state)}
              >
                FILTRAR
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }

  // Custom methods

  renderMoreFilters() {
    return (
      <React.Fragment>
        {Object.keys(this.state.selects).map((filter, idx) => (
          <React.Fragment key={idx}>
            {this.state.selects[filter].length > 0 && this.renderTags(filter)}
          </React.Fragment>
        ))}
        <div
          className="chip dropdown-trigger chip-filter-options"
          data-target="filter-options"
        >
          <div className="chip-content">
            <i className="hashtag-icon mdi mdi-plus"></i>
            <span>FILTROS</span>
          </div>
        </div>
        <ul className="dropdown-content" id="filter-options">
          {Object.keys(this.props.filters).map((filter, idx) => (
            <li key={idx}>
              <a
                test-name={`modal-trigger-${filter}`}
                className="modal-trigger"
                href="#modal-base-filter"
                onClick={() => this.handleModal(filter)}
              >
                <span className="translation_missing">
                  {this.props.filters[filter].label}
                </span>
              </a>
            </li>
          ))}
        </ul>
      </React.Fragment>
    );
  }

  renderTags(filter) {
    return (
      <div test-name="select-group-tags" className="chip">
        <div className="chip-content">
          <a
            test-name={`open-modal-${filter}`}
            className="black-text modal-trigger"
            href="#modal-base-filter"
            onClick={() => this.handleModal(filter)}
          >
            {this.props.filters[filter].label}{" "}
            {this.state.selects[filter].length > 1
              ? `(${this.state.selects[filter].length} selecionados)`
              : `(${
                  this.props.filters[filter].list.find(
                    (el) => el.id === this.state.selects[filter][0]
                  )?.label
                })`}
          </a>
          <i
            test-name={`unselect-all-tags-${filter}`}
            className="mdi mdi-close"
            onClick={() => {
              const selects = this.state.selects;
              delete selects[filter];
              this.setState({ ...this.state, selects: selects });
            }}
          />
        </div>
      </div>
    );
  }

  clearFilters() {
    const state = {
      period: "",
      search: "",
      selects: {},
      showModal: false,
      modalReference: "",
    };
    this.setState(state);
    this.props.onFilter({ ...state, showFilter: false });
  }

  handleModal(reference) {
    this.setState({
      ...this.state,
      showModal: !this.state.showModal,
      modalReference: reference,
    });
  }
}

Filter.defaultProps = {
  searchPlaceholder: "",
  showPeriodFilter: true,
  searchAfterEachChar: true,
  onFilter: Function,
  filters: {},
};

export default Filter;
