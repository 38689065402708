import React from "react";
import {ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";

const HomeCardSummaryItem = ({icon, title, subtitle}) => {
  return (
    <ListItem disableGutters>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText
          primary={title}
          secondary={<Typography variant="subtitleGray">{subtitle}</Typography>}
          sx={{ mx: -1 }}/>
    </ListItem>
  );
}

export default HomeCardSummaryItem;