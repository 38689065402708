const companyFilters = {
    company_types : {
        label: 'Classificação',
        list: [
            { id: 'active',     label: 'Cliente' },
            { id: 'suspended',  label: 'Desativado' },
            { id: 'implanting', label: 'Em implantação' },
            { id: 'subsidized', label: 'Subsidiada'},
            { id: 'trial',      label: 'Trial'}
        ]
    },
    subscription_plans: {
        label: 'Plano',
        list: [
            { id: 'l2',     label: 'Free' },
            { id: 'l3',     label: 'Business' },
            { id: 'l4',     label: 'Select'}
        ]
    },
    company_test: {
        label: 'Teste',
        list: [
            {id: '1', label: 'Sim'},
            {id: '0', label: 'Não'}
        ]
    },
};

export default companyFilters;
