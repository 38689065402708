import React from "react";

import FeatureRepository from "../feature_repository";
import {openModal, showSnackbar} from "../../../shared/jquery_wrapper";
import BaristaIaLogo from "../../../../../assets/images/admin/barista_ai/barista_ai_purple_logo.svg"
import FeatureGroup from "../FeatureGroup";
import ProhibitiveWordsModal from "./ProhibitiveWordsModal";

class BaristaIAPolicyFeatureGroup extends React.Component {
  constructor(props) {
    super(props);
    this.group = React.createRef();
    this.repository = new FeatureRepository(this.props.featuresEndpoint);
  }

  /* istanbul ignore next */
  prohibitedWordsFeature = () => {
    return this.group.current?.state.features.find((f) => f.attributes.name === 'prohibited_words')
  }

  onUpdate = (feature, activate, error) => {
    let content_text = '';
    let state = activate == true ? 'ativado' : 'desativado';

    if (error) {
      let message = feature.attributes.name == 'automatic_approval' ? 'Não foi possível ativar a funcionalidade.' : 'Não foi possível ativar o alerta.'

      showSnackbar({
        content: message,
        style: "alert",
        timeout: 5000,
      });
    } else {
      switch (feature.attributes.name) {
        case 'alcohol_alert':
          content_text = `Palavra proibitiva para bebida alcoólica ${state}!`;
          break;
        case 'tobacco_alert':
          content_text = `Palavra proibitiva para tabaco e derivados ${state}!`;
          break;
        case 'automatic_approval':
          content_text = `Aprovação automática ${state}!`;
          break;
        case 'car_ride_notes':
          content_text = `Geração de Comprovantes ${state}!`;
          break;
        case 'prohibited_words':
          content_text = `Palavras proibitivas ${state}!`;
          break;
        default:
          content_text = `Antifraude ${state}!`
      }
    };
    
    showSnackbar({
      content: content_text,
      style: "alert",
      timeout: 3000,
    });
  };

  handleSwitch = async (feature, checked) => {
    let metadata = feature.attributes.metadata;

    if (checked && feature.attributes.name === 'prohibited_words') {
      openModal('#prohibitive-words-modal');
    } else {
      await this.group.current.updateFeature(feature, checked, metadata)
    }
  }
  
  render() {
    return (
      <div>
        <div style={{marginBottom: '14px'}}>
          <BaristaIaLogo style={{height: 30, width: 130}} />
        </div>
        
        <ProhibitiveWordsModal
          repository={this.repository}
          featureGroup={this.props.featureGroup}
          onConfirm={/* istanbul ignore next */ (keywords) => {
            this.group.current.updateFeature(this.prohibitedWordsFeature(), true, {keywords: keywords})
          }}
        />

        <FeatureGroup
          groupName={""}
          featuresEndpoint={this.props.featuresEndpoint}
          featureGroup={this.props.featureGroup}
          ref={this.group}
          onChangeSwitch={(feature, checked) => this.handleSwitch(feature, checked)}
          onUpdate={(feature, activate, error) => this.onUpdate(feature, activate, error)}
          ignoreAvailability={this.props.ignoreAvailability}
          hidePlanWhenActive={this.props.hidePlanWhenActive}
          baristaIA={true}
          customDescription={this.prohibitedWordsDescription}
        />
      </div>
    )
  }

  prohibitedWordsDescription = (feature) => {
    if (feature.attributes.active && feature.attributes.name === 'prohibited_words') {
      return <div className="col s12 m9 sub-header no-left-padding">
                <a href='#'
                    onClick={(e) => { e.preventDefault(); openModal('#prohibitive-words-modal'); }}
                    id="edit-prohibited-words">
                    {feature.attributes.subtitle} <span style={{color: '#3D0079'}}>(editar)</span>
                </a>
              </div>
    }

    return <div className="col s12 m9 sub-header no-left-padding">
      {feature.attributes.subtitle}
    </div>
  }
}

BaristaIAPolicyFeatureGroup.defaultProps = {
  featureGroup: 6,
  ignoreAvailability: false,
  hidePlanWhenActive: true,
  baristaIA: false
};

export default BaristaIAPolicyFeatureGroup;
