import React from "react";
import { admin_rd_leads_path } from "../../../../routes";

import { openModal } from "../../../shared/jquery_wrapper";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";

import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const HandRaising = ({
  open,
  close,
  userId,
  companyId,
  planLevel,
  planName,
}) => {
  const rdCall = () => {
    $.ajax({
      type: "POST",
      dataType: "json",
      url: admin_rd_leads_path(),
      data: {
        user_id: userId,
        company_id: companyId,
        identify: "upsell_orcamentos",
      },
      complete: function (xhr) {
        close();

        if (xhr.status === 200) {
          openModal("#success");
        } else {
          openModal("#failed");
        }
      },
    });
  };

  const plans = (name, itens) => (
    <Box sx={{ width: "450px" }}>
      <Typography sx={{ fontSize: "24px" }}>
        Atualize para o plano <b>{name}</b>
      </Typography>
      <List>
        {itens.map((item, index) => (
          <ListItem key={index} sx={{ padding: "0" }}>
            <ListItemIcon
              sx={{ minWidth: "auto", color: "#00CBA8", marginRight: "8px" }}
            >
              <CheckCircleIcon sx={{ fontSize: "20px" }} />
            </ListItemIcon>
            <ListItemText primary={item} sx={{ fontSize: "20px" }} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth={"md"}
      fullWidth={true}
      data-testid="dialog-hand-rainsing"
    >
      <DialogTitle
        style={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "#E2E0DF",
          padding: "16px !important",
        }}
      >
        <Typography
          sx={{ display: "flex", alignItems: "center", paddingLeft: "34px" }}
        >
          <LockIcon sx={{ fontSize: "16px", marginRight: "16px" }} />
          <span>
            Atualize o seu plano e libere novas funcionalidades que vão
            facilitar a rotina da sua empresa.
          </span>
        </Typography>
        <CloseIcon onClick={close} sx={{ cursor: "pointer" }} />
      </DialogTitle>
      <DialogContent sx={{ padding: "34px 44px !important" }}>
        <Typography>
          Seu plano atual é o: <b>{planName}</b>
        </Typography>
        <Box sx={{ display: "flex", marginTop: "30px" }}>
          {planLevel === 2 &&
            plans("Business", [
              "Tudo do plano Free",
              "Usuários ilimitados",
              "Conta digital",
              "Cartões físicos e virtuais",
              "Gestão de despesas recorrentes",
              "Cartão na Wallet mobile",
              "Conciliação automática",
            ])}
          {[2, 3].includes(planLevel) &&
            plans("Select", [
              "Tudo do plano Business",
              "Aprovação automática via IA",
              "Integração com ERP's",
              "Integração bancária com cartões",
              "Login via Single Sign ON (SSO)",
              "Aceite de termos personalizados",
              "Suporte via WhatsApp",
              "Campos personalizados",
              "Orçamentos",
            ])}
        </Box>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "flex-end",
          padding: "24px 12px",
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          data-testid="upgrade-button"
          onClick={rdCall}
          variant="contained"
        >
          fazer upgrade de plano
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HandRaising;
