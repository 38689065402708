import React from "react";
import { TextField } from "@mui/material";

const OTPField = ({ value, onChange, error, disabled }) => {
  const onValueChange = (event) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      onChange(value);
    }
  }

  return <TextField
    fullWidth
    name="user[otp_attempt]"
    value={value}
    onChange={onValueChange}
    placeholder="000000"
    data-testid="otp-field"
    inputProps={{
      color: "error",
      maxLength: 6,
      inputMode: "numeric",
    }}
    error={error}
    disabled={disabled}
    helperText={ error && "Código inválido" }
    sx={{
      "& .MuiOutlinedInput-root.Mui-disabled": {
        "& fieldset": {
          borderColor: "red",
        },
      },
      "& .MuiInputBase-input.Mui-disabled": {
        color: "red",
        WebkitTextFillColor: "red"
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiInputBase-input": {
        color: "red",
        WebkitTextFillColor: "red",
      },
    }}
  />
}

export default OTPField;