import React, { useState } from "react";
import { IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from "../appbar/profile_menu/profileMenuDataSource";

const BlockedAppBarMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const onOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const onCloseMenu = () => {
    setAnchorEl(null);
  }

  const onLogout = async () => {
    await logout();
    onCloseMenu();
  }

  return <>
    <IconButton
      onClick={onOpenMenu}
      data-testid={'blocked-appbar-menu-button'}
    >
      <ArrowDropDownIcon/>
    </IconButton>
    <Menu
      open={openMenu}
      onClose={onCloseMenu}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem
        onClick={onLogout}
        data-testid={'logout-menu-item'}
      >
        <ListItemIcon>
          <LogoutIcon/>
        </ListItemIcon>
        <Typography mr={10}>
          Sair
        </Typography>
      </MenuItem>
    </Menu>
  </>
}

export default BlockedAppBarMenu;