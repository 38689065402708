import {Avatar, Badge, Card, CardContent, CardHeader, Stack, Typography} from "@mui/material";
import React from "react";
import {erps} from "../Index";
import {statuses} from "../../components/ErpIntegrationItem";

export const ErpIntegrationResume = ({integration, erp}) => {
    return <Card>
        <CardHeader
            title={erp.title.toUpperCase()}
            titleTypographyProps={{variant: 'body1'}}
            avatar={<Avatar src={erp.image} variant={'rounded'}/>}
            action={<Stack direction={'row'}>
                <Badge variant={'dot'} color={statuses[integration.status].color} sx={{mt: 1.5, mr: 1}}/>
                <Typography variant={'body1'}>{statuses[integration.status].label}</Typography>
            </Stack>}/>
        <CardContent sx={{py: 0}}>
            <Typography variant={'body1'}>{statuses[integration.status].title}</Typography>
            <Typography variant={'body2'} color={'gray'}>
                {statuses[integration.status].subtitle(false)}
            </Typography>
        </CardContent>
    </Card>
}
