import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import dayjs from "dayjs";
import {completed_admin_reports_path} from "../../../../routes";

const categories = ['Total reembolsos', 'Prestação de contas de andiantamentos', 'Prestação de contas de cartão'];
const colors = ['#00CBA8', '#3D0079', '#E0D4D0']

const parseCurrencyString = function (inputString) {
    var numericString = inputString.replace(/[^\d,]/g, '');

    numericString = numericString.replace(',', '.');
    var result = parseFloat(numericString);

    return result;
};

const processData = (data) => {
    return data.map(row => row.map(value => {
        if (value) {
            return {
                y: parseCurrencyString(value),
                currencyValue: value
            };
        }
        return null;
    }));
};

/* istanbul ignore next */
const pointFormatter = function () {
    return `<b>${this.series.name}</b>: ${this.currencyValue}`;
};

const HomeExpensesChart = (props) => {
    if (!props?.values || Object.keys(props.values).length === 0) {
        return null;
    }

    const processedData = processData(props.values);
    const mappedSeries = processedData.map((values, index) => ({
        id: index,
        name: categories[index],
        color: colors[index],
        data: values
    }));

    /* istanbul ignore next */
    const onClickBar = (event) => {
        const baseDate = dayjs().subtract(3 - event.point.index, 'month');
        const startDate = baseDate.startOf('month').format('DD/MM/YYYY');
        const endDate = baseDate.endOf('month').format('DD/MM/YYYY');
        const period = `${startDate} à ${endDate}`;
        window.location.assign(completed_admin_reports_path({filter: {period: period}}))
    }

    const series = mappedSeries.filter(item => !(item.data.every(entry => entry === null)));

    const options = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: null,
        },
        xAxis: {
            categories: props.months,
            labels: {
                enabled: true,
            }
        },
        yAxis: {
            title: {
                text: null,
            },
            gridLineWidth: 0,
        },
        legend: {
            verticalAlign: 'top',
            align: 'left',
            itemStyle: {
                fontWeight: 'normal'
            }
        },
        tooltip: {
            pointFormatter: pointFormatter,
        },
        series: series,
        plotOptions: {
            series: {
                cursor: 'hand',
                point: {
                    events: {
                        click: onClickBar
                    }
                }
            }
        }
    };

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options}/>
        </div>
    );
};

export default HomeExpensesChart;