import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  CardHeader,
  LinearProgress,
  Pagination,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";

import { admin_account_banking_apis_statements_path } from "../../../../routes";

const Statement = ({}) => {
  const [selectTab, setSelectTab] = useState("transactions");
  const [statementList, setStatementList] = useState([]);
  const [statmentListTotal, setStatementListTotal] = useState(0);
  const [statmentListPage, setStatementListPage] = useState(1);
  const [showLoadingStatementList, setShowLoadingStatementList] =
    useState(false);
  const [searchStatementList, setSearchStatementList] = useState("");

  useEffect(() => {
    getStatementList();
  }, []);

  useEffect(() => {
    getStatementList();
  }, [selectTab]);

  const getStatementList = (page = 1, search = "") => {
    setShowLoadingStatementList(true);
    setSearchStatementList(search);

    $.ajax({
      type: "GET",
      url: admin_account_banking_apis_statements_path(),
      data: {
        filter: { q: { card_custom_id: search, scopes: [selectTab] }, page },
      },
    })
      .then((r) => {
        setStatementList(r.data.list);
        setShowLoadingStatementList(false);
        setStatementListTotal(r.data.total_count);
        setStatementListPage(r.data.current_page);
      })
      .catch((e) => {
        setShowLoadingStatementList(false);
      });
  };

  return (
    <Card sx={{ marginTop: "30px" }}>
      <CardHeader title="Extrato" />
      <Box sx={{ borderBottom: "solid 1px rgba(0, 0, 0, 0.12)" }}>
        <Tabs value={selectTab} onChange={(e, value) => setSelectTab(value)}>
          <Tab label={"transações"} value={"transactions"} />
          <Tab label={"recargas e resgates"} value={"transfers"} />
        </Tabs>
      </Box>
      <Box sx={{ padding: "15px" }}>
        <TextField
          sx={{ width: "630px" }}
          label={"Buscar"}
          placeholder="Identificador"
          value={searchStatementList}
          onChange={(e) => getStatementList(1, e.target.value)}
        />
      </Box>
      {showLoadingStatementList && <LinearProgress />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Descrição</TableCell>
            <TableCell>Nome</TableCell>
            {selectTab === "transactions" && (
              <TableCell>ID da transação</TableCell>
            )}
            <TableCell>Cartão</TableCell>
            <TableCell>Data</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statementList.map((activity, idx) => (
            <TableRow key={idx}>
              <TableCell>
                <Box>
                  <Typography>{activity.name}</Typography>
                  <Typography
                    sx={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6)" }}
                  >
                    {activity.cost}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: "14px" }}>
                  {activity.holder}
                </Typography>
              </TableCell>
              {selectTab === "transactions" && (
                <TableCell>
                  <Typography sx={{ fontSize: "14px" }}>
                    {activity.custom_id}
                  </Typography>
                </TableCell>
              )}
              <TableCell>
                <Typography sx={{ fontSize: "14px" }}>
                  {activity.card_number}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: "14px" }}>
                  {activity.date}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <Pagination
          style={{ margin: "12px" }}
          sx={{ mb: 3, ml: 3 }}
          count={Math.ceil(statmentListTotal / 5)}
          page={Number(statmentListPage)}
          onChange={(event, value) =>
            getStatementList(value, searchStatementList)
          }
          color="primary"
          shape="rounded"
        />
      </Table>
      {showLoadingStatementList && <LinearProgress />}
    </Card>
  );
};

export default Statement;
