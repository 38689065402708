import React from "react";
import SubmitReportRepository from "./submit_report_repository";
import Currency from "../../shared/Currency";
import {showSnackbar} from "../../shared/jquery_wrapper";

class SubmitReportModal extends React.Component {
  constructor(props) {
    super(props);
    this.repository = new SubmitReportRepository(this.props.submissionEndpoint)
    this.state = {
      advances: [],
      selected: new Set()
    }
  }

  async componentDidMount() {
    const submitForm = $('#modal-submit-report')
    const observer = new MutationObserver((mutationList, _) => {
      let opened = mutationList.some((e) => this.isModalOpening(e));
      if (opened) this.getUsableAdvances(submitForm);
    })
    observer.observe(submitForm.get(0), {attributes: true})
  }

  isModalOpening(mutation) {
    return mutation.type === 'attributes'
      && mutation.attributeName === 'class'
      && mutation.target.className.includes('open');
  }

  async getUsableAdvances(submitForm) {
    try {
      let expenseNodes = submitForm.find('form').find('.js-input-expense');
      let expenseIds = expenseNodes.get().map((e) => e.value)
      let response = await this.repository.submissionData(expenseIds)
      this.setState({
        ...this.state,
        advances: response.data.usable_advances,
        selected: new Set(response.data.usable_advances.map(a => a.id)),
      });
    } catch (error) {
      showSnackbar({
        content: "Não foi possível carregar os adiantamentos descontáveis",
        style: "alert",
        timeout: 3000
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.advances.length > 0 && <section>
          <h4>Saldo disponível</h4>
          <p>Indique abaixo os adiantamentos que podem ser utilizados para descontar o valor
            das despesas reembolsáveis. Deixe em branco se preferir que o relatório seja
            reembolsado.</p>
          {this.state.selected.size === 0 &&
            <input type="hidden"
                   id={'report_suggested_advance_ids'}
                   name="report[suggested_advance_ids]"
                   value={undefined}/>}
          {this.state.advances.map((advance, index) => (
            <p key={index}>
              <label>
                <input type="checkbox"
                       id={`report_suggested_advance_ids_${advance.id}`}
                       name={this.state.selected.size === this.state.advances.length ? null : "report[suggested_advance_ids][]"}
                       value={advance.id}
                       className="filled-in"
                       checked={this.state.selected.has(advance.id)}
                       onChange={(_) => this.handleCheck(advance)}/>
                <span>{this.advanceChecklistName(advance)}</span>
              </label>
            </p>
          ))}
        </section>}
      </React.Fragment>
    );
  }

  advanceChecklistName(advance) {
    const advanceName = advance.type === 'withdrawal_advance' ? 'Saque' : 'Adiantamento'
    return (
      <span>
        <a href={advance.path}
           target="_blank">{`${advanceName} #${advance.number}`}</a>
        - <Currency currency={advance.currency} cost={advance.balance}/>
      </span>
    );
  }

  handleCheck(advance) {
    let newSet = this.state.selected;
    if (newSet.has(advance.id)) {
      newSet.delete(advance.id);
    } else {
      newSet.add(advance.id);
    }
    this.setState({...this.state, selected: newSet});
  }
}

SubmitReportModal.defaultProps = {
  submissionEndpoint: "",
};

export default SubmitReportModal;
