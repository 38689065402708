import React from "react";
import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Alert,
  Divider,
  CardActions,
} from "@mui/material";

const BudgetEmptyStateCard = ({ budgetsEnabled, buttonProps }) => {
  const [enabled, setEnabled] = React.useState(
    budgetsEnabled ? "budgetsEnabled" : "budgetsNotEnabled"
  );

  const textVariables = {
    budgetsEnabled: {
      title: "Gerencie seus orçamentos por projetos",
      bannerName: "banner_abilited.png",
      resume:
        "Personalize seus orçamentos com opções de recorrência e mantenha total controle sobre as finanças dos seus projetos.",
      whatIsABudget:
        "Um orçamento de projeto é essencial para gerenciar suas finanças. Ele oferece uma visão clara das receitas e despesas, permitindo um planejamento e alocação eficiente dos recursos.",
      whyDoBudgets:
        "Com orçamentos detalhados, você pode tomar decisões financeiras mais estratégicas, evitar gastos desnecessários e ter melhor controle do fluxo de caixa em seus projetos.",
      howDoesCreateBudget: (
        <React.Fragment>
          <span>Nossa funcionalidade permite que você:</span>
          <ul style={{ margin: "0", paddingLeft: "24px" }}>
            <li>Crie orçamentos detalhados.</li>
            <li>Defina recorrências flexíveis.</li>
            <li>
              Ajuste suas projeções facilmente com a opção de edição
              pós-criação.
            </li>
          </ul>
        </React.Fragment>
      ),
      buttonText: "criar orçamento",
    },
    budgetsNotEnabled: {
      title:
        "Descubra o poder da gestão com Orçamentos detalhados no Plano Select!",
      bannerName: "banner_disabled.png",
      resume:
        "Leve sua gestão de projetos ao próximo nível com orçamentos personalizados e recorrentes, mantendo controle total sobre receitas, despesas e recursos.",
      whatIsABudget:
        "Orçamentos são a base para uma gestão financeira inteligente. Com eles, você tem uma visão clara das receitas e despesas de cada projeto, permitindo um planejamento preciso e uma alocação eficiente de recursos.",
      whyDoBudgets:
        "Com orçamentos detalhados, você terá o controle financeiro necessário para tomar decisões mais estratégicas, evitar surpresas no fluxo de caixa e eliminar gastos desnecessários.",
      howDoesCreateBudget: (
        <span>
          A criação de orçamentos é simples e flexível! Com o Plano Select, você
          cria orçamentos detalhados, ajusta as projeções em tempo real e define
          recorrências de maneira intuitiva. Tudo para que seu time tenha mais
          controle e eficiência na gestão de projetos.
        </span>
      ),
      buttonText: "Fazer upgrade para usar orçamentos",
    },
  };

  return (
    <Card
      sx={{ p: 2.0, width: "80%", marginBottom: "60px" }}
      data-testid="budgets"
    >
      <CardHeader sx={{}} title={textVariables[enabled].title} />
      <CardContent>
        <img
          src={require(`../../../../../../app/assets/images/admin/budgets/${textVariables[enabled].bannerName}`)}
          alt="banner"
          style={{ width: "100%" }}
        />
        <Typography sx={{ margin: "8px 0" }}>
          {textVariables[enabled].resume}
        </Typography>
        {budgetsEnabled && (
          <React.Fragment>
            <Alert
              severity="info"
              sx={{ margin: "8px 0", alignItems: "center" }}
            >
              <Typography style={{ fontWeight: "bold" }}>
                Importante:
              </Typography>
              <Typography>
                Antes de criar um orçamento, certifique-se de ter um projeto
                criado e ativo.
              </Typography>
            </Alert>
            <Typography style={{ fontWeight: "700", margin: "16px 0" }}>
              Como configurar a gestão de orçamentos:
            </Typography>
            <ul style={{ color: "rgba(0, 0, 0, 0.6)" }}>
              <li>
                Ativar alocação em Projetos: Vá até "Classificação de Despesas"
                na aba de Funcionalidades e habilite essa opção.
              </li>
              <li>
                Aprovação Multiníveis: Se o fluxo de aprovação for necessário,
                ative "Aprovação Multiníveis" e configure a "Alçada de
                Aprovação" na seção de Aprovações.
              </li>
            </ul>
          </React.Fragment>
        )}
        <Divider />
        <Typography style={{ fontWeight: "700", margin: "16px 0" }}>
          O que é um orçamento de projeto?
        </Typography>
        <Typography style={{ color: "rgba(0, 0, 0, 0.6)" }}>
          {textVariables[enabled].whatIsABudget}
        </Typography>
        <Typography style={{ fontWeight: "700", margin: "16px 0" }}>
          Por que fazer um orçamento?
        </Typography>
        <Typography style={{ color: "rgba(0, 0, 0, 0.6)" }}>
          {textVariables[enabled].whyDoBudgets}
        </Typography>
        <Typography style={{ fontWeight: "700", margin: "16px 0" }}>
          Como criar orçamentos?
        </Typography>
        <Typography style={{ color: "rgba(0, 0, 0, 0.6)" }}>
          {textVariables[enabled].howDoesCreateBudget}
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "right" }}>
        <Button
          variant="contained"
          sx={{ mt: 1.0 }}
          data-testid="action-button"
          {...buttonProps}
        >
          {textVariables[enabled].buttonText}
        </Button>
      </CardActions>
    </Card>
  );
};

export default BudgetEmptyStateCard;
