import React, { useState } from "react";
import { Link, Typography } from "@mui/material";
import TwoFactorFaqDrawer from "./TwoFactorFaqDrawer";

const TwoFactorConfigFaqLink = () => {
  const [openFaqDrawer, setOpenFaqDrawer] = useState(false);
  return <>
    <Typography mt={2}>
      Está com dúvidas? Acesse nossa <Link onClick={() => setOpenFaqDrawer(true)}>FAQ</Link>
    </Typography>
    <TwoFactorFaqDrawer
      open={openFaqDrawer}
      onClose={() => setOpenFaqDrawer(false)}
    />
  </>
}

export default TwoFactorConfigFaqLink;