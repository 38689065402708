import React from "react";

class InputDateRangePicker extends React.Component {
  state = {};

  componentDidUpdate(prevProps, prevState) {
    if (
      (prevProps.period !== this.props.period && this.props.period === "") ||
      (prevProps.period === "" && this.props.period === "")
    ) {
      $("input#date-period.range-datepicker").val("");
    }
  }

  render() {
    /* istanbul ignore next */
    $("input#date-period.range-datepicker").on(
      "apply.daterangepicker",
      function (ev, picker) {
        const auxiliarElement = document.getElementById("auxiliar-date-period");
        auxiliarElement.value =
          picker.startDate.format("DD-MM-YYYY") +
          " " +
          picker.endDate.format("DD-MM-YYYY");
        auxiliarElement.click();

        $(this).val(
          picker.startDate.format("DD/MM/YYYY") +
            " - " +
            picker.endDate.format("DD/MM/YYYY")
        );
      }
    );

    return (
      <React.Fragment>
        <input
          test-name="date-picker-input-invisible"
          id="auxiliar-date-period"
          className="hide"
          onClick={(e) => this.props.onChange(e.target.value)}
        />
        <input
          test-name="date-picker-input-visible"
          id="date-period"
          className="range-datepicker"
          type="text"
          placeholder="Selecione o período"
          readOnly={"readonly"}
        />
        <label htmlFor="date-period" className="active">
          Período
        </label>
      </React.Fragment>
    );
  }
}

InputDateRangePicker.defaultProps = {
  onChange: () => {},
  period: undefined,
};

export default InputDateRangePicker;
