import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Box, Badge } from '@mui/material';
import Typography from '@mui/material/Typography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {getPlatformUpdates, updatePlatformUpdates} from "../../appbar/platform_updates/platformUpdatesDataSource";
import dayjs from "dayjs";
import { useTheme } from "@mui/material/styles";

const HomeNoticeCard = (props) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [lastSeen, setLastSeen] = React.useState(props.lastSeenUpdate ? dayjs(props.lastSeenUpdate * 1000) : null);
  const newUpdates = React.useMemo(
    () => {
      return updates.filter((update) => lastSeen == null || dayjs(update.createdAt).isAfter(lastSeen))
    },
    [updates, lastSeen]
  )

  useEffect(() => {
    getPlatformUpdates().then((data) => setUpdates(data))
  }, []);

  const handleExpandClick = (event) => {
    updatePlatformUpdates();
    setLastSeen(dayjs());
    setExpanded(!expanded);
  };

  return (
    updates.length > 0 && (
      <Card sx={{marginBottom: '20px'}} data-testid="home-notice">
        <CardContent style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ p: 1.5, display: 'flex' }} style={{ backgroundColor: theme.palette.surfaceLight5.main, borderRadius: '50%' }}>
              <Badge
                color={"pink"}
                badgeContent={newUpdates.length}>
                {newUpdates.length > 0 ? <NotificationsIcon color="primary" /> : <NotificationsNoneIcon/>}
              </Badge>
            </Box>

            <Typography variant="subtitle1" sx={{ marginLeft: '8px' }}>
              Avisos
            </Typography>
          </div>
          
          <ExpandMoreIcon
            onClick={handleExpandClick}
            style={{ transform: `rotate(${expanded ? '180deg' : '0deg'})` }}
            data-testid="expand-icon"
          />
        </CardContent>

        <Collapse in={expanded}>
          {updates.map((update, idx) => (
            <Card key={idx}>
              <CardActionArea href={update.redirectUrl}>
                {update.bannerUrl && (
                  <CardMedia component="img" image={update.bannerUrl} data-testid="card-media" />
                )}

                <CardContent>
                    <Typography variant={"caption"}>
                        {dayjs(update.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        {update.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {update.body}
                    </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Collapse>
      </Card>
    )
  );
};

export default HomeNoticeCard;
