import React from "react";
import { Grid } from '@mui/material';
import HomeTotalCard from "./HomeTotalCard";
import DvrIcon from '@mui/icons-material/Dvr';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

export const SubmittedExpensesTotalCard = ({total}) => {
    return <HomeTotalCard
      total={total}
      title={"Gestão de despesas"}
      description={"Total de despesas submetidas no último mês"}
      icon={<DvrIcon color={"primary"} />} />
}

export const AccountBalanceTotalCard = ({total}) => {
    return <HomeTotalCard
      total={total}
      title={"Saldo da conta digital"}
      description={"Total disponível (alocado + disponível)"}
      icon={<AttachMoneyIcon color={"primary"} />} />
}

export const CardsSpendingTotalCard = ({total}) => {
    return <HomeTotalCard
      total={total}
      title={"Subcontas"}
      description={"Total gasto nos cartões corporativos no último mês"} 
      icon={<PersonIcon color={"primary"} />} />
}

export const SubscriptionsSpendingTotalCard = ({total}) => {
    return <HomeTotalCard
      total={total}
      title={"Fornecedores"}
      description={"Total gasto com fornecedores no último mês"}
      icon={<BusinessIcon color={"primary"} />} />
}

const HomeTotalCardList = (props) => {
  return (
    <Grid container spacing={2} alignItems={'stretch'} sx={{ mb: 2 }} data-testid="home-total-cards">
      <Grid item md={ props.hasAccount ? 3 : 6 } sm={6} xs={12}>
        <SubmittedExpensesTotalCard total={props.submittedExpensesTotal} />
      </Grid>
      <Grid item md={ props.hasAccount ? 3 : 6 } sm={6} xs={12}>
        <AccountBalanceTotalCard total={props.accountBalanceTotal} />
      </Grid>
      { props.hasAccount ?
        <Grid item md={3} sm={6} xs={12}>
          <CardsSpendingTotalCard total={props.cardsSpendingTotal} />
        </Grid> : <div/> }
      { props.hasAccount ?
        <Grid item md={3} sm={6} xs={12}>
          <SubscriptionsSpendingTotalCard total={props.subscriptionsSpendingTotal} />
        </Grid> : <div/> }
    </Grid>
  );
}

export default HomeTotalCardList;