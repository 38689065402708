import React from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from '@mui/material';

const Confirm = ({ open, title, content, onConfirm, onCancel }) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" data-testid="cancel-dialog">
          Cancelar
        </Button>
        <Button onClick={onConfirm} color="primary" data-testid="confirm-dialog">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;