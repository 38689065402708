import cable from '../../cable';

const AccountBulkBalanceAdjustmentChannel = ({ userId, key, onReceive, onConnected }) => {
  return cable.subscriptions.create(
    { channel: "Account::BulkBalanceAdjustmentChannel", user_id: userId, key: key },
    {
      received: (data) => onReceive(data),
      connected: () => onConnected(),
    }
  );
};

export default AccountBulkBalanceAdjustmentChannel;
