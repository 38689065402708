import React, {useCallback, useState} from "react";
import {
    Alert,
    Avatar,
    Button,
    CardActions,
    CircularProgress,
    Divider,
    Grid,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import CheckIcon from "@mui/icons-material/Check";

export const EventItem = ({event, onArchive, afterArchive, onOpenLog, onOpenRelated}) => {
    const [open, setOpen] = useState(false)

    return <>
        <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemAvatar>
                <Avatar sx={{bgcolor: '#F5F2F8'}}>
                    <PersonIcon color={'primary'}/>
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={event.eventType}
                secondary={eventStatuses[event.statusName].title}
                secondaryTypographyProps={{color: eventStatuses[event.statusName].color}}/>
            {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </ListItemButton>
        <Collapse sx={{px: 2}} in={open} timeout="auto" unmountOnExit>
            {event.failure && (
                <EventFailure event={event} onArchive={onArchive} afterArchive={afterArchive}/>
            )}
            <Grid container>
                {[...Object.entries(event.payload).map(([key, value]) =>
                    <Grid item key={key}>
                        <ListItem>
                            <ListItemText
                                primary={payloadKeys[key]}
                                secondary={payloadValue(key, value)}/>
                        </ListItem>
                    </Grid>
                )]}
            </Grid>
            <CardActions sx={{p: 2, justifyContent: 'flex-end'}}>
                {onOpenLog && <Button onClick={onOpenLog}>Ver Log</Button>}
                {onOpenRelated && <Button onClick={onOpenRelated} variant={'contained'}>
                    Eventos relacionados
                </Button>}
            </CardActions>
        </Collapse>
        <Divider/>
    </>
}

const EventFailure = ({event, onArchive, afterArchive}) => {
    const [archiving, setArchiving] = useState(false)
    const [archived, setArchived] = useState(false)

    const archive = useCallback(async () => {
        setArchiving(true)
        const succeed = await onArchive(event.uuid)
        setArchived(succeed)
        setArchiving(false)
        afterArchive?.()
    }, []);

    return <Alert
        severity="error" sx={{my: 2}}
        action={onArchive && <Button
            color={'inherit'}
            disabled={archiving}
            variant={'outlined'}
            onClick={archived ? null : archive}
            startIcon={archiving ? (
                <CircularProgress size={24} color={'disabled'}/>
            ) : archived ? (
                <CheckIcon/>
            ) : null}>
            {archiving ? 'Arquivando...' : archived ? 'Arquivado' : 'Arquivar'}
        </Button>}>
        {event.failure}
    </Alert>
}

const payloadValue = (key, value) => {
    if (key === 'active') {
        return value ? 'Ativo' : 'Inativo'
    }
    return value
}

const payloadKeys = {
    'name': 'Nome do usuário',
    'email': 'E-mail',
    'code': 'Identificador no ERP',
    'cpfCnpj': 'CPF/CNPJ',
    'active': 'Status'
}

const eventStatuses = {
    'archived': {
        title: 'Arquivado',
        color: '#616161'
    },
    'pending': {
        title: 'Pendente',
        color: "#EF6C00"
    },
    'failed': {
        title: 'Erro',
        color: 'error'
    },
    'completed': {
        title: 'Concluído',
        color: '#2E7D32'
    }
}