import React from "react";
import {Grid, Stack} from "@mui/material";
import {ShadowTheme} from "../ShadowTemplate";
import Grow from '@mui/material/Grow';
import HomeHeader from "./HomeHeader";
import HomeSubAccountsCard from "./sub_accounts/HomeSubAccountsCard";
import HomeTotalCardList from "./total_cards/HomeTotalCardList";
import HomeSubscriptionsCard from "./subscriptions/HomeSubscriptionsCard";
import HomeExpensesCard from "./expenses/HomeExpensesCard";
import HomeAccountCard from "./account/HomeAccountCard";
import HomeInsightsCard from "./insights/HomeInsightsCard";
import HomeNoticeCard from "./notice/HomeNoticeCard";
import useMediaQuery from "@mui/material/useMediaQuery";
import {hot} from "react-hot-loader";

const HomePageShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <HomePage {...props} />
    </ShadowTheme>
}

const HomePage = (props) => {
    const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

    return (
        <Grow in={true} timeout={1000}>
            <Stack sx={{px: 5.0, mb: 3}}>
                <HomeHeader {...props.header}/>
                <HomeTotalCardList {...props.totals} hasAccount={props.hasAccount}/>
                {smallScreen ? <HomeSingleColumn {...props} /> : <HomeDoubleColumn {...props} />}
            </Stack>
        </Grow>
    );
}

const HomeSingleColumn = (props) => {
    return <Stack spacing={2}>
        <HomeNoticeCard {...props.notice}/>
        <HomeExpensesCard {...props.expenses} advanceLegacy={props.advanceLegacy}/>
        <HomeAccountCard {...props.account} planLevel={props.planLevel}/>
        {props.hasAccount && <HomeSubAccountsCard {...props.subAccounts} />}
        {props.hasAccount && <HomeSubscriptionsCard {...props.subscriptions} />}
        {props.planLevel > 2 && <HomeInsightsCard/>}
    </Stack>
}

const HomeDoubleColumn = (props) => {
    const insightOnLeft = !props.hasAccount && (props.expenses.emptyState || props.advanceLegacy);

    return <Grid container spacing={2}>
        <Grid item xs={6}>
            <HomeExpensesCard {...props.expenses} advanceLegacy={props.advanceLegacy}/>
            {props.hasAccount && <HomeSubAccountsCard {...props.subAccounts} />}
            {props.hasAccount && <HomeSubscriptionsCard {...props.subscriptions} />}
            {insightOnLeft && props.planLevel > 2 && <HomeInsightsCard/>}
        </Grid>
        <Grid item xs={6}>
            <HomeNoticeCard {...props.notice}/>
            <HomeAccountCard {...props.account} planLevel={props.planLevel}/>
            {!insightOnLeft && props.planLevel > 2 && <HomeInsightsCard/>}
        </Grid>
    </Grid>
}

export default hot(module)(HomePageShadow);