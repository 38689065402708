import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";

const HomeEmptyCardContent = ({icon, title, subtitle, buttonText, buttonProps}) => {
  const theme = useTheme();
  return (
    <Stack alignItems={"center"} sx={{ mt: 2.0 }}>
      <Box sx={{ p: 1.5, display: 'flex' }} style={{ backgroundColor: theme.palette.surfaceLight5.main, borderRadius: 5.0 }}>
        {icon}
      </Box>
      <Typography variant="h6" sx={{ mt: 1.0 }}>{title}</Typography>
      <Typography
          whiteSpace={'pre-line'}
          minHeight={'3em'}
          maxWidth={'860px'}
          textAlign={'center'}
          variant="body1"
          color={"GrayText"}>
          {subtitle}
      </Typography>
      <Divider flexItem sx={{ my: 1.5 }} />
      <Button variant="outlined" {...buttonProps}>{buttonText}</Button>
    </Stack>
  );
}

export default HomeEmptyCardContent;