import React from "react";
import { Stack, Typography } from "@mui/material";

const MandatoryTwoFactorExplanation = () => {
  return <Stack>
    <Typography variant='h6'>
      Por que devo obrigar os colaboradores a ativar o 2FA?
    </Typography>
    <Typography my={2}>
      A ativação obrigatória do 2FA força os colaboradores a configurarem a autenticação de dois fatores, garantindo mais segurança. Essa funcionalidade não ativa o 2FA automaticamente, mas exige que cada colaborador o faça para proteger suas contas.
    </Typography>
    <Typography variant='h6'>
      Como funciona o 2FA obrigatório?
    </Typography>
    <Typography my={2}>
      A ativação do 2FA obrigatório funciona impondo uma política de segurança que exige que todos os colaboradores ativem a autenticação de dois fatores em suas contas antes de acessarem o sistema.
    </Typography>
    <Typography mb={2}>
      O que acontece quando essa política é habilitada pela empresa:
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      1. Primeiro Login: <Typography component={'span'}>
        Na próxima tentativa de login, o colaborador será direcionado a uma página de configuração do 2FA, onde deverá escolher um método de autenticação adicional, como um aplicativo de autenticação (Google Authenticator, Authy, etc.) ou E-mail.
      </Typography>
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      2. Configuração do Método: <Typography component={'span'}>
        O colaborador deve seguir as instruções para configurar o método escolhido. Por exemplo, ao usar um aplicativo de autenticação, ele precisa escanear um código QR ou inserir manualmente uma chave secreta.
      </Typography>
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      3. Verificação: <Typography component={'span'}>
        Após configurar, o colaborador deverá inserir um código gerado pelo método escolhido para verificar que o 2FA foi ativado corretamente.
      </Typography>
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      4. Acesso ao Sistema: <Typography component={'span'}>
        Somente após completar essa configuração e verificação o colaborador poderá acessar sua conta. A partir de então, em cada login, ele precisará fornecer tanto a senha quanto o código gerado pelo 2FA.
      </Typography>
    </Typography>
    <Typography variant='h6' my={2}>
      Quais são os benefícios para quem utiliza o 2FA?
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      1. Mais segurança: <Typography component={'span'}>
        Mesmo que alguém roube a senha do colaborador, não conseguirá acessar a conta dele sem o segundo fator de autenticação.
      </Typography>
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      2. Proteção contra hackers: <Typography component={'span'}>
        Reduz drasticamente as chances de invasão, pois é muito mais difícil para hackers conseguirem tanto a sua senha quanto o seu código de verificação.
      </Typography>
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      3. Tranquilidade: <Typography component={'span'}>
        Você pode dormir tranquilo sabendo que suas informações e dinheiro estão protegidos.
      </Typography>
    </Typography>
    <Typography sx={{ fontWeight: 'bold' }}>
      4. Simplicidade: <Typography component={'span'}>
        É fácil de usar e configurar, e os poucos segundos extras evitam sérias dores de cabeça.
      </Typography>
    </Typography>
  </Stack>
}

export default MandatoryTwoFactorExplanation;