import React from "react";
import { Stack, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.surfaceLight5.main,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.secondary.main,
  },
}));

const AccountBalanceProgress = (props) => {
  return (
    <Stack sx={{ mt: 1 }} data-testid="account-balance-progress">
      <CustomLinearProgress 
        height={50}
        variant="determinate"
        value={props.allocatedBalancePercent === 0 ? 1 : props.allocatedBalancePercent}
      />
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ py: 0.5 }}>
        <Typography variant="subtitleGray">
          Alocados nos cartões: {props.allocatedBalance}
        </Typography>
        <Typography variant="subtitleGray">
          Disponível para uso (na conta): {props.availableBalance}
        </Typography>
      </Stack>
    </Stack>
  );
}

export default AccountBalanceProgress;