import React from "react";
import { Button } from "@mui/material";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const FaqSupportButton = () => {
  return <Button
    variant="outlined"
    data-testid={"support-button"}
    href="https://suporte.espressoapp.com.br/support/home"
    target="_blank"
    startIcon={<HelpOutlineIcon/>}
    sx={{ mt: "auto", mb: 2, justifyContent: "left", textTransform: "none" }}
  >
    Ir para central de ajuda
  </Button>
}

export default FaqSupportButton;