import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import {admin_account_balance_statement_path} from "../../../../routes";

const HomeAccountChart = ({ data }) => {
	if (!data) return null;

	const values = !!data.length ? data : [{month: 'MÊS', total_amount: 'R$0,00'}]
	const months = values.map((value) => value.month);
	const series = values.map((value) => ({
		y: parseFloat(value.total_amount.replace('R$', '').replace(',', '.')),
		tooltipValue: value.total_amount,
	}));

	/* istanbul ignore next */
	const onClickPoint = () => {
		window.location.assign(admin_account_balance_statement_path())
	}

	const options = {
		chart: {
			type: 'line',
		},
		credits: {
			enabled: false
		},
		title: {
			text: null,
		},
		xAxis: {
			categories: months,
		},
		yAxis: {
			title: {
				text: null,
			},
			gridLineWidth: 1,
		},
		legend: {
			enabled: false,
		},
		series: [
			{
				name: 'Valor',
				data: series,
				color: '#D9A9FF',
				marker: {
					fillColor: '#3D0079',
					lineColor: '#3D0079',
				},
			}
		],
		tooltip: {
			pointFormat: '{point.tooltipValue}',
		},
		plotOptions: {
			series: {
				cursor: 'hand',
				point: {
					events: {
						click: onClickPoint
					}
				}
			}
		},
	};

	return (
		<div>
			<HighchartsReact highcharts={Highcharts} options={options} />
		</div>
	);
};

export default HomeAccountChart;