import React from "react";

class Balance extends React.Component {
  state = {};
  render() {
    return (
      <div className="col s12 m6">
        <div className="card-panel">
          <div className="account-infos">
            <div className="info button">
              <div className="data">
                <p className="balance">{this.props.balance}</p>
                <span>Saldo disponível</span>
              </div>
              {this.props.income && (
                <>
                  <div className="divider vertical-divider" />
                  {this.props.income && (
                    <div test-name="balance" className="data">
                      <p className="balance">{this.props.income}</p>
                      <span>Rendimentos este mês</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Balance;

Balance.defaultProps = {
  balance: "R$ 0,00",
  income: null,
};
