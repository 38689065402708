import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { expire_admin_security_password_expiration_index_path } from '../../../../routes';

const Immediate = (props) => {
  const [confirmation, setConfirmation] = useState('');
  const [openActionConfirmation, setOpenActionConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showSuccessButton, setShowSuccessButton] = useState(!props.manualExpirationAllowed);
  const [showExpireButton, setShowExpireButton] = useState(props.manualExpirationAllowed);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const handleConfirmationChange = (event) => {
    setConfirmation(event.target.value)
  }

  const handleExpirationButtonClick = () => {
    setOpenActionConfirmation(true);
  }

  const handleCloseDialog = () => {
    setOpenActionConfirmation(false);
  };

  const handleSubmit = async () => {
    try {
      if (confirmation !== "CONFIRMO") {
        setErrorMessage('É necessário digitar CONFIRMO para confirmar sua solicitação!');
        return;
      }

      const response = await fetch(expire_admin_security_password_expiration_index_path(), {
        method: 'POST',
        headers: {
          "X-CSRF-Token": props.csrfToken,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        setShowSuccessButton(true);
        setShowExpireButton(false);
        setOpenActionConfirmation(false);
      } else {
        const errorData = await response.json();
        setOpenSnackbar(true);
        setSnackbarMessage(`Ocorreu um erro ${errorData.error}`);
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(`Ocorreu um erro ${error.message}`);
    }
  }

  return  (
    <React.Fragment>
      <Typography variant="h6">
        Expiração Imediata
      </Typography>
                
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <Typography variant="subtitle1" style={{ flex: 1 }}>
          Essa ação fará com que todos os usuários criem novas senhas imediatamente
        </Typography>

        {showExpireButton && (
          <Button variant="contained" color="error" size="large" onClick={handleExpirationButtonClick}>
            Expirar agora
          </Button>
        )}

        {showSuccessButton && (
          <Button variant="outlined" color="success" size="large">
            Senhas Expiradas
          </Button>
        )}
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '3px' }}>
        {showSuccessButton && (
          <Typography variant="subtitle2">
            Solicite novamente em 30 dias
          </Typography>
        )}
      </div>

      <Dialog open={openActionConfirmation}>
        <DialogTitle>
          Digite CONFIRMO para confirmar sua ação
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <TextField
              label="Digite CONFIRMO para confirmar sua ação"
              type="confirmation"
              error={!!errorMessage}
              helperText={errorMessage}
              value={confirmation}
              onChange={handleConfirmationChange}
              pattern="CONFIRMO"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="text">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} variant="text" color="primary">
            Avançar
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        data-testid="snackbar"
        open={openSnackbar}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
      />
    </React.Fragment>
  )
};

export default Immediate;