import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    MobileStepper,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";
import BaristaIcon from "../../../shared/BaristaIcon";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import HomeInsightsStep from "./HomeInsightsStep";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {insight_admin_home_path} from "../../../../routes";

const HomeInsightsCard = () => {
    const [loading, setLoading] = useState(true);
    const [activeStep, setActiveStep] = useState(0);
    const [currentInsight, setCurrentInsight] = useState(null);
    const [insightIds, setInsightIds] = useState([0, 1, 2]);

    useEffect(() => {
        fetchInsight();
    }, [activeStep, insightIds]);

    const fetchInsight = async () => {
        if (insightIds.length === 0) return;

        setLoading(true);
        const insight = await getInsight(insightIds[activeStep]);
        setCurrentInsight(insight);
        if (!insight || insight.data.length === 0) {
            insightIds.splice(activeStep, 1);
            setInsightIds(insightIds);
            nextStep();
        }
        setLoading(false);
    }

    const getInsight = async (insightId) => {
        try {
            const response = await fetch(insight_admin_home_path({insight_id: insightId}));
            if (response.ok) {
                const body = await response.json();
                return body.insight;
            }
            return null;
        } catch (_) {
            return null;
        }
    }

    const nextStep = () => setActiveStep((step) => (step + 1) % insightIds.length);
    const previousStep = () => setActiveStep((step) => step === 0 ? insightIds.length - 1 : step - 1);

    if (insightIds.length === 0) return <></>;
    return <Card sx={{p: 2.0, border: 1, borderColor: 'primary90.main'}} data-testid="home-insights">
        <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
            <BaristaIcon icon={<AutoAwesomeIcon color="primary" fontSize="small"/>}/>
            <Typography variant="h6" color={"primary"}>Insights</Typography>
        </Stack>
        {loading && <HomeInsightLoading/>}
        {!loading && <HomeInsightsStep {...currentInsight} />}
        <MobileStepper
            sx={{mx: -1.5, mb: -2}}
            variant="dots"
            steps={insightIds.length}
            position="static"
            activeStep={activeStep}
            nextButton={<Button
                color="inherit"
                onClick={nextStep}
                endIcon={<KeyboardArrowRight/>}>Próximo</Button>}
            backButton={<Button
                color="inherit"
                onClick={previousStep}
                startIcon={<KeyboardArrowLeft/>}>Voltar</Button>}/>
    </Card>;
}


const HomeInsightLoading = () => {
    return <>
        <Skeleton animation="wave" height={12} width="50%" sx={{my: 2}}/>
        <LoadingListItem/>
        <Divider flexItem/>
        <LoadingListItem/>
        <Divider flexItem/>
        <LoadingListItem/>
        <Divider flexItem/>
    </>
}

const LoadingListItem = () => {
    return <ListItem data-testid={"loading-search-results"}>
        <ListItemIcon sx={{my: 1}}>
            <Skeleton variant="circular" width={40} height={40} animation={"wave"}/>
        </ListItemIcon>
        <ListItemText
            primary={<Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>}
            secondary={<Skeleton animation="wave" height={10} width="40%"/>}/>
    </ListItem>
}


export default HomeInsightsCard;