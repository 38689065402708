import React, {useCallback, useRef} from "react";


import EspressoTemplate from "../../../EspressoTemplate";
import {Stack} from "@mui/material";
import {PageNavbar} from "../../../components/PageNavbar";
import Container from "@mui/material/Container";
import {hot} from "react-hot-loader";
import {
    BistroTableCell,
    BistroTableColumn,
    BistroTableRow,
    EspressoTable
} from "../../../../components/collection/table/EspressoTable";
import {fetchActivityLogs} from "../erp_template_connector";
import {admin_erp_integration_template_events_path} from "../../../../../routes";

const Index = (props) => {
    const {erp, template, data, totalCount} = props;

    const tableRef = useRef(null);

    const fetchPage = useCallback(async (page) => {
        const body = await fetchActivityLogs(erp, template.templateType, page)
        return {...body, data: activityRows(body.data)}
    }, []);

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar
                title={'Histórico de ações'}
                backTo={admin_erp_integration_template_events_path(erp, template.templateType)}/>
            <Container maxWidth={'lg'} sx={{my: 5}}>
                <EspressoTable
                    ref={tableRef}
                    data={activityRows(data)}
                    totalCount={totalCount}
                    title={'Histórico'}
                    subtitle={'Registro detalhado das tentativas de integração de dados de ' +
                        'usuários com o ERP, incluindo ação, usuário e data.'}
                    hoverRows={false}
                    selectable={false}
                    fetchPage={fetchPage}
                    columns={activitiesColumns}
                    fixedPageSize={true}
                    emptyMessage={'Nenhuma ação registrada até o momento'}/>
            </Container>
        </Stack>
    </EspressoTemplate>
}

export const activitiesColumns = [
    BistroTableColumn({id: 'event', padding: 'normal', label: 'Ação', align: 'left'}),
    BistroTableColumn({id: 'user_name', label: 'Usuário', align: 'left'}),
    BistroTableColumn({id: 'created_at', label: 'Data e hora', align: 'left'}),
];

export const activityRows = (activities) => {
    return activities.map((activity) => BistroTableRow({
        id: activity.id,
        data: activity,
        cells: new Map([
            ['event', BistroTableCell({value: activity.event})],
            ['user_name', BistroTableCell({value: activity.userName})],
            ['created_at', BistroTableCell({value: activity.createdAt})],
        ])
    }));
}

export default hot(module)(Index)