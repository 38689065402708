import React from "react";
import { cnpj } from "cpf-cnpj-validator";

import { initSelects, showSnackbar } from "../../shared/jquery_wrapper";
import MaskedInput from "../../shared/inputs/Masked";
import {
  MainActivityMask,
  brazilStates,
  cnpjMask,
  dateMask,
  parserCNPJ,
  parserDate,
  parserMainActivity,
  parserPhone,
  parserPostalCode,
  phoneMask,
  postal_codeMask,
  validateDate,
} from "../../shared/helpers";
import Select from "../../shared/inputs/Select";

class LegalInfosForm extends React.Component {
  state = {
    cnpj: "",
    founding_date: "",
    main_activity: "",
    legal_name: "",
    business_type: "",
    email: "",
    phone: "",
    postal_code: "",
    street: "",
    number: "",
    complement: "",
    neighborhood: "",
    city: "",
    state: "",
    buttonDescription: "Salvar",
    id: "",
    try_to_save: false,
  };

  componentDidMount() {
    if (this.props.has_legal_info) {
      const infos = this.props.legal_infos;

      let address = this.props.address;
      delete address.id;

      this.setState({
        ...this.state,
        ...infos,
        ...address,
        phone: parserPhone(infos.phone),
        postal_code: parserPostalCode(address.postal_code),
        founding_date: parserDate(infos.founding_date),
        cnpj: parserCNPJ(infos.cnpj),
        main_activity: parserMainActivity(infos.main_activity),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      initSelects();
    }
  }

  render() {
    return (
      <form
        className="form-design-on-surfaces d-flex justify-between flex-wrap account-new-legal-infos"
        onSubmit={(e) => this.handleSubmit(e)}
      >
        <div className="full-width">
          <h5 className="tittle">Faça o cadastro dos dados da empresa</h5>
          <p>Insira os dados abaixo</p>
        </div>
        <MaskedInput
          required={true}
          error={this.invalidField("cnpj")}
          forceError={this.invalidField("cnpj") && this.state.try_to_save}
          test={"cnpj"}
          size={30}
          placeholder={"00.000.000/0000-00"}
          label={"CNPJ"}
          mask={cnpjMask()}
          value={this.state.cnpj}
          onChange={(e) => this.handleChange("cnpj", e.target.value)}
        />
        <MaskedInput
          required={true}
          error={this.invalidField("founding_date")}
          forceError={
            this.invalidField("founding_date") && this.state.try_to_save
          }
          test={"founding-date"}
          size={30}
          placeholder={"00/00/0000"}
          label={"Data de fundação"}
          mask={dateMask()}
          value={this.state.founding_date}
          onChange={(e) => this.handleChange("founding_date", e.target.value)}
        />
        <MaskedInput
          required={true}
          error={this.invalidField("main_activity")}
          forceError={
            this.invalidField("main_activity") && this.state.try_to_save
          }
          test={"main-activity"}
          size={30}
          placeholder={"Insira aqui"}
          label={"Principal atividade"}
          mask={MainActivityMask()}
          value={this.state.main_activity}
          onChange={(e) => this.handleChange("main_activity", e.target.value)}
        />
        <MaskedInput
          required={true}
          error={this.invalidField("legal_name")}
          forceError={this.invalidField("legal_name") && this.state.try_to_save}
          test={"legal-name"}
          size={65}
          placeholder={"Nome da empresa"}
          label={"Razão social"}
          value={this.state.legal_name}
          onChange={(e) => this.handleChange("legal_name", e.target.value)}
        />
        <Select
          required={true}
          error={this.invalidField("business_type") && this.state.try_to_save}
          value={this.state.business_type}
          onChange={(e) => this.handleChange("business_type", e.target.value)}
          test={"business-type"}
          size={30}
          name={"business_type"}
          placeholder={"Selecione o tipo da empresa"}
          label={"Tipo de empresa"}
          options={[
            { value: "MEI", label: "MEI" },
            { value: "ME", label: "ME" },
            { value: "EPP", label: "EPP" },
            { value: "EI", label: "EI" },
            { value: "EIRELI", label: "EIRELI" },
            { value: "LTDA", label: "LTDA" },
            { value: "SA ABERTA", label: "SA ABERTA" },
            { value: "SA FECHADA", label: "SA FECHADA" },
            { value: "DEMAIS", label: "DEMAIS" },
          ]}
        />
        <div className="full-width">
          <h5>Cadastrar endereço</h5>
          <p>Informe os dados de endereço da empresa</p>
        </div>
        <MaskedInput
          required={true}
          error={this.invalidField("street")}
          forceError={this.invalidField("street") && this.state.try_to_save}
          test={"street"}
          size={100}
          placeholder={"Insira aqui"}
          label={"Rua"}
          value={this.state.street}
          onChange={(e) => this.handleChange("street", e.target.value)}
        />
        <MaskedInput
          test={"number"}
          size={30}
          placeholder={"Insira aqui"}
          label={"Número"}
          value={this.state.number}
          onChange={(e) => this.handleChange("number", e.target.value)}
        />
        <MaskedInput
          test={"complement"}
          size={30}
          placeholder={"Insira aqui"}
          label={"Complemento"}
          value={this.state.complement}
          onChange={(e) => this.handleChange("complement", e.target.value)}
        />
        <MaskedInput
          required={true}
          error={this.invalidField("postal_code")}
          forceError={
            this.invalidField("postal_code") && this.state.try_to_save
          }
          test={"postal-code"}
          size={30}
          placeholder={"00.000-000"}
          label={"CEP"}
          mask={postal_codeMask()}
          value={this.state.postal_code}
          onChange={(e) => this.handleChange("postal_code", e.target.value)}
        />
        <MaskedInput
          required={true}
          error={this.invalidField("neighborhood")}
          forceError={
            this.invalidField("neighborhood") && this.state.try_to_save
          }
          test={"neighborhood"}
          size={30}
          placeholder={"Insira aqui"}
          label={"Bairro"}
          value={this.state.neighborhood}
          onChange={(e) => this.handleChange("neighborhood", e.target.value)}
        />
        <MaskedInput
          required={true}
          error={this.invalidField("city")}
          forceError={this.invalidField("city") && this.state.try_to_save}
          test={"city"}
          size={30}
          placeholder={"Insira aqui"}
          label={"Cidade"}
          value={this.state.city}
          onChange={(e) => this.handleChange("city", e.target.value)}
        />
        <Select
          required={true}
          error={this.invalidField("state") && this.state.try_to_save}
          test={"state"}
          size={30}
          placeholder={"Selecione o estado"}
          label={"Estado"}
          options={brazilStates()}
          value={this.state.state}
          onChange={(e) => this.handleChange("state", e.target.value)}
        />

        <div className="full-width">
          <h5>Dados do administrador da conta</h5>
          <p>Informe os dados do responsável pela conta digital</p>
        </div>
        <MaskedInput
          required={true}
          error={this.invalidField("email")}
          forceError={this.invalidField("email") && this.state.try_to_save}
          test={"email"}
          size={65}
          placeholder={"Insira o e-mail"}
          label={"E-mail"}
          type={"email"}
          value={this.state.email}
          onChange={(e) => this.handleChange("email", e.target.value)}
        />
        <MaskedInput
          required={true}
          error={this.invalidField("phone")}
          forceError={this.invalidField("phone") && this.state.try_to_save}
          test={"phone"}
          size={30}
          placeholder={"+00 (00) 00000-0000"}
          label={"Telefone"}
          mask={phoneMask(this.state.phone)}
          value={this.state.phone}
          onChange={(e) => this.handleChange("phone", e.target.value)}
        />

        <div className="d-flex justify-end full-width button-group">
          <button
            test-name="save-button"
            className="btn save-button"
            type="submit"
            disabled={this.state.buttonDescription === "Salvando"}
          >
            {this.state.buttonDescription}
          </button>
          {this.props.edit && (
            <button
              type="button"
              test-name="go-review-button"
              className="btn to-review-button"
              onClick={(e) => {
                e.preventDefault();
                window.location.reload(false);
              }}
            >
              Ir para revisão
            </button>
          )}
        </div>
      </form>
    );
  }

  invalidField(checked) {
    const errors = {
      cnpj: !cnpj.isValid(cnpj.strip(this.state.cnpj)),
      founding_date: !validateDate(this.state.founding_date),
      main_activity: this.state.main_activity.length < 10,
      legal_name: this.state.legal_name.length === 0,
      business_type: this.state.business_type === "",
      street: this.state.street.length === 0,
      postal_code: this.state.postal_code.length < 10,
      neighborhood: this.state.neighborhood.length === 0,
      city: this.state.city.length === 0,
      state: this.state.state.length < 2,
      email: this.state.email.length === 0,
      phone: this.state.phone.length < 18,
    };

    return checked === "all"
      ? Object.values(errors).includes(true)
      : errors[checked];
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.invalidField("all")) {
      this.handleChange("try_to_save", true);

      showSnackbar({
        content: "Campos obrigatórios não preenchidos corretamente!",
        style: "alert",
      });

      return;
    }

    this.handleChange("buttonDescription", "Salvando");

    $.ajax({
      type: this.state.id === "" ? "POST" : "PUT",
      dataType: "json",
      url: this.submitEndpoint(),
      data: this.state,
    })
      .then((response) => {
        showSnackbar({ content: "Salvo com sucesso!", style: "notice" });

        window.location.reload(false);
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possível salvar, tente novamente!",
          style: "alert",
        });
        this.setState({ ...this.state, buttonDescription: "Salvar" });
      });
  }

  submitEndpoint() {
    const tmpEndpoint = this.props.endpoint.replace(
      "company_id",
      this.props.company_id
    );

    return this.state.id === ""
      ? tmpEndpoint
      : `${tmpEndpoint}/${this.state.id}`;
  }

  handleChange(proprety, value) {
    if (proprety === "state") {
      this.setState({ ...this.state, [proprety]: value.toUpperCase() });
    } else {
      this.setState({ ...this.state, [proprety]: value });
    }
  }
}

LegalInfosForm.defaultProps = {
  company_id: "",
  endpoint: "",
  legal_infos: {},
  address: {},
  has_legal_info: false,
};

export default LegalInfosForm;
