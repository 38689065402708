import React from "react";
import { ShadowTheme } from "../ShadowTemplate";
import { hot } from "react-hot-loader";
import BudgetResume from "./BudgetResume";

const BudgetPageShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <BudgetPage {...props} />
  </ShadowTheme>
}

const BudgetPage = (props) => {
  return <>
    <BudgetResume {...props}/>
  </>
}

export default hot(module)(BudgetPageShadow);