import {get, post} from "@rails/request.js";
import {
    admin_erp_integration_template_events_path,
    archive_admin_erp_integration_template_event_path,
    related_admin_erp_integration_template_event_path
} from "../../../../../routes";

export const archiveEvent = async (erp, type, uuid) => {
    const response = await post(archive_admin_erp_integration_template_event_path(erp, type, uuid))
    return response.ok
}

export const fetchTemplateEvents = async (erp, type, page, perPage, failed) => {
    const response = await get(
        admin_erp_integration_template_events_path(erp, type, {page, failed}),
        {responseKind: 'json'}
    )
    if (response.ok) {
        return response.json
    }
    return {page, perPage, data: []}
}

export const fetchRelatedEvents = async (erp, type, uuid) => {
    const response = await get(
        related_admin_erp_integration_template_event_path(erp, type, uuid),
        {responseKind: 'json'}
    )
    if (response.ok) {
        const body = await response.json
        return body.data
    }
    return []
}