import React, { useState } from "react";
import moment from "moment";

import { admin_account_banking_apis_cards_path } from "../../../../routes";
import { showSnackbar } from "../../../shared/jquery_wrapper";
import {
  parserCurrencyBRLValue,
  parserValueWithBrlCurrency,
} from "../../../shared/helpers";

import {
  Box,
  Button,
  CardActions,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import MaskedInput from "react-text-mask";
import AddCardIcon from "@mui/icons-material/AddCard";

const DateMaskedInput = (props) => {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      guide={false}
      mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
};

const CardCreate = ({ getCardList, balance }) => {
  const [openCardCreate, setOpenCardCreate] = useState(false);
  const [createFields, setCreateFields] = useState({
    holder: "",
    limit: "",
    allowed_mcc: "",
    date_frame_start: "",
    date_frame_end: "",
    custom_id: "",
  });

  const handleChangeCreate = (field, value) => {
    setCreateFields({ ...createFields, [field]: value });
  };
  const isDateGreaterOrEqual = (dateString, compare = moment()) => {
    if (dateString.length < 10) {
      return true;
    }

    const date = moment(dateString, "DD/MM/YYYY");
    return date.isSameOrAfter(compare, "day");
  };
  const postCreateCard = () => {
    let tmpObj = {};
    Object.keys(createFields).forEach((key) => {
      tmpObj[key] = "";
    });

    $.ajax({
      type: "POST",
      url: admin_account_banking_apis_cards_path(),
      data: {
        ...createFields,
        limit: Number(
          createFields.limit
            .replace("R$", "")
            .replace(".", "")
            .replace(",", ".")
        ),
      },
    })
      .then((r) => {
        getCardList();
        handleClose();
        setCreateFields(tmpObj);
        showSnackbar({
          content: "O cartão foi criado com sucesso!",
          style: "notice",
        });
      })
      .catch((e) => {
        showSnackbar({
          content: "Não foi possivel criar o cartão!",
          style: "alert",
        });
      });
  };

  const handleClose = () => setOpenCardCreate(false);

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        sx={{ height: "56px", marginLeft: "10px" }}
        onClick={() => setOpenCardCreate(true)}
      >
        <AddCardIcon sx={{ marginRight: "10px" }} />
        gerar cartão
      </Button>

      <Dialog open={openCardCreate} onClose={handleClose} maxWidth={"lg"}>
        <DialogContent style={{ width: "964px" }}>
          <Typography sx={{ fontSize: "20px", marginBottom: "20px" }}>
            Gerar cartão
          </Typography>
          <Typography sx={{ margin: "10px 0" }}>
            Dê um nome para o seu cartão virtual
          </Typography>
          <TextField
            sx={{ width: "620px" }}
            label={"Dê um nome para o seu cartão virtual"}
            placeholder="Escreva aqui o nome do cartão"
            value={createFields.holder}
            onChange={(e) =>
              handleChangeCreate("holder", e.target.value.toUpperCase())
            }
          />
          <Typography sx={{ margin: "10px 0" }}>
            Identificador do cartão
          </Typography>
          <TextField
            sx={{ width: "620px" }}
            label={"Nº do identificador"}
            placeholder="Escreva aqui o número"
            value={createFields.custom_id}
            onChange={(e) => handleChangeCreate("custom_id", e.target.value)}
          />
          <Typography sx={{ margin: "10px 0" }}>
            Data de vigência do cartão
          </Typography>
          <Box>
            <TextField
              sx={{ width: "302px", marginRight: "18px" }}
              label={"Início"}
              placeholder="DD/MM/AAAA"
              InputProps={{ inputComponent: DateMaskedInput }}
              value={createFields.date_frame_start}
              onChange={(e) =>
                handleChangeCreate("date_frame_start", e.target.value)
              }
              error={!isDateGreaterOrEqual(createFields.date_frame_start)}
            />
            <TextField
              sx={{ width: "302px" }}
              label={"Fim"}
              placeholder="DD/MM/AAAA"
              InputProps={{ inputComponent: DateMaskedInput }}
              value={createFields.date_frame_end}
              onChange={(e) =>
                handleChangeCreate("date_frame_end", e.target.value)
              }
              error={
                !isDateGreaterOrEqual(
                  createFields.date_frame_end,
                  moment(createFields.date_frame_start, "DD/MM/YYYY")
                )
              }
            />
          </Box>

          <Typography sx={{ margin: "10px 0" }}>
            Defina os MCCs liberados
          </Typography>
          <FormControl sx={{ width: "620px" }}>
            <InputLabel id="mcc_allowed-select-label">MCC liberado</InputLabel>
            <Select
              labelId="mcc_allowed-select-label"
              label="Defina os MCCs"
              value={createFields.allowed_mcc}
              onChange={(e) =>
                handleChangeCreate("allowed_mcc", e.target.value)
              }
            >
              <MenuItem value={"all_mcc"}>Todos liberados</MenuItem>
              <MenuItem value={"airlines"}>Somente aéreo</MenuItem>
              <MenuItem value={"lodging"}>Somente hotelaria</MenuItem>
            </Select>
          </FormControl>
          <Typography sx={{ margin: "10px 0" }}>
            Defina o limite do cartão
          </Typography>
          <Box sx={{ display: "flex", marginBottom: "10px" }}>
            <Typography>Saldo disponível na conta digital: </Typography>
            <Typography sx={{ color: "#00CBA8" }}>
              {parserCurrencyBRLValue(balance)}
            </Typography>
          </Box>
          <TextField
            sx={{ width: "620px" }}
            label={"Limite programado"}
            placeholder="R$ 0,00"
            value={createFields.limit}
            onChange={(e) =>
              handleChangeCreate(
                "limit",
                parserValueWithBrlCurrency(e.target.value)
              )
            }
          />
        </DialogContent>
        <CardActions sx={{ justifyContent: "flex-end", padding: "20px" }}>
          <Button
            sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}
            onClick={handleClose}
          >
            fechar
          </Button>
          <Button onClick={postCreateCard}>salvar</Button>
        </CardActions>
      </Dialog>
    </Box>
  );
};

export default CardCreate;
