import React, {useEffect} from "react";
import {hot} from "react-hot-loader";

const ShadowComponent = ({rootId, path, props}) => {
  const createShadowRoot = () => {
    const shadowRoot = document.createElement('div')
    const componentProps = JSON.stringify({...props, rootSelector: `#${rootId}`});
    shadowRoot.setAttribute("id", "shadow_root")
    shadowRoot.setAttribute("data-react-class", path)
    shadowRoot.setAttribute("data-react-props", componentProps)
    return shadowRoot
  }

  const createStyleRoot = () => {
    const styleRoot = document.createElement('style')
    styleRoot.setAttribute("id", "emotion_root")
    return styleRoot
  }

  /* istanbul ignore next */
  const shadowDomFix = (shadowDom, root) => {
    Object.defineProperty(root, "ownerDocument", {value: shadowDom})
    shadowDom.createElement = (...args) => document.createElement(...args)
    shadowDom.createElementNS = (...args) => document.createElementNS(...args)
    shadowDom.createTextNode = (data) => document.createTextNode(data)
    shadowDom.body = document.body
  }

  const mountShadowComponent = (rootId) => {
    const container = document.querySelector(`#${rootId}`)
    const shadow = container.attachShadow({mode: 'open'})
    const shadowRoot = createShadowRoot()
    shadow.appendChild(createStyleRoot())
    shadow.appendChild(shadowRoot)
    shadowDomFix(shadow, shadowRoot)
    ReactRailsUJS.mountComponents(shadow)
  }

  useEffect(() => mountShadowComponent(rootId), []);

  return <div id={rootId}/>
}

export default hot(module)(ShadowComponent);