import React from "react";
import classNames from "classnames";

class CardInfos extends React.Component {
  render() {
    return (
      <div
        className={classNames(
          "d-flex",
          "flex-wrap",
          "justify-between",
          "card-info",
          {
            "border-b": this.props.border,
          }
        )}
      >
        {this.props.items.map((item, idx) => (
          <div
            key={idx}
            className={classNames("item", {
              address: item.label === "Endereço",
              "more-space": this.props.moreSpace,
            })}
          >
            <label>{item.label}</label>
            <p className="d-flex align-center">{item.description || "-"}</p>
          </div>
        ))}
      </div>
    );
  }
}

CardInfos.defaultProps = {
  items: [],
  sections: [],
  border: false,
  moreSpace: false,
};

export default CardInfos;
