import React from "react";
import { Box } from "@mui/material";
import TwoFactorImage from '../../../../../../app/assets/images/two_factor.svg';
import CheckCircleOutlineImage from '../../../../../../app/assets/images/check_circle_outline.svg';

const MandatoryTwoFactorBanner = ({ theme, active }) => {
  return <Box
    py={3}
    mt={2}
    display={'flex'}
    justifyContent={'center'} sx={{ backgroundColor: theme.palette.surfaceLight9.main }}
  >
    { active ? <CheckCircleOutlineImage/> : <TwoFactorImage/> }
  </Box>
}

export default MandatoryTwoFactorBanner;