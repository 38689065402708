import React, {useState} from "react";
import AddIcon from '@mui/icons-material/Add';
import {
    Alert,
    Card,
    CardContent,
    Container,
    Fab,
    Grid,
    Pagination,
    Skeleton,
    Snackbar,
    Typography
} from "@mui/material";
import {bistro_lightbox_path, bistro_lightboxes_path, new_bistro_lightbox_path} from "../../../routes";
import {ShadowTheme} from "../../admin/ShadowTemplate";
import {Lightbox} from "../../admin/lightboxes/Lightbox";
import {hot} from "react-hot-loader";
import LightboxItem from "./list/LightboxItem";
import DeleteLightboxDialog from "./list/DeleteLightboxDialog";

const LightboxIndexShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <LightboxIndex {...props}/>
    </ShadowTheme>
}


const LightboxIndex = ({lightboxes, currentPage, totalCount, csrfToken}) => {
    const [data, setData] = useState(lightboxes ?? []);
    const [page, setPage] = useState(currentPage ?? 1);

    const [lightboxToPreview, setLightboxToPreview] = useState(null);
    const [lightboxToDelete, setLightboxToDelete] = useState(null);

    const [loadingPage, setLoadingPage] = useState(false);

    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const fetchPage = async (page) => {
        setLoadingPage(true);
        const response = await fetch(bistro_lightboxes_path({page: page}), {
            method: 'GET',
            headers: {"X-CSRF-Token": csrfToken, 'Accept': 'application/json'},
        })
        const data = await response.json();
        setData(data.lightboxes);
        setPage(data.currentPage);
        setLoadingPage(false);
    }

    const deleteLightbox = async (lightboxId) => {
        const response = await fetch(bistro_lightbox_path(lightboxId), {
            method: 'DELETE',
            headers: {"X-CSRF-Token": csrfToken},
        });
        if (response.ok) {
            setSuccessMessage('Lightbox excluído com sucesso');
            await fetchPage(page);
        } else {
            setErrorMessage('Não foi possível excluir o lightbox. Tente novamente');
        }
        setLightboxToDelete(null);
    }

    const handleConfirmDelete = () =>  deleteLightbox(lightboxToDelete.id);
    const handlePagination = (_, page) => fetchPage(page);
    const handlePreview = (lightbox) => () => setLightboxToPreview(lightbox)
    const handleDelete = (lightbox) => () => setLightboxToDelete(lightbox);
    const handleCancelDelete = () => setLightboxToDelete(null);
    const handleCloseSuccess = () => setSuccessMessage(null);
    const handleCloseError = () => setErrorMessage(null);

    return <>
        <Container maxWidth={'xl'}>
            <Typography variant={'h4'}>Lightboxes</Typography>
            {data.length === 0 && (
                <EmptyLightboxList/>
            )}
            {data.length > 0 && (
                <LightboxList
                    data={data}
                    loading={loadingPage}
                    onDelete={handleDelete}
                    onPreview={handlePreview}/>
            )}
            {totalCount > 8 && (
                <Pagination
                    disabled={loadingPage}
                    page={page}
                    count={Math.ceil(totalCount / 8)}
                    shape="rounded"
                    onChange={handlePagination}/>
            )}
        </Container>
        {lightboxToPreview && (
            <Lightbox
                open={!!lightboxToPreview}
                lightbox={lightboxToPreview}
                onClose={() => setLightboxToPreview(null)}/>
        )}
        <DeleteLightboxDialog
            open={!!lightboxToDelete}
            onCancel={handleCancelDelete}
            onConfirm={handleConfirmDelete}/>
        <Snackbar open={!!successMessage} autoHideDuration={5000} onClose={handleCloseSuccess}>
            <Alert onClose={handleCloseSuccess} severity="success" sx={{width: '100%'}}>
                {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar open={!!errorMessage} autoHideDuration={5000} onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                {errorMessage}
            </Alert>
        </Snackbar>
        <Fab
            aria-label={'Novo lightbox'}
            color="primary"
            href={new_bistro_lightbox_path()}
            sx={{position: 'fixed', right: 28, bottom: 28}}>
            <AddIcon/>
        </Fab>
    </>
}

const EmptyLightboxList = () => {
    return <Card sx={{mt: 2}}>
        <CardContent sx={{p: 3}}>
            Nenhum lightbox cadastrado
        </CardContent>
    </Card>
}

const LightboxList = ({data, loading, onPreview, onDelete}) => {
    return <Grid container spacing={2}>
        {data.map((lightbox, index) => {
            return <Grid key={index} item lg={3} md={4} sm={6} xs={12} sx={{my: 2}}>
                {loading && <Skeleton variant="rounded" height={340}/>}
                {!loading && (
                    <LightboxItem
                        lightbox={lightbox}
                        onPreview={onPreview(lightbox)}
                        onDelete={onDelete(lightbox)}/>
                )}
            </Grid>
        })}
    </Grid>
}

export default hot(module)(LightboxIndexShadow);
