import React from 'react';
import {Card} from "@mui/material";
import HomeExpensesCardContent from "./HomeExpensesCardContent";
import HomeEmptyCardContent from "../common/HomeEmptyCardContent";
import BarChartIcon from '@mui/icons-material/BarChart';
import {admin_expenses_path} from '../../../../routes';

const HomeExpensesEmptyCardContent = () => {
    return <HomeEmptyCardContent
        icon={<BarChartIcon color="primary"/>}
        title={"Não há despesas geradas pelos funcionários"}
        subtitle={"Os dados aparecerão conforme os relatórios forem aprovados"}
        buttonText={"ADICIONAR DESPESAS"}
        buttonProps={{href: admin_expenses_path()}}/>
}

const HomeExpensesCard = (props) => {
    return (
        <Card sx={{p: 2, display: 'flex', flexDirection: 'column', mb: 2}} data-testid="home-expenses">
            {props.emptyState ? <HomeExpensesEmptyCardContent/> : <HomeExpensesCardContent {...props} />}
        </Card>
    );
}

export default HomeExpensesCard;

