import React, { useState } from "react";

import { bistro_company_account_path } from "../../../../routes";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import { showSnackbar } from "../../../shared/jquery_wrapper";

const ModalCancelAccount = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const handleCloseModal = () => setOpenModal(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setSelectedFile(file);
    setFileName(file.name);
  };

  const handleCancel = (event) => {
    event.preventDefault();

    if (!selectedFile) {
      showSnackbar({ content: "Nenhum arquivo selecionado", style: "alert" });
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    $.ajax({
      type: "DELETE",
      url: bistro_company_account_path(props.companyId),
      data: formData,
      processData: false,
      contentType: false,
    })
      .then((response) => {
        showSnackbar({
          content: "Conta em processo de cancelamento",
          style: "notice",
        });

        handleCloseModal();
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possivel cancelar a conta",
          style: "alert",
        });
      });
  };

  return (
    <React.Fragment>
      <Button
        data-testid="btn-cancel-account"
        style={{ backgroundColor: "#2e2f35", color: "white" }}
        onClick={() => setOpenModal(true)}
      >
        Cancelar conta
      </Button>
      {/* modal */}
      <Dialog
        data-testid="dialog-cancel-account"
        open={openModal}
        onClose={handleCloseModal}
        maxWidth={"lg"}
      >
        <DialogTitle>
          <Typography>Cancelar conta</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            <b>Atenção:</b>Ao cancelar a conta, a mesma não poderá ser mais
            utilizada e todas as funcionalidades relacionadas a gestão serão
            bloqueadas, ficando disponível somente para consulta as informações
            já existentes. Este é um processo IRREVERSÍVEL, então tenha certeza
            se é isto que deseja.
          </Typography>
          <Typography style={{ marginTop: "20px" }}>
            Para seguir com o cancelamento da conta é necessário anexar o
            documento de distrato assinado pelo cliente no formato pdf
          </Typography>

          <div
            style={{ display: "flex", alignItems: "center", marginTop: "30px" }}
          >
            <Button
              style={{ color: "red" }}
              component="label"
              startIcon={<PictureAsPdfIcon />}
            >
              <input
                data-testid="input-cancel"
                type="file"
                accept=".pdf"
                hidden
                onChange={handleFileChange}
              />
            </Button>
            {fileName && (
              <Typography>Arquivo selecionado: {fileName}</Typography>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="btn-close-cancel-account"
            sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}
            onClick={handleCloseModal}
          >
            fechar
          </Button>
          <Button
            data-testid="btn-import-and-cancel-account"
            onClick={handleCancel}
          >
            importar e cancelar conta{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ModalCancelAccount;
