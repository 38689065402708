import React, { useState } from "react";
import { ShadowTheme } from "../ShadowTemplate";
import { hot } from "react-hot-loader";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Menu, MenuItem } from "@mui/material";
import { edit_admin_budget_path } from "../../../routes";
import OptionsChipButton from "../../shared/OptionsChipButton";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { closeBudget } from "./budgets_service";

const BudgetDropdownShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <BudgetDropdown
      id={props.id}
      csrfToken={props.csrfToken}
    />
  </ShadowTheme>;
}

const BudgetDropdown = ({ csrfToken, id }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const openDropdown = Boolean(anchorEl);

  const onOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const onCloseDropdown = () => {
    setAnchorEl(null);
  }

  const toggleOpenDialog = () => {
    onCloseDropdown();
    setOpenDialog(!openDialog);
  }

  const handleSubmit = async () => {
    setLoading(true);
    await closeBudget(csrfToken, id);
    setLoading(false);
  }

  return <>
    <OptionsChipButton onClick={onOpenDropdown}/>
    <Menu
      open={openDropdown}
      onClose={onCloseDropdown}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem
        component="a"
        href={edit_admin_budget_path(id)}
      >
        <EditIcon sx={{ mr: 1 }}/>
        Ajustar valor orçado
      </MenuItem>
      <MenuItem
        onClick={toggleOpenDialog}
      >
        <DeleteIcon sx={{ mr: 1 }}/>
        Encerrar
      </MenuItem>
    </Menu>
    <Dialog
      open={openDialog}
    >
      <DialogTitle>Deseja encerrar este orçamento?</DialogTitle>
      <DialogContent>
        {loading && <LinearProgress/>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          disabled={loading}
          onClick={toggleOpenDialog}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          onClick={handleSubmit}
        >
          Encerrar
        </Button>
      </DialogActions>
    </Dialog>
  </>
}

export default hot(module)(BudgetDropdownShadow);