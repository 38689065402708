import { Button, DialogActions, DialogContent, Grid, LinearProgress, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import TwoFactorConfigFaqLink from "./TwoFactorConfigFaqLink";
import { enableTwoFactor } from "./two_factor_service";
import OTPField from "../../../shared/OTPField";

const SmallActions = ({ loading, otp, invalidOtp, onBack }) => {
  return <DialogActions sx={{ px: 3 }}>
    <Grid container spacing={2} justifyContent={"flex-end"}>
      <Grid item xs={6}>
        <Button
          fullWidth
          onClick={onBack}
        >
          Voltar
        </Button>
      </Grid>
      <Grid item xs={6}>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={ otp.length !== 6 || loading || invalidOtp }
        >
          Avançar
        </Button>
      </Grid>
    </Grid>
  </DialogActions>
}

const BigActions = ({ loading, otp, invalidOtp, onBack }) => {
  return <DialogActions sx={{ px: 3, justifyContent: "flex-end" }}>
    <Button
      onClick={onBack}
    >
      Voltar
    </Button>
    <Button
      type="submit"
      variant="contained"
      disabled={ otp.length !== 6 || loading || invalidOtp }
    >
      Avançar
    </Button>
  </DialogActions>
}

const TwoFactorConfigStepTwo = ({ onNext, onBack, csrfToken, smallScreen }) => {
  const [otp, setOtp] = useState('');
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChangeOtp = (value) => {
    setOtp(value);
    setInvalidOtp(false);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    await enableTwoFactor(
      otp,
      csrfToken,
      onNext,
      () => setInvalidOtp(true),
    );
    setLoading(false);
  }
  
  return <form onSubmit={onSubmit}>
    <DialogContent>
      <Typography mb={2} color={"GrayText"}>
        Digite o código gerado no aplicativo autenticador
      </Typography>
      <OTPField
        value={otp}
        onChange={onChangeOtp}
        error={invalidOtp}
      />
      { loading && <LinearProgress sx={{ mt: 2 }}/>}
      <TwoFactorConfigFaqLink/>
    </DialogContent>
    { smallScreen &&
      <SmallActions
        loading={loading}
        otp={otp}
        invalidOtp={invalidOtp}
        onBack={onBack}
      />
    }
    { !smallScreen &&
      <BigActions
        loading={loading}
        otp={otp}
        invalidOtp={invalidOtp}
        onBack={onBack}
      />
    }
  </form>
}

export default TwoFactorConfigStepTwo;