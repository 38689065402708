import {
    Alert,
    AlertTitle,
    Avatar,
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    Stack,
    Typography
} from "@mui/material";
import React, {useCallback, useMemo} from "react";
import {
    admin_erp_integration_path,
    admin_erp_integration_template_events_path,
    admin_erp_integration_templates_path
} from "../../../../routes";
import {erps} from "../Index";
import {activateConnection} from "../erp_integration_connector";
import dayjs from "dayjs";
import {templateTypes} from "../templates/Index.jsx";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export const ErpIntegrationItem = ({anyActive, integration}) => {
    const erp = useMemo(() => {
        return erps.find(erp => erp.identifier === integration.erp)
    }, [integration]);

    const activate = useCallback(async () => {
        const success = await activateConnection(integration.erp)
        if (success) {
            window.location.reload()
        }
    }, [integration]);

    const failedTemplates = useMemo(() => {
        return integration.templates.filter(template => template.failureCount > 0)
    }, [integration]);

    return <Card>
        <CardHeader
            title={erp.title.toUpperCase()}
            titleTypographyProps={{variant: 'body1'}}
            avatar={<Avatar src={erp.image} variant={'rounded'}/>}
            action={<Stack direction={'row'}>
                <Badge variant={'dot'} color={statuses[integration.status].color}
                       sx={{mt: 1.5, mr: 1}}/>
                <Typography variant={'body1'}>{statuses[integration.status].label}</Typography>
            </Stack>}/>
        <CardContent sx={{py: 0}}>
            <Typography variant={'body1'}>{statuses[integration.status].title}</Typography>
            <Typography variant={'body2'} color={'gray'}>
                {statuses[integration.status].subtitle(anyActive)}
            </Typography>
            {integration.lastIntegrationAt && <Typography sx={{pt: 2}} variant={'body2'} color={'gray'}>
                {"Última integração de template em "}
                {dayjs(integration.lastIntegrationAt).format('DD/MM/YYYY')}
            </Typography>}
            {failedTemplates.length > 0 && (
                <Alert
                    severity={'error'}
                    sx={{mt: 2}}
                    action={failedTemplates.length === 1 && <Button
                        color={'inherit'}
                        variant={'outlined'}
                        href={admin_erp_integration_template_events_path(
                            integration.erp,
                            failedTemplates[0].templateType
                        )}>
                        Ver template
                    </Button>}>
                    <AlertTitle>Atenção</AlertTitle>
                    Falha na integração
                </Alert>
            )}
            <Stack direction={'row'} spacing={2} sx={{mt: 2}}>
                {[...integration.templates.map(template => (
                    <Chip
                        clickable
                        component={'a'}
                        variant={'outlined'}
                        key={template.templateType}
                        href={admin_erp_integration_template_events_path(
                            integration.erp,
                            template.templateType
                        )}
                        icon={template.failureCount > 0 ? <OpenInNewIcon/> : null}
                        color={template.failureCount > 0 ? 'error' : 'success'}
                        label={templateTypes.find(t => t.identifier = template.templateType).label}/>
                ))]}
            </Stack>
        </CardContent>
        <CardActions sx={{p: 2, justifyContent: 'flex-end'}}>
            {integration.status === 'connected' && (
                <Button
                    color={'primary'}
                    variant={'contained'}
                    href={admin_erp_integration_templates_path(integration.erp)}>
                    {integration.templates.length > 0 ? "Gerenciar templates" : "Configurar templates"}
                </Button>
            )}
            {['connecting', 'disconnected'].includes(integration.status) && (
                <Button
                    color={'primary'}
                    variant={integration.status === 'disconnected' ? 'outlined' : 'contained'}
                    href={admin_erp_integration_path(integration.erp)}>
                    Voltar para conexão
                </Button>
            )}
            {integration.status === 'inactive' && (<>
                <Button
                    onClick={() => activate()}
                    color={'primary'}
                    variant={'outlined'}
                    disabled={anyActive}>
                    Reativar
                </Button>
                <Button
                    color={'primary'}
                    variant={'contained'}
                    sx={{ml: 1}}
                    href={admin_erp_integration_templates_path(integration.erp)}>
                    Acessar templates
                </Button>
            </>)}
        </CardActions>
    </Card>
}

export const statuses = {
    connecting: {
        title: 'Estamos quase lá!',
        subtitle: () => 'Mais alguns instantes e você estará pronto para continuar.',
        label: 'Validando conexão',
        color: 'warning'
    },
    connected: {
        title: 'Conexão com o ERP estabelecida!',
        subtitle: () => 'O próximo passo é configurar seus templates.',
        label: 'Conectado',
        color: 'success'
    },
    disconnected: {
        title: 'Precisa de ajuda?',
        subtitle: () => 'Não se preocupe, nossa Central de Ajuda está aqui para você. Clique no botão “Ajuda” e resolva isso rapidamente!',
        label: 'Falha na conexão',
        color: 'error'
    },
    inactive: {
        title: 'O ERP foi desativado!',
        subtitle: (anyActive) => `Acesse seus templates e históricos sempre que precisar. Quer reativar o ERP? ${anyActive ? 'Você precisa desativar o ERP que está conectado' : 'Você pode fazer isso facilmente'}.`,
        label: 'Desativado',
        color: 'disabledLight'
    },
}