import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton, Box, List, ListItem, ListItemText, MobileStepper, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import RemoveDoneIcon from '@mui/icons-material/RemoveDone';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Item = ({ primaryText, secondaryText }) => {
  return (
    <ListItemText
      primary={
        <Typography
          component="div"
          variant="body2"
          color="textSecondary"
        >
          {primaryText}
        </Typography>
      }
      secondary={
        <Typography
          component="div"
          variant="body1"
        >
          {secondaryText}
        </Typography>
      }
    />
  );
};

const renderCost = (cost) => {
  const [currency, value] = cost.split(' ');

  return (
    <Typography>
      <span style={{ fontSize: '20px' }}>{currency}</span>
      <span style={{ fontSize: '34px' }}>{value}</span>
    </Typography>
  );
};

const AttachmentBackground = ({ child }) => {
  return <>
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ backgroundColor: '#f2f2f2', height: '500px', width: '100%' }}
    >
      {child}
    </Box>
  </>
}

const EmptyAttachments = () => {
  return <>
    <AttachmentBackground
      child={
        <RemoveDoneIcon
          style={{ fontSize: '80px', color: '#A6A6A6' }}
          data-testid="empty-attachments"
        />
      }
    />
  </>
}

const PDFAttachment = ({ thumb, url }) => {
  return <>
    <div style={{ position: 'relative' }}>
      <img
        src={thumb}
        style={{ maxHeight: '500px' }}
      />
      <a href={url} target='_blank'>
        <span
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#000',
            color: '#fff',
            borderRadius: '24px',
            padding: '10px',
            opacity: '0.7',
            width: '100%',
            textAlign: 'center',
          }}
        >
          CLIQUE PARA VER O PDF COMPLETO
        </span>
      </a>
    </div>
  </>
}

const Attachments = ({ transaction, activeStep }) => {
  const attachments = transaction.expense_attachments;
  if (attachments.length == 0) return <EmptyAttachments/>

  const attachment = transaction.expense_attachments[activeStep];
  if (attachment.type === 'pdf') {
    return <>
      <AttachmentBackground
        child={
          <PDFAttachment
            thumb={attachment.thumb}
            url={attachment.url}
          />
        }
      />
    </>
  }
  
  return <>
    <AttachmentBackground
      child={
        <a
          href={attachment.url}
          target={"_blank"}
          style={{ height: "100%" }}
        >
          <CardMedia
            height={"100%"}
            component={"img"}
            image={attachment.url}
            alt={"Attachment"}
            sx={{ objectFit: "contain" }}
          />
        </a>
      }
    />
  </>
}

const ExpenseModal = ({ open, title, transaction, onClose }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <React.Fragment>
        <Dialog
          open={open}
          onClose={onClose}
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle sx={{ m: 0, p: 2, backgroundColor: '#3D0079', color: 'white' }}>
            <Box display="flex" alignItems="center">
              <CreditCardIcon style={{ color: 'white', marginRight: '10px' }} />
              <Typography variant="h6">{title}</Typography>
            </Box>
          </DialogTitle>
          <IconButton
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            aria-label="Close"
            data-testid="close-expense-modal"
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ display: 'flex' }}>
            <Grid container spacing={2} alignItems={"stretch"}>
              <Grid item xs={6}>
                {transaction && (
                  <React.Fragment>
                    <Attachments transaction={transaction} activeStep={activeStep}/>
                    <MobileStepper
                      variant="dots"
                      steps={transaction.expense_attachments.length}
                      position="static"
                      activeStep={activeStep}
                      nextButton={
                        <IconButton
                          size="small"
                          onClick={handleNext} disabled={activeStep === transaction.expense_attachments.length - 1 || transaction.expense_attachments.length === 0}>
                          <ChevronRightIcon />
                        </IconButton>
                      }
                      backButton={
                        <IconButton size="small" onClick={handleBack} disabled={activeStep === 0}>
                          <ChevronLeftIcon />
                        </IconButton>
                      }
                    />
                  </React.Fragment>
                )}
              </Grid>

              <Grid item xs={6}>
                {transaction && (
                  <List>
                    <ListItem>
                      <ListItemText>
                        {renderCost(transaction.cost)}
                      </ListItemText>
                    </ListItem>
                    <ListItem>
                      <Item primaryText="Data" secondaryText={transaction.date} />
                    </ListItem>
                    <ListItem>
                      <Item primaryText="Cartão" secondaryText={transaction.last_digits} />
                    </ListItem>
                    <ListItem>
                      <Item primaryText="Estabelecimento" secondaryText={transaction.merchant_name} />
                    </ListItem>
                    <ListItem>
                      <Item primaryText="Cidade" secondaryText={transaction.city} />
                    </ListItem>
                    <ListItem>
                      <Item primaryText="CEP" secondaryText={transaction.postal_code} />
                    </ListItem>
                    {transaction.user_name && (
                      <ListItem>
                        <Item primaryText="Usuário" secondaryText={transaction.user_name} />
                      </ListItem>
                    )}

                    {transaction.report_link && (
                      <ListItem>
                        <ListItemText
                          primary={
                            <Typography
                              component="div"
                              variant="body2"
                              color="textSecondary"
                            >
                              Relatório
                            </Typography>
                          }
                          secondary={
                            <Typography
                              component="div"
                              variant="body1"
                            >
                              <a href={transaction.report_link} target="_blank">
                                Despesa submetida no relatório #{transaction.report_number}
                              </a>
                            </Typography>
                          }
                        />
                      </ListItem>
                    )}
                  </List>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </React.Fragment>
  )
};

export default ExpenseModal;