import React from "react";
import { ShadowTheme } from "../../../../admin/ShadowTemplate";
import { showSnackbar } from "../../../../shared/jquery_wrapper";

import { retrive_bistro_accounts_chargebacks_path } from "../../../../../routes";

import {
  Box,
  InputAdornment,
  LinearProgress,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

// last import
import { hot } from "react-hot-loader";

const Index = (props) => {
  const [value, setValue] = React.useState("");
  const [logs, setLogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleFind = (event) => {
    if (event.key === "Enter") {
      setLoading(true);
      setLogs([]);

      $.ajax({
        type: "GET",
        url: retrive_bistro_accounts_chargebacks_path(),
        data: { search: event.target.value },
      })
        .then((r) => {
          const dataSorted = r.data.sort((a, b) => {
            const [dayA, monthA, yearA] = a.event_date.split("/").map(Number);
            const [dayB, monthB, yearB] = b.event_date.split("/").map(Number);
            const dateA = new Date(yearA, monthA - 1, dayA);
            const dateB = new Date(yearB, monthB - 1, dayB);
            return dateA - dateB;
          });

          setLoading(false);
          setLogs(dataSorted);

          if (dataSorted.length === 0) {
            showSnackbar({
              content: "Nenhuma informação encontrada",
              style: "info",
            });
          }
        })
        .catch((e) => {
          setLoading(false);
          showSnackbar({
            content: "Não foi possível encontrar a transação",
            style: "error",
          });
        });
    }
  };

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          sx={{ width: "600px" }}
          placeholder="Digite o id da transação e aperte enter"
          data-testid="search"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleFind}
        />
        <LinearProgress
          style={{ width: "600px", display: loading ? "block" : "none" }}
        />
        <Stepper
          style={{ marginTop: "30px" }}
          orientation="vertical"
          activeStep={logs.length - 1}
        >
          {logs.map((log, idx) => (
            <Step key={idx} expanded>
              <StepLabel>{log.status}</StepLabel>
              <StepContent>
                <Typography>{log.event_date}</Typography>
                <Typography>{log.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </ShadowTheme>
  );
};

export default hot(module)(Index);
