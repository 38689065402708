import React from "react";
import DistanceFeatureGroup, {DISTANCE_GROUP_ID} from "../../../admin/features/distance/DistanceFeatureGroup";
import AccountFeatureGroup from "../../../admin/features/account/AccountFeatureGroup";
import ExpensePolicyFeatureGroup from "../../../admin/features/expense_policy/ExpensePolicyFeatureGroup";
import ApprovalFeatureGroup, { APPROVAL_GROUP_ID } from "../../../admin/features/approval/ApprovalFeatureGroup";
import SecurityFeatureGroup from "../../../admin/features/security/SecurityFeatureGroup";
import BudgetsFeatureGroup from "../../../admin/features/budgets/BudgetsFeatureGroup";

class BistroCompanyFeatures extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="title-container">
          <div className="col s12">
            <a href={this.props.companyEndpoint}>
              <i className="mdi mdi-arrow-left back" />
            </a>
            <h1>{this.props.companyName}</h1>
          </div>
        </div>
        <section>
          <h2>Plano atual</h2>
          <ul className="collection design-system">
            <li className="collection-item">
              <h5>{this.props.planName}</h5>
            </li>
          </ul>
        </section>
        <BudgetsFeatureGroup
          featuresEndpoint={this.props.featuresEndpoint}
        />
        <SecurityFeatureGroup
          featureGroup={4}
          groupName={"Segurança"}
          ignoreAvailability={true}
          hidePlanWhenActive={false}
          featuresEndpoint={this.props.featuresEndpoint}
        />
        <DistanceFeatureGroup
          ignoreAvailability={true}
          hidePlanWhenActive={false}
          featuresEndpoint={this.props.featuresEndpoint}
          featureGroup={DISTANCE_GROUP_ID}
        />
        <AccountFeatureGroup
          ignoreAvailability={true}
          featuresEndpoint={this.props.featuresEndpoint}
          featureGroup={2}
        />
        <ExpensePolicyFeatureGroup
          featuresEndpoint={this.props.featuresEndpoint}
          featureGroup={3}
          ignoreAvailability={true}
        />
        <ApprovalFeatureGroup
          groupName={"Aprovação"}
          ignoreAvailability={true}
          featuresEndpoint={this.props.featuresEndpoint}
          featureGroup={APPROVAL_GROUP_ID}
        />
      </React.Fragment>
    );
  }
}

BistroCompanyFeatures.defaultProps = {
    companyName: "",
    planName: "",
    companyEndpoint: "",
    featuresEndpoint: ""
}

export default BistroCompanyFeatures