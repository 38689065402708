import React from "react";
import { initTabs } from "./jquery_wrapper";
import classNames from "classnames";

class SelectTab extends React.Component {
  componentDidMount() {
    initTabs();
  }

  componentDidUpdate(prevProps, prevState) {
    initTabs();

    if (prevProps.list !== this.props.list && this.props.indicator === false) {
      const indicators = document.getElementsByClassName("indicator");

      if (indicators.length > 0) {
        indicators[0].classList.add("hide");
      }
    }
  }

  render() {
    return (
      <ul
        className={`tabs tabs-container-default ${this.props.customClass}`}
        style={{ marginBottom: 0, ...this.props.customStyle }}
      >
        {this.props.list.map((item, idx, arr) => (
          <li
            test-name={`tab-li-${item.testName}`}
            className={classNames("tab", {
              disabled:
                (this.props.disabled && !this.props.forceColorActive) ||
                (this.props.forceColorActive && !item?.active),
            })}
            key={idx}
          >
            <a
              test-name={`tab-${item.testName}`}
              data-testId={`tab-${item.testName}`}
              className={classNames({
                disabled:
                  (this.props.disabled && !this.props.forceColorActive) ||
                  (this.props.forceColorActive && !item?.active),
                active: item?.active,
              })}
              href={item.href}
              onClick={this.props.disabled ? () => {} : item.onClick}
            >
              {item.label}
            </a>
          </li>
        ))}
        {this.props.customList.map((el, idx) => (
          <li className="tab" key={idx}>
            {el}
          </li>
        ))}
      </ul>
    );
  }
}

SelectTab.defaultProps = {
  list: [],
  customList: [],
  customClass: "",
  customStyle: {},
  disabled: false,
  indicator: true,
  forceColorActive: false,
};

export default SelectTab;
