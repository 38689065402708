import React, { useState, useEffect } from "react";
import { Box, Card, CardContent, CardActions } from "@mui/material";
import FirstStep from './carousel_steps/FirstStep';
import SecondStep from './carousel_steps/SecondStep';
import ThirdStep from './carousel_steps/ThirdStep';
import FourthStep from './carousel_steps/FourthStep';

const CarouselCard = ({textButton, isPlanFree}) => {
  const steps = [
    <FirstStep textButton={textButton} isPlanFree={isPlanFree} />,
    <SecondStep textButton={textButton} isPlanFree={isPlanFree} />,
    <ThirdStep textButton={textButton} isPlanFree={isPlanFree} />,
    <FourthStep textButton={textButton} isPlanFree={isPlanFree} />
  ];

  const [slide, setSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setSlide((prevSlide) => (prevSlide + 1) % steps.length);
    }, 5000);

    return () => clearInterval(timer);
  }, [steps.length]);

  return (
    <Card sx={{ backgroundColor: '#F0EBF5', width: '100%', marginTop: 5, maxHeight: { lg: '750px', md: 'auto', xs: 'auto'} }}>
      <CardContent sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: { lg: '490px', md: 'auto', xs: 'auto' },
      }}>
        {steps.map((StepComponent, idx) => (
          <Box
            key={idx}
            sx={{
              display: slide === idx ? 'flex' : 'none',
              width: '100%',
              height: { xs: '552px', sm: '422px', md: '422px', lg: '522px' },
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {StepComponent}
          </Box>
        ))}
      </CardContent>
      <CardActions sx={{ justifyContent: 'center', marginTop: 5 }}>
        {steps.map((_, idx) => (
          <Box
            data-testid='dot'
            key={idx}
            onClick={() => setSlide(idx)}
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: slide === idx ? 'primary.main' : 'grey.400',
              margin: '0 1px',
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            }}
          />
        ))}
      </CardActions>
    </Card>
  );
};

export default CarouselCard;
