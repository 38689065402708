import React, { useEffect, useState } from "react";
import { Box, Chip, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import {showSnackbar} from "../../../shared/jquery_wrapper";

export const FEATURE_NAME = 'prohibited_words';

const ProhibitiveWordsModal = ({ repository, featureGroup, onConfirm }) => {
  const [inputValue, setInputValue] = useState('');
  const [words, setWords] = useState([]);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleTextFieldKeyDown = (event) => {
    /* istanbul ignore next */
    if (event.keyCode === 13) {
      handleAdd();
    }
  };

  const prohibitiveWordsFeature = (data) => {
    return data.filter(item => item.attributes.name === FEATURE_NAME);
  }

  const sortWords = (array) => {
    return array.sort((a, b) => a['word'].localeCompare(b['word']));
  };

  const handleAdd = () => {
    if (inputValue.trim() !== '') {
      const newWord = { word: inputValue.trim(), saved: false };
      const updatedWords = [...words, newWord];
      const sortedWords = sortWords(updatedWords);
      setWords(sortedWords);
      setInputValue('');
      setShowDeleteMessage(false);
    }
  };

  const handleDelete = async (itemIndex) => {
    const array = words.filter((_, index) => index !== itemIndex)
    setWords(array);

    const response = await repository.updateFeature(featureGroup, { attributes: { name: FEATURE_NAME } }, true, { keywords: array.map(item => item.word) });
    setShowDeleteMessage(true);
  };

  const handleSave = async () => {
    try {
      const response = await repository.updateFeature(featureGroup, { attributes: { name: FEATURE_NAME } }, true, { keywords: words.map(item => item.word) });
      const feature = prohibitiveWordsFeature(response.data)
      const [{ attributes: { metadata: { keywords } } }] = feature;
      const chips = words.map(item => ({
        ...item,
        saved: keywords.includes(item.word)
      }));

      setWords(chips);
      onConfirm(words.map(item => item.word));
    } catch (error) {
      showSnackbar({
        content: "Ocorreu um erro ao salvar",
        style: "alert",
        timeout: 3000,
      });
    }
  };

  const getFeatures = async () => {
    try {
      const response = await repository.getFeatures(featureGroup)
      const feature = prohibitiveWordsFeature(response.data)
      const [{ attributes: { metadata: { keywords } } }] = feature;

      /* istanbul ignore next */
      if (keywords) {
        const chips = keywords.map(word => ({
          word: word,
          saved: true
        }));

        setWords(chips);
      }
    } catch (error) {
      showSnackbar({
        content: "Ocorreu um erro ao carregar palavras",
        style: "alert",
        timeout: 3000,
      });
    }
  }

  useEffect(() => {
    getFeatures();
  }, []);

  return (
    <div id="prohibitive-words-modal" className="modal modal-form modal-fixed-footer" style={{height: '45%'}}>
      <div className="modal-content">
          <h4>Adicionar palavra</h4>
          <div style={{marginTop: '-13px'}}>
            A palavra adicionada ficará visível abaixo do campo a ser preenchido e precisará ser salva
          </div>

          <div style={{display: 'flex', alignItems: 'center', marginTop: '5px'}}>
            <input type="text"
                   placeholder="Escreva aqui a sua palavra Ex: (Brahma)"
                   value={inputValue}
                   onChange={handleInputChange}
                   onKeyDown={handleTextFieldKeyDown}
                   style={{width: '490px'}}
            />
            <button className="btn" onClick={handleAdd} style={{marginLeft: '10px'}}>Adicionar</button>
          </div>

          <div>
          {showDeleteMessage &&
                <Box sx={{ display: 'flex', marginTop: 2 }}>
                  <div>A palavra foi removida!</div>
                </Box>
              }
          </div>

          <Box sx={{ display: 'flex', gap: 1, marginTop: 2, flexWrap: 'wrap', width: 630 }}>
            {words.map((item, index) => (
              <div data-testid="prohibitive-word" key={index}>
                <Chip
                  key={index}
                  label={item.word}
                  onDelete={() => handleDelete(index)}
                  deleteIcon={<IconButton data-testid={`delete-icon-${item.word}`}size="small"><CancelIcon /></IconButton>}
                  variant="outlined"
                  data-testid={item.saved ? 'saved' : 'unsaved'}
                  sx={{
                    borderWidth: 1,
                    borderStyle: 'solid',
                    borderColor: item.saved ? '#00CBA8' : '#BDBDBD',
                  }}
                />
              </div>
            ))}
          </Box>
      </div>
      <div className="modal-footer">
        <a className="modal-close grey-text btn-flat">Fechar</a>

        <a className="modal-action btn-flat"
            onClick={handleSave}
            data-testid="save_button"
        >
          Salvar
        </a>
      </div>
    </div>
  );
}

export default ProhibitiveWordsModal;