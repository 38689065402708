const types = {
  transaction_types: {
    label: "Tipo de transação",
    list: [
      { id: "proven_purchase",      label: "Despesas comprovadas",      tabs: ["transactions"] },
      { id: "unproven_purchase",    label: "Despesas não comprovadas",  tabs: ["transactions"] },
      { id: "withdrawal",           label: "Saques",                    tabs: ["transactions"] },
      { id: "withdrawal_fee",       label: "Taxas de saque",            tabs: ["transactions"] },
      { id: "canceled",             label: "Despesas estornadas",       tabs: ["transactions"] },
      { id: "drawback",             label: "Estornos",                  tabs: ["transfers"] },
      { id: "add_balance",          label: "Recarga da subconta",       tabs: ["transfers"] },
      { id: "withdrawal_balance",   label: "Resgate da subconta",       tabs: ["transfers"] },
    ],
  },
};

export default types;
