import {ShadowTheme} from "../../../../admin/ShadowTemplate";
import React, {useState} from "react";
import {Button} from "@mui/material";
import {hot} from "react-hot-loader";
import BulkUpdateDialog from "./BulkUpdateDialog";

const BulkUpdateButtonShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <BulkUpdateButton {...props}/>
    </ShadowTheme>;
};

const BulkUpdateButton = (props) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleDialogOpen = () => setDialogOpen(true);
    const handleDialogClose = () => setDialogOpen(false);

    return <>
        <Button onClick={handleDialogOpen} color={'inherit'}>Atualização em massa</Button>
        <BulkUpdateDialog
            open={dialogOpen}
            onClose={handleDialogClose}
            csrfToken={props.csrfToken}
            companyId={props.companyId}/>
    </>
}

export default hot(module)(BulkUpdateButtonShadow)