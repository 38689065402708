import React from "react";

import PasswordInput from "./PasswordInput";
import classNames from "classnames";

class StrongPassword extends React.Component {
  state = {
    color: "",
    percent: 0,
    label: "",
    password: "",
    passwordConfirmation: "",
    pwdConfirmationDifferent: false,
    quantity: false,
    lowerLetter: false,
    capitalLetter: false,
    number: false,
    special: false,
  };

  render() {
    return (
      <React.Fragment>
        <PasswordInput
          required={this.props.required}
          active={this.props.active}
          label={this.props.label}
          placeholder={this.props.label}
          classField={"pswd-strong-input-field"}
          id={this.props.id_password}
          onChange={(e) => this.checkLevel(e.target.value)}
          value={this.state.password}
          labelClass={this.props.hide_label && "hide"}
          inputClass={this.props.classPassword}
        />
        {(this.state.password.length > 0 || !this.props.hide_checks_when_blank) && this.renderLevel(this.state.percent)}
        {(this.state.password.length > 0 || !this.props.hide_checks_when_blank) && this.renderChecks()}
        {this.props.confirmation && (
          <React.Fragment>
            <PasswordInput
              required={this.props.required}
              active={this.props.active}
              label={`Repita sua nova senha`}
              placeholder={`Repita sua nova senha`}
              classField={"pswd-strong-input-field-confirmation"}
              inputClass={this.state.pwdConfirmationDifferent ? "error" : ""}
              labelClass={classNames({
                "error": this.state.pwdConfirmationDifferent,
                "hide": this.props.hide_label
              })}
              id={this.props.id_password_confirmation}
              value={this.state.passwordConfirmation}
              onChange={(e) =>
                this.setState({
                  ...this.state,
                  passwordConfirmation: e.target.value,
                  pwdConfirmationDifferent: this.passwordConfirmationDifferent(
                    e.target.value
                  ),
                })
              }
            />
            <p className="pswd-strong-dont-match">
              {this.state.pwdConfirmationDifferent &&
                "As senhas não são iguais"}
            </p>
          </React.Fragment>
        )}
        {this.props.submit && (
          <button
            type="submit"
            disabled={this.submitDisabled()}
            className="btn teal lighten-3 pswd-strong-btn"
          >
            {this.props.submit_label}
          </button>
        )}
      </React.Fragment>
    );
  }

  checkLevel(value) {
    const temp = {
      password: value,
      quantity: value.length >= 8 && value.length <= 32,
      lowerLetter: value.match(/[a-z]/gm)?.length > 0,
      capitalLetter: value.match(/[A-Z]/gm)?.length > 0,
      number: value.match(/[0-9]/gm)?.length > 0,
      special: value.match(/[^0-9A-Za-z ]/gm)?.length > 0,
    };
    const valids = Object.values(temp).filter((e) => e === true).length;
    const label = valids == 5 ? "Senha forte" : "Senha fraca";

    this.setState({
      ...this.state,
      ...temp,
      percent: valids * 20,
      color: valids == 5 ? "strong" : "medium",
      label: value.length > 0 ? label : "",
      pwdConfirmationDifferent: this.passwordConfirmationDifferent(
        this.state.passwordConfirmation,
        value
      ),
    });
  }

  passwordConfirmationDifferent(pwdConfirmation, password) {
    return (
      pwdConfirmation.length > 0 &&
      (password || this.state.password) !== pwdConfirmation
    );
  }

  submitDisabled() {
    return (
      Object.values(
        Object.fromEntries(
          Object.entries(this.state).filter(
            ([key]) => key !== "pwdConfirmationDifferent"
          )
        )
      ).includes(false) ||
      this.state.password !== this.state.passwordConfirmation
    );
  }

  renderLevel(percent) {
    return (
      <div className="pswd-strong-level">
        <div
          className={`pswd-strong-level ${this.state.color}`}
          style={{
            padding: `1px ${this.state.password.length > 0 ? "8px" : "0"}`,
            width: `${percent}%`,
          }}
        >
          {this.state.label}
        </div>
      </div>
    );
  }

  renderChecks() {
    const pswdNotBlank = this.state.password.length > 0;
    const textRight = "match";
    const textWrong = "dont-match";
    const iconRight = "check";
    const iconWrong = "close";

    return (
      <div
        className={`pswd-strong-checks ${
          this.props.white_text && "white-text"
        }`}
      >
        <p
          className={`${
            pswdNotBlank && (this.state.quantity ? textRight : textWrong)
          }`}
        >
          <i
            test-name="check-quantity"
            className={`mdi mdi-${
              pswdNotBlank && this.state.quantity ? iconRight : iconWrong
            }`}
          />
          Ter entre 8 e 32 digitos
        </p>
        <p
          className={`${
            pswdNotBlank && (this.state.lowerLetter ? textRight : textWrong)
          }`}
        >
          <i
            test-name="check-lower-letter"
            className={`mdi mdi-${
              pswdNotBlank && this.state.lowerLetter ? iconRight : iconWrong
            }`}
          />
          Ter no mínimo 1 letra minúscula
        </p>
        <p
          className={`${
            pswdNotBlank && (this.state.capitalLetter ? textRight : textWrong)
          }`}
        >
          <i
            test-name="check-capital-letter"
            className={`mdi mdi-${
              pswdNotBlank && this.state.capitalLetter ? iconRight : iconWrong
            }`}
          />
          Ter no mínimo 1 letra maiúscula
        </p>
        <p
          className={`${
            pswdNotBlank && (this.state.number ? textRight : textWrong)
          }`}
        >
          <i
            test-name="check-number"
            className={`mdi mdi-${
              pswdNotBlank && this.state.number ? iconRight : iconWrong
            }`}
          />
          Ter no mínimo 1 numeral
        </p>
        <p
          className={`${
            pswdNotBlank && (this.state.special ? textRight : textWrong)
          }`}
        >
          <i
            test-name="check-special"
            className={`mdi mdi-${
              pswdNotBlank && this.state.special ? iconRight : iconWrong
            }`}
          />
          Ter no mínimo 1 caracter especial
        </p>
      </div>
    );
  }
}

StrongPassword.defaultProps = {
  submit: true,
  required: true,
  confirmation: true,
  label: "Insira sua senha",
  id_password: "password",
  id_password_confirmation: "password_confirmation",
  classPassword: "",
  submit_label: "criar nova senha",
  hide_label: false,
  white_text: false,
  active: true,
  hide_checks_when_blank: false,
};

export default StrongPassword;
