import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Radio,
  FormControlLabel,
  Button,
  RadioGroup,
  FormControl,
  Box
} from '@mui/material';
import { admin_card_transaction_users_path } from '../../../../routes';

const UserSelectionModal = ({transaction, open, onClose, csrfToken, handleDisplaySnackbar, updateTransactions}) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const regex = new RegExp(searchTerm, 'i');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(admin_card_transaction_users_path(transaction), {
          headers: {
            "Accept": "application/json"
          }
        });

        const result = await response.json();
        setUsers(result.users);
      } catch (error) {
        handleDisplaySnackbar(`Ocorreu um erro ${error.message}`)
      }
    };

    /* istanbul ignore next */
    if (open) {
      fetchData();
    }
  }, [open]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const filteredUsers = users.filter((user) => regex.test(user.name));

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const update = async () => {
    let body = {
      card_transaction: {
        user_id: selectedUser
      }
    }

    try {
      const response = await fetch(admin_card_transaction_users_path(transaction), {
        method: 'PUT',
        headers: {
          "X-CSRF-Token": csrfToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if (response.ok) {
        handleDisplaySnackbar("Usuário associado com sucesso.");  
      } else {
        handleDisplaySnackbar("Não foi possível associar o usuário.");
      }

      onClose();
      updateTransactions();
      setSelectedUser(null);
      setSearchTerm('');
    } catch (error) {
      handleDisplaySnackbar(`Ocorreu um erro ${error.message}`);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      data-testid="dialog-user-selection"
    >
      <DialogTitle>Selecione um usuário para associar</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <TextField
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder={'Digite para filtrar os usuários'}
          />

          <RadioGroup
            value={selectedUser}
            onChange={handleUserChange}
            style={{marginTop: '20px'}}
          >
            {filteredUsers.map((user) => (
              <Box borderBottom={1}borderColor="grey.300" key={user.id}>
                <FormControlLabel
                  key={user.id}
                  value={user.id}
                  control={<Radio />}
                  label={
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <img src={user.avatar} width={36} height={36} />
                      {`${user.name} (${user.email})`}
                    </div>
                  }
                  style={{padding: '10px'}}
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" data-testid="close-user-selection">
          Cancelar
        </Button>
        <Button color="primary" onClick={() => update()} disabled={!selectedUser}>
          Associar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserSelectionModal;