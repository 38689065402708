import React from "react";
import { Button } from "@mui/material";
import { new_user_session_path } from "../../../routes";

const TwoFactorBackButton = () => {
  const onBack = () => {
    window.location.assign(new_user_session_path());
  }

  return <Button
    onClick={onBack}
    data-testid={"two-factor-back-button"}
  >
    Voltar para login
  </Button>
}

export default TwoFactorBackButton;