import React, { useState, useEffect } from 'react';
import {  Stack, Typography, Grid, Snackbar } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/Person';
import PaidIcon from '@mui/icons-material/Description';
import LoadingChart from "../../../../../assets/images/admin/shared/empty_space_conta_digital.svg";
import { account_chart_admin_home_path } from '../../../../routes';
import HomeAccountChart from "./HomeAccountChart";
import HomeCardSummaryItem from "../common/HomeCardSummaryItem";
import ReceiptIcon from "@mui/icons-material/Receipt";

const HomeAccountCardContent = (props) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(account_chart_admin_home_path());
        const result = await response.json();

        setChartData(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setSnackbarMessage(`Ocorreu um erro ao carregar o gráfico de despesas mensais: ${error}`);
        setOpenSnackbar(true);
      }
    };

    fetchData();
  }, []); 

  return (
    <Stack data-testid="account-content">
      <Typography mb={1} variant="h6" style={{color: "#3D0079"}}>
        Conta Digital
      </Typography>

      {!!chartData.length && (
          <Grid container spacing={2} sx={{mb: 1}}>
            <Grid item xs={12} sm={6}>
              <HomeCardSummaryItem
                  title={`${props.balanceAvailable} disponível`}
                  subtitle={"para alocação"}
                  icon={<AttachMoneyIcon fontSize="large"/>}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <HomeCardSummaryItem
                  title={`${props.allocatedBalance} alocados`}
                  subtitle={"no momento"}
                  icon={<PaidIcon fontSize="large"/>}/>
            </Grid>
          </Grid>
      )}

      {loading ? (
        <LoadingChart />
      ) : (
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>Histórico de compras nos cartões</Typography>

          <HomeAccountChart data={chartData} />
        </Grid>
      )}

      <Snackbar
        data-testid="snackbar"
        open={openSnackbar}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
      />
    </Stack>
  );
}

export default HomeAccountCardContent;

