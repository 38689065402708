import {Card, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import React from "react";

export const FaqDrawerButton = ({onClick, disabled}) => {
    return <Card>
        <ListItemButton
            data-testid={'faq-drawer-button'}
            onClick={onClick}
            disabled={disabled ?? false}>
            <ListItemIcon><HelpOutlineIcon color="disabled"/></ListItemIcon>
            <ListItemText primary="Precisa de ajuda?"/>
            <ChevronRightIcon color="disabled"/>
        </ListItemButton>
    </Card>
}