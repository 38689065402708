import {Grid, Typography} from "@mui/material";
import {ErpConnection} from "../ErpConnection";
import React, {useMemo, useState} from "react";
import {CredentialField} from "../CredentialField";
import {updateConnection} from "../../erp_integration_connector";
import {OmieConnectionSteps} from "./OmieConnectionSteps";

export const OmieConnection = ({integration}) => {
    const initialAppSecret = useMemo(() => integration?.maskedCredentials?.appSecret ?? '', [integration])
    const initialAppKey = useMemo(() => integration?.maskedCredentials?.appKey ?? '', [integration])
    const [appSecret, setAppSecret] = useState(initialAppSecret)
    const [appKey, setAppKey] = useState(initialAppKey)

    const validCredential = useMemo(() => {
        const validAppKey = appKey.length > 0 && !appKey.includes('*')
        const validAppSecret = appSecret.length > 0 && !appSecret.includes('*')
        return validAppKey && validAppSecret
    }, [appKey, appSecret]);

    return <ErpConnection
        erp={'omie'}
        integration={integration}
        videoTitle={'Veja no vídeo abaixo o passo a passo completo de como obter o Token do ERP para integrar com nossa plataforma:'}
        videoId={'0iivj-S3Q4Q'}
        steps={<OmieConnectionSteps/>}
        testConnection={() => updateConnection('omie', {app_secret: appSecret, app_key: appKey})}
        validCredential={validCredential}
        form={(loading, failed) =>
            <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Inserir “Token de integração” e clicar em “Testar conexão”.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CredentialField
                        initialValue={initialAppKey}
                        loading={loading}
                        onChange={setAppKey}
                        label={'APP Key'}
                        placeholder={'Insira a credencial do sistema Omie aqui'}
                        error={failed ? 'Revise o código informado e tente novamente' : ''}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CredentialField
                        initialValue={initialAppSecret}
                        loading={loading}
                        onChange={setAppSecret}
                        label={'APP Secret'}
                        placeholder={'Insira a credencial do sistema Omie aqui'}
                        error={failed ? 'Revise o código informado e tente novamente' : ''}/>
                </Grid>
            </Grid>
        }/>
}

