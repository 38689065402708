import React from "react";
import { ShadowTheme } from "../../ShadowTemplate";

import { Toolbar, Typography } from "@mui/material";

import ApiToken from "./ApiToken";

// last import
import { hot } from "react-hot-loader";

const NavBAr = (props) => {
  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#F0EBF5",
          marginBottom: "30px",
        }}
      >
        <Typography>Banking API</Typography>
        <ApiToken />
      </Toolbar>
    </ShadowTheme>
  );
};

export default hot(module)(NavBAr);
