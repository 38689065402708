import React from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const HomeTotalCard = (props) => {
  const theme = useTheme();
  return (
    <Card variant={"elevation"} sx={{ p: 2.0, height: 'calc(100% - 32px)' }}>
      <Stack spacing={1.5} height={'100%'}>
        <Stack spacing={1.5} direction={"row"} alignItems={"center"}>
          <Box sx={{ p: 0.5, display: 'flex' }} style={{ backgroundColor: theme.palette.surfaceLight5.main, borderRadius: 5.0 }}>
            {props.icon}
          </Box>
          <Typography variant="subtitle1">{props.title}</Typography>
        </Stack>
        <Stack flexGrow={1} spacing={-0.5} justifyContent={'space-between'}>
          <Typography variant="subtitleGray">{props.description}</Typography>
          <Typography variant="h6">{props.total}</Typography>
        </Stack>
      </Stack>
    </Card>
  );
}

export default HomeTotalCard;