import React, {useCallback, useState} from "react";
import TextField from "@mui/material/TextField";

export const CredentialField = ({initialValue, label, onChange, placeholder, error, disabled}) => {
    const [value, setValue] = useState(initialValue)

    const onClickField = useCallback(() => {
        if (value === initialValue && initialValue !== '') {
            setValue('')
        }
    }, [value, initialValue])

    const onBlurField = useCallback(() => {
        if (value === '' && initialValue !== '') {
            setValue(initialValue)
        }
    }, [value, initialValue])

    const onChangeValue = useCallback((value) => {
        setValue(value)
        onChange(value)
    }, []);

    return <TextField
        required
        fullWidth
        label={label}
        value={value}
        error={!!error}
        disabled={disabled}
        helperText={error}
        onBlur={onBlurField}
        onClick={onClickField}
        placeholder={placeholder}
        onChange={(e) => onChangeValue(e.target.value)}/>
}