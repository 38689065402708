import React from "react";
import { Paper, ListItem, ListItemText, Button, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';

const SelectionSummary = ({ actionTitle, title, description, totalSelectedTransactions, handleOpenDialog, setSelectedTransactions, setSelectAll }) => {
  const totalTransactionsText = `${totalSelectedTransactions} ${
    totalSelectedTransactions === 1 ? 'selecionada' : 'selecionadas'
  }`;

  return (
    <Paper sx={{backgroundColor: '#F0F0F1', my: 3}}>
      <ListItem
        secondaryAction={
          <React.Fragment>
            <Button
              variant="contained"
              style={{
                backgroundColor: "#3D0079",
                color: "white"
              }}
              startIcon={<ArchiveIcon />}
              onClick={() => handleOpenDialog(null, title, description)}
              aria-label={actionTitle}
            >
              {actionTitle}
            </Button>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              sx={{marginLeft: '10px'}}
              onClick={() => {
                setSelectedTransactions([]);
                setSelectAll(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </React.Fragment>
        }
      >
        <ListItemText
            primaryTypographyProps={{fontWeight: 500}}
            secondaryTypographyProps={{fontWeight: 400}}
            secondary={totalTransactionsText}
        >
            <Stack direction={'row'} spacing={1}><span>{title}</span></Stack>
        </ListItemText>
      </ListItem>
    </Paper>
  );
}

export default SelectionSummary;
