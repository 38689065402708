import React from "react";
import {ShadowTheme} from "../../admin/ShadowTemplate";
import {LightboxForm} from "./form/LightboxForm";
import {hot} from "react-hot-loader";

const LightboxEditShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <LightboxForm {...props}/>
    </ShadowTheme>
}

export default hot(module)(LightboxEditShadow);
