import React from "react";
import { cpf } from "cpf-cnpj-validator";

import SelectTab from "../../shared/SelectTab";
import Masked from "../../shared/inputs/Masked";
import { initSelects, showSnackbar } from "../../shared/jquery_wrapper";
import Select from "../../shared/inputs/Select";
import {
  parserDate,
  parserCPF,
  validateDate,
  dateMask,
  cpfMask,
} from "../../shared/helpers";

class HoldersForm extends React.Component {
  state = {
    document: "",
    name: "",
    birth_date: "",
    partner_type: "",

    buttonClicked: "",
    id: "",

    holdersList: [],
    exibAllHolders: false,
    loading: false,
    holder_type: "physical",

    try_to_save: false,
  };

  componentDidMount() {
    const tempHolders = this.props.holders.filter(
      (e) => e.holder_type === "physical"
    );

    this.setState({ ...this.state, holdersList: tempHolders });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.id !== this.state.id) {
      initSelects();
    }
  }

  render() {
    return (
      <div className="card-panel account-new-holders">
        <SelectTab
          list={[
            {
              testName: "pf-holder",
              label: "Dados do sócio pessoa física (PF)",
            },
          ]}
          customClass="tab-new-holder"
        />

        <div className="content">
          {this.state.holdersList.filter(
            (e) => e.holder_type === this.state.holder_type
          ).length > 0 && this.renderHolderList()}

          <form
            test-name="physical-form"
            className="form-design-on-surfaces d-flex justify-between flex-wrap"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="d-flex justify-between full-width">
              <div>
                <h5>
                  Cadastre os dados e adicione o número total de sócios da
                  empresa
                </h5>
                <p>Informe os dados do sócio PF</p>
              </div>
            </div>
            <Masked
              required={true}
              error={this.invalidField("name")}
              forceError={this.invalidField("name") && this.state.try_to_save}
              test={"name"}
              size={65}
              placeholder={"Nome completo do sócio"}
              label={"Nome completo"}
              value={this.state.name}
              onChange={(e) => this.handleChange("name", e.target.value)}
            />
            <Masked
              required={true}
              error={this.invalidField("document")}
              forceError={
                this.invalidField("document") && this.state.try_to_save
              }
              test={"document"}
              size={30}
              placeholder={"000.000.000-00"}
              label={"CPF"}
              mask={cpfMask()}
              value={this.state.document}
              onChange={(e) => this.handleChange("document", e.target.value)}
            />
            <Masked
              required={true}
              error={this.invalidField("birth_date")}
              forceError={
                this.invalidField("birth_date") && this.state.try_to_save
              }
              test={"birth_date"}
              size={30}
              placeholder={"00/00/0000"}
              label={"Data de nascimento"}
              mask={dateMask()}
              value={this.state.birth_date}
              onChange={(e) => this.handleChange("birth_date", e.target.value)}
            />
            <Select
              required={true}
              error={
                this.invalidField("partner_type") && this.state.try_to_save
              }
              test={"partner_type"}
              size={30}
              placeholder={"Selecione o tipo de sócio"}
              label={"Tipo de sócio"}
              options={[
                { value: "partner", label: "Sócio" },
                { value: "proxyholder", label: "Procurador" },
                {
                  value: "legal_representative",
                  label: "Representante Legal",
                },
              ]}
              value={this.state.partner_type}
              onChange={(e) =>
                this.handleChange("partner_type", e.target.value)
              }
            />
            <div style={{ width: "30%" }} />
            <div className="d-flex justify-end full-width button-group">
              {this.renderSaveAddButton()}
              {this.renderContinueButton()}
              {this.renderReviewButton()}
            </div>
          </form>
        </div>
      </div>
    );
  }

  renderHolderList() {
    const list = this.state.holdersList.filter(
      (e) => e.holder_type === "physical"
    );

    return (
      <React.Fragment>
        <div className="full-width holder-list-tittle">
          <h5>Sócios cadastrados</h5>
        </div>

        {list.map((holder, idx) =>
          idx <= 2 || this.state.exibAllHolders ? (
            <div
              key={idx}
              className="collection z-depth-1 d-flex justify-between align-center holder-list-item"
            >
              {holder.name}
              <div>
                <i
                  test-name={`delete-holder-${holder.id}`}
                  className="mdi mdi-delete icon delete"
                  onClick={() => this.handleDelete(holder.id)}
                />
                <i
                  test-name={`edit-holder-${holder.id}`}
                  className="mdi mdi-pencil icon"
                  onClick={() => this.fillState(holder)}
                />
              </div>
            </div>
          ) : (
            <React.Fragment key={idx} />
          )
        )}

        {list.length > 3 && (
          <p
            test-name="handle-see-all-holders"
            className="d-flex justify-center holder-list-more"
            onClick={() =>
              this.handleChange("exibAllHolders", !this.state.exibAllHolders)
            }
          >
            {this.state.exibAllHolders ? "Ver menos" : "Ver todos os"} sócios
            cadastrados{" "}
            <i
              className={`mdi mdi-chevron-${
                this.state.exibAllHolders ? "up" : "down"
              }`}
            />
          </p>
        )}
      </React.Fragment>
    );
  }

  renderSaveAddButton() {
    return (
      <button
        type="submit"
        test-name="save-add-button"
        className="btn"
        onClick={() => this.handleChange("buttonClicked", "add")}
        disabled={this.state.loading === true}
      >
        {this.state.loading ? "Salvando" : "Salvar e Adicionar novo Sócio PF"}
      </button>
    );
  }

  renderContinueButton() {
    return (
      <button
        type="submit"
        test-name="save-continue-button"
        className="btn save-continue-button"
        onClick={() => this.handleChange("buttonClicked", "continue")}
        disabled={this.state.loading === true}
      >
        {this.state.loading ? "Salvando" : "Salvar e Continuar"}
      </button>
    );
  }

  renderReviewButton() {
    return (
      this.state.holdersList.length > 0 && (
        <button
          test-name="go-review-button"
          className="btn review-button"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload(false);
          }}
          disabled={this.state.loading}
        >
          {this.props.edit ? "Voltar para revisão" : "Ir para revisão"}
        </button>
      )
    );
  }

  clearState(customState) {
    const clear = {
      document: "",
      name: "",
      birth_date: "",
      partner_type: "",

      buttonClicked: "",
      id: "",

      try_to_save: false,
    };

    this.setState({ ...this.state, ...clear, ...customState });
  }

  fillState(data) {
    let infos = { ...data };
    delete infos.address;

    this.setState({
      ...this.state,
      ...infos,
      birth_date: parserDate(infos.birth_date),
      document: parserCPF(infos.document),
    });
  }

  invalidField(checked) {
    const errors = {
      document: !cpf.isValid(cpf.strip(this.state.document)),
      birth_date: !validateDate(this.state.birth_date),
      name: this.state.name.length === 0,
      partner_type: this.state.partner_type === "",
    };

    return checked === "all"
      ? Object.values(errors).includes(true)
      : errors[checked];
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.invalidField("all")) {
      this.handleChange("try_to_save", true);

      showSnackbar({
        content: "Campos obrigatórios não preenchidos corretamente!",
        style: "alert",
      });

      return;
    }

    this.handleChange("loading", true);

    $.ajax({
      type: this.state.id === "" ? "POST" : "PUT",
      dataType: "json",
      url: this.submitEndpoint(),
      data: this.state,
    })
      .then((response) => {
        showSnackbar({ content: "Salvo com sucesso!", style: "notice" });

        let tmpList = [...this.state.holdersList];

        if (this.state.buttonClicked === "add") {
          if (this.state.id === "") {
            tmpList.push(response);
          } else {
            const idx = tmpList.findIndex((e) => e.id === this.state.id);
            tmpList[idx] = response;
          }

          this.clearState({ holdersList: tmpList, loading: false });
        } else {
          window.location.reload(false);
        }
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possível salvar, tente novamente!",
          style: "alert",
        });
        this.handleChange("loading", false);
      });
  }

  handleDelete(id) {
    $.ajax({
      type: "DELETE",
      dataType: "json",
      url: `${this.props.endpoint.replace(
        "company_id",
        this.props.company_id
      )}/${id}`,
    })
      .then((response) => {
        showSnackbar({ content: "Excluído com sucesso!", style: "notice" });

        let tmpList = [...this.state.holdersList];
        const idx = tmpList.findIndex((e) => e.id === id);
        tmpList.splice(idx, 1);

        this.handleChange("holdersList", tmpList);
      })
      .catch((error) => {
        showSnackbar({
          content: "Não foi possível excluir, tente novamente!",
          style: "alert",
        });
      });
  }

  submitEndpoint() {
    return this.state.id === ""
      ? this.props.endpoint.replace("company_id", this.props.company_id)
      : `${this.props.endpoint.replace("company_id", this.props.company_id)}/${
          this.state.id
        }`;
  }

  handleChange(proprety, value) {
    this.setState({ ...this.state, [proprety]: value });
  }
}

HoldersForm.defaultProps = {
  company_id: "",
  endpoint: "",
  holders: [],
  edit: false,
};

export default HoldersForm;
