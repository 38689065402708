import React from "react";
import { Box, Button, DialogActions, DialogContent, Link, Typography } from "@mui/material";
import TwoFactorConfigFaqLink from "./TwoFactorConfigFaqLink";
import TwoFactorCopyCodeButton from "./TwoFactorCopyCodeButton";
import QRCode from "react-qr-code";

const IosLink = () => {
  return <Link
    href="https://apps.apple.com/app/google-authenticator/id388497605"
    target="_blank"
  >
    iOS
  </Link>
}

const AndroidLink = () => {
  return <Link
    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
    target="_blank"
  >
    Android
  </Link>
}

const TwoFactorConfigStepOne = ({ secret, qrCode, onNext, smallScreen }) => {
  return <>
    <DialogContent>
      <Typography variant="h6">
        1. Como utilizar o Google Authenticator?
      </Typography>
      <Typography mt={1}>
        Para autenticar, recomendamos o aplicativo Google Authenticator que é totalmente gratuito e seguro.
      </Typography>
      <Typography>
        Faça o download <IosLink/> ou <AndroidLink/>, a instalação e avance. O download do aplicativo deve ser feito pelo seu aparelho celular.
      </Typography>
      <Typography my={2} sx={{ fontWeight: "bold" }}>
        Após concluir a ativação do Duplo Fator de Autenticação mantenha o aplicativo do Google Authenticator em seu aparelho para continuar com a autenticação ativa. O aplicativo é necessário toda vez que logar no Espresso.
      </Typography>
      <Typography variant="h6">
        2. Ativação pelo QR Code ou código
      </Typography>
      <Typography mt={1}>
        { smallScreen ? "Ao abrir o aplicativo do Google Authenticator, opte por copiar e colar o código diponível para realizar a Autenticação do Duplo Fator."
          : "Ao abrir o aplicativo do Google Authenticator, opte por escanear o QR Code ou copie e cole o código diponível para realizar a configuração do seu 2FA." }
      </Typography>
      { !smallScreen && <Box display={"flex"} justifyContent={"center"}>
          <QRCode data-testid="qr-code" value={qrCode}/>
        </Box> }
      <Box py={1} display={"flex"} justifyContent={"center"}>
        <TwoFactorCopyCodeButton value={secret}/>
      </Box>
      <TwoFactorConfigFaqLink/>
    </DialogContent>
    <DialogActions sx={{ justifyContent: "flex-end", px: 3 }}>
      <Button onClick={onNext} variant="contained">Avançar</Button>
    </DialogActions>
  </>
}

export default TwoFactorConfigStepOne;