import { IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export const isValidStrongPassword = (input) => {
    return hasValidPasswordSize(input) && 
        hasAnyLowerCharacter(input) &&
        hasAnyUpperCharacter(input) &&
        hasAnyNumberCharacter(input) &&
        hasAnySymbolCharacter(input);
}

export const hasValidPasswordSize = (input) => {
    return input.length >= 8 && input.length <= 32;
}

export const hasAnyLowerCharacter = (input) => {
    return input.match(/[a-z]/gm)?.length > 0;
}

export const hasAnyUpperCharacter = (input) => {
    return input.match(/[A-Z]/gm)?.length > 0;
}

export const hasAnyNumberCharacter = (input) => {
    return input.match(/[0-9]/gm)?.length > 0;
}

export const hasAnySymbolCharacter = (input) => {
    return input.match(/[^0-9A-Za-z ]/gm)?.length > 0;
}

const NewStrongPasswordField = ({ value, error, onChange }) => {
  const [password, setPassword] = useState(value);
  const [showPassword, setShowPassword] = useState(false);

  const sizeCheck = useMemo(() => hasValidPasswordSize(password), [password]);
  const lowerCheck = useMemo(() => hasAnyLowerCharacter(password), [password]);
  const upperCheck = useMemo(() => hasAnyUpperCharacter(password), [password]);
  const digitCheck = useMemo(() => hasAnyNumberCharacter(password), [password]);
  const symbolCheck = useMemo(() => hasAnySymbolCharacter(password), [password]);

  const handleOnChange = (event) => {
        setPassword(event.target.value);
        onChange(event);
  }

  const ruleColor = (check) => {
        if (password.length === 0) return 'inherit';
        return check ? 'primary' : 'error';
  }

  const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
  }
  
  return (
        <TextField
            required
            fullWidth
            data-testid={'user-password-field'}
            error={error}
            InputLabelProps={{ shrink: true }}
            type={showPassword ? 'text' : 'password'}
            size={'medium'}
            label={'Crie uma senha'}
            placeholder={'Crie uma senha'}
            helperText={<>
                <Typography color={ruleColor(sizeCheck)}
                            variant={'inherit'}
                            component={'span'}>{" 8 à 32 caracteres"}</Typography>,
                <Typography color={ruleColor(lowerCheck)}
                            variant={'inherit'}
                            component={'span'}>{" 1 letra minúscula"}</Typography>,
                <Typography color={ruleColor(upperCheck)}
                            variant={'inherit'}
                            component={'span'}>{" 1 letra maiúscula"}</Typography>,
                <Typography color={ruleColor(digitCheck)}
                            variant={'inherit'}
                            component={'span'}>{" 1 numeral"}</Typography>,
                <Typography color={ruleColor(symbolCheck)}
                            variant={'inherit'}
                            component={'span'}>{" 1 caractere especial"}</Typography>
            </>}
            value={password}
            onChange={handleOnChange}
            InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end">
                                {showPassword ? <VisibilityOffIcon/> : <VisibilityIcon/>}
                        </IconButton>
                    </InputAdornment>
            }}
        />
  );
}

export default NewStrongPasswordField;