/* istanbul ignore file */

import React, { useEffect, useState } from "react";
import { ShadowTheme } from "../ShadowTemplate";
import moment from "moment";
require("moment/locale/pt-br");

import { parserCurrencyBRLValue } from "../../shared/helpers";
import { deposit_admin_account_balance_statement_path } from "../../../../../app/javascript/routes";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import Circle from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import HighchartsReact from "highcharts-react-official";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ReceiptIcon from "@mui/icons-material/Receipt";

// last import
import { hot } from "react-hot-loader";

const CustomCard = ({ children }) => {
  return (
    <Card style={{ width: "49%", minWidth: "500px", marginBottom: "25px" }}>
      {children}
    </Card>
  );
};

const BalanceStatements = (props) => {
  const [chartBalanceOptions, setChartBalanceOptions] = useState({
    credits: { enabled: false },
    tooltip: { enabled: false },
    colors: ["#D0B6AD", "#00CBA8"],
    chart: { type: "pie", height: "120px" },
    title: { enabled: false, text: null },
    plotOptions: {
      pie: {
        dataLabels: { enabled: false },
        showInLegend: false,
      },
      series: { states: { hover: { enabled: false } } },
    },
    series: [
      {
        innerSize: "60%",
        data: [
          { name: "alocado", y: Number(props.allocatedBalance) },
          { name: "disponível para alocação", y: Number(props.balance) },
        ],
      },
    ],
  });

  const [chartInAndOut, setChartInAndOut] = useState({
    credits: { enabled: false },
    chart: { type: "column", height: "230px" },
    colors: ["#00CBA8", "#D0B6AD"],
    title: { text: null },
    xAxis: {
      title: { text: null },
      categories: [],
      accessibility: { description: "Entradas e saídas mensais" },
    },
    yAxis: {
      gridLineWidth: 0,
      title: { text: null },
      labels: {
        overflow: "top",
        align: "center",
        formatter: function () {
          /* istanbul ignore next */
          return this.isLast ? this.value / 1000 + " mil" : this.value / 1000;
        },
      },
    },
    tooltip: {
      valuePrefix: "R$ ",
      stickOnContact: true,
      backgroundColor: "rgba(255, 255, 255, 0.93)",
      shared: true,
    },
    legend: { enabled: true, verticalAlign: "top" },
    series: [
      { name: "Entradas", data: [] },
      { name: "Saídas", data: [] },
    ],
  });

  // set data chart
  useEffect(() => {
    let last12Months = [];
    let first = false;
    const fund_in = props.lastYearSumFundInPerMonth;
    const cost_out = props.lastYearSumCostOutPerMonth;

    let last12MonthsName = [];
    let fundIn = [];
    let costOut = [];

    for (let i = 11; i >= 0; i--) {
      last12Months.push(moment().subtract(i, "months").format("YYYY-MM"));
    }

    moment.locale("pt-br");

    last12Months.forEach((month) => {
      if (first || fund_in[month] || cost_out[month]) {
        first = true;
        last12MonthsName.push(moment(month).format("MMM").toUpperCase());
        fundIn.push(fund_in[month] || 0);
        costOut.push(cost_out[month] || 0);
      }
    });

    setChartInAndOut({
      ...chartInAndOut,
      xAxis: { ...chartInAndOut.xAxis, categories: last12MonthsName },
      series: [
        { name: "Entradas", data: fundIn },
        { name: "Saídas", data: costOut },
      ],
    });
  }, []);

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <CustomCard>
          <CardHeader
            sx={{ fontSize: "20px" }}
            title={"Distribuição de saldo"}
            action={
              props.active && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() =>
                    window.location.assign(
                      deposit_admin_account_balance_statement_path()
                    )
                  }
                  data-testid="btn-add-balance"
                >
                  <AddIcon sx={{ marginRight: "10px" }} />
                  adicionar saldo
                </Button>
              )
            }
          />
          <CardContent>
            <Grid>
              <Grid item>
                <p
                  style={{
                    margin: "0px",
                    color: "rgba(0, 0, 0, 0.6)",
                    fontSize: "24px",
                  }}
                >
                  Saldo total
                </p>
                <p style={{ margin: "0px", fontSize: "48px" }}>
                  {parserCurrencyBRLValue(props.totalBalance)}
                </p>
              </Grid>
              <div style={{ display: "flex" }}>
                <Grid item style={{ width: "120px" }}>
                  <HighchartsReact options={chartBalanceOptions} />
                </Grid>
                <List>
                  <ListItem>
                    <ListItemAvatar style={{ minWidth: "32px" }}>
                      <Circle sx={{ color: "#D0B6AD" }} />
                    </ListItemAvatar>
                    <ListItemText>
                      Alocados nos cartões:{" "}
                      {parserCurrencyBRLValue(props.allocatedBalance)}
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar style={{ minWidth: "32px" }}>
                      <Circle sx={{ color: "#00CBA8" }} />
                    </ListItemAvatar>
                    <ListItemText>
                      Disponível para alocação:{" "}
                      {parserCurrencyBRLValue(props.balance)}
                    </ListItemText>
                  </ListItem>
                </List>
              </div>
            </Grid>
          </CardContent>
        </CustomCard>
        <CustomCard>
          <CardHeader title={"Entradas e saídas mensais"} />
          <CardContent>
            <HighchartsReact options={chartInAndOut} />
          </CardContent>
        </CustomCard>
        <CustomCard>
          <CardHeader
            title={
              <Stack spacing={1.5} direction={"row"} alignItems={"center"}>
                <Box
                  sx={{ p: 0.5, display: "flex" }}
                  style={{
                    backgroundColor: "rgba(240, 235, 245, 1)",
                    borderRadius: 5.0,
                    color: "#3D0079",
                  }}
                >
                  <CreditCardIcon />
                </Box>
                <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
                  Cartões
                </Typography>
              </Stack>
            }
            action={
              props.active && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() =>
                    window.open(
                      "https://docs.google.com/forms/d/e/1FAIpQLSe2AZbrSO9uMEYktdk3CcIRibP1s3ZXSPAN1kBC0EE-4798Rw/viewform"
                    )
                  }
                  data-testid="btn-order-cards"
                >
                  <CreditCardIcon sx={{ marginRight: "10px" }} />
                  solicitar cartões físicos
                </Button>
              )
            }
          />
          <CardContent>
            <Typography sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "14px" }}>
              Total de cartões ativos
            </Typography>
            <Container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              style={{ padding: 0 }}
            >
              <Typography sx={{ fontSize: "24px" }}>{`${props.totalCards} cart${
                props.totalCards === 1 ? "ão" : "ões"
              }`}</Typography>
              <div>
                <Chip
                  label={`${props.totalPhysicalCards} físico${
                    props.totalPhysicalCards === 1 ? "" : "s"
                  }`}
                  color="default"
                  variant="outlined"
                  sx={{
                    marginRight: "10px",
                    borderColor: "rgba(0, 0, 0, 0.6)",
                  }}
                />
                <Chip
                  label={`${props.totalVirtualCards} virtua${
                    props.totalVirtualCards === 1 ? "l" : "is"
                  }`}
                  color="default"
                  variant="outlined"
                  sx={{ borderColor: "rgba(0, 0, 0, 0.6)" }}
                />
              </div>
            </Container>
          </CardContent>
        </CustomCard>
        <CustomCard>
          <CardHeader
            title={
              <Stack spacing={1.5} direction={"row"} alignItems={"center"}>
                <Box
                  sx={{ p: 0.5, display: "flex" }}
                  style={{
                    backgroundColor: "rgba(240, 235, 245, 1)",
                    borderRadius: 5.0,
                    color: "#3D0079",
                  }}
                >
                  <ReceiptIcon />
                </Box>
                <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
                  Despesas
                </Typography>
              </Stack>
            }
          />
          <CardContent>
            <Typography sx={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "14px" }}>
              Despesas não comprovadas
            </Typography>
            <Container
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              style={{ padding: 0 }}
            >
              <Typography sx={{ fontSize: "24px" }}>{`${
                props.totalUnprovenExpenses
              } despesa${
                props.totalUnprovenExpenses === 1 ? "" : "s"
              }`}</Typography>
              <Chip
                label={`${props.percentageUnprovenExpenses}% do total de transações`}
                variant="outlined"
                color="default"
                sx={{ marginRight: "10px", borderColor: "rgba(0, 0, 0, 0.6)" }}
              />
            </Container>
          </CardContent>
        </CustomCard>
      </div>
    </ShadowTheme>
  );
};

export default hot(module)(BalanceStatements);
