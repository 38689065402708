import React from "react";
import currency from "currency.js/dist/currency.min.js";

class Currency extends React.Component {
  render() {
    return (
      <React.Fragment>
        { (this.props.currency === 'BRL') ? this.renderBRLCurrency() : this.renderNonBRLCurrency() }
      </React.Fragment>
    );
  }

  renderBRLCurrency() {
    return(<>{`R$ ${this.formatCost()}`}</>);
  }

  renderNonBRLCurrency() {
    return(<>{`${this.formatCost()} ${this.props.currency}`}</>);
  }

  formatCost() {
    return currency(this.props.cost, { decimal: ',', separator: '.' }).format();
  }
}

export default Currency;
