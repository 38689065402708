export function checkTabActive(tab, types, selectTab, filters) {
  if (types.length === 0 && selectTab === tab) {
    return true;
  }

  let active = false;
  types.forEach((type) => {
    if (!active) {
      active = filters.transaction_types.list.find((t) => t.id === type).tabs.includes(tab);
    }
  });

  return active;
}
