import {
    Button,
    FormControl,
    InputLabel,
    ListSubheader,
    MenuItem,
    Select,
    Stack,
    TextField
} from "@mui/material";
import * as React from "react";
import {useMemo, useState} from "react";
import {DateField, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const EspressoTableFilterForm = ({columns, onAdd}) => {
    const [column, setColumn] = useState(null);
    const [value, setValue] = useState('');

    const invalid = useMemo(() => {
        return !column || (typeof value === 'string' && value.trim() === '')
    }, [column, value]);

    const handleColumn = (event) => setColumn(columns.find(column => column.id === event.target.value));

    const handleValue = (value) => setValue(value);

    const handleAdd = () => {
        onAdd({column: column.id, value});
        setColumn(null);
        setValue('');
    }

    return <Stack spacing={2} sx={{mt: 2}}>
        <ListSubheader aria-label={'Novo filtro'}>{'Novo filtro'}</ListSubheader>
        <FormControl fullWidth>
            <InputLabel>Coluna</InputLabel>
            <Select
                inputProps={{"aria-label": 'Coluna'}}
                label="Coluna"
                value={column?.id ?? ''}
                onChange={handleColumn}>
                {columns.map(column => (
                    <MenuItem key={column.id} value={column.id}>{column.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
        <BistroTableFilterValueForm column={column} value={value} onChange={handleValue}/>
        <Button
            onClick={handleAdd}
            sx={{height: '100%'}}
            variant="outlined"
            disabled={invalid}>
            Adicionar Filtro
        </Button>
    </Stack>
}

export const BistroTableFilterValueForm = ({column, value, onChange, showColumnLabel = false}) => {
    if (column?.filterType === 'select') {
        const label = showColumnLabel ? `${column.label} é` : "É igual a";

        return <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                inputProps={{"aria-label": label}}
                label={label}
                value={value}
                onChange={(event) => onChange(event.target.value)}>
                {column.values.map(option => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    } else if (column?.filterType === 'datetime') {
        return <DateTimeRangePicker period={value} onChange={onChange}/>
    }

    const label = showColumnLabel ? `${column.label} contém` : "Contém";

    return <TextField
        fullWidth
        disabled={!column}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        label={label}
        variant="outlined"/>
}

const DateTimeRangePicker = ({period, onChange}) => {
    const [start, setStart] = useState(dayjs(period?.split(' a ')?.[0], 'DD/MM/YYYY'));
    const [end, setEnd] = useState(dayjs(period?.split(' a ')?.[1], 'DD/MM/YYYY'));

    const handleStart = (value) => {
        setStart(value);
        if (!end || !value) return;
        onChange(`${value.format('DD/MM/YYYY')} a ${end.format('DD/MM/YYYY')}`)
    }

    const handleEnd = (value) => {
        setEnd(value);
        if (!start || !value) return;
        onChange(`${start.format('DD/MM/YYYY')} a ${value.format('DD/MM/YYYY')}`)
    }

    return <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack gap={2}>
            <DateField
                inputProps={{"aria-label": 'A partir de'}}
                label={'A partir de'}
                format={'DD/MM/YYYY'}
                value={start}
                onChange={handleStart}/>
            <DateField
                inputProps={{"aria-label": 'Até'}}
                label={'Até'}
                format={'DD/MM/YYYY'}
                value={end}
                onChange={handleEnd}/>
        </Stack>
    </LocalizationProvider>
}