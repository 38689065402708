import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Snackbar, Typography } from "@mui/material";
import { disableTwoFactor } from "./two_factor_service";
import { admin_users_two_factor_path } from "../../../../routes";

const TwoFactorDisableDialog = ({ open, onClose, csrfToken, mandatory }) => {
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);

  const onSuccess = () => {
    if (mandatory) {
      window.location.assign(admin_users_two_factor_path());
    } else {
      setOpenSnack(true);
      setTimeout(() => window.location.reload(), 500);
    }
  }

  const handleDisable = async () => {
    setLoading(true);
    await disableTwoFactor(
      csrfToken,
      () => onSuccess(),
      () => setLoading(false),
    );
  }

  return <>
    <Dialog
      fullWidth
      open={open}
      maxWidth={"md"}
      data-testid={"two-factor-disable-dialog"}
    >
      <DialogTitle>
        Atenção: Você está desconfigurando o 2FA { mandatory ? 'obrigatório' : '' }
      </DialogTitle>
      <DialogContent>
        <Typography>
          { mandatory ? 'O 2FA oferece uma camada extra de segurança para sua conta, ajudando a proteger suas informações contra acessos não autorizados. Ao desconfigurar o 2FA será necessário definir outra configuração (Ex.: Aplicativo autenticador, via E-mail), não será mais possível ficar sem o 2FA desativado.'
            : 'O 2FA oferece uma camada extra de segurança para sua conta, ajudando a proteger suas informações contra acessos não autorizados.' }
        </Typography>
        { loading && <LinearProgress sx={{ mt: 2 }} data-testid={"loading"}/> }
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          disabled={loading}
          onClick={handleDisable}
        >
          Desconfigurar o 2FA
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          onClick={onClose}
        >
          Manter o 2FA ativo
        </Button>
      </DialogActions>
    </Dialog>
    <Snackbar
      open={openSnack}
      message={"O duplo fator foi desconfigurado!"}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    />
  </>
}

export default TwoFactorDisableDialog;