import React from "react";
import classNames from "classnames";

export function parserSwapCardNumber(cardNumber, cardType = null) {
  if (cardType) {
    return cardType === "physical"
      ? `Físico - Final ${cardNumber}`
      : `Virtual - Final ${cardNumber}`;
  } else {
    return `Final ${cardNumber}`;
  }
}

export function parserSwapCardObject(swapCard) {
  return [
    { label: "ID", description: swapCard.card_id },
    { label: "Empresa", description: swapCard.company },
    { label: "Usuário associado", description: swapCard.user_name },
    { label: "E-mail do usuário", description: swapCard.user_email },

    {
      label: "Cartão",
      description: parserSwapCardNumber(swapCard.card_number),
    },
    {
      label: "Código de rastreio do cartão",
      description: swapCard.tracking_code,
    },
    { label: "Balance_account_group_id", description: swapCard.bag_id },
    {
      label: "Balance_account_group_status",
      description: swapCard.bag_status,
    },
    {
      label: "Cartão associado",
      description: swapCard.card_associated ? "Associado" : "Não Associado",
    },
    {
      label: "Status do cartão na Swap",
      description: swapCard.card_status ? swapCard.card_status : "Bloqueado",
    },
    {
      label: "Status do cartão",
      description:
        swapCard.status === "integrated"
          ? swapCard.card_integrated_status
          : "Emitido",
    },
    {
      label: "Saque",
      description: renderTrueOrFalseModalDetail(
        swapCard.withdrawal,
        "Ativo",
        "Desativado"
      ),
    },
    {
      label: "Contactless",
      description: renderTrueOrFalseModalDetail(
        swapCard.contactless,
        "Ativo",
        "Desativado"
      ),
    },
    { label: "Limite Contactless", description: swapCard.contactless_limit },
    {
      label: "Tipo",
      description: swapCard.card_type === "physical" ? "Físico" : "Virtual",
    },
  ];
}

export function renderTrueOrFalseModalDetail(value, valueTrue, valueFalse) {
  if (value) {
    return (
      <React.Fragment>
        <i className="mdi mdi-check-circle icon icon-true" />
        {valueTrue}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <i className="mdi mdi-close-circle icon icon-false" />
        {valueFalse}
      </React.Fragment>
    );
  }
}

export function renderCardStatus(
  status,
  status_integrated,
  status_color,
  activation_error
) {
  if (status === "integrated") {
    return (
      <span className={classNames("status-color", status_color)}>
        {status_integrated}
      </span>
    );
  } else if (activation_error) {
    return (
      <span className={classNames("status-color", "failed")}>
        Erro na ativação
      </span>
    );
  } else {
    return (
      <span className={classNames("status-color", "issued")}>Emitido</span>
    );
  }
}
