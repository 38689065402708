import React from "react";
import { ShadowTheme } from "../ShadowTemplate";

import { new_admin_budget_path } from "../../../routes";

import { AppBar, Fab, Stack, Toolbar, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import BudgetEmptyStateCard from "./common/BudgetEmptyCardContent";
import HandRaising from "./common/HandRaising";
import BudgetsList from "./BudgetsList";

const getEmptyStateCardContent = (
  budgetsEnabled,
  buttonProps,
  openModal = false,
  props = {}
) => (
  <React.Fragment>
    <HandRaising
      open={openModal}
      close={buttonProps.onClick?.bind(this)}
      userId={props.userId}
      companyId={props.companyId}
      planLevel={props.planLevel}
      planName={props.planName}
    />
    <BudgetEmptyStateCard
      budgetsEnabled={budgetsEnabled}
      buttonProps={buttonProps}
    />
  </React.Fragment>
);

const BudgetsPage = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const content = () => {
    if (!props.budgetsEnabled) {
      const btn = { onClick: () => handleClickOpen() };

      return getEmptyStateCardContent(false, btn, open, props);
    } else if (props.emptyState && props.admin) {
      const btn = { href: new_admin_budget_path() };

      return getEmptyStateCardContent(true, btn);
    } else {
      return <BudgetsList admin={props.admin} />;
    }
  };

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#F0EBF5",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <Typography sx={{ color: "black", fontSize: "18px" }}>
            Orçamentos
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack alignItems={"center"} sx={{ mt: 2.0 }}>
        {content()}
      </Stack>
      {props.budgetsEnabled && !props.emptyState && props.admin && (
        <Fab
          color="primary"
          data-testid={"new-budget-fab"}
          aria-label={"Novo orçamento"}
          href={new_admin_budget_path()}
          sx={{ position: "fixed", right: 30, bottom: 30 }}
        >
          <AddIcon />
        </Fab>
      )}
    </ShadowTheme>
  );
};

export default BudgetsPage;
