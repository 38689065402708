import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import slide from '../../../../../../../app/assets/images/admin/account/account_opening/slide-3.webp';
import ButtonAccountOpening from '../ButtonAccountOpening';

const ThirdStep = ({ textButton, isPlanFree }) => {
  return (
    <Container maxWidth="lg" sx={{ height: 'auto', maxHeight: { lg: '530px', md: 'auto', xs: 'auto'} }}>
      <Grid container spacing={2} sx={{
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Grid item xs={12} lg={7} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: { xs: 'center', lg: 'left' }
        }}>
          <Box p={2} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', lg: 'flex-start' },
            maxWidth: { xs: '244px', sm: '506px', md: '506px', lg: '566px' }
          }}>
            <Typography variant="h3" gutterBottom fontWeight={900} color="#3D0079" sx={{
              fontSize: { xs: '24px', sm: '32px', md: '36px', lg: '40px' },
              textAlign: { xs: 'center', lg: 'left' },
              maxWidth: { xs: '218px', sm: '356px', md: '449px', lg: '532px' }
            }}>
              Acabe com a conciliação manual de faturas
            </Typography>
            <Typography sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px', lg: '20px' },
              fontWeight: 400,
              lineHeight: '35.2px',
              color: '#000000DE',
              textAlign: { xs: 'center', lg: 'left' },
              maxWidth: { xs: '244px', sm: '451px', md: '506px', lg: '448px' }
            }}>
              Transfira fundos via PIX e ajuste os limites de
              gastos e cartões, toda conciliação de faturas
              acontece de forma automatizada.
            </Typography>
            <Box component="ul" sx={{
              listStylePosition: { lg: 'outside', xs: 'inside' },
              mt: 2,
              textAlign: { xs: 'center', lg: 'left' },
              paddingLeft: {lg: '20px'},
              padding: {xs: '0', sm: '0', md: '0'},
              '& li': {
                fontSize: { xs: '14px', sm: '18px', md: '20px', lg: '20px' },
                fontWeight: 400,
                lineHeight: '35.2px',
                color: '#000000DE',
              },
              maxWidth: { xs: '244px', sm: '451px', md: '506px', lg: '566px' }
            }}>
              <li>Cartão físico e virtual;</li>
              <li>Emita quantos cartões precisar;</li>
              <li>Controle as despesas por centros de custos;</li>
              <li>Distribuição de saldo e recarga em poucos segundos;</li>
            </Box>
            <ButtonAccountOpening text={textButton} isPlanFree={isPlanFree} marginTop={3} />
          </Box>
        </Grid>
        <Grid item lg={5} sx={{ 
          display: { lg: 'flex', xs: 'none' },
          justifyContent: 'center', 
          alignItems: 'center',
        }}>
          <Box sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={slide} alt="Descrição da imagem" style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'contain'
            }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ThirdStep;
