import React, { useState } from "react";
import { Box, Button, Card, CardActions, CardContent, LinearProgress, Typography } from "@mui/material";
import { hot } from "react-hot-loader";
import { ShadowTheme } from "../../admin/ShadowTemplate";
import useMediaQuery from "@mui/material/useMediaQuery";
import TwoFactorHelpCenterLink from "./TwoFactorHelpCenterLink";
import TwoFactorBackButton from "./TwoFactorBackButton";
import OTPField from "../../shared/OTPField";
import { new_user_session_path } from "../../../routes";

const TwoFactorShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <TwoFactor
      csrfToken={props.csrfToken}
      invalid={props.invalid}
      code={props.code}
    />
  </ShadowTheme>
}

const TwoFactor = ({ csrfToken, invalid, code }) => {
  const [otp, setOtp] = useState(code);
  const [invalidOtp, setInvalidOtp] = useState(invalid);
  const [loading, setLoading] = useState(false);

  const onChangeOtp = (value) => {
    setOtp(value);
    setInvalidOtp(false);
  }

  const onSubmit = (event) => {
    setLoading(true);
    event.target.submit();
  }
  
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  
  return <>
    <Box
      mb={2} pt={2}
      display={"flex"}
      justifyContent={"center"}
    >
      <form onSubmit={onSubmit} method="POST" action={new_user_session_path()} data-testid={"two-factor-form"}>
        <Card sx={{ width: smallScreen ? 300 : 500, p: 2 }}>
          <CardContent>
            <Typography variant="h6">
              Autenticação de duplo fator
            </Typography>
            <Typography mt={2}>
              Confirme o código
            </Typography>
            <Typography mb={3} color={"GrayText"}>
              Digite o código gerado no aplicativo autenticador
            </Typography>
            <OTPField
              value={otp}
              onChange={onChangeOtp}
              error={invalidOtp}
            />
            <input type="hidden" name="authenticity_token" value={csrfToken}/>
            { loading && <LinearProgress sx={{ mt: 2 }}/> }
            <Typography mt={3}>
              Está com dúvidas? Acesse nossa <TwoFactorHelpCenterLink/>
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "space-between" }}>
            <TwoFactorBackButton/>
            <Button
              type="submit"
              variant="contained"
              data-testid={"two-factor-enter-button"}
              disabled={ otp.length !== 6 || invalidOtp || loading }
            >
              Entrar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Box>
  </>
}

export default hot(module)(TwoFactorShadow);