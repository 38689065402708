import React, { useEffect, useState } from "react";

import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";

import {
  admin_account_sub_accounts_privacy_users_path,
  non_alloweds_admin_account_sub_accounts_privacy_users_path,
} from "../../../../../routes";
import { showSnackbar } from "../../../../shared/jquery_wrapper";

const UsersAddModal = () => {
  const [openUsersModal, setOpenUsersModal] = useState(false);
  const [searchModalValue, setSearchModalValue] = useState("");
  const [showLoadingModalUsers, setShowLoadingModalUsers] = useState(false);
  const [userModalList, setUserModalList] = useState([]);
  const [pageUserModalList, setPageUserModalList] = useState(1);
  const [totalUserModalList, setTotalUSerModalList] = useState(0);
  const [selectedUserModalListId, setSelectedUserModalListId] = useState([]);

  useEffect(() => {
    getUserModalList();
  }, []);

  const handleClose = () => {
    setSelectedUserModalListId([]);
    setOpenUsersModal(false);
  };

  const getUserModalList = (value = "", page = 1) => {
    setSearchModalValue(value);

    setShowLoadingModalUsers(true);

    $.ajax({
      type: "GET",
      url: non_alloweds_admin_account_sub_accounts_privacy_users_path(),
      data: { page, search: value },
    })
      .then((r) => {
        setUserModalList(r.data.list);
        setPageUserModalList(r.data.current_page);
        setTotalUSerModalList(r.data.total_count);
        setShowLoadingModalUsers(false);
      })
      .catch((e) => {
        setShowLoadingModalUsers(false);
      });
  };

  const handleCheck = (id) => {
    let arr = [...selectedUserModalListId];
    const index = arr.indexOf(id);

    if (index === -1) {
      arr.push(id);
    } else {
      arr.splice(index, 1);
    }

    setSelectedUserModalListId(arr);
  };

  const postCreate = () => {
    $.ajax({
      type: "POST",
      url: admin_account_sub_accounts_privacy_users_path(),
      data: { employee_ids: selectedUserModalListId },
    })
      .then((r) => {
        showSnackbar({
          content: "Usuários associados com sucesso!",
          style: "notice",
        });

        window.location.reload(false);
      })
      .catch((e) => {
        showSnackbar({
          content: "Não foi possível associar os usuários!",
          style: "alert",
        });

        getUserModalList();
      });
  };

  return (
    <React.Fragment>
      <Button
        size="medium"
        variant="contained"
        sx={{ ml: 3 }}
        onClick={() => setOpenUsersModal(true)}
      >
        ASSOCIAR USUÁRIOS
      </Button>
      {/* modal */}
      <Dialog open={openUsersModal} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle>
          <Typography sx={{ fontSize: "20px" }}>Associar usuários</Typography>
        </DialogTitle>
        <DialogContent sx={{ width: "900px" }}>
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Selecione usuários que tem permissão para ativar cartões
          </Typography>
          <TextField
            sx={{ width: "100%", margin: "20px 0" }}
            label="Buscar"
            placeholder="Digite o nome ou e-mail do usuário e pressione a tecla Enter para filtrar"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchModalValue}
            onChange={(e) => setSearchModalValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                getUserModalList(searchModalValue);
              }
            }}
          />
          <List>
            {userModalList.map((user, idx) => (
              <ListItem
                key={idx}
                sx={{
                  padding: "5px 0",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Checkbox
                  data-testid={`checkbox-id-${user.id}`}
                  checked={selectedUserModalListId.includes(user.id)}
                  onClick={() => handleCheck(user.id)}
                  inputProps={{
                    "aria-label": `select subaccount ${user.id}`,
                  }}
                />
                <ListItemAvatar
                  style={{ minWidth: "32px", marginRight: "10px" }}
                >
                  <Avatar src={user.avatar} />
                </ListItemAvatar>
                <ListItemText>{`${user.name} (${user.email})`}</ListItemText>
              </ListItem>
            ))}
          </List>
          {showLoadingModalUsers && <LinearProgress />}
          <Pagination
            style={{
              margin: "12px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            sx={{ mb: 3, ml: 3 }}
            count={Math.ceil(totalUserModalList / 5)}
            page={Number(pageUserModalList)}
            onChange={(event, value) =>
              getUserModalList(searchModalValue, value)
            }
            color="primary"
            shape="rounded"
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", padding: "20px" }}>
          <Button
            sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}
            onClick={handleClose}
          >
            cancelar
          </Button>
          <Button onClick={postCreate}>
            {`associar (${selectedUserModalListId.length})`}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UsersAddModal;
