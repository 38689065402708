import {background_job_path} from "../../routes";

export const polling = (csrfToken, jobId, onComplete, onProgress, interval = 3000) => {
    setTimeout(async () => {
        const response = await fetchBackgroundJob(jobId, csrfToken);
        if (!response.ok) return onComplete(false, await response.text())

        const data = await response.json();
        if (data.status === 'completed') return onComplete(true, data.error)

        if (data.status === 'failed') return onComplete(false, data.error)

        onProgress(data);
        polling(csrfToken, jobId, onComplete, onProgress, interval);
    }, interval);
}

const fetchBackgroundJob = async (jobId, csrfToken) => {
    try {
        return await fetch(background_job_path(jobId), {
            method: 'GET',
            headers: {"X-CSRF-Token": csrfToken, 'Content-Type': 'application/json'}
        });
    } catch (e) {
        return {ok: false, text: () => Promise.resolve(e)}
    }
}
