import React, {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import {Button, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";

export const SingleFieldDialog = (props) => {
    const {
        title,
        content,
        open,
        confirmLabel,
        onConfirm,
        cancelLabel,
        onCancel,
        validator
    } = props;

    const [value, setValue] = useState('');
    const [error, setError] = useState(null);

    const handleChange = (event) => setValue(event.target.value);

    const handleConfirm = () => {
        const error = validator(value);
        setError(error);
        if (error) return;

        setError(null);
        onConfirm(value);
    }

    return <Dialog data-testid={'single-field-dialog'} open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText sx={{mb: 3}}>{content}</DialogContentText>
            <TextField
                data-testid={'single-field-dialog-field'}
                fullWidth required
                error={!!error}
                value={value}
                onChange={handleChange}
                label="Email"
                type="email"
                helperText={error}
                placeholder={'joao@email.com'}
                variant={'outlined'}/>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>{cancelLabel ?? 'Cancelar'}</Button>
            <Button data-testid={'confirm-dialog'} onClick={handleConfirm}>
                {confirmLabel ?? 'Confirmar'}
            </Button>
        </DialogActions>
    </Dialog>
}

SingleFieldDialog.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    open: PropTypes.bool,
    confirmLabel: PropTypes.string,
    onConfirm: PropTypes.func,
    cancelLabel: PropTypes.string,
    onCancel: PropTypes.func,
    validator: PropTypes.func
}