import React from "react";
import { Link } from "@mui/material";

const TwoFactorHelpCenterLink = () => {
  return <Link
    target="_blank"
    href={"https://suporte.espressoapp.com.br/support/solutions/articles/73000608292-duplo-fator-de-autentica%C3%A7%C3%A3o"}
    data-testid={"two-factor-help-center-link"}
  >
    Central de ajuda
  </Link>
}

export default TwoFactorHelpCenterLink;