import classNames from "classnames";
import React from "react";

class CardType extends React.Component {
  render() {
    return (
      <React.Fragment>
        <i
          className={classNames("mdi mdi-credit-card-outline card-type", {
            virtual: this.props.virtual,
            physical: !this.props.virtual,
          })}
        />
        {this.props.label && (
          <span>Cartão {this.props.virtual ? "virtual" : "físico"}</span>
        )}
      </React.Fragment>
    );
  }
}

CardType.defaultProps = {
  virtual: false,
  label: true,
};

export default CardType;
