import { Alert, AlertTitle } from "@mui/material";
import React from "react";

const MandatoryTwoFactorActiveAlerts = ({ hasAccount }) => {
  return <>
    {
      hasAccount && <Alert severity='info'>
        <AlertTitle>
          Uma vez ativada a autenticação em dois fatores (2FA) essa medida de segurança não poderá mais ser desativada.
        </AlertTitle>
          Quando o 2FA é ativado para uma empresa que possui uma conta digital, ele não pode mais ser desativado devido à necessidade de garantir a segurança máxima para as transações financeiras e dados sensíveis da empresa.
      </Alert>
    }
  </>
}

export default MandatoryTwoFactorActiveAlerts;