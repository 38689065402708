import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Stack,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {ErpConnectionSteps} from "./ErpConnectionSteps";
import {getConnection} from "../erp_integration_connector";
import {FaqDrawerButton} from "../../components/faq/FaqDrawerButton";
import {admin_erp_integrations_path} from "../../../../routes";
import CheckIcon from "@mui/icons-material/Check";

export const ErpConnection = (props) => {
    const {
        erp,
        integration,
        testConnection,
        validCredential,
        info,
        videoTitle,
        videoId,
        form,
        steps
    } = props;

    const [loading, setLoading] = useState(integration?.status === 'connecting');
    const [failed, setFailed] = useState(false);
    const [succeed, setSucceed] = useState(false)

    const onTestConnection = useCallback(async () => {
        setLoading(true)
        const success = await testConnection()
        if (success) {
            pollIntegration();
        } else {
            setFailed(true)
            setLoading(false)
        }
    }, [testConnection]);

    const pollIntegration = useCallback(() => {
        setTimeout(async () => {
            const data = await getConnection(erp);
            if (data.integration.status === 'connected') {
                setLoading(false)
                setSucceed(true)
                window.location.assign(admin_erp_integrations_path())
                return;
            }
            if (data.integration.status === 'disconnected') {
                setFailed(true)
                setSucceed(false)
                setLoading(false)
                return;
            }
            pollIntegration();
        }, 3000);
    }, []);

    useEffect(() => {
        if (integration?.status === 'connecting') {
            pollIntegration();
        }
    }, [integration]);

    return <Stack spacing={2}>
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    <Typography variant={'h6'}>
                        Siga os passos abaixo para conectar seu sistema no ERP
                    </Typography>
                    {info && <Alert severity="info">{info}</Alert>}
                    {/*<Typography variant={'h6'}>{videoTitle}</Typography>*/}
                    {/*<YouTubeEmbed embedId={videoId}/>*/}
                    {form(loading, failed)}
                    <ErpConnectionSteps>{steps}</ErpConnectionSteps>
                </Stack>
            </CardContent>
            <CardActions sx={{p: 2, justifyContent: 'flex-end'}}>
                <Button
                    size={'large'}
                    variant={'contained'}
                    disabled={!validCredential || loading}
                    onClick={!succeed && onTestConnection}
                    color={succeed ? 'success' : 'primary'}
                    startIcon={loading ? (
                        <CircularProgress size={24} color={'disabled'}/>
                    ) : succeed ? (
                        <CheckIcon/>
                    ) : null}>
                    {loading ? 'Validando credencial' : succeed ? 'Validado' : 'Testar conexão'}
                </Button>
            </CardActions>
        </Card>
        <FaqDrawerButton disabled={true}/>
    </Stack>
}