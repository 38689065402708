import React, { useEffect, useState } from "react";

import { admin_account_banking_apis_cards_path } from "../../../../routes";
import { parserCurrencyBRLValue } from "../../../shared/helpers";

import {
  Box,
  InputAdornment,
  LinearProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import CardCreate from "./CardCreate";
import CardDetail from "./CardDetail";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from "@mui/icons-material/Search";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const CardList = ({ balance }) => {
  const [cardList, setCardList] = useState([]);
  const [showLoadingCardList, setShowLoadingCardList] = useState(false);
  const [searchList, setSearchList] = useState("");
  const [searchTotal, setSearchTotal] = useState(0);
  const [searchPage, setSearchPage] = useState(1);
  const [openCardDetail, setOpenCardDetail] = useState(false);
  const [cardDetailSelected, setCardDetailSelected] = useState({});

  useEffect(() => {
    getCardList();
  }, []);

  const getCardList = (page = 1, search = "") => {
    setShowLoadingCardList(true);
    setSearchList(search);
    $.ajax({
      type: "GET",
      url: admin_account_banking_apis_cards_path(),
      data: { search, page },
    })
      .then((r) => {
        setCardList(r.data.list);
        setShowLoadingCardList(false);
        setSearchTotal(r.data.total_count);
        setSearchPage(r.data.current_page);
      })
      .catch((e) => {
        setShowLoadingCardList(false);
      });
  };

  const selectCardDetail = (id) => {
    setCardDetailSelected(cardList.find((e) => e.id === id));
    setOpenCardDetail(true);
  };
  const parserStatus = (status) => {
    const boxStyle = {
      display: "flex",
      alignItems: "center",
      width: "fit-content",
    };
    const iconStyle = { fontSize: "12px", marginRight: "5px" };

    switch (status) {
      case "pending":
        return (
          <Box sx={boxStyle}>
            <FiberManualRecordIcon sx={{ ...iconStyle, color: "#FFC30E" }} />
            <Typography>Pendente</Typography>
          </Box>
        );
      case "active":
        return (
          <Box sx={boxStyle}>
            <FiberManualRecordIcon sx={{ ...iconStyle, color: "#00CBA8" }} />
            <Typography>Ativo</Typography>
          </Box>
        );
      case "blocked":
        return (
          <Box sx={boxStyle}>
            <FiberManualRecordIcon
              sx={{ ...iconStyle, color: "rgba(0, 0, 0, 0.38)" }}
            />
            <Typography>Bloqueado</Typography>
          </Box>
        );
      case "canceled":
        return (
          <Box sx={boxStyle}>
            <FiberManualRecordIcon
              sx={{ ...iconStyle, color: "rgba(0, 0, 0, 0.38)" }}
            />
            <Typography>Cancelado</Typography>
          </Box>
        );
      default:
        return status;
    }
  };

  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <Typography sx={{ fontSize: "24px" }}>Cartões</Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            sx={{ width: "600px" }}
            placeholder="Buscar"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchList}
            onChange={(e) => getCardList(1, e.target.value)}
          />
          {/* modal card create */}
          <CardCreate getCardList={getCardList} balance={balance} />
        </Box>
      </Box>
      {showLoadingCardList && <LinearProgress />}
      <Table style={{ backgroundColor: "#FFFFFF" }}>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Número</TableCell>
            <TableCell>Identificador</TableCell>
            <TableCell>Limite</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cardList.map((card, idx) => (
            <TableRow key={idx}>
              <TableCell>{card.holder}</TableCell>
              <TableCell>{card.number}</TableCell>
              <TableCell>{card.custom_id}</TableCell>
              <TableCell>{parserCurrencyBRLValue(card.limit)}</TableCell>
              <TableCell>{parserStatus(card.status)}</TableCell>
              <TableCell>
                <MoreVertIcon
                  data-testid="more-icon"
                  style={{ cursor: "pointer" }}
                  onClick={() => selectCardDetail(card.id)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <Pagination
          style={{ margin: "12px" }}
          sx={{ mb: 3, ml: 3 }}
          count={Math.ceil(searchTotal / 5)}
          page={Number(searchPage)}
          onChange={(event, value) => getCardList(value, searchList)}
          color="primary"
          shape="rounded"
        />
      </Table>
      {showLoadingCardList && <LinearProgress />}
      {/* modal card detail */}
      <CardDetail
        openCardDetail={openCardDetail}
        setOpenCardDetail={setOpenCardDetail}
        cardDetailSelected={cardDetailSelected}
      />
    </Box>
  );
};

export default CardList;
