import React, {useMemo} from "react";

import {Grid, Stack, Typography} from "@mui/material";
import Container from "@mui/material/Container";
import EspressoTemplate from "../EspressoTemplate";
import {hot} from "react-hot-loader";
import {ErpItem} from "./components/ErpItem";
import {ErpIntegrationItem} from "./components/ErpIntegrationItem";
import {PageNavbar} from "../components/PageNavbar";
import omie from '../../../../assets/images/admin/erp_integrations/omie.png'
import sankhya from '../../../../assets/images/admin/erp_integrations/sankhya.png'
import totvs from '../../../../assets/images/admin/erp_integrations/totvs.png'
import sap from '../../../../assets/images/admin/erp_integrations/sap.png'

export const erps = [
    {identifier: 'sankhya', title: 'Sankhya', available: true, image: sankhya},
    {identifier: 'omie', title: 'Omie', available: true, image: omie},
    {identifier: 'sap', title: 'SAP', available: false, image: sap},
    {identifier: 'totvs', title: 'Totvs', available: false, image: totvs},
]

const Index = (props) => {
    const {integrations} = props;

    const availableErps = useMemo(() => {
        return erps.filter(erp => integrations.every((integration) => integration.erp !== erp.identifier))
    }, [integrations]);

    const anyActive = useMemo(() => {
        return integrations.some((integration) => integration.status !== 'inactive')
    }, [integrations]);

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar showBackButton={false} title={'Integrações para ERP\'s'}/>
            <Container maxWidth={'lg'} sx={{mt: 5}}>
                <Grid container columnSpacing={3} rowSpacing={3}>
                    {[...integrations.map((integration, index) =>
                        <Grid key={`integration_${index}`} item xs={12}>
                            <ErpIntegrationItem integration={integration} anyActive={anyActive}/>
                        </Grid>
                    )]}

                    {integrations.length > 0 && (
                        <Grid item xs={12}>
                            <Typography variant={'h5'}>
                                Conheça outras integrações de ERP's
                            </Typography>
                        </Grid>
                    )}

                    {[...availableErps.map((erp, index) =>
                        <Grid key={`erp_${index}`} item xs={12} sm={6} md={4} lg={3}>
                            <ErpItem erp={erp} anyActive={anyActive}/>
                        </Grid>
                    )]}
                </Grid>
            </Container>
        </Stack>
    </EspressoTemplate>
}

export default hot(module)(Index)