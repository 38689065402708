import {Button, DialogContentText, Stack, Typography} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React, {useState} from "react";
import {polling} from "../../shared/polling";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";

export const JobProgressDialog = (props) => {
    const {
        csrfToken,
        title,
        open,
        confirmLabel,
        confirmDisabled = false,
        onCancel,
        onComplete,
        fetchJobId,
        renderInitial,
        renderFail,
        renderSuccess,
        renderPartialSuccess,
        loadingMessage,
        pollingInterval
    } = props;

    const [succeed, setSucceed] = useState(false);
    const [failed, setFailed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [total, setTotal] = useState(0);
    const [error, setError] = useState(null);

    const initial = !loading && !succeed && !failed;

    const handleConfirm = async () => {
        setLoading(true);
        const jobId = await fetchJobId();
        if (jobId) {
            polling(csrfToken, jobId, handleComplete, handleProgress, pollingInterval);
        } else {
            handleComplete(false, 'Não foi possível realizar essa ação');
        }
    }

    const handleProgress = (data) => {
        setTotal(data.total);
        setProgress(data.progress);
    }

    const handleComplete = (success, error) => {
        setSucceed(success);
        setFailed(!success);
        setLoading(false);
        setError(error);
    }

    return <Dialog data-testid={'job-progress-dialog'} open={open} onClose={onCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {initial && renderInitial()}
            {succeed && !error && renderSuccess()}
            {succeed && !!error && renderPartialSuccess(error)}
            {failed && renderFail(error)}
            {loading && (
                <>
                    <DialogContentText>{loadingMessage}</DialogContentText>
                    <JobProgressIndicator total={total} progress={progress}/>
                </>
            )}
        </DialogContent>
        {initial && (
            <DialogActions>
                <Button onClick={onCancel}>Cancelar</Button>
                <Button
                    data-testid={'confirm-dialog'}
                    disabled={confirmDisabled}
                    onClick={handleConfirm}>
                    {confirmLabel ?? 'Confirmar'}
                </Button>
            </DialogActions>
        )}
        {!initial && (
            <DialogActions>
                <Button  data-testid={'close-dialog'} onClick={onComplete}>{'Fechar'}</Button>
            </DialogActions>
        )}
    </Dialog>
}

const JobProgressIndicator = ({total, progress}) => {
    return <Stack sx={{my: 1}}>
        <LinearProgress
            variant={!!total ? "determinate" : 'indeterminate'}
            value={progress / total * 100}/>
        {!!total && (
            <Typography variant={"overline"} component="div" color="text.secondary">
                {`${progress}/${total}`}
            </Typography>
        )}
    </Stack>
}

JobProgressDialog.propTypes = {
    csrfToken: PropTypes.string,
    title: PropTypes.string,
    open: PropTypes.bool,
    confirmLabel: PropTypes.string,
    confirmDisabled: PropTypes.bool,
    onCancel: PropTypes.func,
    onComplete: PropTypes.func,
    fetchJobId: PropTypes.func,
    renderInitial: PropTypes.func,
    renderFail: PropTypes.func,
    renderSuccess: PropTypes.func,
    renderPartialSuccess: PropTypes.func,
    loadingMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    pollingInterval: PropTypes.number
}