import React from "react";
import { Stack, Typography } from "@mui/material";

const HomeHeader = (props) => {
  return (
    <Stack data-testid="home-header" direction={"row"} justifyContent={"space-between"} sx={{ my: 3.5 }}>
      <Stack direction={"row"}>
        <Typography variant="h6" color={"GrayText"}>Olá,</Typography>
        <Typography variant="h6" fontWeight={"bold"} style={{ whiteSpace: "pre" }}> {props.employeeName}</Typography>
      </Stack>
      <Stack direction={"row"}>
        <Typography variant="h6" color={"GrayText"}>Seu plano é o</Typography>
        <Typography variant="h6" fontWeight={"bold"} color={"primary"} style={{ whiteSpace: "pre" }}> {props.companyPlanName}</Typography>
      </Stack>
    </Stack>
  );
}

export default HomeHeader;