export default class SubmitReportRepository {
    constructor(endpoint) {
        this.endpoint = endpoint
    }

    submissionData(expenseIds) {
        return $.ajax({
            type: "GET",
            contentType: "application/json",
            url: this.endpoint,
            data: {
                expenses: {
                    ids: expenseIds
                }
            },
        })
    }
}