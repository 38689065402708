import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import Side2 from '../../../../../../app/assets/images/admin/account/account_opening/side2.svg';
import { openModal } from '../../../shared/jquery_wrapper';
import { new_admin_account_path } from "../../../../routes";

const PromotionalInfoBox = ({ textButton, isPlanFree }) => {
  const handleClick = () => {
    if (isPlanFree) {
      openModal('#show-plans');
    } else {
      window.location.href = new_admin_account_path();
    }
  };

  return (
    <Box sx={{
      position: 'relative',
      backgroundImage: 'linear-gradient(to right, #0F001E, #3D0079)',
      borderRadius: 2,
      overflow: 'hidden',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: { xs: 'auto', md: '560px' },
      paddingY: { xs: '20px', md: 0 },
      width: '100%',
      marginTop: '20px',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
      <Grid container spacing={2} sx={{
        flexDirection: { md: 'column-reverse', lg: 'row' },
      }}>
        <Grid item xs={12} md={12} lg={8} sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }} >
          <Box sx={{
            marginLeft: { xs: '0', lg: '64px' },
            marginRight: { xs: '0', md: '0' },
            marginBottom: '59px',
            marginTop: { xs: '59px', lg: '59px' },
            textAlign: { lg: 'left', md: 'center', sm: 'center', xs: 'center' },
            maxWidth: { xs: '289px', sm: '492px', md: '441px', lg: '646px' }
          }}>
            <Typography gutterBottom sx={{
              fontWeight: 700,
              lineHeight: '46px',
              letterSpacing: '0.15px',
              fontSize: { xs: '22px', sm: '32px', md: '44px', lg: '44px' },
              maxWidth: { xs: '289px', sm: '492px', md: '435px', lg: '646px' },
            }}>
              Todos os serviços que você precisa, online e sem burocracia!
            </Typography>
            <Box component="ul" sx={{
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '36px',
              listStylePosition: { md: 'inside', xs: 'inside', lg: 'outside' },
              padding: {xs: '0', sm: '0', md: '0'},
              paddingLeft: {lg: '20px'},
              '& li': {
                fontSize: { xs: '14px', sm: '18px', md: '20px', lg: '22px' },
                fontWeight: 400,
                lineHeight: '36px',
              },
              maxWidth: { xs: '289px', sm: '492px', md: '441px', lg: '478px' }
            }}>
              <li>Aloque e resgate saldos entre os cartões.</li>
              <li>Economia com transferência bancária.</li>
              <li>Automação da política de reembolsos.</li>
              <li>Previsão dos gastos e orçamento mensal.</li>
              <li>Conciliação automática de faturas.</li>
              <li>Gestão de fornecedores e muito mais!</li>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'center', md: 'center', lg: 'flex-start' },
              width: '100%',
              mt: 2,
            }}>
              <Button onClick={handleClick} variant="contained" sx={{
                mt: 2,
                bgcolor: 'white',
                color: '#3D0079',
                '&:hover': { bgcolor: '#e0e0e0' },
                fontWeight: 'bold',
                fontSize: '15px',
                lineHeight: '26px',
                letterSpacing: '0.46px',
              }}>
                {textButton}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={4} sx={{
          display: { xs: 'none', md: 'none', lg: 'flex' },
          justifyContent: 'center',
          alignItems: 'center',
          paddingRight: { lg: '150px' },
        }}>
          <Box sx={{
            height: '100%', 
            width: 'auto', 
            marginTop: { xs: '0', lg: '60px' }
          }}>
            <Side2 style={{ height: '100%', width: 'auto' }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PromotionalInfoBox;
