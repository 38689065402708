import React from "react";

import {Stack} from "@mui/material";
import Container from "@mui/material/Container";
import EspressoTemplate from "../EspressoTemplate";
import {hot} from "react-hot-loader";
import {PageNavbar} from "../components/PageNavbar";
import {SankhyaConnection} from "./components/sankhya/SankhyaConnection";
import {erps} from "./Index";
import {OmieConnection} from "./components/omie/OmieConnection";
import {admin_erp_integrations_path} from "../../../routes";

const Show = (props) => {
    const {integration, erp} = props;

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar
                backTo={admin_erp_integrations_path()}
                title={`Conexão ${erps.find((item) => item.identifier === erp).title}`}/>
            <Container maxWidth={'lg'} sx={{my: 5}}>
                {erp === 'sankhya' && (
                    <SankhyaConnection integration={integration}/>
                )}
                {erp === 'omie' && (
                    <OmieConnection integration={integration}/>
                )}
            </Container>
        </Stack>
    </EspressoTemplate>
}


export default hot(module)(Show)