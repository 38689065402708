import React from "react";

import Pagination from "../../shared/Pagination";
import Loading from "../../shared/Loading";
import { parserCurrencyBRLValue, parserDate } from "../../shared/helpers";

class SubscriptionMerchants extends React.Component {
  state = {
    forcePageOne: false,
    loading: false,
    merchants: [],
  };

  componentDidMount() {
    if (this.props.has_activities) {
      this.setState({ forcePageOne: !this.state.forcePageOne });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.has_activities ? (
          <div className="collection-item" style={{ padding: "10px 0" }}>
            <Loading show={this.state.loading} />
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Fornecedores</th>
                  <th>Data da última cobrança</th>
                  <th>Valor da última cobrança</th>
                  <th>Valor total gasto</th>
                </tr>
              </thead>
              <tbody>
                {this.state.merchants.map((merchant, idx) => (
                  <tr key={idx}>
                    <td className="d-flex justify-center">
                      <img
                        className="d-flex justify-center align-center circle"
                        style={{ width: "30px" }}
                        src={require(`../../../../../app/assets/images/admin/account/merchants/${merchant.icon}.png`)}
                      />
                    </td>
                    <td>{merchant.name}</td>
                    <td>{parserDate(merchant.last_date)}</td>
                    <td>{parserCurrencyBRLValue(merchant.last_cost)}</td>
                    <td>{parserCurrencyBRLValue(merchant.total_cost)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              perPage={5}
              forcePageOne={this.state.forcePageOne}
              url={this.props.endpoints.merchants}
              setExternalList={(list) =>
                this.setState({ merchants: list, loading: false })
              }
              setExternalLoading={(show) => this.setState({ loading: show })}
            />
          </div>
        ) : (
          <div
            className="collection-item d-flex justify-center align-center"
            style={{ padding: "100px" }}
          >
            Você ainda não tem transações!
          </div>
        )}
      </React.Fragment>
    );
  }
}

SubscriptionMerchants.defaultProps = {
  has_activities: false,
  endpoints: {
    merchants: "",
  },
};

export default SubscriptionMerchants;
