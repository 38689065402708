import * as React from "react";
import GeneralSearchResult from "./GeneralSearchResults";
import SearchIcon from "@mui/icons-material/Search";
import {Box, InputBase, Popover} from "@mui/material";
import {alpha, styled} from '@mui/material/styles';
import {debounce} from '@mui/material/utils';
import {generalSearch} from "./generalSearchDataSource";


const SearchFieldIcon = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const BorderlessContainer = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#F5F2F8',
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
    },
}));

const BorderlessInput = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    },
}));


export default function GeneralSearchField() {
    const [value, setValue] = React.useState('');
    const [results, setResults] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [searchTarget, setSearchTarget] = React.useState(null);
    const searchOpen = Boolean(searchTarget);

    const handleCloseSearchMenu = () => {
        setSearchTarget(null);
    };

    const handleSearchFocus = (event) => {
        if (value.trim() !== '') {
            setSearchTarget(event.currentTarget);
        }
    }

    const handleTypeSearch = (event) => {
        if (event.target.value.trim() !== '') {
            setSearchTarget(event.currentTarget);
        } else {
            setSearchTarget(null);
        }
        setValue(event.target.value)
    }

    const search = debounce(async (query, callback) => {
        callback(await generalSearch(query))
    }, 500);

    React.useEffect(() => {
        let active = true;
        if (value.trim() === '') {
            setResults([])
            return undefined;
        }
        setLoading(true)
        search(value, (data) => {
            if (!active) return
            setLoading(false)
            setResults(data)
        });
        return () => active = false;
    }, [value]);

    return <Box sx={{flexGrow: 0}}>
        <BorderlessContainer sx={{width: 416}}>
            <SearchFieldIcon><SearchIcon/></SearchFieldIcon>
            <BorderlessInput
                sx={{width: 416}}
                placeholder="Busque relatórios, adiantamentos ou usuários"
                onFocus={handleSearchFocus}
                onChange={handleTypeSearch}/>
        </BorderlessContainer>
        <Popover
            data-testid={'general-search-popover'}
            anchorEl={searchTarget}
            open={searchOpen}
            autoFocus={false}
            disableAutoFocus
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            slotProps={{paper: {sx: {width: 416}}}}
            onClose={handleCloseSearchMenu}>
            <GeneralSearchResult
                loading={loading}
                results={results}
                onClickResult={handleCloseSearchMenu}/>
        </Popover>
    </Box>
}
