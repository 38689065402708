import React, {useRef, useState} from "react"
import CssBaseline from "@mui/material/CssBaseline";
import {
    Box,
    Button,
    Card,
    Container,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    Stack,
    TextField,
    ThemeProvider,
    Typography
} from "@mui/material";
import EspressoLogo from "../../../../assets/images/logo_new.svg"
import FreeStickers from "../../../../assets/images/companies/free_stickers.svg"
import FreeImage from "../../../../assets/images/companies/free_image.svg"
import {createTheme, useTheme} from "@mui/material/styles";
import {hot} from "react-hot-loader"
import {CompanySignUpDto, signUp, UserSignUpDto} from "./signUpDataSource";
import CNPJInputMask from "../../shared/masks/CNPJInputMask";
import PhoneInputMask from "../../shared/masks/PhoneInputMask";
import NewStrongPasswordField, { isValidStrongPassword } from "../../shared/inputs/NewStrongPasswordField";

const activities = [
    'Administrativo',
    'Comercial',
    'Compras',
    'Contabilidade',
    'Controladoria',
    'Executivo (a)',
    'Financeiro',
    'Gestão',
    'Inovação',
    'Marketing',
    'Operações',
    'Outro',
    'Processos',
    'RH',
    'TI',
    'Viagens'
]

const signUpTheme = createTheme({
    palette: {
        primary: {
            main: '#5ABAF6',
            dark: '#5ABAF6',
            contrastText: '#fff'
        },
        background: {
            default: "#F3E9FC"
        }
    },
    typography: {
        fontFamily: "Lato, sans-serif",
        caption: {
            fontSize: '0.625rem'
        },
        h4: {
            fontSize: '2.375rem'
        },
        h3: {
            lineHeight: '3rem'
        }
    },
});

function SignUp() {
    const formRef = useRef(null)

    return <ThemeProvider theme={signUpTheme}>
        <CssBaseline/>
        <SignUpFormSection formRef={formRef}/>
        <SignUpResumeSection formRef={formRef}/>
        <SignUpFooter/>
    </ThemeProvider>
}

const SignUpFormSection = ({formRef}) => {
    const [companyName, setCompanyName] = useState('');
    const [companyDocument, setCompanyDocument] = useState('');
    const [userName, setUserName] = useState('');
    const [activity, setActivity] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userPassword, setUserPassword] = useState('');

    const theme = useTheme();

    const handleCompanyName = (event) => setCompanyName(event.target.value);
    const handleCompanyDocument = (event) => setCompanyDocument(event.target.value);
    const handleUserName = (event) => setUserName(event.target.value);
    const handleActivity = (event) => setActivity(event.target.value);
    const handleUserEmail = (event) => setUserEmail(event.target.value);
    const handleUserPhone = (event) => setUserPhone(event.target.value);
    const handleUserPassword = (event) => setUserPassword(event.target.value);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!validateFields()) return false;
        const company = new CompanySignUpDto(companyName, companyDocument, activities[activity])
        const user = new UserSignUpDto(company, userName, userEmail, userPhone, userPassword)
        signUp(user)
    }

    const validateFields = () => {
        let valid = true;
        if (!(isValidStrongPassword(userPassword))) {
            setPasswordError(true)
            valid = false;
        } else {
            setPasswordError(false)
        }
        return valid;
    }

    const [passwordError, setPasswordError] = useState(false)

    return <Box ref={formRef} sx={{px: 16, py: 6, [theme.breakpoints.down('sm')]: {p: 3}}}>
        <Container maxWidth={'lg'}>
            <Grid container justifyContent={'space-between'}>
                <Grid item md={6} sx={{[theme.breakpoints.up('md')]: {pr: 8}}}>
                    <Stack>
                        <EspressoLogo fill={'#000'} style={{height: 40, width: 172}}/>
                        <Typography color={'#521F8A'} variant={'h4'} fontWeight={800} sx={{pt: 6}}>
                            Sua gestão de<br/>despesas corporativas
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            background: '#521F8A',
                            py: 1,
                            my: 1
                        }}>
                            <Typography color={'#5FC6A9'} variant={'h4'} fontWeight={800}>
                                GRÁTIS de verdade
                            </Typography>
                        </Box>
                        <Typography variant={'body1'} sx={{pb: 6}}>
                            A plataforma do Espresso é grátis, com todas as funcionalidades liberadas e por
                            tempo
                            ilimitado. Comece agora mesmo a simplificar todo o seu processo de adiantamento,
                            reembolso e
                            prestação de contas.
                        </Typography>
                        <Box sx={{display: {xs: 'none', md: 'block'}}}>
                            <FreeImage style={{width: 360, height: 280}}/>
                        </Box>
                    </Stack>
                </Grid>
                <Grid item md={6}>
                    <Card elevation={0} sx={{
                        borderRadius: '32px',
                        p: 6,
                        background: '#521F8A',
                        [theme.breakpoints.down('sm')]: {px: 3, py: 4}
                    }}>
                        <ThemeProvider
                            theme={createTheme({
                                ...signUpTheme,
                                palette: {
                                    mode: 'dark',
                                    primary: {main: '#5ABAF6', dark: '#5ABAF6', contrastText: "#521F8A"}
                                }
                            })}>
                            <form onSubmit={handleSubmit}>
                                <Stack spacing={2}>
                                    <FormControl fullWidth>
                                        <InputLabel title={'Nome da empresa'}/>
                                        <TextField
                                            data-testid={'company-name-field'}
                                            required
                                            InputLabelProps={{shrink: true}}
                                            size={'medium'}
                                            label={'Nome da empresa'}
                                            placeholder={'Insira o nome da empresa'}
                                            value={companyName}
                                            onChange={handleCompanyName}/>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel title={'CNPJ (opcional)'}/>
                                        <TextField
                                            data-testid={'company-document-field'}
                                            InputLabelProps={{shrink: true}}
                                            size={'medium'}
                                            label={'CNPJ (opcional)'}
                                            placeholder={'Insira o CNPJ aqui'}
                                            value={companyDocument}
                                            onChange={handleCompanyDocument}
                                            InputProps={{
                                                inputComponent: CNPJInputMask,
                                            }}/>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel title={'Seu nome completo'}></InputLabel>
                                        <TextField
                                            data-testid={'user-name-field'}
                                            required
                                            InputLabelProps={{shrink: true}}
                                            size={'medium'}
                                            label={'Seu nome completo'}
                                            placeholder={'Insira seu nome aqui'}
                                            value={userName}
                                            onChange={handleUserName}/>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel shrink title={'Área de atuação'}>Área de
                                            atuação</InputLabel>
                                        <Select
                                            data-testid={'company-activity-field'}
                                            required
                                            notched
                                            displayEmpty={true}
                                            size={'medium'}
                                            label={'Area de atuação'}
                                            value={activity}
                                            onChange={handleActivity}>
                                            {[
                                                <MenuItem key={'empty'} value={''}>Selecione uma
                                                    opção</MenuItem>,
                                                ...activities.map((area, idx) => <MenuItem key={idx}
                                                                                        value={idx}>{area}</MenuItem>)]
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel title={'Seu email'}/>
                                        <TextField
                                            data-testid={'user-email-field'}
                                            required
                                            InputLabelProps={{shrink: true}}
                                            size={'medium'}
                                            type={'email'}
                                            label={'Seu email'}
                                            placeholder={'Coloque seu email corporativo'}
                                            value={userEmail}
                                            onChange={handleUserEmail}/>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel title={'Seu telefone'}/>
                                        <TextField
                                            data-testid={'user-phone-field'}
                                            required
                                            InputLabelProps={{shrink: true}}
                                            size={'medium'}
                                            label={'Seu telefone'}
                                            placeholder={'Coloque seu telefone com DDD'}
                                            value={userPhone}
                                            onChange={handleUserPhone}
                                            InputProps={{
                                                inputComponent: PhoneInputMask,
                                            }}/>
                                    </FormControl>
                                    <NewStrongPasswordField
                                        value={userPassword}
                                        error={passwordError}
                                        onChange={handleUserPassword}
                                    />
                                    <Button type={'submit'} color={'primary'} variant={'contained'}>
                                        Criar conta free
                                    </Button>
                                    <Typography variant={'caption'} color={'white'}>
                                        Ao se cadastrar você automaticamente concorda com nossos
                                        <Link color="inherit"
                                            href="https://www.espressoapp.com.br/termos-de-uso/"
                                            target="_blank">
                                            {" termos de uso "}
                                        </Link>
                                        e
                                        <Link color="inherit"
                                            href="https://www.espressoapp.com.br/termos-de-privacidade"
                                            target="_blank">
                                            {" política de privacidade "}
                                        </Link>
                                    </Typography>
                                </Stack>
                            </form>
                        </ThemeProvider>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </Box>
}

const SignUpResumeSection = ({formRef}) => {
    const theme = useTheme();
    const scrollToForm = () => formRef.current.scrollIntoView()

    return <Box sx={{p: 8, [theme.breakpoints.down('sm')]: {p: 3}}}>
        <Container maxWidth={'lg'}>
            <Grid container display={'flex'} justifyContent={'space-between'}>
                <Grid container item md={8} sx={{py: 8}} order={{md: 1, xs: 2}}>
                    <Stack spacing={4} sx={{alignItems: 'start'}}>
                        <Typography variant={'h3'} fontWeight={800} color={'#521F8A'}>
                            Com o Espresso, você realmente tem controle das suas despesas corporativas
                        </Typography>
                        <Typography variant={'h6'} sx={{maxWidth: 420}}>
                            Somos o único SaaS de gestão de despesas corporativas grátis de verdade, e com
                            funcionalidades liberadas por tempo ilimitado. Comece agora a revolução do seu
                            financeiro!
                        </Typography>
                        <Button fullWidth={false} size={'large'} variant={'contained'} onClick={scrollToForm}>
                            Criar conta grátis
                        </Button>
                    </Stack>
                </Grid>
                <Grid item md={4} xs={12} order={{md: 2, xs: 1}} display={'flex'} justifyContent={'center'}>
                    <FreeStickers style={{width: 360, height: 480}}/>
                </Grid>
            </Grid>
        </Container>
    </Box>
}

const SignUpFooter = () => {
    return <Box sx={{background: '#521F8A'}}>
        <Container maxWidth={'lg'}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: 140}}>
                <EspressoLogo fill={'#FFF'} style={{height: 64, width: 256}}/>
            </Box>
        </Container>
    </Box>
}

export default hot(module)(SignUp)