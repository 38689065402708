import { admin_budget_path, admin_budgetables_path, admin_budgets_path } from "../../../routes";
import { parseAmount } from "../../shared/balance_service";

export const BudgetableType = {
  project: 'Project'
}

export const budgetableTypeName = (type, capitalized = false) => {
  switch(type) {
    case BudgetableType.project:
      return capitalized ? 'Projeto' : 'projeto';
  }
}

export const BudgetFrequency = {
  fixed: 1,
}

const BudgetFrequencyName = {
  fixed: 'fixed',
}

export const budgetFrequencyName = (frequency) => {
  switch(frequency) {
    case BudgetFrequencyName.fixed:
      return 'Pontual';
  }
}

export const totalBy = (limit, performed, approving, over) => {
  if (over > 0 || (performed + approving) > limit) {
    return performed + approving;
  }
  return limit;
}

export class UpdateBudgetDto {
  constructor(limit) {
    this.budget = {
      limit: limit
    }
  }
}

export const updateBudget = async (csrfToken, id, dto, onSuccess, onFail) => {
  const response = await fetch(admin_budget_path(id), {
    method: 'PUT',
    headers: {"X-CSRF-TOKEN": csrfToken, 'Content-Type': 'application/json' },
    body: JSON.stringify(dto)
  });
  if (response.ok) {
    onSuccess();
    window.location.assign(admin_budget_path(id));
  } else {
    onFail();
    window.alert("Não foi possível editar o orçamento");
  }
}

export const closeBudget = async(csrfToken, id) => {
  const response = await fetch(admin_budget_path(id), {
    method: 'DELETE',
    headers: {"X-CSRF-Token": csrfToken},
  });
  if (response.ok) {
    window.location.assign(admin_budget_path(id));
  } else {
    window.alert("Não foi possível encerrar o orçamento");
  }
}

export const fetchBudgetablesBy = async (page, name, type) => {
  const url = admin_budgetables_path({ page: page, filter: { name: name, type: type } });
  const response = await fetch(url);
  if (response.ok) {
    const body = await response.json();
    return body;
  }
  return null;
}

export class CreateBudgetDto {
  constructor({ name, limit, startDate, endDate, frequency, budgetableId, budgetableType }) {
    this.budget = {
      name: name,
      limit: parseAmount(limit),
      start: startDate,
      end: endDate,
      frequency: frequency,
      budgetable: {
        id: budgetableId,
        type: budgetableType,
      }
    }
  }
}

export const createBudget = async (csrfToken, dto, onFail) => {
  const response = await fetch(admin_budgets_path(), {
    method: 'POST',
    headers: {"X-CSRF-TOKEN": csrfToken, 'Content-Type': 'application/json' },
    body: JSON.stringify(dto)
  });
  if (response.ok) {
    const body = await response.json();
    window.location.assign(admin_budget_path(body.id));
  } else {
    onFail();
    window.alert("Não foi possível criar o orçamento");
  }
}