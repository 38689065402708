import React from "react";
import {Grid} from "@mui/material";
import AccountBalanceProgress from "../common/AccountBalanceProgress";
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HomeCardSummaryItem from "../common/HomeCardSummaryItem";

const UnprovenExpensesItem = ({count}) => {
  return <HomeCardSummaryItem
          title={`${count} despesas não comprovadas`}
          subtitle={"no momento"}
          icon={<ReceiptIcon fontSize="large" />} />
}

const ActiveCardsItem = ({count}) => {
  return <HomeCardSummaryItem
          title={`${count} cartões ativados`}
          subtitle={"no momento"}
          icon={<CreditCardIcon fontSize="large" />} />
}

const HomeSubAccountsCardContent = (props) => {
    return <>
        <Grid data-testid="sub-accounts-content" container spacing={2} sx={{mb: 1}}>
            <Grid item xs={12} sm={6}>
                <UnprovenExpensesItem count={props.unprovenExpenses}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <ActiveCardsItem count={props.activeCards}/>
            </Grid>
        </Grid>
        <AccountBalanceProgress {...props}/>
    </>;
}

export default HomeSubAccountsCardContent;