import React from "react";

import { showSnackbar } from "../../../shared/jquery_wrapper";
import FeatureGroup from "../FeatureGroup";

class AccountFeatureGroup extends React.Component {
  constructor(props) {
    super(props);
    this.group = React.createRef();
    this.modalInput = React.createRef();
  }

  onUpdate = (feature, activate, error) => {
    if (error) {
      showSnackbar({
        content: "Não foi possível habilitar a conta digital, tente novamente.",
        style: "alert",
        timeout: 5000,
      });
    } else {
      showSnackbar({
        content: "Abertura de conta digital habilitado!",
        style: "alert",
        timeout: 3000,
      });
    }
  };

  render() {
    return (
      <FeatureGroup
        groupName={"Conta digital"}
        featuresEndpoint={this.props.featuresEndpoint}
        featureGroup={this.props.featureGroup}
        ref={this.group}
        onUpdate={(feature, activate, error) => this.onUpdate(feature, activate, error)}
        ignoreAvailability={this.props.ignoreAvailability}
        hidePlanWhenActive={this.props.hidePlanWhenActive}
      />
    );
  }
}

AccountFeatureGroup.defaultProps = {
  featuresEndpoint: "",
  featureGroup: 2,
  ignoreAvailability: false,
  hidePlanWhenActive: true,
};

export default AccountFeatureGroup;
