import React, { useState } from "react";
import { ShadowTheme } from "../../ShadowTemplate";

import {
  Avatar,
  Box,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

import { admin_account_sub_accounts_privacy_path } from "../../../../routes";
import { showSnackbar } from "../../../shared/jquery_wrapper";

// last import
import { hot } from "react-hot-loader";

const PrivacyCard = (props) => {
  const handleClick = () => {
    if (props.public) {
      $.ajax({
        type: "PUT",
        url: admin_account_sub_accounts_privacy_path(),
        data: { public: false },
      })
        .then((r) => {
          showSnackbar({
            content: "Privacidade alterada com sucesso!",
            style: "notice",
          });

          window.location.assign(admin_account_sub_accounts_privacy_path());
        })
        .catch((e) => {
          showSnackbar({
            content: "Não foi possível tornar privado!",
            style: "alert",
          });
        });
    } else {
      window.location.assign(admin_account_sub_accounts_privacy_path());
    }
  };

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <Typography style={{ fontSize: "20px" }}>Privacidade</Typography>
      <Typography style={{ fontSize: "16px", color: "rgba(0, 0, 0, 0.6)" }}>
        {props.public
          ? "Por ser público, todos os usuários tem permissão para ativar cartões."
          : "A privacidade define quais os usuários tem permissão para ativar cartões."}
      </Typography>
      <Paper sx={{ borderRadius: "0px", margin: "20px 0" }}>
        <ListItem
          secondaryAction={
            <Button sx={{ color: "#3D0079" }} onClick={handleClick}>
              {props.public ? "tornar privado" : "gerenciar usuários"}
            </Button>
          }
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: "rgba(240, 235, 245, 1)" }}>
              <ManageAccountsIcon sx={{ color: "#3D0079" }} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={props.public ? "Público" : "Privado"}
            secondary={
              props.public
                ? "Todos os usuários tem permissão para ativar cartões"
                : `${props.quantity} usuários associados`
            }
          />
        </ListItem>
      </Paper>
    </ShadowTheme>
  );
};

export default hot(module)(PrivacyCard);
