import {Avatar, Button, Card, CardActions, CardHeader, Chip} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import React from "react";
import {admin_erp_integration_path} from "../../../../routes";

export const ErpItem = ({anyActive, erp}) => {
    return <Card sx={{p: 2}}>
        <CardHeader
            title={erp.title.toUpperCase()}
            titleTypographyProps={{variant: 'body1'}}
            avatar={<Avatar src={erp.image} variant={'rounded'}/>}/>
        <CardActions>
            {erp.available ? (
                <Button
                    fullWidth
                    href={admin_erp_integration_path(erp.identifier)}
                    color={'primary'}
                    disabled={anyActive}
                    variant={'contained'}>
                    Conectar
                </Button>
            ) : (
                <Chip sx={{width: '100%', textAlign: 'start'}}
                      label={'Em breve'}
                      icon={<LockIcon/>}/>
            )}
        </CardActions>
    </Card>
}
