import React, {useState} from "react";
import {
    Button,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {hot} from "react-hot-loader";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DescriptionIcon from "@mui/icons-material/Description";
import Paper from "@mui/material/Paper";
import {bistro_company_users_bulk_path, deactivate_bistro_company_users_bulk_path} from "../../../../../routes";
import {JobProgressDialog} from "../../../common/JobProgressDialog";
import {fetchJobId} from "../workflow_service";

const BulkUpdateDialog = ({open, onClose, csrfToken, companyId, pollingInterval = 3000}) => {
    const [radioType, setRadioType] = useState('update');
    const [file, setFile] = useState(null);

    const bulkInfo = {
        'update': {
            title: 'Atualizar',
            loading: 'Atualizando',
            endpoint: bistro_company_users_bulk_path(companyId),
            method: 'PUT',
        },
        'deactivate': {
            title: 'Desativar',
            loading: 'Desativando',
            endpoint: deactivate_bistro_company_users_bulk_path(companyId),
            method: 'POST'
        }
    }

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append("file[csv]", file);

        return fetchJobId(() => fetch(bulkInfo[radioType].endpoint, {
            method: bulkInfo[radioType].method,
            body: formData,
            headers: {"X-CSRF-Token": csrfToken},
        }));
    }

    const handleRadioType = (event) => setRadioType(event.target.value);

    const handleFile = event => setFile(event.target.files[0]);

    const handleComplete = () => {
        onClose();
        window.location.reload();
    }

    return <JobProgressDialog
        open={open}
        onCancel={onClose}
        csrfToken={csrfToken}
        title={`${bulkInfo[radioType].title} usuários em massa`}
        onComplete={handleComplete}
        fetchJobId={handleSubmit}
        pollingInterval={pollingInterval}
        confirmLabel={'Iniciar'}
        confirmDisabled={!file}
        loadingMessage={`${bulkInfo[radioType].loading} usuários. Aguarde...`}
        renderSuccess={() => <BulkUpdateDialogSuccess />}
        renderPartialSuccess={(errors) => <BulkUpdateDialogSuccess errors={errors} />}
        renderInitial={() => <BulkUpdateDialogInitial
            radioType={radioType}
            file={file}
            handleRadioType={handleRadioType}
            handleFile={handleFile}/>}
        renderFail={(errors) => <BulkUpdateDialogFail errors={errors}/>}/>
}

const BulkUpdateDialogInitial = ({radioType, file, handleRadioType, handleFile}) => {
    return <Stack>
        <BulkRadioOptions onChange={handleRadioType}/>
        <FileUploaderButton file={file} onChange={handleFile}/>
        {radioType === 'update' ? <UpdateInstructions/> : <DeactivateInstructions/>}
    </Stack>
}

const BulkUpdateDialogSuccess = ({errors}) => {
    return <Stack>
        <DialogContentText sx={{mb: 2}}>Operação realizada com sucesso!</DialogContentText>
        {Array.isArray(errors) && errors.length > 0 && <BulkErrors errors={errors}/>}
    </Stack>
}

const BulkUpdateDialogFail = ({errors}) => {
    return <Stack>
        <DialogContentText>
            Não foi possível realizar a ação em massa. Verifique o formato do arquivo e tente novamente
        </DialogContentText>
        {Array.isArray(errors) && errors.length > 0 && <BulkErrors errors={errors}/>}
    </Stack>
}

const BulkRadioOptions = ({onChange}) => {
    return <FormControl>
        <FormLabel>Selecione o tipo de ação em massa</FormLabel>
        <RadioGroup row defaultValue="update" onChange={onChange}>
            <FormControlLabel value="update" control={<Radio/>} label="Atualizar usuários"/>
            <FormControlLabel value="deactivate" control={<Radio/>} label="Desativar usuários"/>
        </RadioGroup>
    </FormControl>
}

const FileUploaderButton = ({file, onChange}) => {
    return <Button
        sx={{my: 1}}
        color={file ? 'success' : 'primary'}
        component={"label"}
        variant={"outlined"}
        startIcon={file ? <DescriptionIcon/> : <CloudUploadIcon/>}>
        {file?.name ?? 'Adicionar arquivo'}
        <input data-testid={'csv_input'} type="file" onChange={onChange} hidden accept=".csv"/>
    </Button>
}

const BulkErrors = ({errors}) => {
    return <>
        <Typography variant={'body2'}>Foram encontrados os seguintes problemas:</Typography>
        <Paper elevation={0} sx={{my: 1, maxHeight: 200, overflow: 'auto', px: 2}}>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Linha</TableCell>
                        <TableCell>Erro</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {errors.map((error, index) => (
                        <TableRow key={index}>
                            <TableCell>Linha {error.index}</TableCell>
                            <TableCell>{error.message}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    </>
}

const DeactivateInstructions = () => {
    return <Typography>
        O arquivo .CSV deve conter a lista de emails dos usuários a serem desativados, na coluna email.
    </Typography>
}

const UpdateInstructions = () => {
    const fields = [
        {name: 'email*', description: 'Email do usuário'},
        {name: 'new_email', description: 'Novo email'},
        {name: 'name', description: 'Nome do usuário'},
        {name: 'cpf', description: 'CPF do usuário'},
        {name: 'code', description: 'Código do usuário'},
        {name: 'bank', description: 'Banco'},
        {name: 'agency', description: 'Agência'},
        {name: 'account_type', description: 'Tipo de conta'},
        {name: 'account', description: 'Número da conta'},
        {name: 'department', description: 'Número da conta'},
        {name: 'branch', description: 'Label Filial'},
        {name: 'department', description: 'Label Departamento'},
        {name: 'unit', description: 'Label Unidade'},
        {name: 'division', description: 'Label Divisão'},
        {name: 'category_ids**', description: 'IDs das categorias restritas'},
        {name: 'subcategory_ids**', description: 'IDs das subcategorias restritas'},
        {name: 'cost_center_ids**', description: 'IDs dos centros de custo restritos'},
        {name: 'tag_group_ids**', description: 'IDs dos grupos de tags restritos'},
        {name: 'project_ids**', description: 'IDs dos projetos restritos'},
        {name: 'card_ids**', description: 'IDs dos cartões restritos'},
        {name: 'team_ids**', description: 'IDs dos times'},
        {name: 'policy_id', description: 'ID da política'},
    ];

    return <>
        <Typography>
            O arquivo .CSV deve conter as informações dos usuários a serem atualizados.
            As seguintes colunas são aceitas. Separe-as com ponto-e-vírgula:
        </Typography>
        <Paper elevation={0} sx={{my: 1, maxHeight: 200, overflow: 'auto'}}>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Coluna</TableCell>
                        <TableCell>Descrição</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {fields.map((field, index) => (
                        <TableRow key={index}>
                            <TableCell>{field.name}</TableCell>
                            <TableCell>{field.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
        <Typography variant={'caption'}>* obrigatório</Typography>
        <Typography variant={'caption'}>** separar IDs por vírgula</Typography>
    </>
}

export default hot(module)(BulkUpdateDialog)