import React from "react";
import Currency from "../shared/Currency";

class ReportApprove extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reimbursements: [],
      advances: this.props.advances.map((advance) => ({ ...advance, checked: advance.suggested }))
    };

    this.handleCheck = this.handleCheck.bind(this);
  }

  componentDidMount() {
    this.setState({ ...this.state, reimbursements: this.setReimbursements(this.state.advances) });
  }

  render() {
    return (
      <React.Fragment>
        <section>
          {this.renderReimbursements()}
        </section>

        {this.renderAdvances()}

        {this.renderFinancialApproveOption()}
      </React.Fragment>
    );
  }

  renderReimbursements() {
    if (this.hasReimbursements()) {
      return (
        <div>
          <div className="collection design-system">
            {
              this.state.reimbursements.map((reimbursement, index) => (
                <div key={index} className="collection-item">
                  <span className="title">Resultado</span>

                  <div className="secondary-content">
                    <p className="pink-text">
                      <Currency currency={reimbursement.currency} cost={reimbursement.cost}/>
                    </p>
                    <span>a serem reembolsados</span>
                  </div>
                </div>
              ))
            }
          </div>
          {this.state.advances.every(a => !a.suggested) &&
            <p>
              <i className="mdi mdi-alert yellow-text text-darken-2"/>️
              O usuário solicitou o reembolso desse relatório
            </p>}
        </div>
      );
    } else {
      return(<p>Este relatório não possui reembolsos.</p>);
    }
  }

  renderAdvances() {
    if (this.state.advances.length > 0) {
      return(
        <section>
          <div className="component-organizer">
            <div className="separator"></div>
          </div>

          <h4>Saldo disponível</h4>
          <p>Os adiantamentos selecionados abaixo vão ser utilizados para descontar o valor das
            despesas reembolsáveis.</p>
          {this.renderAdvanceSection()}
        </section>
      );
    }
  }

  renderAdvanceSection() {
    if (this.state.advances.some(a => a.suggested) && this.state.advances.some(a => !a.suggested)) {
      return <div>
        <p>Sugeridos pelo usuário:</p>
        {this.state.advances.filter(advance => advance.suggested)
          .map((advance, i) => this.renderAdvanceItem(i, advance))}
        <p>Outros adiantamentos:</p>
        {this.state.advances.filter(advance => !advance.suggested)
          .map((advance, i) => this.renderAdvanceItem(i, advance))}
      </div>;
    }
    return this.state.advances.map((advance, i) => this.renderAdvanceItem(i, advance));
  }

  renderAdvanceItem(index, advance) {
    return <p key={index}>
      <label>
        <input type="checkbox" name="report[advance_ids][]"
               id={`report_advance_ids_${advance.id}`} value={advance.id}
               className="filled-in"
               checked={advance.checked}
               onChange={this.handleCheck}/>
        <span
          htmlFor={`report_advance_ids_${advance.id}`}>{this.advanceChecklistName(advance)}</span>
      </label>
    </p>;
  }

  renderFinancialApproveOption() {
    if (this.props.is_financial && this.hasReimbursements()) {
      return(
        <section>
          <div className="component-organizer">
            <div className="separator"></div>
          </div>

          <h4>Transferência</h4>
          <p>O valor já foi para a conta do funcionário?</p>

          <p>
            <label>
              <input type="radio" name="report[pay]" id="report_pay_false" value="false" className="with-gap" defaultChecked="checked"/>
              <span htmlFor="report_pay_false">Não, a transferência será feita depois.</span>
            </label>
          </p>

          <p>
            <label>
              <input type="radio" name="report[pay]" id="report_pay_true" value="true" className="with-gap"/>
              <span htmlFor="report_pay_true">Sim, a transferência já foi efetuada.</span>
            </label>
          </p>
        </section>
      );
    }
  }

  advanceChecklistName(advance) {
    const advanceName = advance.class == 'withdrawal_advance' ? 'Saque' : 'Adiantamento'

    return(
      <span>
        <a href={advance.path} target="_blank">{`${advanceName} #${advance.number}`}</a>
        - <Currency currency={advance.currency} cost={advance.balance} />
      </span>
    );
  }

  setReimbursements(advances) {
    const reimbursements = this.props.reimbursables.map((reimbursement) => {
      const advanceBalance = advances.reduce((acc, advance) => {
        const balance = (advance.currency === reimbursement.currency && advance.checked) ? advance.balance : 0

        return acc += balance
      }, 0);

      return { ...reimbursement, cost: (reimbursement.cost - advanceBalance) }
    });

    return reimbursements.filter((reimbursement) => reimbursement.cost > 0);
  }

  hasReimbursements() {
    return this.state.reimbursements.length > 0;
  }

  handleCheck(event) {
    const index = this.state.advances.findIndex((advance) => advance.id == event.target.value);
    const value = this.state.advances[index].checked;

    let newList = this.state.advances;

    newList[index].checked = !value;

    this.setState({...this.state, advances: newList, reimbursements: this.setReimbursements(newList) });
  }
}

export default ReportApprove;
