import React from "react";
import { Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CallMadeIcon from '@mui/icons-material/CallMade';

const HomeInsightsStepItemChip = ({label, link}) => {
  const theme = useTheme();

  const bgColor = `${theme.palette.surfaceLight12.main}`;
  const openLink = (event, url) => {
    event.stopPropagation();
    window.open(url, "_blank");
  };

  return (
    <Chip
      data-testid="trailing-chip"
      sx={{
        mx: 1.5,
        bgcolor: bgColor,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: '200px'
      }}
      label={label}
      onClick={(event) => openLink(event, link)}
      onDelete={(event) => openLink(event, link)}
      deleteIcon={<CallMadeIcon data-testid="trailing-chip-icon" />} />
  );
}

export default HomeInsightsStepItemChip;