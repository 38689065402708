import {
    activate_admin_erp_integration_template_path,
    admin_erp_integration_template_activity_logs_path,
    admin_erp_integration_templates_path,
    deactivate_admin_erp_integration_template_path
} from "../../../../routes";
import {get, post} from "@rails/request.js";

export const updateTemplate = async (erp, type, settings, frequency = 'unset') => {
    const response = await post(admin_erp_integration_templates_path(erp), {
        body: JSON.stringify({erp, template_type: type, frequency, settings})
    })
    return response.ok
}

export const deactivateTemplate = async (erp, type) => {
    const response = await post(deactivate_admin_erp_integration_template_path(erp, type))
    return response.ok
}

export const activateTemplate = async (erp, type) => {
    const response = await post(activate_admin_erp_integration_template_path(erp, type))
    return response.ok
}

export const fetchActivityLogs = async (erp, type, page) => {
    const response = await get(
        admin_erp_integration_template_activity_logs_path(erp, type, {page}),
        {responseKind: 'json'}
    )
    if (response.ok) {
        return response.json
    }
    return {page, perPage: 20, data: []}
}