import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material';

const TableSkeleton = () => (
  <Table data-testid="table-skeleton">
    <TableHead>
      <TableRow>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
        <TableCell>
          <Skeleton variant="text" />
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {Array.from({ length: 10 }).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

export default TableSkeleton;