import React, { useEffect, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@mui/material";

import { admin_account_banking_apis_integrations_path } from "../../../../routes";
import { showSnackbar } from "../../../shared/jquery_wrapper";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ApiToken = () => {
  const [openToken, setOpenToken] = useState(false);
  const [token, setToken] = useState("");
  const [tryNewToken, setTryNewToken] = useState(false);
  const [loadingNewToken, setLoadingNewToken] = useState(false);

  useEffect(() => {
    getToken();
  }, []);

  const getToken = () => {
    $.ajax({
      type: "GET",
      url: admin_account_banking_apis_integrations_path(),
    }).then((r) => setToken(r.data));
  };

  const postToken = () => {
    if (tryNewToken) {
      setTryNewToken(false);
      setLoadingNewToken(true);

      $.ajax({
        type: "PUT",
        url: admin_account_banking_apis_integrations_path(),
      })
        .then((r) => {
          setToken(r.data);
          setLoadingNewToken(false);
          showSnackbar({
            content: "Token re-gerado com sucesso!",
            style: "notice",
          });
        })
        .catch((e) =>
          showSnackbar({
            content: "Não foi possível regerar o token!",
            style: "alert",
          })
        );
    } else if (token === "") {
      setLoadingNewToken(true);

      $.ajax({
        type: "POST",
        url: admin_account_banking_apis_integrations_path(),
      })
        .then((r) => {
          setToken(r.data);
          setLoadingNewToken(false);
          showSnackbar({
            content: "Token gerado com sucesso!",
            style: "notice",
          });
        })
        .catch((e) =>
          showSnackbar({
            content: "Não foi possível gerar o token!",
            style: "alert",
          })
        );
    } else {
      setTryNewToken(true);
    }
  };

  const handleClose = () => {
    setTryNewToken(false);
    setLoadingNewToken(false);
    setOpenToken(false);
  };

  const breakLongWord = (word) => {
    const maxLength = 110;
    const regex = new RegExp(`.{1,${maxLength}}`, "g");
    const brokenWord = word.match(regex).join(" ");

    return brokenWord;
  };

  const handleCopy = () => {
    navigator.clipboard?.writeText(token);

    showSnackbar({ content: "Token copiado!", style: "notice" });
  };

  return (
    <Box>
      <Button onClick={() => setOpenToken(true)}>acessar token api</Button>
      {/* token modal */}
      <Dialog open={openToken} onClose={handleClose} maxWidth={"lg"}>
        <DialogTitle>
          <Typography sx={{ fontSize: "20px" }}>Token de acesso</Typography>
        </DialogTitle>
        <DialogContent sx={{ width: "964px" }}>
          <Typography sx={{ color: "rgba(0, 0, 0, 0.87)" }} wrap="wrap">
            {token === "" ? (
              "Ainda não há token de acesso, gere o código para acessar a API clicando no botão abaixo."
            ) : (
              <Box>
                {breakLongWord(token)}
                <ContentCopyIcon
                  data-testid="copy-icon"
                  sx={{
                    fontSize: "16px",
                    marginLeft: "5px",
                    cursor: "pointer",
                    color: "#3D0079",
                  }}
                  onClick={() => handleCopy()}
                />
              </Box>
            )}
          </Typography>
          <Typography
            sx={{
              color: "#3D0079",
              marginTop: "5px",
              marginBottom: "10px",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}
            onClick={() =>
              window.open(
                "https://documenter.getpostman.com/view/3705662/2sA2xfXDBG",
                "_blank"
              )
            }
          >
            Link para documentação da API
            <OpenInNewIcon sx={{ fontSize: "16px", marginLeft: "5px" }} />
          </Typography>
          {tryNewToken && (
            <Typography>
              Tem certeza que deseja regerar o token de acesso à API da empresa?
              Ao realizar essa ação a empresa perderá o acesso à API com o token
              atual.
            </Typography>
          )}
          {loadingNewToken && <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button sx={{ color: "rgba(0, 0, 0, 0.87)" }} onClick={handleClose}>
            fechar
          </Button>
          <Button onClick={() => postToken()}>
            {token === "" ? "gerar" : "regerar"} token
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ApiToken;
