import React, {useRef, useState} from "react";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    Alert,
    Container,
    IconButton,
    Snackbar,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import dayjs from "dayjs";
import {bistro_lightbox_path, bistro_lightboxes_path} from "../../../../routes";
import {Lightbox} from "../../../admin/lightboxes/Lightbox";
import {LightboxFormInfo} from "./LightboxFormInfo";
import {LightboxFormSteps} from "./LightboxFormSteps";
import {LightboxFormButtons} from "./LightboxFormButtons";

const parseExpiredAt = expiredAt => {
    return expiredAt ? dayjs(expiredAt) : dayjs().add(7, 'day')
}

export const LightboxForm = ({rootSelector, csrfToken, lightbox}) => {
    const [identifier, setIdentifier] = useState(lightbox?.identifier ?? '');
    const [roles, setRoles] = useState(lightbox?.roles ?? []);
    const [expiredAt, setExpiredAt] = useState(parseExpiredAt(lightbox?.expiredAt));
    const [steps, setSteps] = useState(lightbox?.steps ?? []);
    const [buttons, setButtons] = useState(lightbox?.buttons ?? []);

    const formRef = useRef(null);
    const [formStep, setFormStep] = React.useState(0);

    const [previewData, setPreviewData] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);

    const prepareLightbox = buttons => ({
        id: lightbox?.id,
        identifier: identifier,
        expiredAt: expiredAt,
        roles: roles,
        buttons: buttons,
        steps: steps.map((item, index) => ({...item, order: index})),
    })

    const prepareFormData = lightbox => {
        let formData = new FormData(formRef.current);
        lightbox.steps.forEach((step) => {
            formData.append('lightbox[steps][][id]', step.id);
            formData.append('lightbox[steps][][order]', step.order);
            formData.append('lightbox[steps][][image]', step.file);
            formData.append('lightbox[steps][][brightness]', step.brightness);
        })
        return formData;
    }

    const saveLightbox = async lightbox => {
        const savePath = lightbox.id ? bistro_lightbox_path(lightbox.id) : bistro_lightboxes_path();
        const response = await fetch(savePath, {
            method: lightbox.id ? 'PUT' : 'POST',
            headers: {"X-CSRF-Token": csrfToken},
            body: prepareFormData(lightbox),
        });
        if (response.ok) {
            setSuccessMessage('Lightbox salvo com sucesso');
            window.location.assign(bistro_lightboxes_path());
        } else {
            setErrorMessage('Não foi possível salvar o lightbox, verifique e tente novamente');
        }
    }

    const handleBasicStep = (identifier, roles, expireAt) => {
        setIdentifier(identifier);
        setRoles(roles);
        setExpiredAt(expireAt);
        handleNext();
    }

    const handleImagesStep = files => {
        setSteps(files);
        handleNext();
    }
    const handleSave = async buttons => {
        setButtons(buttons);
        const lightbox = prepareLightbox(buttons);
        await saveLightbox(lightbox);
    }

    const handlePreview = buttons => {
        setButtons(buttons);
        setPreviewData(prepareLightbox(buttons));
    }
    const handleNext = () => setFormStep((prev) => prev + 1);
    const handleBack = () => setFormStep((prev) => prev - 1);

    const handleCloseSuccess = () => setSuccessMessage(null);
    const handleCloseError = () => setErrorMessage(null);

    return <>
        <Container maxWidth={'md'}>
            <form ref={formRef} encType="multipart/form-data">
                <Stack spacing={2}>
                    <Typography variant={'h4'}>
                        <IconButton href={bistro_lightboxes_path()}>
                            <ArrowBackIcon/>
                        </IconButton>
                        {lightbox?.identifier ?? 'Novo lightbox'}
                    </Typography>
                    <Stepper activeStep={formStep} orientation="vertical">
                        <Step>
                            <StepLabel>Informações básicas</StepLabel>
                            <StepContent TransitionProps={{unmountOnExit: false}}>
                                <LightboxFormInfo
                                    initialIdentifier={identifier}
                                    initialRoles={roles}
                                    initialExpiredAt={expiredAt}
                                    onNext={handleBasicStep}/>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Imagens</StepLabel>
                            <StepContent TransitionProps={{unmountOnExit: false}}>
                                <LightboxFormSteps
                                    initialSteps={steps}
                                    onNext={handleImagesStep}
                                    onBack={handleBack}
                                    rootSelector={rootSelector}/>
                            </StepContent>
                        </Step>
                        <Step>
                            <StepLabel>Botões de ações</StepLabel>
                            <StepContent TransitionProps={{unmountOnExit: false}}>
                                <LightboxFormButtons
                                    editing={!!lightbox?.id}
                                    initialButtons={buttons}
                                    onSave={handleSave}
                                    onPreview={handlePreview}
                                    onBack={handleBack}/>
                            </StepContent>
                        </Step>
                    </Stepper>
                </Stack>
            </form>
        </Container>
        <Snackbar open={!!successMessage} autoHideDuration={5000} onClose={handleCloseSuccess}>
            <Alert
                data-testid={'lightbox-success-message'}
                onClose={handleCloseSuccess}
                severity="success"
                sx={{width: '100%'}}>
                {successMessage}
            </Alert>
        </Snackbar>
        <Snackbar
            data-testid={'lightbox-failure-message'}
            open={!!errorMessage}
            autoHideDuration={5000}
            onClose={handleCloseError}>
            <Alert onClose={handleCloseError} severity="error" sx={{width: '100%'}}>
                {errorMessage}
            </Alert>
        </Snackbar>
        {previewData && (
            <Lightbox
                open={!!previewData}
                lightbox={previewData}
                onClose={() => setPreviewData(null)}/>
        )}
    </>
};