import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import { admin_security_password_expiration_index_path } from '../../../../routes';

const Planned = (props) => {
  const [isSwitchEnabled, setIsSwitchEnabled] = useState(props.active);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState({ type: '', title: '', text: '', button: '' });
  const [expirationInterval, setExpirationInterval] = useState(props.active ? props.expirationInterval : '');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleSelectChange = (event) => {
    setExpirationInterval(event.target.value)
  };

  const handleSwitchChange = (event) => {
    setIsSwitchEnabled(event.target.checked);

    if (!event.target.checked) {
      setExpirationInterval(0);
      disablePlannedExpiration()
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const disablePlannedExpiration = async () => {
    try {
      const response = await fetch(admin_security_password_expiration_index_path(), {
        method: 'POST',
        headers: {
          "X-CSRF-Token": props.csrfToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          active: false
        }),
      });
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(`Ocorreu um erro ${error.message}`);
    }
  }

  const handleButtonClick = async () => {
    try {
      const response = await fetch(admin_security_password_expiration_index_path(), {
        method: 'POST',
        headers: {
          "X-CSRF-Token": props.csrfToken,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          active: isSwitchEnabled,
          expiration_interval: expirationInterval
        }),
      });

      if (response.ok) {
        setData({
          type: 'success',
          title: 'Pronto',
          text: `Você não precisa fazer mais nada! Uma nova solicitação de senha pode ser feita em ${expirationInterval} dias.`,
          button: 'Finalizar'
        });

        setOpenDialog(true);
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage("Não foi possível configurar a expiração planejadda.");
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage(`Ocorreu um erro ${error.message}`);
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        Expiração Planejada
      </Typography>
      
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" style={{ flex: 1 }}>
          Ative ou desative a expiração planejada
        </Typography>
        <Switch
          checked={isSwitchEnabled}
          onChange={handleSwitchChange}
        />
      </div>

      <div style={{ marginTop: '20px' }}>
        <Typography variant="h6">
          Tempo de expiração
        </Typography>
        
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" style={{ flex: 1 }}>
            Configure o tempo que as senhas serão expiradas
          </Typography>

          <FormControl sx={{ m: 1, minWidth: 200 }} size="small" disabled={!isSwitchEnabled}>
            <InputLabel id="select-label">Selecione o período</InputLabel>
            <Select
              inputProps={{ 'data-testid': 'period' }}
              labelId="select-label"
              label="Selecione o período"
              value={expirationInterval}
              onChange={handleSelectChange}
            >
              <MenuItem value="0">Selecione</MenuItem>
              <MenuItem value="60">60 dias</MenuItem>
              <MenuItem value="90">90 dias</MenuItem>
              <MenuItem value="120">120 dias</MenuItem>
              <MenuItem value="365">1 ano</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleButtonClick}
          disabled={expirationInterval == 0}>
          Salvar
        </Button>
      </div>

      <Dialog open={openDialog}>
        <DialogTitle>
          {data.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{data.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="text" color="primary">
            {data.button}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        data-testid="snackbar"
        open={openSnackbar}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
      />
    </React.Fragment>
  );
};

export default Planned;