import React from "react";
import CardType from "../../account/parsers/CardType";

class Card extends React.Component {
  state = {};
  render() {
    return (
      <div id="sub_account_card">
        <div className="collection z-depth-1">
          <table>
            <thead>
              <tr className="collection-item">
                <th className="data-column-1">Final</th>
                <th className="data-column-1">Tipo</th>
                <th className="data-column-1">Data de criação</th>
                <th className="data-column-1">Status</th>
              </tr>
            </thead>
            <tbody>
              {this.props.cards.map((card, idx) => (
                <tr key={idx} className="collection-item">
                  <td className="data-column-1">{card?.description}</td>
                  <td className="data-column-1">
                    <CardType virtual={card?.virtual} />
                  </td>
                  <td className="data-column-1">{card?.created_at}</td>
                  <td className="data-column-1">
                    <div
                      className="chip"
                      style={{ backgroundColor: card?.status_background_color }}
                    >
                      <div
                        className="circle icon"
                        style={{ backgroundColor: card?.status_color }}
                      >
                        <i className={`mdi ${card?.icon_class}`} />
                      </div>
                      <span>{card?.status_translated}</span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  cards: [],
};
export default Card;
