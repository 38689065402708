import { Alert, Stack, Typography } from "@mui/material";
import React from "react";

const MandatoryTwoFactorInactiveAlerts = ({ hasAccount }) => {
  return <Stack spacing={2}>
    <Typography>
      Ao ativar, você vai tornar a configuração do duplo fator obrigatória para todos os colaboradores da empresa a partir do próximo login.
    </Typography>
    { hasAccount && <Alert severity='warning'>
        Uma vez ativada a autenticação em dois fatores (2FA) essa medida de segurança não poderá mais ser desativada.
      </Alert> }
  </Stack>
}

export default MandatoryTwoFactorInactiveAlerts;