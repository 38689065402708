import React from "react";
import { ShadowTheme } from "../ShadowTemplate";

import CardList from "./banking_api/CardList";
import Statement from "./banking_api/Statement";
import BalanceCards from "./banking_api/BalanceCards";

// last import
import { hot } from "react-hot-loader";

const BankingApi = (props) => {
  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      {/* resume cards */}
      <BalanceCards
        balance={props.balance}
        limitForToday={props.limitForToday}
        accountBalance={props.accountBalance}
      />

      {/* list card */}
      <CardList balance={props.balance} />
      {/* statement */}
      <Statement />
    </ShadowTheme>
  );
};

export default hot(module)(BankingApi);
