import React from "react";
import { Typography } from "@mui/material";

const MandatoryTwoFactorSubtitle = ({ active, hasAccount }) => {
  return <Typography variant='h6'>
    { active ? hasAccount ? 'Agora os colaboradores terão que configurar e utilizar o 2FA ao logar na plataforma e aplicativo.'
      : 'O Duplo Fator de Autenticação é uma configuração importante para a segurança dos dados da sua empresa, recomendamos manter ativado!'
      : 'Ative o 2FA como obrigatório e proteja ainda mais a conta de seus colaboradores.' }
  </Typography>
}

export default MandatoryTwoFactorSubtitle;