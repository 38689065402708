import {admin_companies_search_path} from "../../../../routes";

export const generalSearch = async (query) => {
    const response = await fetch(`${admin_companies_search_path()}?query=${query}`);
    if (response.ok) {
        const body = await response.json()
        return body.data;
    }
    return []
}
