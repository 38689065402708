import React from "react";
import { useTheme } from "@mui/material/styles";
import { Avatar, Badge, Typography } from "@mui/material";

const HomeInsightsStepItemAvatar = ({avatar, number}) => {
  const theme = useTheme();
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <Avatar sx={{ bgcolor: theme.palette.surfaceLight12.main, width: 24, height: 24 }}>
          <Typography variant="caption" color="initial">{number}º</Typography>
        </Avatar>}>
      <Avatar src={avatar} />
    </Badge>
  );
}

export default HomeInsightsStepItemAvatar;