import { bistro_companies_path, bistro_company_path } from "../../../../routes";

export class CreateBistroCompanyDto {
    constructor({
        companyName,
        companyDocument,
        companyDomain,
        companySegment,
        companyCity,
        companyState,
        companyTest = true,
        companyLevel = 2,
        companyStatus = 1
    }) {
        this.name = companyName;
        this.cnpj = companyDocument;
        this.domain = companyDomain;
        this.segment = companySegment;
        this.city = companyCity;
        this.state = companyState;
        this.test = companyTest;
        this.level = companyLevel;
        this.status = companyStatus;
    }
}

export class CreateBistroCompanyUserDto {
    constructor({
        userName,
        userEmail,
        userPhone,
        userPassword
    }) {
        this.name = userName;
        this.email = userEmail;
        this.phone = userPhone;
        this.password = userPassword;
    }
}

export class CreateBistroCompanyDtos {
    constructor(company, user) {
        this.company = company;
        this.user = user;
    }
}

export const createBistroCompany = async (csrfToken, dtos) => {
    const response = await fetch(bistro_companies_path(), {
        method: 'POST', credentials: "same-origin", redirect: 'follow',
        headers: { "X-CSRF-TOKEN": csrfToken, 'Content-Type': 'application/json' },
        body: JSON.stringify(dtos) });
    if (response.ok) {
        const body = await response.json();
        window.location.assign(bistro_company_path(body.id));
    } else {
        window.alert("Não foi possível criar a empresa");
    }
}