import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import {Skeleton} from "@mui/material";
import * as React from "react";

export const EspressoTableBody = (props) => {
    const {
        rows,
        columns,
        selected,
        selectable,
        onSelectRow,
        page,
        rowsPerPage,
        hoverRows,
        selectAll,
        loading,
        onClickRow,
    } = props;

    const isSelected = (id) => {
        if (selectAll) {
            return selected.indexOf(id) === -1;
        }
        return selected.indexOf(id) !== -1;
    }

    const emptyRows = page > 1 ? Math.max(0, rowsPerPage - rows.length) : 0;

    const handleSelectRow = (rowId) => (event) => {
        onSelectRow?.(event, rowId);
    }

    return <TableBody>
        {rows.map((row, rowIndex) => {
            const isItemSelected = isSelected(row.id);
            const labelId = `table-cell-${rowIndex}`;
            return (
                <TableRow
                    key={row.id}
                    data-testid={`table-row-${row.id}`}
                    sx={{ cursor: onClickRow ? 'pointer' : 'default'}}
                    hover={!loading && hoverRows}
                    selected={isItemSelected}
                    onClick={() => onClickRow?.(row.data)}>
                    {selectable && (
                        <TableCell
                            padding="checkbox"
                            onClick={handleSelectRow(row.id)}>
                            <Checkbox
                                data-testid={`table-row-check-${row.id}`}
                                inputProps={{'aria-labelledby': `${labelId}-0`}}
                                disabled={loading}
                                color="primary"
                                checked={isItemSelected}/>
                        </TableCell>
                    )}
                    {columns.map((column, columnIndex) => {
                        const cell = row.cells.get(column.id);
                        return <TableCell
                            data-testid={`table-cell-${row.id}-${column.id}`}
                            key={`${rowIndex}_${columnIndex}`}
                            id={`${labelId}-${columnIndex}`}
                            align={column.align}
                            padding={column.padding}>
                            {loading ? (
                                <Skeleton variant="rounded" height={20}/>
                            ) : (
                                column.cellBuilder(cell, row.data)
                            )}
                        </TableCell>
                    })}
                </TableRow>
            );
        })}
        {emptyRows > 0 && (
            <TableRow style={{height: 53 * emptyRows}}><TableCell
                colSpan={columns.length + selectable}/></TableRow>
        )}
    </TableBody>
}