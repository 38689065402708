import React, { useState } from "react";

import {
  admin_account_sub_accounts_path,
  admin_account_sub_accounts_privacy_path,
} from "../../../../../routes";

import { showSnackbar } from "../../../../shared/jquery_wrapper";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import OptionsChipButton from "../../../../shared/OptionsChipButton";

const NavBarModal = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => setAnchorEl(event.currentTarget);
  /* istanbul ignore next */
  const handleClose = () => setAnchorEl(null);

  const [showModal, setShowModal] = useState(false);

  /* istanbul ignore next */
  const handleCloseModal = () => setShowModal(false);

  const handleClick = () => {
    $.ajax({
      type: "PUT",
      url: admin_account_sub_accounts_privacy_path(),
      data: { public: true },
    })
      .then((r) => {
        showSnackbar({
          content: "Privacidade alterada com sucesso!",
          style: "notice",
        });

        window.location.assign(admin_account_sub_accounts_path());
      })
      .catch((e) => {
        showSnackbar({
          content: "Não foi possível tornar privado!",
          style: "alert",
        });
      });
  };

  return (
    <React.Fragment>
      {/* Button */}
      <React.Fragment>
        {/* /* istanbul ignore next */}
        <OptionsChipButton onClick={handleClickListItem} />
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "lock-button",
            role: "listbox",
          }}
        >
          <MenuItem onClick={() => setShowModal(true)}>
            Tornar o acesso público
          </MenuItem>
        </Menu>
      </React.Fragment>
      {/* Modal */}
      <Dialog open={showModal} onClose={handleCloseModal} maxWidth={"lg"}>
        <DialogTitle>
          <Typography sx={{ fontSize: "20px" }}>
            Tornar o acesso público
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Tem certeza que deseja liberar o acesso a ativação do cartão para
            todos os usuários?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", padding: "20px" }}>
          <Button
            sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}
            onClick={handleCloseModal}
          >
            cancelar
          </Button>
          <Button onClick={handleClick}>confirmar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default NavBarModal;
