import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { admin_budget_path, expenses_export_admin_budget_path }  from "../../../../routes";
import _ from 'lodash';
import OptionsChipButton from '../../../shared/OptionsChipButton';

const Navbar = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = () => {
    const queryParams = new URLSearchParams();
    Object.keys(props.filters).forEach(key => {
      /* istanbul ignore next */
      if (props.filters[key]) {
        const mappedKey = _.snakeCase(key);
        queryParams.append(`filter[${mappedKey}]`, props.filters[key]);
      }
    });

    const url = `${expenses_export_admin_budget_path(props.budgetId)}?${queryParams.toString()}`
    window.open(url, '_blank');
  };

  return (
    <div style={{ marginBottom: '50px' }}>
      <AppBar position="static" sx={{ backgroundColor: '#F0EBF5', boxShadow: 'none'}} data-testid="appbar">
        <Toolbar>
          <IconButton
            data-testid={'back-button'}
            href={admin_budget_path(props.budgetId)}
          >
            <ArrowBackIcon/>
          </IconButton>
          <Typography
            sx={{
              ml: 2,
              flexGrow: 1,
              color: 'black',
              fontSize: '18px'
            }}
          >
              Despesas do ciclo atual
          </Typography>
          <OptionsChipButton onClick={handleMenuClick}/>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={/* istanbul ignore next */ () => setAnchorEl(null)}
            data-testid="menu"
          >
            <MenuItem>
              <ListItemIcon>
                <DownloadIcon sx={{ fontSize: '20px' }} />
              </ListItemIcon>
              <ListItemText primary="Exportar" onClick={handleClick} />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Navbar;
