import React, { useState } from "react";
import { ShadowTheme } from "../../../../ShadowTemplate";

import { parserCurrencyBRLValue } from "../../../../../shared/helpers";
import { showSnackbar } from "../../../../../shared/jquery_wrapper";
import {
  parseAmount,
  calcNewBalance,
  postAdjuster,
} from "../../../../../shared/balance_service";

import {
  admin_account_sub_accounts_user_sub_account_path,
  deposit_admin_account_balance_statement_path,
} from "../../../../../../routes";

import BlockBalanceDialog from "./change_balance/BlockBalanceDialog";
import BalanceForm from "./change_balance/BalanceForm";

// last import
import { hot } from "react-hot-loader";

const ChangeBalance = ({
  balance: initialBalance,
  subAccount,
  rootSelector,
}) => {
  const [showBlockBalanceDialog, setShowBlockBalanceDialog] = useState(false);
  const [balanceAccount, setBalanceAccount] = useState(initialBalance);

  /* istanbul ignore next */
  const handleUpdate = async (amount, operation, withdrawAll = false) => {
    const subAccounts = [
      { id: subAccount.id, balance: Number(subAccount.balance) },
    ];
    const numberAmount = withdrawAll
      ? subAccounts.reduce((acc, item) => acc + item.balance, 0)
      : parseAmount(amount);
    const tmpBalance = calcNewBalance(
      operation,
      subAccounts,
      numberAmount,
      balanceAccount,
      withdrawAll
    );

    if (tmpBalance >= 0) {
      setBalanceAccount(tmpBalance);
      setShowBlockBalanceDialog(false);
      try {
        await postAdjuster(
          numberAmount,
          subAccounts.map((e) => e.id),
          operation
        );
        showSnackbar({
          content: "Saldo em processo de ajuste!",
          style: "notice",
        });
        window.location.href = admin_account_sub_accounts_user_sub_account_path(
          { id: subAccount.id }
        );
      } catch (error) {
        setBalanceAccount(initialBalance);
        showSnackbar({
          content: "Não foi possível ajustar o saldo, tente novamente!",
          style: "alert",
        });
      }
    } else {
      setShowBlockBalanceDialog(true);
    }
  };

  return (
    <ShadowTheme rootSelector={rootSelector}>
      <BlockBalanceDialog
        open={showBlockBalanceDialog}
        onClose={() => setShowBlockBalanceDialog(false)}
        onDeposit={() =>
          (window.location.href =
            deposit_admin_account_balance_statement_path())
        }
      />
      <BalanceForm
        balance={parserCurrencyBRLValue(balanceAccount)}
        onUpdate={handleUpdate}
      />
    </ShadowTheme>
  );
};

export default hot(module)(ChangeBalance);
