import {Alert} from "@mui/material";
import React from "react";

export const SankhyaConnectionSteps = () => {
    return <>
        <p><b>Passo 1: </b>Dentro do sistema Sankhya informe as “Informações da Base”, e após
            informar clique em “Salvar”.</p>
        <ul>
            <li>
                <b>Endereço Sankhya: </b>
                Endereço onde você está logado, se está em Produção ou Homologação.
            </li>
            <li>
                <b>Nome: </b>
                Pode ser preenchido com o nome que você achar melhor para identificar o “Gateway”
                (Ex: Configurações Espresso).
            </li>
            <li>
                <b>Tipo de Ambiente: </b>
                É identificado de maneira automática conforme o registro da base (Endereço Sankhya)
                que está efetuando a configuração
            </li>
        </ul>
        <p><b>Passo 2: </b>Salvar “Informações da Base”.</p>
        <ul>
            <li>
                <b>Salvar: </b>
                Após inserir as informações o botão de “Salvar” ficará visível, clique em “Salvar”
                para continuar.
            </li>
        </ul>
        <p><b>Passo 3: </b>Adicionar “Sankhya ID” e gerar o “Token de Integração”.</p>
        <ul>
            <li>
                <b>Aplicação: </b>
                Inserir aqui o ID integracoes-sankhya@espressoapp.com.br
            </li>
            <li>
                <b>Parceiro: </b>
                Esse campo é preenchido automaticamente com a nossa aplicação após inserir o campo
                acima.
            </li>
            <li>
                <b>Cód. Usuário: </b>
                Aqui é o código do usuário parceiro do Sankhya.
            </li>
            <li>
                <b>Gerar Gateway: </b>
                A possibilidade de copiar o gateway libera após salvar as informações que foram
                inseridas.
            </li>
        </ul>
        <Alert severity="info" sx={{mt: 1}}>
            A partir desses passos realizados será possível testar a conexão com o ERP
        </Alert>
    </>
}