import {CardHeader, Divider, Drawer, IconButton, Stack, Typography, Tooltip, Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {LinearProgressWithLabel} from "../../shared/Progress";
import {OnboardingStep} from "./OnboardingStep";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import SuccessfulOnboarding from "./SuccessfulOnboarding";

export const OnboardingDrawer = (props) => {
    const {
        open,
        onClose,
        onboarding,
        currentStep,
        completed,
        onOpenStep,
        onComplete,
        onIgnore
    } = props;

    const [stepOpen, setStepOpen] = useState(currentStep);

    useEffect(() => setStepOpen(currentStep), [currentStep]);

    const isStepCompleted = (step) => {
        return onboarding.completed || onboarding.completedSteps.includes(step.id);
    }

    const handleClickStep = (stepId) => (expand) => {
        setStepOpen(expand ? stepId : null)
    }

    return <Drawer anchor={'right'} open={open} PaperProps={{sx: {borderRadius: 2}}}>
        <Stack sx={{width: 400, height: '100%'}}>
            <OnboardingHeader title={onboarding.title} completed={completed} onClose={onClose}/>
            {!completed && (<>
                <OnboardingProgress onboarding={onboarding}/>
                <Divider/>
                {[...onboarding.steps.sort((a, b) => a.order - b.order).map((step) => (
                    <OnboardingStep
                        key={step.identifier}
                        step={step}
                        completed={isStepCompleted(step)}
                        open={stepOpen === step.id}
                        onClick={handleClickStep(step.id)}
                        onOpen={onOpenStep}/>
                ))]}
            </>)}
            {!completed && !onboarding.ignored && <OnboardingIgnoreButton onIgnore={onIgnore}/>}
            {completed && <SuccessfulOnboarding onBack={onComplete}/>}
        </Stack>
    </Drawer>
}

const OnboardingIgnoreButton = ({onIgnore}) => {
    return <Button
                sx={{ my: 1, px: 2 }}
                data-testid={"ignore-button"}
                onClick={onIgnore}
                style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    fontSize: "14px"
                }}
            >
                Ignorar
            </Button>
}

const OnboardingHeader = ({title, completed, onClose}) => {
    return <CardHeader
        action={<Tooltip title='Fechar onboarding'>
            <IconButton onClick={onClose}>
                <CloseIcon/>
            </IconButton>
        </Tooltip>}
        title={title}
        subheader={completed ? 'Concluído!' : 'É simples como passar um cafezinho!'}/>
}

const OnboardingProgress = ({onboarding}) => {
    const percentage = onboarding.completedSteps.length / onboarding.steps.length * 100;

    return <Stack spacing={2} sx={{p: 2}}>
        <Typography>{getSubtitle(onboarding.completedSteps, onboarding.steps)}</Typography>
        <LinearProgressWithLabel
            color={'secondary'}
            value={percentage}
            variant={'determinate'}
            sx={{height: 12, borderRadius: 4, background: '#F0F0F0'}}/>
    </Stack>
}

const subtitles = [
    'Vamos começar?',
    'Moendo os grãos do café!',
    'Esquentando a água...',
    'O café está passando, o aroma tá no ar!',
    'Ajustando o sabor...',
    'Já dá pra sentir o cheiro bom de café passado!',
    'Hmmm, quase lá...',
    'Cafezinho pronto para servir!',
];

const getSubtitle = (completed, steps) => {
    return subtitles[Math.round(completed.length * (subtitles.length - 1) / steps.length)];
}

OnboardingDrawer.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onboarding: PropTypes.object,
    onSkipStep: PropTypes.func,
    currentStep: PropTypes.number,
    completed: PropTypes.bool,
    onOpenStep: PropTypes.func,
    onIgnore: PropTypes.func
}