import React, {useCallback, useEffect, useRef, useState} from "react";
import {hot} from "react-hot-loader";
import {EspressoTable} from "../../../components/collection/table/EspressoTable";
import {ShadowTheme} from "../../../admin/ShadowTemplate";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography
} from "@mui/material";
import {statementColumns, statementRows} from "./funds_in_table_data";
import {fetchStatements, retryTransfer} from "./funds_in_service";

const FundsInStatementShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <FundsInStatement {...props} />
    </ShadowTheme>
}

const FundsInStatement = ({csrfToken}) => {
    const [openedFundIn, setOpenedFundIn] = useState(null)
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const fetchPage = useCallback(async (page, perPage, filters) => {
        const body = await fetchStatements(csrfToken, page, perPage, filters)
        return {...body, data: statementRows(body.data)}
    }, []);

    const retryFundsIn = useCallback(async (statementId) => {
        const success = await retryTransfer(csrfToken, statementId);
        setSnackbarMessage(success ?
            'Transferência iniciada com sucesso' :
            'Não foi possível iniciar a transferência');
        tableRef.current.refresh()
        closeModal()
    }, []);

    const onClickRow = (data) => setOpenedFundIn(data)

    const closeModal = () => setOpenedFundIn(null);

    const closeMessage = () => setSnackbarMessage('');

    const tableRef = useRef(null);

    useEffect(() => {
        tableRef.current.refresh()
    }, []);

    return <>
        {!!openedFundIn &&
            <FundsInStatementDialog
                statement={openedFundIn}
                onClose={closeModal}
                onRetry={retryFundsIn}/>
        }
        <Snackbar
            data-testid={'funds-in-snackbar'}
            open={!!snackbarMessage}
            autoHideDuration={3000}
            message={snackbarMessage}
            onClose={closeMessage}/>
        <EspressoTable
            ref={tableRef}
            title={'Recargas em conta'}
            hoverRows={true}
            selectable={false}
            onClickRow={onClickRow}
            columns={statementColumns}
            fetchPage={fetchPage}
            emptyMessage={'Nenhuma recarga registrada até o momento'}/>
    </>
}

const FundsInStatementDialog = ({statement, onClose, onRetry}) => {
    return <Dialog
        data-testid={'fund-in-details-dialog'}
        open={!!statement}
        onClose={onClose}
        maxWidth={'lg'}>
        <DialogTitle>Detalhes da recarga</DialogTitle>
        <DialogContent>
            <Stepper activeStep={statusSteps(statement.status)} orientation="vertical">
                {[...fundsInSteps.map((step, index) => {
                    const cardsError = index === 1 && statement.status === 5
                    const accountError = index === 2 && statement.status === 6
                    return <Step key={`step_${index}`}>
                        <StepLabel error={cardsError || accountError}>{step.label}</StepLabel>
                        <FundInStatementInfo statement={statement} onRetry={onRetry}/>
                    </Step>
                })]}
            </Stepper>
        </DialogContent>
        <DialogActions>
            <Button color={'inherit'} onClick={onClose}>Fechar</Button>
        </DialogActions>
    </Dialog>
}

const FundInStatementInfo = ({statement, onRetry}) => {
    const [retrying, setRetrying] = useState(false);
    const handleRetry = async () => {
        setRetrying(true);
        await onRetry(statement.id);
    }

    return <StepContent>
        <Typography>
            <b>Identificador de entrada: </b>{statement.externalId}
        </Typography>
        {!!statement.bagTransactionId && <Typography>
            <b>Identificador em Banking: </b>{statement.bagTransactionId}
        </Typography>}
        {!!statement.taTransactionId && <Typography>
            <b>Identificador na Conta: </b>{statement.taTransactionId}
        </Typography>}
        {(statement.status === 5 || statement.status === 6) &&
            <Button
                data-testid={'retry-dialog-button'}
                variant="outlined"
                onClick={handleRetry}
                disabled={retrying}
                sx={{mt: 2}}>
                {retrying ? 'Aguarde...' : 'Tentar transferência novamente' }
            </Button>
        }
    </StepContent>
}

const fundsInSteps = [
    { label: 'Recebido', statuses: [0]},
    { label: 'Transferência de Banking', statuses: [1, 2, 5]},
    { label: 'Transferência para Conta', statuses: [3, 6]},
    { label: 'Concluído', statuses: [4]}
]

const statusSteps = (status) => {
    return fundsInSteps.findIndex((value) => value.statuses.includes(status));
}

export default hot(module)(FundsInStatementShadow);