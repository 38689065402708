import React from "react";

import ModalDetails from "../modals/Details";
import classNames from "classnames";
class WithModalDetail extends React.Component {
  state = {
    details: [],
    sectionsList: [],
    sectionsTittles: [],
    modalActionButton: false,
    buttonOnClick: Function,
  };

  render() {
    return (
      <React.Fragment>
        <ModalDetails
          tittle={this.props.modalTittle}
          details={this.state.details}
          sectionsList={this.state.sectionsList}
          sectionsTittles={this.state.sectionsTittles}
          actionButton={this.state.modalActionButton}
          buttonLabel={this.props.modalButtonLabel}
          buttonOnClick={this.state.buttonOnClick}
        />
        <div className="collection z-depth-1 white col s12 list-with-modal-detail">
          <table className="table">
            <thead>
              <tr>
                {this.props.header.map((el, idx) => (
                  <th style={{ width: el.width }} key={idx}>{el.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {this.props.list.map((el, idx) => (
                <tr
                  test-name={`list-item-${idx}`}
                  key={idx}
                  className={classNames(
                    "modal-trigger",
                    this.props.listColor[idx]
                  )}
                  href="#modal-detail"
                  onClick={() => this.handleSelect(idx)}
                >
                  {this.props.header.map((elh, idxh) => (
                    <td key={idx + idxh}>{el[idxh] || "-"}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {this.props.list.length === 0 && <p>Nenhuma dado encontrado!</p>}
        </div>
      </React.Fragment>
    );
  }

  handleSelect(index) {
    this.setState({
      details: this.props.listDetail[index],
      sectionsList: this.props.sectionsListDetail[index],
      sectionsTittles: this.props.sectionsListTittles[index],
      buttonOnClick: this.props?.modalActionList[index]
        ? () => this.props.modalAction(index)
        : Function,
      modalActionButton: this.props?.modalActionList[index],
    });
  }
}

WithModalDetail.defaultProps = {
  header: [],
  list: [],
  listDetail: [],
  sectionsListDetail: [],
  sectionsListTittles: [],
  listColor: [],
  modalTittle: "",
  modalButtonLabel: "",
  modalActionList: [],
  modalAction: Function,
};

export default WithModalDetail;
