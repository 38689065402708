import React from "react";
import classNames from "classnames";
import { initModals, showSnackbar } from "../jquery_wrapper";
import SubscriptionForm from "../../account/forms/Subscription";
import MaskedInput from "../inputs/Masked";
import CardSensitiveData from "../../account/parsers/CardSensitiveData.jsx";
import CardType from "../../account/parsers/CardType";
import { parserCurrencyBRLValue, parserValueWithBrlCurrency } from "../helpers";
import { balance_admin_account_sub_accounts_multi_balances_path } from "../../../routes.js";
import { calcNewBalance, parseAmount as parserValue } from "../../shared/balance_service.js";
class Subscription extends React.Component {
  state = {
    showAdjustBalance: false,
    showForm: false,
    showBlock: false,
    showCard: false,
    form_type: "",
    adjust_balance: parserValueWithBrlCurrency("0"),
    try_to_adjust: false,
    adjusting: false,
    blocking: false,
    operation: "",
    withdrawAll: false,
    balance_account: this.props.balance_account
  };

  componentDidMount() {
    initModals();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.update !== this.props.update) {
      this.setState({
        ...this.props.card,
        unblock: this.props.unblock,
        showAdjustBalance: false,
        showForm: false,
        showBlock: false,
        showCard: false,
      });
    }

    if (prevProps.update !== this.props.update && this.props.unblock) {
      this.setState({ showForm: true, form_type: "unblock" });
    }
  }

  render() {
    return (
      <div
        id="modal-subscription"
        className="modal modal-fixed modal-subscription"
      >
        <section className="d-flex justify-between align-center modal-header">
          <h3>
            {this.props.unblock && "Desbloquear "}
            {this.state.description || "Assinaturas"}
          </h3>
          <i className="mdi mdi-close modal-close" />
        </section>

        <div className="modal-content">
          {!this.props.unblock && (
            <section className="d-flex justify-between">
              <CardSensitiveData
                mockCard={this.props.testAccount}
                showCard={this.state.showCard}
                virtual={this.state.virtual}
                endpoint={this.props.endpoints?.token}
                virtual_card_icon={this.props.images?.virtual_card_icon}
                physical_card_icon={this.props.images?.physical_card_icon}
                mastercard_logo={this.props.images?.mastercard_logo}
                espresso_card_logo={this.props.images?.espresso_card_logo}
                external_show={(show) => this.setState({ showCard: show })}
                sub_account_id={this.state.id}
                card_id={this.state.card_id}
              />
              {!this.state.showCard && this.renderButtonsEditBlock()}
            </section>
          )}

          {this.state.showCard && !this.props.unblock && (
            <section>{this.renderButtonsEditBlock()}</section>
          )}

          {this.state.showForm && this.props.can_manage && (
            <section>
              <SubscriptionForm
                {...this.props}
                hasCards={true}
                form_type={this.state.form_type}
                data={{ ...this.state, momentToAllocate: "schedule" }}
              />
            </section>
          )}

          {!this.props.unblock && (
            <React.Fragment>
              {this.state.showBlock &&
                this.props.can_manage &&
                this.renderBlock()}

              {!this.state.showBlock &&
                !this.state.showForm &&
                this.renderAdjustBalance()}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }

  renderButtonsEditBlock() {
    return (
      !this.props.unblock &&
      this.props.can_manage && (
        <div className="d-flex align-center">
          <div
            test-name="show-form"
            className={classNames(
              "icon-background d-flex align-center justify-center pointer",
              { disabled: this.state.showBlock }
            )}
            onClick={() =>
              this.setState({
                showBlock: false,
                showForm: !this.state.showForm,
                form_type: "update",
              })
            }
          >
            <i className="mdi mdi-pencil icon-purple" />
          </div>
          <div
            test-name="show-block"
            className={classNames(
              "icon-background d-flex align-center justify-center pointer",
              { disabled: this.state.showForm }
            )}
            onClick={() =>
              this.setState({
                showForm: false,
                showBlock: !this.state.showBlock,
              })
            }
          >
            <i className="mdi mdi-lock icon-purple" />
          </div>
        </div>
      )
    );
  }

  renderAdjustBalance() {
    return (
      <React.Fragment>
        <section className="d-flex justify-between">
          <div>
            <h3>{this.props.can_manage ? "Ajustar s" : "S"}aldo</h3>
            {this.props.can_manage && (
              <label>
                Clique no item abaixo e ajuste o saldo do seu cartão
              </label>
            )}
          </div>
          <div className="d-flex direction-column align-end justify-between">
            <label className="black-text">
              Recorrência de alocação:{" "}
              {this.props.card?.deposit_interval_parsed} -{" "}
              {parserCurrencyBRLValue(this.state.deposit_value)}
            </label>
            <h3>
              Saldo disponível no cartão:{" "}
              {parserCurrencyBRLValue(this.state.balance)}
            </h3>
          </div>
        </section>

        {this.props.can_manage && (
          <section
            test-name="show-adjust-balance"
            className={classNames("d-flex justify-between pointer", {
              shadow: this.state.showAdjustBalance,
            })}
            onClick={() =>
              this.setState({
                showAdjustBalance: !this.state.showAdjustBalance,
              })
            }
          >
            <div className="d-flex align-center">
              <CardType label={false} virtual={this.state.virtual} />
              <h6 className="card-menu">
                {this.state.description || "Assinaturas"}
              </h6>
            </div>
            <i
              className={classNames("mdi", {
                "mdi-chevron-down": !this.state.showAdjustBalance,
                "mdi-chevron-up": this.state.showAdjustBalance,
              })}
            />
          </section>
        )}

        {this.state.showAdjustBalance && this.props.can_manage && (
          <React.Fragment>
            <section>
              <p>Selecione uma das opções para definir o saldo atual do cartão:</p>

              <div className="row">
                <div className="col s4">
                  <label>
                    <input
                      test-name="radio-deposit"
                      className="with-gap"
                      name="operation"
                      type="radio"
                      value="deposit"
                      required
                      onChange={(e) => this.setState({
                        operation: e.target.value,
                        withdrawAll: false
                      })} 
                    />
                    <span>Adicionar saldo</span>
                  </label>
                </div>
                <div className="col s4">
                  <label>
                    <input
                      test-name="radio-adjust"
                      className="with-gap"
                      name="operation"
                      type="radio"
                      value="adjust"
                      required
                      onChange={(e) => this.setState({
                        operation: e.target.value,
                        withdrawAll: false
                      })}  
                    />
                    <span>Ajustar saldo</span>
                  </label>
                </div>
                <div className="col s4">
                  <label>
                    <input
                      test-name="radio-withdraw"
                      className="with-gap"
                      name="operation"
                      type="radio"
                      value="withdraw"
                      required
                      onChange={(e) => this.setState({
                        operation: e.target.value
                      })} 
                    />
                    <span>Resgatar saldo</span>
                  </label>
                </div>
              </div>

              <p>
                Saldo disponível na conta digital:{" "}
                <b className="balance-account">{this.state.balance_account}</b>
              </p>

              <MaskedInput
                error={this.valueError()}
                forceError={this.valueError() && this.state.try_to_adjust}
                test={"adjust-balance"}
                size={100}
                placeholder={"R$ 0,00"}
                label={"Saldo programado"}
                value={this.state.adjust_balance}
                disabled={this.state.operation === "withdraw" && this.state.withdrawAll}
                onChange={(e) =>
                  this.setState({
                    adjust_balance: parserValueWithBrlCurrency(e.target.value),
                  })
                }
              />

              {this.state.operation === "withdraw" && (
                <div className="row">
                  <label>
                    <input
                      test-name="checkbox-withdrawAll"
                      type="checkbox"
                      className="filled-in"
                      checked={this.state.withdrawAll}
                      onChange={(e) =>
                        this.setState({
                          withdrawAll: e.target.checked
                        })
                      }
                    />
                    <span>Resgatar tudo</span>
                  </label>
                </div>
              )}
            </section>
            <div className="modal-footer">
              <button
                test-name="hide-adjust-balance"
                className="btn btn-adjust cancel-btn"
                disabled={this.state.adjusting}
                onClick={() => this.setState({ showAdjustBalance: false })}
              >
                cancelar
              </button>
              <button
                test-name="button-adjust"
                className="btn btn-adjust confirm-btn"
                disabled={this.state.adjusting}
                onClick={() => this.handleAdjustBalance()}
              >
                {this.state.adjusting ? "ajustando..." : "ajustar"}
              </button>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderBlock() {
    return (
      <React.Fragment>
        <section>
          <h5>Tem certeza que deseja bloquear o seu cartão?</h5>
        </section>
        <div className="modal-footer">
          <button
            test-name="block-button"
            className="btn block-btn"
            disabled={this.state.blocking}
            onClick={() => this.handleblock()}
          >
            {this.state.blocking ? "bloqueando" : "bloquear cartão"}
          </button>
        </div>
      </React.Fragment>
    );
  }

  calcBalanceSubAccount() {
    const balanceSubAccount = Number(this.state.balance);
    const adjust = Number(parserValue(this.state.adjust_balance));
    const options = {
      'deposit': balanceSubAccount + adjust,
      'adjust': adjust,
      'withdraw': this.state.withdrawAll ? 0 : Math.max(0, balanceSubAccount - adjust),
    };

    return options[this.state.operation];
  }

  calcBalance() {
    return calcNewBalance(
      this.state.operation,
      [{id: this.state.id, balance: Number(parserValue(this.state.balance))}],
      Number(parserValue(this.state.adjust_balance)),
      Number(parserValue(this.state.balance_account)),
      this.state.withdrawAll
    )
  }

  valueError() {
    const balance = parserValue(this.state.balance_account);
    const adjust_balance = this.calcBalance();

    return adjust_balance < 0 || this.state.adjust_balance === "";
  }

  defineAmout() {
    if(this.state.operation == "withdraw" && this.state.withdrawAll) {
      return Number(parserValue(this.state.balance));
    }

    return Number(parserValue(this.state.adjust_balance))
  }

  handleAdjustBalance() {
    if (!this.state.operation) {
      this.setState({ try_to_adjust: true });
      showSnackbar({
          content: "Selecione uma opção para ajustar o saldo",
          style: "alert",
      });
      return;
    }

    if (this.calcBalance() < 0) {
      this.setState({ try_to_adjust: true });
      showSnackbar({
          content: "A conta não tem saldo suficiente, por isso não será possível seguir com a ação.",
          style: "alert",
      });
      return;
    }
    
    if (this.valueError()) {
      this.setState({ try_to_adjust: true });
    } else {
      this.setState({ try_to_adjust: true, adjusting: true });

      $.ajax({
        type: "POST",
        dataType: "json",
        url: balance_admin_account_sub_accounts_multi_balances_path(),
        data: {
          amount: this.defineAmout(),
          sub_account_ids: [this.state.id],
          operation: this.state.operation
        },
      })
        .then((r) => {
          this.setState({
            balance: String(this.calcBalanceSubAccount()),
            balance_account: parserCurrencyBRLValue(this.calcBalance()),
            try_to_adjust: false,
            adjusting: false
          });
          showSnackbar({
            content: "Saldo será ajustado automaticamente em breve",
            style: "notice",
          });
        })
        .catch((e) => {
          this.setState({ adjusting: false });

          showSnackbar({
            content: "Não foi possivel ajusta o saldo, tente novamente!",
            style: "alert",
          });
        });
    }
  }

  handleblock() {
    this.setState({ blocking: true });

    $.ajax({
      type: "POST",
      url: this.props.endpoints.block.replace("sub_account_id", this.state.id),
    })
      .then((r) => {
        showSnackbar({ content: "Cartão bloqueado!", style: "notice" });

        window.location.reload(false);
      })
      .catch((e) => {
        this.setState({ blocking: false });

        showSnackbar({
          content: "Não foi possivel bloquear o cartão!",
          style: "alert",
        });
      });
  }
}

Subscription.defaultProsp = {
  testAccount: false,
  update: false,
  card: {},
  balance_account: "",
  endpoints: {
    block: "",
    unblock: "",
    adjust: "",
    token: "",
  },
  unblock: false,
  can_manage: true,
};

export default Subscription;
