/* istanbul ignore file */
import React from "react";
import classNames from "classnames";

import MaskedInput from "../../shared/inputs/Masked";
import { parserNumber, parserValueWithBrlCurrency } from "../../shared/helpers";
import { initTooltips, showSnackbar } from "../../shared/jquery_wrapper";
import CloseIcon from "@mui/icons-material/Close";
import UserMerchantModal from "../../shared/modals/UserMerchantsModal";

class Subscription extends React.Component {
  state = {
    showForm: false,
    openModal: false,
    description: "",
    tryToSave: false,
    saving: false,
    depositInterval: -1,
    depositValue: "",
    momentToAllocate: "",
    id: "",
    company_user_id: "",
    company_user_name: "",
    company_user_email: "",
    company_user_avatar: "",
  };

  componentDidMount() {
    if (this.props.data) {
      this.setState({
        ...this.props.data,
        depositInterval: this.props.data.deposit_interval,
        depositValue: parserNumber(this.props.data.deposit_value, 2),
        showForm: true,
        momentToAllocate: this.props.data.deposit_interval === 0 ? "now" : "",
      });
    }

    initTooltips();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showForm !== this.state.showForm) {
      this.state.showForm && this.state.id === ""
        ? this.props.showList(false)
        : this.props.showList(true);
    }
  }

  render() {
    return (
      this.props.account_active && (
        <div
          className={classNames("subscription-form", {
            "collection-item": this.props.collectionItem,
          })}
        >
          <div
            className={classNames({
              "collection-item": this.props.form_type === "create",
              hide: this.props.hasCards && !this.state.showForm,
            })}
          >
            {this.renderTittle()}
            {this.state.showForm && this.props.can_manage && this.renderForm()}
          </div>
          {this.props.can_manage && (
            <div
              className={classNames("d-flex", {
                "collection-item": this.props.form_type === "create",
                "justify-end": this.props.can_manage,
                "justify-start": !this.props.can_manage,
              })}
            >
              <button
                test-name="add-button"
                style={{ backgroundColor: "#3D0079" }}
                className={classNames("btn", {
                  "btn-full": ["create", "unblock"].includes(
                    this.props.form_type
                  ),
                  "btn-unblock": this.props.form_type === "unblock",
                })}
                disabled={this.state.saving}
                onClick={(e) =>
                  this.state.showForm
                    ? this.handleSave(e)
                    : this.setState({ showForm: true })
                }
              >
                {this.state.saving
                  ? this.variables().saving[this.props.form_type]
                  : this.variables().saveLabel[this.props.form_type]}
              </button>
            </div>
          )}
        </div>
      )
    );
  }

  renderTittle() {
    const close = (
      <h5 className="d-flex justify-center">
        Você ainda não tem cartões ativos
        {this.props.can_manage && ", crie um agora"}!
      </h5>
    );
    const open = this.props.form_type !== "update" && (
      <h5 className="d-flex justify-between">
        {this.variables().tittle[this.props.form_type]}{" "}
        <i
          test-name="btn-close"
          className="mdi mdi-window-close btn-close"
          onClick={() => this.setState({ showForm: false })}
        />
      </h5>
    );

    if (this.props.hasCards) {
      return this.state.showForm && open;
    } else {
      return this.state.showForm ? open : close;
    }
  }

  onSelectUser(user) {
    this.setState({
      openModal: false,
      company_user_id: user.id,
      company_user_name: user.name,
      company_user_email: user.email,
      company_user_avatar: user.avatar,
    });
  }

  renderForm() {
    return (
      <React.Fragment>
        <UserMerchantModal
          edit={this.state.id !== ""}
          open={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
          onSelect={(user) => this.onSelectUser(user)}
        />

        <form
          className={classNames(
            "form-design-on-surfaces d-flex justify-between flex-wrap",
            { "margin-top-zero": this.props.form_type === "update" }
          )}
        >
          <div className="full-width">
            <h5
              className={classNames("tittle", {
                "margin-top-zero": this.props.form_type === "update",
              })}
            >
              {this.variables().description[this.props.form_type]}
            </h5>
            {this.props.form_type == "create" && (
              <p>Como você quer chamar esse cartão?</p>
            )}
          </div>
          <MaskedInput
            error={this.state.description.length === 0}
            forceError={
              this.state.description.length === 0 && this.state.tryToSave
            }
            test={"card-description"}
            size={40}
            placeholder={"Escreve aqui o nome do cartão"}
            label={"Nome do cartão"}
            value={this.state.description}
            onChange={(e) => this.setState({ description: e.target.value })}
          />

          <div className="full-width">
            <h5>{this.variables().associateUser[this.props.form_type]}</h5>
            <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
              {!this.state.company_user_id || this.state.company_user_id === ""
                ? "Selecione o usuário responsável pela prestação de contas das compras realizadas neste cartão."
                : "Você tem um usuário associado a este cartão para prestação de contas"}
            </p>
          </div>
          <div style={{ marginBottom: "24px" }}>
            {!this.state.company_user_id ||
            this.state.company_user_id === "" ? (
              <button
                className={classNames("btn", {
                  "modal-close": this.state.id !== "",
                })}
                style={{ backgroundColor: "#3D0079" }}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ openModal: true });
                }}
              >
                Associar usuário
              </button>
            ) : (
              <div
                className="d-flex align-center justify-between"
                style={{
                  padding: "8px 16px",
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                }}
              >
                <div className="d-flex align-center">
                  <img
                    className="avatar d-flex-justify-center align-center circle"
                    style={{ width: "40px" }}
                    src={this.state.company_user_avatar}
                  />
                  <div style={{ margin: "0 16px" }}>
                    <p style={{ margin: "0" }}>
                      {this.state.company_user_name}
                    </p>
                    <p style={{ margin: "0" }}>
                      {this.state.company_user_email}
                    </p>
                  </div>
                </div>
                <CloseIcon
                  sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.56)" }}
                  onClick={() => this.setState({ company_user_id: "" })}
                />
              </div>
            )}
          </div>

          <div className="full-width">
            <h5>{this.variables().depositInterval[this.props.form_type]}</h5>
          </div>
          <div className="radio-group d-flex flex-wrap full-width">
            {this.renderRadioButton(
              "daily",
              "deposit-interval-daily",
              "depositInterval",
              1,
              this.intervalChecked(1),
              this.intervalError(),
              "Diária, às 07:00"
            )}
            {this.renderRadioButton(
              "weekly",
              "deposit-interval-weekly",
              "depositInterval",
              2,
              this.intervalChecked(2),
              this.intervalError(),
              "Semanal - Segunda-feira, às 07:00"
            )}
            {this.renderRadioButton(
              "monthly",
              "deposit-interval-monthly",
              "depositInterval",
              3,
              this.intervalChecked(3),
              this.intervalError(),
              "Mensal - 1º dia do mês, às 07:00"
            )}
            {this.renderRadioButton(
              "unique",
              "deposit-interval-unique",
              "depositInterval",
              0,
              this.intervalChecked(0),
              this.intervalError(),
              "Alocação única"
            )}
          </div>

          <div className="full-width">
            <h5 className="tittle">
              {this.variables().depositValue[this.props.form_type]}
            </h5>
            <p>
              Saldo disponível na conta digital:{" "}
              <b className="balance-account">{this.props.balance_account}</b>
            </p>
          </div>
          <MaskedInput
            error={this.valueError()}
            forceError={this.valueError() && this.state.tryToSave}
            test={"deposit-value"}
            size={40}
            placeholder={"R$ 0,00"}
            label={"Saldo programado"}
            value={this.state.depositValue}
            onChange={(e) =>
              this.setState({
                depositValue: parserValueWithBrlCurrency(e.target.value),
              })
            }
          />
          <div className="radio-group d-flex flex-wrap full-width">
            {this.renderRadioButton(
              "now",
              "momento-to-allocate-now",
              "momentToAllocate",
              "now",
              this.momentToAllocateChecked("now"),
              this.moment_error(),
              "Alocar saldo agora"
            )}
            {this.renderRadioButton(
              "schedule",
              "momento-to-allocate-schedule",
              "momentToAllocate",
              "schedule",
              this.momentToAllocateChecked("schedule"),
              this.moment_error(),
              "Alocar saldo conforme recorrência",
              this.state.depositInterval === 0
            )}
          </div>
        </form>
      </React.Fragment>
    );
  }

  renderRadioButton(id, test, propety, value, checked, error, label, disabled) {
    return (
      <label
        className={classNames({
          checked,
          error,
          disabled,
        })}
      >
        <input
          test-name={test}
          type="radio"
          id={id}
          name={propety}
          onClick={() => this.handleChecked(propety, value)}
        />
        <span htmlFor={id}>{label}</span>
      </label>
    );
  }

  // Functions

  variables() {
    return {
      tittle: {
        create: "Siga os passos abaixo para ativar o cartão virtual",
        unblock:
          "Para desbloquear o cartão redefina a recorrência de alocação e o saldo a ser alocado",
      },
      description: {
        create: "1. Dê um nome para o seu cartão virtual",
        update: "Altere o nome do cartão",
        unblock: "1. Altere o nome do cartão virtual",
      },
      associateUser: {
        create: "2. Associar usuário",
        update: "Associar usuário",
        unblock: "2. Associar usuário",
      },
      depositInterval: {
        create: "3. Recorrência da alocação de saldo",
        update: "Defina a recorrência da alocação de saldo",
        unblock: "3. Recorrência de alocação de saldo",
      },
      depositValue: {
        create: "4. Para finalizar, coloque saldo no seu cartão",
        update: "Para finalizar, coloque saldo no seu cartão",
        unblock: "4. Valor do saldo de alocação",
      },
      endpoint: {
        create: this.props.endpoints.create,
        update: this.props.endpoints.edit?.replace(
          "sub_account_id",
          this.state.id
        ),
        unblock: this.props.endpoints.unblock?.replace(
          "sub_account_id",
          this.state.id
        ),
      },
      method: { create: "POST", update: "PUT", unblock: "POST" },
      saveLabel: {
        create: "+ ativar novo cartão",
        update: "salvar alterações",
        unblock: "desbloquear cartão",
      },
      saving: {
        create: "Ativando...",
        update: "Salvando...",
        unblock: "Desbloqueando...",
      },
      successSave: {
        create: "Ativado com sucesso",
        update: "Editado com sucesso",
        unblock: "Desbloqueado com sucesso",
      },
      failSave: {
        create: "Não foi possivel ativar, tente novamente!",
        update: "Não foi possivel editar, tente novamente!",
        unblock: "Não foi possivel desbloquear, tente novamente!",
      },
    };
  }

  parserValue(value) {
    return Number(
      value.replace("R$", "").replace(/\./gm, "").replace(",", ".")
    );
  }

  valueError() {
    return (
      this.parserValue(this.state.depositValue) >
        this.parserValue(this.props.balance_account) &&
      this.state.momentToAllocate === "now"
    );
  }

  can_save() {
    return (
      this.state.description.length > 0 &&
      this.state.depositInterval !== -1 &&
      this.state.momentToAllocate !== "" &&
      !this.valueError()
    );
  }

  handleSave() {
    if (this.can_save()) {
      this.setState({ tryToSave: true, saving: true });

      $.ajax({
        type: this.variables().method[this.props.form_type],
        url: this.variables().endpoint[this.props.form_type],
        data: {
          ...this.state,
          moment_to_allocate: this.state.momentToAllocate,
          deposit_interval: this.state.depositInterval,
          deposit_value: this.parserValue(this.state.depositValue),
        },
      })
        .then((r) => {
          showSnackbar({
            content: `${this.variables().successSave[this.props.form_type]}${
              this.state.momentToAllocate === "now"
                ? ", o saldo será transferido automaticamente em breve"
                : ""
            }!`,
            style: "notice",
          });

          window.location.reload(false);
        })
        .catch((e) => {
          showSnackbar({
            content: this.variables().failSave[this.props.form_type],
            style: "alert",
          });

          this.setState({ saving: false });
        });
    } else {
      this.setState({ tryToSave: true });
    }
  }

  handleChecked(propety, value) {
    if (propety === "momentToAllocate" && this.state.depositInterval === 0) {
      return;
    } else if (this.state[propety] === value) {
      this.setState({ [propety]: -1 });
    } else {
      this.setState({
        momentToAllocate:
          propety === "depositInterval" && value === 0
            ? "now"
            : this.state.momentToAllocate,
        ...{ [propety]: value },
      });
    }
  }
  intervalChecked(interval) {
    return this.state.depositInterval === interval;
  }
  intervalError() {
    return this.state.depositInterval === -1 && this.state.tryToSave;
  }

  momentToAllocateChecked(moment) {
    if (this.state.depositInterval === 0) {
      return moment === "now";
    } else {
      return this.state.momentToAllocate === moment;
    }
  }
  moment_error() {
    return this.state.momentToAllocate === "" && this.state.tryToSave;
  }
}

Subscription.defaultProps = {
  hasCards: false,
  balance_account: "",
  endpoints: { create: "", edit: "", unblock: "" },
  form_type: "create",
  data: null,
  can_manage: true,
  showList: () => {},
  collectionItem: false,
  account_active: true,
};

export default Subscription;
