import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import * as React from "react";
import PropTypes from "prop-types";

export const EspressoTableHead = (props) => {
    const {
        selectable,
        onSelectAll,
        selectedCount,
        selectAll,
        totalRows,
        columns,
        loading
    } = props;

    return (
        <TableHead data-testid={'table-head'}>
            <TableRow>
                {selectable && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            data-testid={`table-head-check`}
                            disabled={loading}
                            color="primary"
                            indeterminate={selectedCount > 0 && selectedCount < totalRows}
                            checked={selectAll && selectedCount === 0 || !selectAll && selectedCount === totalRows}
                            onChange={onSelectAll}/>
                    </TableCell>
                )}
                {[...columns.map((column) => (
                    <TableCell key={column.id} align={column.align} padding={column.padding}>
                        {column.label}
                    </TableCell>
                ))]}
            </TableRow>
        </TableHead>
    );
}

EspressoTableHead.propTypes = {
    selectable: PropTypes.bool,
    onSelectAll: PropTypes.func,
    selectedCount: PropTypes.number,
    selectAll: PropTypes.bool,
    totalRows: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool
}