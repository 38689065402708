import {Grid, Typography} from "@mui/material";
import {ErpConnection} from "../ErpConnection";
import React, {useMemo, useState} from "react";
import {CredentialField} from "../CredentialField";
import {updateConnection} from "../../erp_integration_connector";
import {SankhyaConnectionSteps} from "./SankhyaConnectionSteps";

export const SankhyaConnection = ({integration}) => {
    const initialToken = useMemo(() => integration?.maskedCredentials?.token ?? '', [integration])
    const [token, setToken] = useState(initialToken)

    const validCredential = useMemo(() => {
        const regex = /^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/;
        return regex.test(token)
    }, [token]);

    return <ErpConnection
        erp={'sankhya'}
        integration={integration}
        videoTitle={'Veja no vídeo abaixo o passo a passo completo de como obter o Token do ERP para integrar com nossa plataforma:'}
        videoId={'0iivj-S3Q4Q'}
        steps={<SankhyaConnectionSteps/>}
        info={'Lembre-se que, para utilização dessa conexão com o ERP é necessário ser usuário SUP e possuir a versão 4.16 ou superior do sistema Sankhya Om.'}
        testConnection={() => updateConnection('sankhya', {token})}
        validCredential={validCredential}
        form={(loading, failed) =>
            <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                    <Typography variant={'h6'}>
                        Inserir “Token de integração” e clicar em “Testar conexão”.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CredentialField
                        initialValue={initialToken}
                        disabled={loading}
                        onChange={(value) => setToken(value)}
                        label={'Token de integração'}
                        placeholder={'Insira o Token gerado no sistema Sankhya aqui'}
                        error={failed ? 'Revise o código informado e tente novamente' : ''}/>
                </Grid>
            </Grid>
        }/>
}

