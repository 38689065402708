import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import slide from '../../../../../../../app/assets/images/admin/account/account_opening/slide-4.webp';
import ButtonAccountOpening from '../ButtonAccountOpening';

const FourthStep = ({ textButton, isPlanFree }) => {
  return (
    <Container maxWidth="lg" sx={{
      marginTop: { lg: 6, md: 0, xs: 0 }
    }}>
      <Grid container spacing={2} sx={{
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <Grid item xs={12} lg={6} sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: { xs: 'center', lg: 'left' }
        }}>
          <Box p={2} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', lg: 'flex-start' },
            maxWidth: { xs: '268px', sm: '395px', md: '521px', lg: '475px' }
          }}>
            <Typography variant="h3" gutterBottom fontWeight={900} color="#3D0079" sx={{
              fontSize: { xs: '24px', sm: '32px', md: '36px', lg: '40px' },
              textAlign: { xs: 'center', lg: 'left' },
              maxWidth: { xs: '212px', sm: '389px', md: '515px', lg: '475px' }
            }}>
              Seu colaborador não precisa mais pagar as despesas do próprio bolso
            </Typography>
            <Typography sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px', lg: '20px' },
              fontWeight: 400,
              lineHeight: '35.2px',
              color: '#000000DE',
              textAlign: { xs: 'center', lg: 'left' },
              maxWidth: { xs: '260px', sm: '368px', md: '470px', lg: '410px' }
            }}>
              Elimine o uso de cartões pessoais ou
              compartilhados para pagar as despesas que são
              da empresa, acabe com os reembolsos e ganhe
              um processo automatizado de ponta a ponta.
            </Typography>
            <ButtonAccountOpening text={textButton} isPlanFree={isPlanFree} marginTop={3} />
          </Box>
        </Grid>
        <Grid item lg={6} sx={{ 
            display: { lg: 'flex', xs: 'none' },
            justifyContent: 'center', 
            alignItems: 'center',
        }}>
          <Box sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={slide} alt="Descrição da imagem" style={{ maxWidth: '100%', height: 'auto', objectFit: 'contain' }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FourthStep;
