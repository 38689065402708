import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import slide from '../../../../../../../app/assets/images/admin/account/account_opening/slide-1.webp';
import ButtonAccountOpening from '../ButtonAccountOpening';

const FirstStep = ({ textButton, isPlanFree }) => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{ 
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <Grid item xs={12} lg={5} sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: { xs: 'center', lg: 'left' },
        }}>
          <Box p={2} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: { xs: 'center', lg: 'flex-start' },
            alignItems: { xs: 'center', lg: 'flex-start' },
            maxWidth: { xs: '209px', sm: '341px', md: '414px', lg: '414px' }
          }}>
            <Typography variant="h4" sx={{
              fontSize: { xs: '24px', sm: '32px', md: '36px', lg: '40px' },
              fontWeight: 900,
              lineHeight: { xs: '30px', lg: '46px' },
              color: '#3D0079',
              letterSpacing: '0.15px',
              mb: 2,
              maxWidth: { xs: '209px', sm: '277px', md: '346px', lg: '346px' },
              textAlign: { xs: 'center', lg: 'left' },
            }}>
              Conta Digital Espresso gratuita, simples e completa
            </Typography>
            <Typography sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px', lg: '20px' },
              fontWeight: 400,
              lineHeight: '35.2px',
              color: '#000000DE',
              letterSpacing: '0.15px',
              mb: 2,
              maxWidth: { xs: '179px', sm: '341px', md: '397px', lg: '410px' },
              textAlign: { xs: 'center', lg: 'left' },
            }}>
              Simplifique a prestação de contas eliminando os adiantamentos em dinheiro e as inúmeras solicitações de reembolso.
            </Typography>
            <ButtonAccountOpening text={textButton} isPlanFree={isPlanFree} marginTop={2} />
          </Box>
        </Grid>
        <Grid item lg={7} sx={{ 
          display: { lg: 'flex', xs: 'none' },
          justifyContent: 'center', 
          alignItems: 'center',
        }}>
          <Box sx={{ p: 2, maxWidth: '100%', display: 'flex', justifyContent: 'center', maxHeight: {xl: '473px', lg: '410px'} }}>
            <img src={slide} alt="Descrição da imagem" style={{
              maxWidth: '100%',
              height: 'auto',
              objectFit: 'contain',
            }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FirstStep;
