import React from "react";
import classNames from "classnames";
import BaristaIAStars from "../../../../assets/images/admin/barista_ai/barista_ai_stars.svg"

class FeatureItem extends React.Component {

    get featureAvailable() {
        return this.props.feature.attributes.available || this.props.ignoreAvailability
    }

    get canActivateFeature() {
        return this.featureAvailable && this.props.feature.attributes.feature_satisfied
    }

    get featureDisabled() {
        return !(this.props.feature.attributes.active || this.canActivateFeature)
    }

    get showLockedTooltip() {
        return !(this.props.feature.attributes.active || this.featureAvailable)
    }

    get showPlanChip() {
        return !this.props.feature.attributes.available && !(this.props.hidePlanWhenActive && this.props.feature.attributes.active)
    }

    render() {
        return (<li id={this.props.feature.attributes.name} className="collection-item">
            <div className="row valign-wrapper no-margin-bottom">
                {this.props.baristaIA && <BaristaIAStars style={{marginRight: '10px'}} />}
                <h5 className={classNames({
                    "disabled": this.featureDisabled,
                    "dark-purple-text": this.props.baristaIA
                })}>
                    {this.props.feature.attributes.title}
                    {this.props.feature.attributes.help_link && this.renderHelpLink(this.props.feature)}
                </h5>
                {this.renderInformation(this.props.feature.attributes.information)}
                {this.showLockedTooltip && this.renderUnavailableTooltip()}
                {this.props.feature.attributes.new_feature && <span className="new-badge-pink">NOVO</span>}
            </div>
            <div
                id={"subtitle-container"}
                className={classNames("row no-margin-bottom", {"disabled": this.featureDisabled})}>
                {this.renderDescription(this.props.feature)}
                {this.renderSwitch(this.props.feature)}
            </div>
            <div className="row s2 no-margin-bottom">
                {this.showPlanChip && this.renderPlanChip(this.props.feature)}
                {this.showLockedTooltip}
            </div>
            <div className="clearfix"/>
        </li>)
    }

    renderHelpLink(feature) {
        return <a href={feature.attributes.help_link}
                  className="tooltipped"
                  target={'_blank'}
                  data-position={'right'}
                  data-tooltip={'Saiba mais'}>
            <i className="mdi mdi-help-circle-outline"/>
        </a>
    }

    renderUnavailableTooltip() {
        return <div
            className="col mdi mdi-lock tooltipped"
            data-position={'right'}
            data-tooltip={'Essa funcionalidade não está disponível no seu plano atual =/'}/>;
    }

    renderInformation(information) {
        if (information) {
            return <div
                className="tooltipped"
                style={{padding: "4px"}}
                data-position={'top'}
                data-tooltip={information}>
                    <i className="mdi mdi-information-outline"
                       style={{lineHeight: "unset"}} />
            </div>;
        }
    }

    renderDescription(feature) {
        if (this.props.customDescription) {
            return this.props.customDescription(feature)
        }
        return <div className="col s12 m9 sub-header no-left-padding">
            {feature.attributes.subtitle}
        </div>
    }

    renderSwitch(feature) {
        return <div className="col s12 m3">
            <div className="row right valign-wrapper no-margin-bottom">
                <div className={classNames("switch-label", {"active-switch": feature.attributes.active})}>
                    {feature.attributes.status_name}
                </div>
                <div className="switch js-distance-source-switch">
                    <label>
                        <input type="checkbox"
                               checked={feature.attributes.active}
                               disabled={this.featureDisabled}
                               onChange={(event) => {
                                   this.props.onChangeSwitch(feature, event.target.checked)
                               }}/>
                        <span className="lever"/>
                    </label>
                </div>
            </div>
        </div>
    }

    renderPlanChip(feature) {
        const plan = feature.attributes.plan_available
        return plan && <span className="rect-chip">{plan.name}</span>
    }
}

FeatureItem.defaultProps = {
    feature: {},
    ignoreAvailability: false,
    hidePlanWhenActive: true,
    baristaIA: false
};

export default FeatureItem;