import {
    AppBar,
    Box,
    IconButton,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Typography
} from "@mui/material";
import React from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import OptionsChipButton from "../../shared/OptionsChipButton";

export const PageNavbar = ({title, showBackButton = true, backTo, options}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const openMenu = (event) => setAnchorEl(event.currentTarget);

    const closeMenu = () => setAnchorEl(null);

    return <AppBar
        position={'static'}
        sx={{backgroundColor: '#F0EBF5', boxShadow: 'none'}}>
        <Toolbar variant={'dense'}>
            {showBackButton && <IconButton
                edge="start"
                size="small"
                sx={{mr: 3}}
                href={backTo}
                onClick={backTo ? null : () => history.back()}>
                <ArrowBackIcon/>
            </IconButton>}
            <Typography sx={{color: 'black', fontSize: '18px'}}>{title}</Typography>
            <Box sx={{flexGrow: 1}}/>
            {options && <OptionsChipButton onClick={openMenu}/>}
            {options && <Menu
                keepMounted
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={closeMenu}>
                {options.map((option, idx) =>
                    <PageNavbarMenuItem idx={idx} key={idx} option={option} onClose={closeMenu}/>
                )}
            </Menu>}
        </Toolbar>
    </AppBar>
}

export const PageNavbarMenuItem = ({idx, option, onClose}) => {
    const onClick = () => {
        onClose()
        option.onClick()
    }

    return <MenuItem
        data-testid={`menu-item-${idx}`}
        component={'button'}
        onClick={option.onClick && onClick}
        href={option.href}
        disabled={option.disabled}>
        <ListItemText primary={option.label}/>
    </MenuItem>
}