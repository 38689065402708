import React from "react";

import { showSnackbar } from "../../../shared/jquery_wrapper";
import FeatureGroup from "../FeatureGroup";

class ExpensePolicyFeatureGroup extends React.Component {
  constructor(props) {
    super(props);
  }

  onUpdate = (feature, activate, error) => {
    let content_text = '';
    let state = activate == true ? 'ativado' : 'desativado';
    
    if (error) {
      showSnackbar({
        content: "Não foi possível ativar o alerta.",
        style: "alert",
        timeout: 5000,
      });
    } else {
      switch (feature.attributes.name) {
        case 'alcohol_alert':
          content_text = `Palavra proibitiva para bebida alcoólica ${state}!`;
          break;
        case 'tobacco_alert':
          content_text = `Palavra proibitiva para tabaco e derivados ${state}!`;
          break;
        default:
          content_text = `Antifraude ${state}!`
      }
    };
    
    showSnackbar({
      content: content_text,
      style: "alert",
      timeout: 3000,
    });
  };

  render() {
    return (
      <FeatureGroup
        groupName={"Politica de Despesa"}
        featuresEndpoint={this.props.featuresEndpoint}
        featureGroup={this.props.featureGroup}
        ref={this.group}
        onUpdate={(feature, activate, error) => this.onUpdate(feature, activate, error)}
        ignoreAvailability={this.props.ignoreAvailability}
        hidePlanWhenActive={this.props.hidePlanWhenActive}
      />
    );
  }
}

ExpensePolicyFeatureGroup.defaultProps = {
  featuresEndpoint: "",
  featureGroup: 3,
  ignoreAvailability: false,
  hidePlanWhenActive: true,
};

export default ExpensePolicyFeatureGroup;
