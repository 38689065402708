import React from "react";

import Filter from "../shared/Filter";
import AvatarAndDetail from "../shared/lists/AvatarAndDetail";
import Loading from "../shared/Loading";
import SelectTab from "../shared/SelectTab";
import Pagination from "../shared/Pagination";
import filters from "./filters";

class StatementWithFilters extends React.Component {
  state = {
    showFilter: false,
    loading: false,
    subAccountActivities: [],
    totalUnproven: 0,
    forcePageOne: false,
    selectTab: "transactions",
    search: "",
    period: "",
    transaction_types: [],
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
      forcePageOne: !this.state.forcePageOne,
    });
  }

  render() {
    return (
      <React.Fragment>
        <SelectTab
          indicator={false}
          disabled={this.state.transaction_types.length > 0}
          list={this.listTab()}
          customList={[
            <div style={{ position: "absolute", right: 0, bottom: 0 }}>
              <div
                test-name="show-filters"
                className="btn-flat"
                onClick={() =>
                  this.setState({
                    ...this.state,
                    showFilter: !this.state.showFilter,
                  })
                }
              >
                <i className="mdi mdi-filter-variant left" />
                <span className="hide-on-med-and-down">
                  FILTRAR VISUALIZAÇÃO
                </span>
              </div>
            </div>,
          ]}
        />
        <div
          test-name="div-show-filter"
          style={{ display: `${this.state.showFilter ? "block" : "none"}` }}
        >
          <Filter
            searchPlaceholder={"Busque por estabelecimento ou valor"}
            filters={filters}
            onFilter={(filters) =>
              this.setState({
                ...this.state,
                loading: true,
                forcePageOne: !this.state.forcePageOne,
                transaction_types: filters.selects?.transaction_types || [],
                ...filters,
              })
            }
          />
        </div>
        {this.state.selectTab === "transactions" && this.renderTotalUnproven()}
        <Loading show={this.state.loading} />
        <AvatarAndDetail
          hasTableheader
          hasUser={
            this.state.selectTab === "transfers" ||
            this.state.selectTab === "activities"
          }
          list={this.state.subAccountActivities.map((e) => ({
            ...e,
            title: e.name,
            description: e.cost,
            tag: e.label,
            color_tag: e.color_label?.color_label,
            b_color_tag: e.color_label?.b_color_label,
            secondary: e.date,
          }))}
        />

        <Pagination
          forcePageOne={this.state.forcePageOne}
          url={this.props.endpoint}
          setExternalList={(list, others) =>
            this.setState({
              ...this.state,
              loading: false,
              subAccountActivities: list,
              totalUnproven: others.total_unproven,
            })
          }
          params={{
            q: {
              scopes: this.state.transaction_types.length === 0 && [
                this.state.selectTab,
              ],
              store_or_value: this.state.search,
              period: this.state.period,
              transaction_types: this.state.transaction_types,
            },
          }}
        />
      </React.Fragment>
    );
  }

  renderTotalUnproven() {
    return (
      <div
        test-name="total-unproven-purchase"
        style={{
          textAlign: "end",
          fontSize: "10px",
          textTransform: "uppercase",
        }}
      >
        {this.state.totalUnproven > 1
          ? this.state.totalUnproven + " despesas não comprovadas"
          : this.state.totalUnproven + " despesa não comprovada"}
      </div>
    );
  }

  handleTab(tab) {
    this.setState({
      ...this.state,
      selectTab: tab,
      loading: true,
      forcePageOne: !this.state.forcePageOne,
    });
  }

  checkTabActive(tab) {
    if (
      this.state.transaction_types.length === 0 &&
      this.state.selectTab === tab
    ) {
      return true;
    }

    let active = false;

    this.state.transaction_types.forEach((type) => {
      if (!active) {
        active = filters.transaction_types.list
          .find((tt) => tt.id === type)
          .tabs.includes(tab);
      }
    });

    return active;
  }

  listTab() {
    let list = [
      {
        testName: "transactions",
        href: "#transactions",
        label: "TRANSAÇÕES",
        onClick: () => {
          this.handleTab("transactions");
        },
        active: this.checkTabActive("transactions"),
      },
      {
        testName: "incomes",
        href: "#incomes",
        label: "RENDIMENTOS",
        onClick: () => {
          this.handleTab("incomes");
        },
        active: this.checkTabActive("incomes"),
      },
      {
        testName: "transfers",
        href: "#transfers",
        label: "RECARGAS E RESGATES",
        onClick: () => {
          this.handleTab("transfers");
        },
        active: this.checkTabActive("transfers"),
      },
      {
        testName: "activities",
        href: "#activities",
        label: "ATIVIDADES",
        onClick: () => {
          this.handleTab("activities");
        },
        active: this.checkTabActive("activities"),
      },
    ];

    if (!this.props.show_incomes) {
      list.splice(
        list.findIndex((e) => e.testName === "incomes"),
        1
      );
    }

    return list;
  }
}

StatementWithFilters.defaultProps = {
  endpoint: "",
  show_incomes: true,
};

export default StatementWithFilters;
