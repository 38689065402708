import {ShadowTheme} from "../../ShadowTemplate";
import React from "react";
import {Menu, MenuItem} from "@mui/material";
import CodeSheetDialog from "./SheetDownloadDialog";
import {hot} from "react-hot-loader";
import OptionsChipButton from "../../../shared/OptionsChipButton";

const IntegrationsDropdownShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <IntegrationsDropdown {...props}/>
    </ShadowTheme>;
};

const IntegrationsDropdown = ({clientId}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const dropdownOpen = Boolean(anchorEl);
    const onClickButton = (event) => setAnchorEl(event.currentTarget);
    const onCloseDropdown = () => setAnchorEl(null);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleDialogOpen = () => {
        onCloseDropdown();
        setDialogOpen(true);
    }
    const handleDialogClose = () => setDialogOpen(false);

    return <>
        <OptionsChipButton onClick={onClickButton}/>
        <Menu
            anchorEl={anchorEl}
            open={dropdownOpen}
            onClose={onCloseDropdown}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}>
            <MenuItem onClick={handleDialogOpen}>Planilha de Códigos do ERP</MenuItem>
        </Menu>
        <CodeSheetDialog open={dialogOpen} onClose={handleDialogClose} clientId={clientId}/>
    </>
}

export default hot(module)(IntegrationsDropdownShadow)