import React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { openModal } from '../../../shared/jquery_wrapper';
import { admin_rd_leads_path } from "../../../../routes";

const HandRaising = ({isPlanFree, userId, companyId}) => {
  const identify = () => {
    return isPlanFree ? 'upsell_plano_free' : 'contadigitalbase';
  };

  const rdCall = () => {
    $.ajax({
      type: "POST",
      dataType: "json",
      url: admin_rd_leads_path(),
      data: {
        user_id: userId,
        company_id: companyId,
        identify: identify()
      },
      complete: function (xhr) {
        if (xhr.status === 200) {
          openModal('#success-account-opening');
        } else {
          openModal('#failed');
        }
      }
    })
  };

  return (
    <Card sx={{ backgroundColor: '#F0EBF5', width: '100%', marginTop: '20px' }}>
      <CardContent sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: { lg: '320px', md: '320px', sm: '230px', xs: '209px' },
      }}>
        <Typography variant="h4" sx={{
          fontSize: { xs: '20px', sm: '28px', md: '40px', lg: '40px' },
          fontWeight: 600,
          lineHeight: { xs: '30px', lg: '46px' },
          color: '#3D0079',
          letterSpacing: '0.15px',
          mb: 2,
          maxWidth: { xs: '286px', sm: '402px', md: '587px', lg: '560px' },
          textAlign: 'center',
        }}>
          Quer conhecer a Conta Digital?
          Agende um papo com o nosso time e tire suas dúvidas.
        </Typography>

        <Box sx={{ mt: 3, justifyContent: 'center', display: 'flex' }}>
        <Button onClick={rdCall} variant="contained" 
          sx={{
            backgroundImage: 'linear-gradient(to right, #0F001E, #3D0079)',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '15px',
            lineHeight: '26px',
            letterSpacing: '0.46px',
          }}
        >
          <SupportAgentIcon sx={{ mr: 1 }} />
          Agendar
        </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default HandRaising;
