import React from "react";
import SearchField from "./SearchField";

class QuickSearch extends React.Component {
  render() {
    const { value, label, placeholder, onChange, onKeyDown } = this.props;

    return(
      <div className="quick-search-container">
        <span className="title">{label}</span>
        <div className="form-design-on-surfaces">
          <SearchField
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
    );
  }
}

QuickSearch.defaultProps = {
  placeholder: "Buscar",
  label: "Busca rápida",
  value: "",
  onChange: Function,
  onKeyDown: Function
};

export default QuickSearch;
