import React, { useReducer, useState } from "react";
import { ShadowTheme } from "../ShadowTemplate";
import { hot } from "react-hot-loader";
import { Button, Card, CardActions, CardContent, CardHeader, FormControl, Grid, TextField, Typography } from "@mui/material";
import { parserValueWithBrlCurrency } from "../../shared/helpers";
import { BudgetFrequency, BudgetableType, CreateBudgetDto, budgetableTypeName, createBudget } from "./budgets_service";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SelectBudgetableModal from "./SelectBudgetableModal";
import BudgetableField from "./BudgetableField";
import { parseAmount } from "../../shared/balance_service";
import { showSnackbar } from "../../shared/jquery_wrapper";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

const CreateBudgetShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <CreateBudget csrfToken={props.csrfToken}/>
  </ShadowTheme>
}

const CreateBudget = ({ csrfToken }) => {
  const Inputs = {
    budgetableId: 'budgetableId',
    budgetableType: 'budgetableType',
    frequency: 'frequency',
    startDate: 'startDate',
    endDate: 'endDate',
    limit: 'limit',
    name: 'name',
  }

  const initialState = {
    [Inputs.budgetableId]: 0,
    [Inputs.budgetableType]: BudgetableType.project,
    [Inputs.frequency]: BudgetFrequency.fixed,
    [Inputs.startDate]: '',
    [Inputs.endDate]: '',
    [Inputs.limit]: '',
    [Inputs.name]: '',
  }

  const reducer = (state, action) => {
    return { ...state, [action.type]: action.value };
  }

  const dispatchValueOn = (value, input) => {
    dispatch({ type: input, value: value });
  }

  const handleLimit = (event) => {
    dispatchValueOn(parserValueWithBrlCurrency(event.target.value), Inputs.limit);
  }

  const handleStartDate = (value) => {
    dispatchValueOn(dayjs(value).format('YYYY-MM-DD'), Inputs.startDate);
  }

  const handleEndDate = (value) => {
    dispatchValueOn(dayjs(value).format('YYYY-MM-DD'), Inputs.endDate);
  }

  const minEndDate = dayjs();

  const validateDateRange = () => {
    const endDate = dayjs(state.endDate);
    const startDate = dayjs(state.startDate);
    const valid = !endDate.isBefore(minEndDate, 'day') && endDate.isAfter(startDate, 'day');
    if (!valid) {
      showSnackbar({ content: dateRangeError(), style: "notice" });
    }
    return valid;
  }

  const dateRangeError = () => {
    const endDate = dayjs(state.endDate);
    if (endDate.isBefore(minEndDate, 'day')) {
      return 'A data do fim não pode ser retroativa';
    }
    return 'A data do fim não pode anteceder a data de início';
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  }

  const handleCloseModal = () => {
    setOpenModal(false);
  }

  const handleSelected = (selected) => {
    dispatchValueOn(selected.id, Inputs.budgetableId);
    dispatchValueOn(selected.name, Inputs.name);
    handleCloseModal();
  }

  const validateBudgetable = () => {
    return state.budgetableId !== 0;
  }

  const validateLimit = () => {
    return parseAmount(state.limit) !== 0;
  }

  const validateDate = (date) => {
    return dayjs(date).isValid();
  }

  const validateFields = () => {
    setValidating(true);
    return validateBudgetable() && validateLimit() &&
           validateDate(state.startDate) && validateDate(state.endDate) && validateDateRange();
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateFields()) {
      setLoading(true);
      const dto = new CreateBudgetDto({...state});
      await createBudget(
        csrfToken,
        dto,
        () => setLoading(false),
      );
    }
  }
  
  const [state, dispatch] = useReducer(reducer, initialState);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return <>
    <SelectBudgetableModal
      open={openModal}
      onSelect={handleSelected}
      onClose={handleCloseModal}
      type={state.budgetableType}
    />
    <Card sx={{ mx: smallScreen ? "1rem" : "10rem", p: 1, mb: 6 }}>
      <CardHeader
        title="Criar orçamento"
        titleTypographyProps={{ variant: "h5" }}
      />
      <form onSubmit={handleSubmit}>
        <CardContent>
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Typography>
                {`Busque um ${budgetableTypeName(state.budgetableType)} para o seu orçamento`}
              </Typography>
            </Grid>
            <Grid item xs={ smallScreen ? 12 : 6}>
              <BudgetableField
                disabled={loading}
                onClick={handleOpenModal}
                value={state.name}
                placeholder={`Busque pelo ${budgetableTypeName(state.budgetableType)}`}
                error={validating && !validateBudgetable()}
                helperText={
                  validating && !validateBudgetable() &&
                  `Selecione um ${budgetableTypeName(state.budgetableType)} para o orçamento`
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography>Defina o período</Typography>
            </Grid>
            <Grid item xs={ smallScreen ? 12 : 6 }>
              <FormControl fullWidth>
                <LocalizationProvider
                  adapterLocale="pt-br"
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    disableHighlightToday
                    label="Data início"
                    format={'DD/MM/YYYY'}
                    value={dayjs(state.startDate)}
                    onChange={handleStartDate}
                    slots={{ openPickerIcon: ArrowDropDownIcon }}
                    disabled={loading}
                    data-testid={"start-field"}
                    slotProps={{
                      textField: {
                        error: validating && !validateDate(state.startDate),
                        helperText: validating && !validateDate(state.startDate) &&
                                    "Defina a data do início do orçamento"
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={ smallScreen ? 12 : 6 }>
              <FormControl fullWidth>
                <LocalizationProvider
                  adapterLocale="pt-br"
                  dateAdapter={AdapterDayjs}
                >
                  <DatePicker
                    disableHighlightToday
                    minDate={minEndDate}
                    label="Data fim"
                    format={'DD/MM/YYYY'}
                    value={dayjs(state.endDate)}
                    onChange={handleEndDate}
                    slots={{ openPickerIcon: ArrowDropDownIcon }}
                    disabled={loading}
                    data-testid={"end-field"}
                    slotProps={{
                      textField: {
                        error: validating && !validateDate(state.endDate),
                        helperText: validating && !validateDate(state.endDate) &&
                                    "Defina a data do fim do orçamento"
                      }
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography>Defina o valor orçado</Typography>
            </Grid>
            <Grid item xs={ smallScreen ? 12 : 6}>
              <TextField
                fullWidth
                label="Valor (R$)"
                variant="outlined"
                size="medium"
                value={state.limit}
                onChange={handleLimit}
                placeholder="R$ 0,00"
                disabled={loading}
                data-testid={"limit-field"}
                error={validating && !validateLimit()}
                helperText={validating && !validateLimit() && "Defina o valor do orçamento"}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ my: 1 }}>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={loading}
          >
            Salvar
          </Button>
        </CardActions>
      </form>
    </Card>
  </>
}

export default hot(module)(CreateBudgetShadow);