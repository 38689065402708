import React from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Button, Stack, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const OptionsChipButton = ({ onClick }) => {
    const theme = useTheme();

    return (
        <Button
            variant="outlined"
            onClick={onClick}
            aria-label="mais opções"
            sx={{
                px: 1,
                borderRadius: 50,
                textTransform: "none",
                borderColor: "#BDBDBD",
            }}
        >
            {
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                >
                    <Box
                        display={"flex"}
                        sx={{
                            p: 0.1,
                            backgroundColor: theme.palette.surfaceLight12.main,
                            borderRadius: "50%"
                        }}
                    >
                        <MoreVertIcon color="primary"/>
                    </Box>
                    <Typography
                        color={"black"}
                        variant="body2"
                    >
                        Mais opções
                    </Typography>
                </Stack>
            }
        </Button>
    );
}

export default OptionsChipButton;