import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

export const ErpConnectionSteps = ({children}) => {
    return <Accordion
        elevation={0}
        sx={{borderBottom: '1px solid #dddddd', '&:before': {display: 'none'}}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography variant={'body'} sx={{color: '#00000099'}}>
                Clique aqui para ver o passo a passo detalhado por escrito
            </Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
}
