import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React from "react";

const BaristaIcon = ({icon}) => {
  const theme = useTheme();
  const primary = theme.palette.primary.main;
  const secondary = theme.palette.secondary.main;
  return (
    <Box sx={{ p: 0.8, borderRadius: '50%', background: `linear-gradient(${secondary}, ${primary})` }}>
      <Box sx={{ p: 0.5, borderRadius: '50%', bgcolor: 'white', display: 'flex' }}>
        {icon}
      </Box>
    </Box>
  );
}

export default BaristaIcon;