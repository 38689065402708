import React from "react";
import { Button, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import TwoFactorConfigFaqLink from "./TwoFactorConfigFaqLink";
import TwoFactorCopyCodeButton from "./TwoFactorCopyCodeButton";
import { admin_root_path } from "../../../../routes";

const TwoFactorConfigStepThree = ({ backupCodes, mandatory }) => {
  const onFinish = () => {
    if (mandatory) {
      window.location.assign(admin_root_path());
    } else {
      window.location.reload();
    }
  }

  return <>
    <DialogContent>
      <Typography>
        Parabéns, deu tudo certo!
      </Typography>
      <Typography my={2} sx={{ fontWeight: "bold" }}>
        Salve esses códigos para a sua segurança e lembre-se de manter o aplicativo instalado.
      </Typography>
      <Typography sx={{ fontWeight: "bold" }}>
        Códigos reserva:
      </Typography>
      <Typography my={2}>
        Os códigos reserva são como a chave reserva de um automóvel, assim como ela serve para ser utilizada quando a chave principal é perdida os códigos reserva também. Por isso, guarde eles em um lugar seguro que você lembre com facilidade!
      </Typography>
      <Stack alignItems={"flex-start"}>
        {
          backupCodes.map((code, index) => {
            return <TwoFactorCopyCodeButton key={index} value={code}/>
          })
        }
      </Stack>
      <TwoFactorConfigFaqLink/>
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={onFinish}
      >
        Concluir
      </Button>
    </DialogActions>
  </>
}

export default TwoFactorConfigStepThree;