import {Box, IconButton, Tooltip} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React from "react";

export const OnboardingTrigger = ({onClick, top}) => {
    return <Box
        width={36}
        height={36}
        sx={{
            position: 'fixed',
            right: 0,
            top,
            background: 'white',
            borderRadius: '4px 0 0 4px',
            borderWidth: '1px 0 1px 1px',
            borderStyle: 'solid',
            borderColor: 'rgba(217, 169, 255, 1)'
        }}>
        <Tooltip title={'Abrir onboarding'}>
            <IconButton size={'small'} onClick={onClick}>
                <ArrowBackIosNewIcon/>
            </IconButton>
        </Tooltip>
    </Box>
}