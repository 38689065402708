import React from "react";

import { showSnackbar } from "../../shared/jquery_wrapper";
import {
  parserCNPJ,
  parserCPF,
  parser_resume_address,
  parserDate,
  parserMainActivity,
  parserPhone,
  parserHolderType,
} from "../../shared/helpers";

import SelectTab from "../../shared/SelectTab";
import CardInfos from "../../shared/CardInfos";

class Resume extends React.Component {
  state = {
    selectTab: "legal_infos",
    tittles: {
      legal_infos: "Revise os dados da empresa",
      holders: "Revise os dados dos sócios",
    },
    holdersList: [],
    loading: false,
  };

  componentDidMount() {
    this.setState({ ...this.state, holdersList: this.props.holders });
  }

  render() {
    return (
      <div className="card-panel account-new-review">
        <section
          className="banner"
          style={{
            backgroundImage: `url(${
              this.props[`${this.state.selectTab}_img`]
            })`,
          }}
        >
          <h2 className="tittle">{this.state.tittles[this.state.selectTab]}</h2>
        </section>
        <section>
          <SelectTab
            list={[
              {
                testName: "legal_infos",
                onClick: () =>
                  this.setState({ ...this.state, selectTab: "legal_infos" }),
                label: "DADOS DA EMPRESA",
                href: "legal_infos",
              },
              {
                testName: "holders",
                onClick: () =>
                  this.setState({ ...this.state, selectTab: "holders" }),
                label: "DADOS DOS SÓCIOS",
                href: "holders",
              },
            ]}
          />
        </section>
        <section>{this[`render_${this.state.selectTab}`]()}</section>

        <section className="d-flex justify-end button-group">
          <button
            test-name="review-button"
            className="btn review-button"
            disabled={this.state.loading}
            onClick={() =>
              this.props.setStage(
                this.state.selectTab === "legal_infos" ? 2 : 3
              )
            }
          >
            <i className={`mdi mdi-pencil`} />
            Revisar dados
          </button>
          <button
            test-name="open-button"
            className="btn open-button"
            onClick={() => this.handleOpen()}
            disabled={this.state.loading}
          >
            {this.senButtonDescription()}
          </button>
        </section>
      </div>
    );
  }

  render_legal_infos() {
    const legal_infos = this.props.legal_infos;
    const legal_infos_address = this.props.address;

    return (
      <CardInfos
        items={this.parser_juridic_infos(legal_infos, legal_infos_address)}
      />
    );
  }

  render_holders() {
    const physicalHolders = this.state.holdersList.filter(
      (holder) => holder.holder_type === "physical"
    );

    return (
      <React.Fragment>
        {physicalHolders.length > 0 && (
          <React.Fragment>
            <h6 className="holder-tittle">
              Sócios pessoa física (CPF){"  "}
              <label>
                ({physicalHolders.length < 10 && 0}
                {physicalHolders.length} sócio
                {physicalHolders.length > 1 && "s"})
              </label>
            </h6>
            {physicalHolders.map((holder, idx, arr) => (
              <React.Fragment key={idx}>
                <h6 className="holder-number">
                  Sócio {`${idx + 1 < 10 && 0}${idx + 1}`}
                </h6>
                <CardInfos
                  items={this.parser_physical_infos(holder, holder.address)}
                  border={idx + 1 === arr.length}
                />
              </React.Fragment>
            ))}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  parser_juridic_infos(infos, address) {
    return [
      { label: "CNPJ", description: parserCNPJ(infos.cnpj) },
      { label: "Razão social", description: infos.legal_name },
      {
        label: "Data de fundação",
        description: parserDate(infos.founding_date),
      },
      {
        label: "Principal atividade",
        description: parserMainActivity(infos.main_activity),
      },
      { label: "Tipo de empresa", description: infos.business_type },
      { label: "E-mail", description: infos.email },
      { label: "Telefone", description: parserPhone(infos.phone) },
      {
        label: "Endereço",
        description: parser_resume_address(
          address.street,
          address.number,
          address.complement,
          address.postal_code,
          address.neighborhood,
          address.city,
          address.state
        ),
      },
    ];
  }

  parser_physical_infos(infos, address) {
    return [
      { label: "CPF", description: parserCPF(infos.document) },
      { label: "Nome", description: infos.name },
      {
        label: "Data de nascimento",
        description: parserDate(infos.birth_date),
      },
      {
        label: "Tipo de Sócio",
        description: parserHolderType(infos.partner_type),
      },
    ];
  }

  handleOpen() {
    this.setState({ loading: true });

    $.ajax({
      type: this.props.create_account ? "POST" : "PUT",
      url: this.props.endpoint.replace("company_id", this.props.company_id),
    })
      .then((response) => {
        showSnackbar({
          content: `${
            this.props.create_account ? "Abertura" : "Edição"
          } solicitada com sucesso!`,
          style: "notice",
        });

        this.props.edit_redirect_endpoint === ""
          ? window.location.reload(false)
          : window.location.assign(this.props.edit_redirect_endpoint);
      })
      .catch((error) => {
        this.setState({ loading: false });

        showSnackbar({
          content: `Não foi possível solicitar a ${
            this.props.create_account ? "abertura" : "atualização"
          }, tente novamente!`,
          style: "alert",
        });
      });
  }

  senButtonDescription() {
    if (this.props.create_account) {
      return this.state.loading
        ? "Solicitando..."
        : "Solicitar abertura de conta";
    } else {
      return this.state.loading ? "Atualizando..." : "Atualizar dados da conta";
    }
  }
}

Resume.defaultProps = {
  setStage: Function,
  legal_infos: {},
  address: {},
  holders: [],
  endpoint: "",
  create_account: true,
  company_id: "",
  edit_redirect_endpoint: "",
};

export default Resume;
