import {
    Button,
    Card,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Tooltip
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import * as React from "react";
import {useMemo, useState} from "react";
import {BistroTableFilterValueForm} from "./EspressoTableFilterForm";

export const EspressoTableFilterItem = ({column, value, onRemove, onSave}) => {
    const [editing, setEditing] = useState(false);
    const [newValue, setNewValue] = useState('');

    const handleEdit = () => {
        setNewValue(value);
        setEditing(true);
    }

    const handleCancel = () => setEditing(false)

    const handleNewValue = (value) => setNewValue(value);

    const handleSave = () => {
        setEditing(false);
        onSave({column: column.id, value: newValue});
    }

    const valueLabel = () => {
        if (column.filterType === 'select') {
            const valueLabel = column.values.find(val => val.value === value).label;
            return `É "${valueLabel}"`
        } else if (column.filterType === 'datetime') {
            return value
        }
        return `Contém "${value}"`;
    }

    const invalidNewValue = useMemo(() => {
        return newValue === value || (typeof newValue === 'string' && newValue.trim() === '')
    }, [value, newValue]);

    return <Card variant={'outlined'} sx={{mt: 1}}>
        {editing && (
            <Stack spacing={2} sx={{my: 2, mx: 1}}>
                <BistroTableFilterValueForm
                    column={column}
                    value={newValue}
                    showColumnLabel={true}
                    onChange={handleNewValue}/>
                <Stack direction={'row'} spacing={2} justifyContent={'flex-end'}>
                    <Button variant={'text'} onClick={handleCancel}>Cancelar</Button>
                    <Button
                        variant={'outlined'}
                        disabled={invalidNewValue}
                        onClick={handleSave}>
                        Salvar
                    </Button>
                </Stack>
            </Stack>
        )}
        {!editing && (
            <ListItem
                dense
                disablePadding
                aria-label={'Filtro na coluna ' + column.label}
                secondaryAction={
                    <Tooltip title={'Editar filtro de ' + column.label}>
                        <IconButton edge="end" onClick={handleEdit}>
                            <EditIcon/>
                        </IconButton>
                    </Tooltip>
                }>
                <ListItemIcon>
                    <Tooltip title={'Remover filtro de ' + column.label}>
                        <IconButton edge="end" onClick={onRemove}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </ListItemIcon>
                <ListItemText primary={column.label} secondary={valueLabel()}/>
            </ListItem>
        )}
    </Card>
}