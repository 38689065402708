import React from "react";
import classNames from "classnames";

export function renderActivityStatus(status_color, status_name) {
  return (
    <React.Fragment>
      <span className={classNames("status-color", status_color)}>
        {status_name}
      </span>
    </React.Fragment>
  );
}

export function parserActivityObject(activity) {
  return [
    { label: "ID da transação", description: activity.external_id },
    { label: "Fornecedor", description: activity.baas_provider },
    { label: "Empresa", description: activity.company_name },
    { label: "Usuário", description: activity.user_name },
    { label: "Valor", description: activity.cost },
    { label: "Data", description: activity.date_performed_at },
    { label: "Hora", description: activity.time_performed_at },
    { label: "Detalhes", description: activity.details },
    { label: "Status", description: activity.status_name },
    { label: "Cartão", description: activity.card_number },
    { label: "Tipo", description: activity.activity_type },
    { label: "E-mail", description: activity.user_email },
    { label: "Código da compra", description: activity.purchase_code },
    { label: "Tipo de compra", description: activity.purchase_type },
    { label: "MCC", description: activity.purchase_mcc },
    {
      label: "ID do estabelecimento",
      description: activity.purchase_merchant_id,
    },
    {
      label: "Nome do estabelecimento",
      description: activity.purchase_merchant_name,
    },
    {
      label: "Cidade do estabelecimento",
      description: activity.purchase_merchant_city,
    },
    {
      label: "Pais do estabelecimento",
      description: activity.purchase_merchant_country,
    },
  ];
}
