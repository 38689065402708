import React, {useEffect, useRef, useState} from "react";
import {ShadowTheme} from "../../../ShadowTemplate";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import {hot} from "react-hot-loader";
import {
    admin_erp_templates_client_setting_path,
    admin_erp_templates_client_settings_path,
    checking_accounts_admin_erp_templates_client_settings_path,
} from "../../../../../routes";

const OmieConfigFormShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <OmieConfigForm {...props}/>
    </ShadowTheme>;
}

const OmieConfigForm = ({clientId, type, config, csrfToken}) => {
    const [currentConfig, setCurrentConfig] = useState(config);
    const [responseMessage, setResponseMessage] = useState();
    const formRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const [account, setAccount] = useState(config?.data.checkAccountId ?? 0);
    const [dueDate, setDueDate] = useState(config?.data.dueDate ?? 0);
    const [accountError, setAccountError] = useState();
    const [dueDateError, setDueDateError] = useState()

    const handleAccount = (event) => {
        setAccountError(null);
        setAccount(event.target.value);
    }
    const handleDueDate = (event) => {
        setDueDateError(null);
        setDueDate(event.target.value);
    }

    const handleSubmit = async () => {
        if (!validateFields()) return;

        setLoading(true);
        try {
            await saveConfig();
        } catch {
            setResponseMessage('Não foi possível salvar as configurações');
        } finally {
            setLoading(false);
        }
    };


    const validateAccount = () => {
        if (account === 0) {
            setAccountError('Escolha uma conta');
            return false;
        }
        setAccountError(null);
        return true;
    }
    const validateDueDate = () => {
        if (dueDate === 0) {
            setDueDateError('Escolha uma data de vencimento')
            return false;
        }
        setDueDateError(null);
        return true;
    }

    const validateFields = () => !!(validateDueDate() * validateAccount());

    const submitPath = (config) => {
        const createPath = () => admin_erp_templates_client_settings_path(clientId);
        const editPath = () => admin_erp_templates_client_setting_path(clientId, config.id);
        return config ? editPath() : createPath();
    }

    const saveConfig = async () => {
        const formData = new FormData(formRef.current);
        formData.append('kind', type);
        const response = await fetch(submitPath(currentConfig), {
            method: currentConfig ? 'PUT' : 'POST',
            headers: {"X-CSRF-Token": csrfToken},
            body: formData,
        });
        const data = await response.json();
        if (response.ok) {
            setCurrentConfig(data.config);
            setResponseMessage('Configuração salva com sucesso')
        } else {
            setResponseMessage('Não foi possível salvar as configurações');
        }
    }

    return <>
        <Card sx={{p: 1, mt: 4}}>
            <CardContent>
                <form ref={formRef}>
                    <Stack spacing={4} alignItems={'flex-start'}>
                        <Typography>1. Conta e Data de Vencimento</Typography>
                        <AccountsSelect
                            clientId={clientId}
                            account={account}
                            onChange={handleAccount}
                            error={accountError}/>
                        <DueDateSelect
                            dueDate={dueDate}
                            onChange={handleDueDate}
                            error={dueDateError}/>
                    </Stack>
                </form>
            </CardContent>
            <CardActions sx={{justifyContent: "flex-end"}}>
                <Button disabled={loading} variant={'contained'} onClick={handleSubmit}>
                    {loading ? 'Salvando...' : 'Salvar'}
                </Button>
            </CardActions>
        </Card>
        <MessageSnackbar key={responseMessage} message={responseMessage}/>
    </>
}

const MessageSnackbar = ({message}) => {
    const [currentMessage, setCurrentMessage] = useState(message);

    const handleCloseMessage = () => setCurrentMessage(null);

    return <Snackbar
        open={!!currentMessage}
        autoHideDuration={5000}
        message={currentMessage}
        onClose={handleCloseMessage}/>
}

const DueDateSelect = ({dueDate, onChange, error}) => {
    const dueDates = [
        {label: '5 dias úteis após', value: 1},
        {label: 'Dia fixo mais próximo (5, 15 ou 25)', value: 2},
    ]

    return <FormControl fullWidth required error={!!error}>
        <InputLabel shrink title={'Data de Vencimento'}>Data de Vencimento</InputLabel>
        <Select
            notched
            label={'Data de Vencimento'}
            name={'barista_configs_omie_accounts_payable[due_date]'}
            value={dueDate}
            onChange={onChange}>
            <MenuItem key={'empty'} value={0}>Selecione</MenuItem>
            {dueDates.map((dueDate) => (
                <MenuItem key={dueDate.value} value={dueDate.value}>{dueDate.label}</MenuItem>)
            )}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
}

const AccountsSelect = ({account, onChange, clientId, error}) => {
    const [accounts, setAccounts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [retry, setRetry] = useState(false);

    const handleRetry = () => setRetry(true);

    const fetchAccounts = async () => {
        setLoading(true);
        const accountsEndpoint = checking_accounts_admin_erp_templates_client_settings_path(clientId);
        const response = await fetch(accountsEndpoint)
        try {
            if (response.ok) {
                const body = await response.json()
                setLoading(false)
                setAccounts(body.data)
            }
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchAccounts();
        return () => {
            setRetry(false);
        }
    }, [retry]);

    const hasAccounts = accounts.length > 0;

    const emptyValue = (loading, hasAccounts) => {
        if (loading) return 'Carregando...';

        if (hasAccounts) return 'Selecione';

        return 'Não foi possível carregar as contas';
    }

    return <FormControl fullWidth required error={!!error}>
        <InputLabel shrink title={'Conta'}>Conta</InputLabel>
        <Select
            required
            notched
            label={'Conta'}
            name={'barista_configs_omie_accounts_payable[check_account_id]'}
            value={hasAccounts ? account : 0}
            onChange={onChange}>
            <MenuItem key={'empty'} value={0}>{emptyValue(loading, hasAccounts)}</MenuItem>
            {loading && !hasAccounts && (
                [<AccountLoading key={0}/>, <AccountLoading key={2}/>, <AccountLoading key={3}/>]
            )}
            {!loading && !hasAccounts && (
                <Button startIcon={<ReplayIcon/>} onClick={handleRetry}>Tentar novamente</Button>
            )}
            {!loading && hasAccounts && accounts.map((account) => (
                <MenuItem key={account.id} value={account.id}>{account.label}</MenuItem>)
            )}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
}

const AccountLoading = () => {
    return <Skeleton sx={{mt: 1}} variant={'rectangular'} animation="wave" height={32}/>
}


export default hot(module)(OmieConfigFormShadow)