import React, { useState, useEffect } from 'react';
import BalanceProgressFeedback from './BalanceProgressFeedback';
import { Button } from '@mui/material';
import { parserCurrencyBRLValue } from '../../../../../../../app/javascript/components/shared/helpers';

const ProgressFeedbackDisplay = ({
  progressData,
  lastProcessedSubAccount,
  balanceDeficit,
  operation,
  redirectToDepositBalanceStatement
}) => {
  const [balanceProgressFeedbackDescription, setBalanceProgressFeedbackDescription] = useState('');
  const [balanceProgressFeedbackAlertMessage, setBalanceProgressFeedbackAlertMessage] = useState('');
  const [balanceProgressFeedbackAlertSeverity, setBalanceProgressFeedbackAlertSeverity] = useState('');
  const [balanceProgressFeedbackAlertTitle, setBalanceProgressFeedbackAlertTitle] = useState('');
  const [balanceProgressFeedbackAction, setBalanceProgressFeedbackAction] = useState(null);
  const isInsufficientBalance = balanceDeficit < 0;
  const isProcessing = progressData.progress > 0 && progressData.progress < progressData.total;
  const isFinished = progressData.progress === -1 || (progressData.total && progressData.progress >= progressData.total) || isInsufficientBalance;

  useEffect(() => {
    if (isFinished) {
      setFinishedBalanceProgressFeedback();
    }

    if (isProcessing) {
      setBalanceProgressFeedbackDescription('Status: Em processamento');
    }
  }, [progressData.progress]);

  useEffect(() => {
    if (isInsufficientBalance) {
      setInsufficientBalanceProgressFeedback();
    }
  }, [balanceDeficit]);

  const setFinishedBalanceProgressFeedback = () => {
    const { title, description, message, severity } = buildBalanceProgressFeedbackAlertData();

    setBalanceProgressFeedbackAlertTitle(title);
    setBalanceProgressFeedbackDescription(description);
    setBalanceProgressFeedbackAlertMessage(message);
    setBalanceProgressFeedbackAlertSeverity(severity);
  }

  const setInsufficientBalanceProgressFeedback = () => {
    setBalanceProgressFeedbackAlertTitle('Atenção!');
    setBalanceProgressFeedbackAlertMessage(insufficientBalanceMessage());
    setBalanceProgressFeedbackAlertSeverity('warning');
    setBalanceProgressFeedbackAction(
      <Button color="inherit" onClick={redirectToDepositBalanceStatement}>Adicionar saldo</Button>
    );
  }

  const insufficientBalanceMessage = () => {
    const balanceDeficitParsed = parserCurrencyBRLValue(Math.abs(balanceDeficit));

    return `O saldo disponível não é suficiente para concluir a solicitação. Por favor, adicione ${balanceDeficitParsed} e repita a ação.`;
  };

  const getSuccessProgressFeedbackAlert = () => {
    let description = '';

    if(operation === 'withdraw') {
      description = 'Seu resgate foi concluído!';
    } else {
      description = 'Seu saldo foi ajustado com sucesso!';
    }

    return {
      description: description,
      title: 'Status',
      message: 'Concluído com sucesso.',
      severity: 'success'
    };
  }

  const getErrorProgressFeedbackAlert = () => {
    return {
      description: 'Erro de processamento!',
      title: 'Status',
      message: 'Erro na definição de saldo, tente novamente.',
      severity: 'error'
    };
  }

  const getWarningProgressFeedbackAlert = () => {
    return {
      description: 'Oops, não foi possível concluir o processo!',
      title: 'Status',
      message: `A definição de saldo foi feita até a subconta ${lastProcessedSubAccount.name}`,
      severity: 'warning'
    };
  }

  const buildBalanceProgressFeedbackAlertData = () => {
    if (progressData.status === 'success' && progressData.progress >= progressData.total) {
      return getSuccessProgressFeedbackAlert();
    } else if (progressData.status === 'error') {
      if (lastProcessedSubAccount?.id) {
        return getWarningProgressFeedbackAlert();
      } else {
        return getErrorProgressFeedbackAlert();
      }
    }

    return { description: '', title: '', message: '', severity: '' };
  }

  if (!isInsufficientBalance && (!progressData.progress || !balanceProgressFeedbackDescription)) return null;

  return (
    <BalanceProgressFeedback
      description={balanceProgressFeedbackDescription}
      progress={progressData.progress || 0}
      total={progressData.total}
      alertMessage={balanceProgressFeedbackAlertMessage}
      alertSeverity={balanceProgressFeedbackAlertSeverity}
      alertTitle={balanceProgressFeedbackAlertTitle}
      showLinearProgress={isProcessing && !isInsufficientBalance}
      showProgressMessage={balanceProgressFeedbackAlertSeverity !== 'error' && !isInsufficientBalance}
      action={isInsufficientBalance ? balanceProgressFeedbackAction : null}
    />
  );
};

export default ProgressFeedbackDisplay;
