import React, {useEffect} from "react";
import {code_sheet_admin_erp_templates_client_settings_path} from "../../../../routes";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import {hot} from "react-hot-loader";

const SheetDownloadDialog = ({open, onClose, clientId}) => {
    const [sheetUrl, setSheetUrl] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        if (open) {
            setLoading(true)
            fetch(code_sheet_admin_erp_templates_client_settings_path(clientId)).then(async (response) => {
                const body = await response.json()
                setLoading(false)
                setSheetUrl(body.url)
            })
        }
        return () => {
        }
    }, [open])

    return <Dialog
        open={open}
        onClose={onClose}>
        <DialogTitle>Download</DialogTitle>
        <DialogContent>
            {(!loading && sheetUrl) &&
                <DialogContentText>
                    A planilha foi gerada com sucesso.<br/>
                    Também enviamos o link para download no seu e-mail.
                </DialogContentText>
            }
            {loading &&
                <DialogContentText>
                    Gerando planilha de códigos do ERP.<br/>
                    Em alguns instantes estará disponível para download.
                </DialogContentText>
            }
            {(!loading && !sheetUrl) &&
                <DialogContentText>
                    Não foi possível gerar a planilha<br/>
                    Tente novamente mais tarde ou entre em contato com o suporte.
                </DialogContentText>
            }
        </DialogContent>
        <DialogActions>
            <Button color={'inherit'} onClick={onClose}>Fechar</Button>
            {loading &&
                <Box display={'flex'} justifyContent={'center'} width={100}>
                    <CircularProgress size={24}/>
                </Box>
            }
            {(!loading && sheetUrl) &&
                <Button href={sheetUrl} onClick={onClose} autoFocus>Baixar planilha</Button>
            }
        </DialogActions>
    </Dialog>
}

export default hot(module)(SheetDownloadDialog)