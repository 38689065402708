import React from 'react';
import {Card} from "@mui/material";
import HomeAccountCardContent from "./HomeAccountCardContent";
import HomeEmptyCardContent from "../common/HomeEmptyCardContent";
import BarChartIcon from '@mui/icons-material/BarChart';
import {admin_account_path} from '../../../../routes';
import {openModal} from "../../../shared/jquery_wrapper";

const HomeAccountEmptyCardContent = (props) => {
  const buttonProps = props.planLevel > 2 ? { href: admin_account_path() } : { onClick: () => openModal('#upgrade') }
  return <HomeEmptyCardContent
          icon={<BarChartIcon color="primary" />}
          title={"Você ainda não tem a Conta Digital do Espresso"}
          subtitle={"Quer emitir cartões corporativos, criar múltiplos cartões virtuais e gerenciar todos "+
          "os seus fornecedores no mesmo lugar? Clique abaixo para conhecer a nossa conta digital"}
          buttonText={props.planLevel > 2 ? "CADASTRE AGORA" : "FAZER UPGRADE DE PLANO"}
          buttonProps={buttonProps} />
}

const HomeAccountCard = (props) => {
  return (
    <Card sx={{p: 2, display: 'flex', flexDirection: 'column', mb: 2}} data-testid="home-account">
      {props.emptyState ? <HomeAccountEmptyCardContent {...props} /> : <HomeAccountCardContent {...props} />}
    </Card>
  );
}

export default HomeAccountCard;

