import moment from "moment";

/* istanbul ignore next */
export const showSnackbar = (options) => {
  $.snackbar({
    content: options.content,
    style: options.style,
    timeout: options.timeout ?? 3000,
  });
};

/* istanbul ignore next */
export const openModal = (selector) => {
  $(selector).modal("open");
};

/* istanbul ignore next */
export const closeModal = (selector) => {
  $(selector).modal("close");
};

/* istanbul ignore next */
export const initTooltips = () => {
  $(".tooltipped").tooltip();
};

/* istanbul ignore next */
export const initSelects = () => {
  $("select").formSelect();
};

/* istanbul ignore next */
export const initTabs = () => {
  $(".tabs").tabs();
};

/* istanbul ignore next */
export const initSingleDatePickerMaxToday = () => {
  const WEEK_LIST = ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];
  const MONTH_LIST = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  const datepicker = $("input.single-datepicker-max-today");
  const auxiliarElement = $("input.auxiliar-datepicker");

  if (auxiliarElement) {
    datepicker.daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      autoUpdateInput: false,
      maxDate: moment().format("DD/MM/YYYY"),

      locale: {
        format: "DD/MM/YYYY",
        daysOfWeek: WEEK_LIST,
        monthNames: MONTH_LIST,
      },
    });

    datepicker.on("show.daterangepicker", () => initSelects());
    datepicker.on("showCalendar.daterangepicker", () => initSelects());

    datepicker.on("apply.daterangepicker", function (ev, picker) {
      auxiliarElement.val(picker.startDate.format("DD/MM/YYYY"));
      auxiliarElement.click();
    });
  }
};

/* istanbul ignore next */
export const initModals = () => {
  $(".modal").modal();
};
