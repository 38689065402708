import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, List, Card, ListItemText, ListItemAvatar, Avatar, Pagination, InputAdornment, Snackbar, ListItemButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import RocketIcon from '@mui/icons-material/Rocket';
import { admin_budget_path, admin_budgets_path }  from "../../../routes";
import dayjs from 'dayjs';

const BudgetsList = (props) => {
  const [budgets, setBudgets] = useState(props.budgets || []);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [status, setStatus] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    const fetchBudgets = async () => {
      try {
        const response = await fetch(`${admin_budgets_path()}?filter[status]=${status}&filter[project_name]=${searchTerm}&page=${page}`, {
          headers: {
            'Accept': 'application/json'
          }
        });
        const data = await response.json();
        setBudgets(data.budgets);
        setTotalPages(data.total_pages);
      } catch (error) {
        setOpenSnackbar(true);
        setSnackbarMessage(`Ocorreu um erro ${error.message}`);
      }
    };

    fetchBudgets();
  }, [searchTerm, page, status]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const renderMessage = (alert_status) => {
    switch (alert_status) {
      case 'no_expenses':
        return <span style={{ color: '#000000' }}>Não há despesas registradas</span>;
      case 'risk':
        return <span style={{ color: '#EF6C00' }}>Risco de ultrapassar o limite estipulado</span>;
      case 'within_limit':
        return <span style={{ color: '#2E7D32' }}>Dentro do limite estipulado</span>;
      case 'exceeded':
        return <span style={{ color: '#D32F2F' }}>Excedeu o limite estipulado</span>;
    }
  };

  const budgetDateRange = (budget) => {
    const start = dayjs(budget.current_cycle.start_date).format('DD/MM/YYYY');
    const end = dayjs(budget.current_cycle.end_date).format('DD/MM/YYYY');
    return `${start} a ${end}`;
  }

  const budgetTitle = (budget) => {
    if (status == 1) {
      const dateRange = budgetDateRange(budget);
      return `${budget.name} - ${dateRange}`;
    }
    return `${budget.name} - Pontual`;
  }

  return (
    <React.Fragment>
      <Card sx={{p: 2.0, mb: 2, width: '90%'}}>
        <Typography variant="h5" sx={{marginBottom: props.admin ? 5 : 1}}>
          Lista de orçamentos
        </Typography>
        {
          props.admin &&
          <Box display="flex" alignItems="center" marginBottom={2}>
            <TextField
              variant="outlined"
              placeholder="Buscar por projeto"
              size={"small"}
              sx={{width: 535}}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant={status === 1 ? 'contained' : 'outlined'}
              color="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => setStatus(1)}
            >
              ATIVOS
            </Button>
            <Button
              variant={status === -1 ? 'contained' : 'outlined'}
              color="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => setStatus(-1)}
            >
              ENCERRADOS
            </Button>
          </Box>
        }
        <List>
          {budgets.map((budget, index) => (
            <ListItemButton
              key={index}
              component="a"
              disableRipple
              href={admin_budget_path(budget.id)}
              sx={{
                paddingLeft: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
            >
              <ListItemAvatar>
                <Avatar sx={{bgcolor: '#F0EBF5'}}>
                  <RocketIcon color="primary" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={budgetTitle(budget)}
                secondary={ status == 1 ? renderMessage(budget.alert_status) : budgetDateRange(budget) }
              />
              <Typography variant="body1">
                { status == 1 ? budget.current_cycle.limit : 'Encerrado' }
              </Typography>
            </ListItemButton>
          ))}
        </List>

        {budgets.length > 0 && totalPages > 1 && (
          <Box display="flex" justifyContent="flex-end" marginTop={2}>
            <Pagination
              shape='rounded'
              count={totalPages}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        )}
      </Card>

      <Snackbar
        data-testid="snackbar"
        open={openSnackbar}
        message={snackbarMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
      />
    </React.Fragment>
  );
}

export default BudgetsList;