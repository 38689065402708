import React from "react";
import DistanceCostModal from "./DistanceCostModal";
import FeatureGroup from "../FeatureGroup";
import {openModal, showSnackbar} from "../../../shared/jquery_wrapper";

const numberFormat = new Intl.NumberFormat('pt-BR', {minimumFractionDigits: 2})

export const DISTANCE_GROUP_ID = 1;

class DistanceFeatureGroup extends React.Component {

    constructor(props) {
        super(props);
        this.group = React.createRef()
        this.modalInput = React.createRef()
    }

    get distanceFeature() {
        return this.group.current?.state.features.find((f) => f.attributes.name === 'distance')
    }

    onUpdate = (feature, activate, error) => {
        if (error) {
            showSnackbar({
                content: "É necessário manter pelo menos duas modalidades, quando Google Maps ou GPS estão ativas.",
                style: "alert",
                timeout: 5000
            })
        } else {
            showSnackbar({
                content: "Registro de despesa de quilometragem atualizado!",
                style: "alert",
                timeout: 3000
            });
        }
    }

    handleSwitch = async (feature, checked) => {
        let metadata = feature.attributes.metadata;
        if (checked && feature.attributes.name === 'distance') {
            this.modalInput.current.value = numberFormat.format(metadata?.distance_cost ?? 0)
            openModal('#edit-distance-cost-modal');
        } else {
            await this.group.current.updateFeature(feature, checked, metadata)
        }
    }

    render() {
        return (<React.Fragment>
            <DistanceCostModal
                inputRef={this.modalInput}
                onConfirm={(value) => {
                    this.group.current.updateFeature(this.distanceFeature, true, {distance_cost: value})
                }}
            />
            <FeatureGroup
                groupName={'Quilometragem'}
                featuresEndpoint={this.props.featuresEndpoint}
                featureGroup={this.props.featureGroup}
                ref={this.group}
                onChangeSwitch={(feature, checked) => this.handleSwitch(feature, checked)}
                onUpdate={(feature, activate, error) => this.onUpdate(feature, activate, error)}
                ignoreAvailability={this.props.ignoreAvailability}
                hidePlanWhenActive={this.props.hidePlanWhenActive}
                customDescription={this.distanceDescription}/>
        </React.Fragment>)
    }

    distanceDescription = (feature) => {
        if (feature.attributes.active && feature.attributes.name === 'distance') {
            let distanceCost = numberFormat.format(feature.attributes.metadata?.distance_cost ?? 0);
            return <div className="col s12 m9 sub-header no-left-padding">
                Operando a R$ {distanceCost} por KM rodado
                <a href='#'
                   onClick={(_) => this.handleSwitch(feature, true)}
                   style={{color: '#3D0079'}}
                   >
                    {" (editar)"}
                </a>
            </div>
        }
        return <div className="col s12 m9 sub-header no-left-padding">
            {feature.attributes.subtitle}
        </div>
    }
}

DistanceFeatureGroup.defaultProps = {
    featuresEndpoint: "",
    featureGroup: DISTANCE_GROUP_ID,
    ignoreAvailability: false,
    hidePlanWhenActive: true,
};

export default DistanceFeatureGroup;
