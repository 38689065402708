import React from "react";
import Pagination from "../shared/Pagination";
import Loading from "../shared/Loading";
import Filter from "../shared/Filter";
import moment from "moment";
import companyFilters from "./companies/company_filters";

class CompanyList extends React.Component {
  state = {
    showFilter: false,
    loading: false,
    companies: [],
    count: 0,
    forcePageOne: false,
    query: "",
    types: [],
    plans: [],
    test: [],
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
      forcePageOne: !this.state.forcePageOne,
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="left">
          <h1>
            Empresas<small> (Total: {this.state.count})</small>
          </h1>
        </div>
        {this.renderFilterTrigger()}
        {this.renderFilter()}
        {<Loading show={this.state.loading} />}
        {this.renderCompanyList()}
        {this.renderPagination()}
      </React.Fragment>
    );
  }

  renderFilterTrigger() {
    return (
      <div className="row">
        <div className="col s12">
          <div className="right-align">
            <a
              className="btn-flat"
              onClick={() =>
                this.setState({
                  ...this.state,
                  showFilter: !this.state.showFilter,
                })
              }
            >
              <i className="mdi mdi-filter-variant left" />
              FILTRAR VISUALIZAÇÃO
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderFilter() {
    return (
      <div
        test-name="div-show-filter"
        style={{ display: `${this.state.showFilter ? "block" : "none"}` }}
      >
        <Filter
          searchPlaceholder={"Busque pelo nome"}
          showPeriodFilter={false}
          searchAfterEachChar={false}
          filters={companyFilters}
          onFilter={(filters) =>
            this.setState({
              ...this.state,
              loading: true,
              forcePageOne: !this.state.forcePageOne,
              types: filters.selects?.company_types || [],
              plans: filters.selects?.subscription_plans || [],
              test: filters.selects?.company_test || [],
              query: filters.search,
              ...filters,
            })
          }
        />
      </div>
    );
  }

  renderCompanyList() {
    return (
      <div className="col s12">
        <div className="collection z-depth-1 white" style={{ padding: "20px" }}>
          {this.state.companies.length > 0 && (
            <table className="table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Classificação</th>
                  <th>Plano</th>
                  <th>Teste</th>
                  <th className="center-align" style={{ width: "100px" }}>
                    {" "}
                    Cadastro
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.companies.map((company, idx) =>
                  this.renderCompanyItem(idx, company)
                )}
              </tbody>
            </table>
          )}
          {this.state.companies.length === 0 && (
            <p>Nenhuma empresa encontrada!</p>
          )}
        </div>
      </div>
    );
  }

  renderCompanyItem(index, company) {
    return (
      <tr key={index}>
        <td>
          <a href={this.props.baseEndpoint + "/" + company.id}>
            {company.attributes.name}
          </a>
        </td>
        <td>{company.attributes.client_type}</td>
        <td>{company.attributes.subscription_level}</td>
        <td>{company.attributes.test ? "Sim" : "Não"}</td>
        <td className="center-align">
          {moment(company.attributes.created_at).format("DD/MM/YYYY")}
        </td>
      </tr>
    );
  }

  renderPagination() {
    return (
      <Pagination
        perPage={20}
        forcePageOne={this.state.forcePageOne}
        url={this.props.searchEndpoint}
        setExternalList={(list, extra) =>
          this.setState({
            ...this.state,
            loading: false,
            companies: list,
            count: extra.total_count,
          })
        }
        params={{
          q: this.state.query,
          types: this.state.types,
          plans: this.state.plans,
          test: this.state.test,
        }}
      />
    );
  }
}

CompanyList.defaultProps = {
  searchEndpoint: "",
  baseEndpoint: "",
};

export default CompanyList;
