import React from "react";
import {Card, Typography} from "@mui/material";
import {admin_account_sub_accounts_path} from "../../../../routes";
import HomeSubAccountsCardContent from "./HomeSubAccountsCardContent";
import HomeEmptyCardContent from "../common/HomeEmptyCardContent";
import PersonIcon from '@mui/icons-material/Person';

const HomeSubAccountsEmptyCardContent = () => {
    return <HomeEmptyCardContent
        icon={<PersonIcon color="primary"/>}
        title={"Você ainda não possui subcontas cadastradas"}
        subtitle={"Melhore o seu controle financeiro"}
        buttonText={"CADASTRE AGORA"}
        buttonProps={{href: admin_account_sub_accounts_path()}}/>
}

const HomeSubAccountsCard = (props) => {
    return (
        <Card sx={{p: 2.0, mb: 2}} data-testid="home-sub-accounts">
            <Typography variant="h6" color={"primary"}>Subcontas</Typography>
            {props.emptyState ? <HomeSubAccountsEmptyCardContent/> : <HomeSubAccountsCardContent {...props}/>}
        </Card>
    );
}

export default HomeSubAccountsCard;