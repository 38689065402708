import { admin_users_two_factor_path } from "../../../../routes";

export const enableTwoFactor = async (otp, csrfToken, onSuccess, onFail) => {
  const response = await fetch(admin_users_two_factor_path(), {
    method: 'POST',
    headers: { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json' },
    body: JSON.stringify({ two_factor: { otp: otp } })
  });
  if (response.ok) {
    const data = await response.json();
    onSuccess(data.backup_codes);
  } else {
    onFail();
  }
}

export const disableTwoFactor = async (csrfToken, onSuccess, onFail) => {
  const response = await fetch(admin_users_two_factor_path(), {
    method: 'DELETE', headers: { 'X-CSRF-Token': csrfToken }
  });
  if (response.ok) {
    onSuccess();
  } else {
    onFail();
  }
}