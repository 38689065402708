import React, { useEffect, useState } from "react";

import {
  admin_account_sub_accounts_privacy_user_path,
  admin_account_sub_accounts_privacy_users_path,
} from "../../../../../routes";

import { showSnackbar } from "../../../../shared/jquery_wrapper";

import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

import UsersAddModal from "./UsersAddModal";

const UsersCardWithAlloweds = (props) => {
  const [quantityAllowed, setQuantityAllowed] = useState(props.quantity);

  const [searchAllowedValue, setSearchAllowedValue] = useState("");
  const [showLoadingAllowedUsers, setShowLoadingAllowedUsers] = useState(false);
  const [userAllowedList, setUserAllowedList] = useState([]);
  const [pageUserAllowedList, setPageUserAllowedList] = useState(1);
  const [totalUserAllowedList, setTotalUSerAllowedList] = useState(0);
  const [destroySelected, setDestroySelected] = useState("");
  const [showModalDestroy, setShowModalDestroy] = useState(false);

  useEffect(() => {
    if (quantityAllowed > 0) {
      getAllowedList();
    }
  }, []);

  const getAllowedList = (value = "", page = 1) => {
    setSearchAllowedValue(value);
    setShowLoadingAllowedUsers(true);

    $.ajax({
      type: "GET",
      url: admin_account_sub_accounts_privacy_users_path(),
      data: { page, search: value },
    })
      .then((r) => {
        setUserAllowedList(r.data.list);
        setPageUserAllowedList(r.data.current_page);
        setTotalUSerAllowedList(r.data.total_count);
        if (value == "" && page == 1) {
          setQuantityAllowed(r.data.total_count);
        }
        setShowLoadingAllowedUsers(false);
      })
      .catch((e) => {
        showSnackbar({
          content: "Erro ao buscar os usuários!",
          style: "alert",
        });
        setShowLoadingAllowedUsers(false);
      });
  };

  const handleDestroy = (id = "") => {
    setDestroySelected(id);
    setShowModalDestroy(!showModalDestroy);
  };
  const deleteAllowed = () => {
    $.ajax({
      type: "DELETE",
      url: admin_account_sub_accounts_privacy_user_path(destroySelected),
    })
      .then((r) => {
        showSnackbar({
          content: "Usuário devinculado com sucesso!",
          style: "notice",
        });

        handleDestroy();
        getAllowedList();
      })
      .catch((e) => {
        showSnackbar({
          content: "Não foi possível desvincular o usuário!",
          style: "alert",
        });

        getAllowedList();
      });
  };

  return (
    <Card>
      <CardHeader
        title={
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography sx={{ fontSize: "24px" }}>Usuários</Typography>
            <UsersAddModal />
          </Stack>
        }
      />
      <CardContent>
        {/* list */}
        <React.Fragment>
          <TextField
            sx={{ width: "50%", margin: "20px 0" }}
            label="Buscar"
            placeholder="Digite o nome ou e-mail do usuário e pressione a tecla Enter para filtrar"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchAllowedValue}
            onChange={(e) => setSearchAllowedValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                getAllowedList(searchAllowedValue);
              }
            }}
          />
          <List>
            {userAllowedList.map((user, idx) => (
              <ListItem key={idx}>
                <ListItem sx={{ padding: 0 }}>
                  <ListItemAvatar
                    style={{ minWidth: "32px", marginRight: "10px" }}
                  >
                    <Avatar src={user.avatar} />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography>{user.name}</Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {user.email}
                    </Typography>
                  </ListItemText>
                </ListItem>
                <Tooltip
                  placement="left"
                  title={
                    <div style={{ padding: "8px" }}>Desvincular usuário</div>
                  }
                  disableInteractive
                >
                  <CancelIcon
                    aria-label={`cancel-icon-${user.id}`}
                    sx={{ cursor: "pointer" }}
                    onClick={() => handleDestroy(user.id)}
                  />
                </Tooltip>
              </ListItem>
            ))}
          </List>
          {showLoadingAllowedUsers && <LinearProgress />}
          <Pagination
            style={{
              margin: "12px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            sx={{ mb: 3, ml: 3 }}
            count={Math.ceil(totalUserAllowedList / 5)}
            page={Number(pageUserAllowedList)}
            onChange={(event, value) =>
              getAllowedList(searchAllowedValue, value)
            }
            color="primary"
            shape="rounded"
          />
        </React.Fragment>
      </CardContent>
      {/* modal */}
      <Dialog open={showModalDestroy} onClose={handleDestroy} maxWidth={"lg"}>
        <DialogTitle>
          <Typography sx={{ fontSize: "20px" }}>Desvincular usuário</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
            Tem certeza que deseja desvincular o usuário da conta digital?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-end", padding: "20px" }}>
          <Button
            sx={{ color: "rgba(0, 0, 0, 0.6)", marginRight: "10px" }}
            onClick={handleDestroy}
          >
            cancelar
          </Button>
          <Button onClick={deleteAllowed}>confirmar</Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default UsersCardWithAlloweds;
