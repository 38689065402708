import React from "react";
import Select from "../../shared/inputs/Select";

class CardsFilter extends React.Component {
  render() {
    const { value, showFilter, onChange, onFilterClick, onClearClick } = this.props;

    return (
      <div
        test-name="div-show-filter"
        className="cards-filter"
        style={{ display: showFilter ? "block" : "none" }}
      >
        <span className="title">Filtros</span>
        <div className="form-design-on-surfaces">
          <Select
            key={value}
            value={value}
            placeholder="Selecione um status"
            test="status-select"
            label="Status"
            required={false}
            size={50}
            options={[
              {value: [6], label: "Ativo"},
              {value: [7], label: "Bloqueado"},
              {value: [8], label: "Cancelado"},
              {value: [1, 2, 3, 4, 5], label: "Emitido"},
              {value: 'activation_error', label: "Erro na ativação"}
            ]}
            onChange={onChange}
          />
        </div>
        <hr />
        <div className="d-flex justify-end">
          <button
            test-name="button-clear-filter"
            className="btn clear-filter-btn"
            onClick={onClearClick}
          >
            Limpar
          </button>

          <button
            test-name="button-filter"
            className="btn"
            onClick={onFilterClick}
          >
            Filtrar
          </button>
        </div>
      </div>
    );
  }
}

CardsFilter.defaultProps = {
  showFilter: false,
  onChange: Function,
  onFilterClick: Function,
  onClearClick: Function
};

export default CardsFilter;
