import {Box, CardMedia, IconButton, Tooltip} from "@mui/material";
import Fade from "@mui/material/Fade";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import React from "react";

export const OnboardingThumbnail = ({open, youtubeId, onClick}) => {
    return <Box>
        <CardMedia
            sx={{height: 200, mb: 2}}
            component="img"
            image={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}>
        </CardMedia>
        <Fade appear in={open} style={{transitionDelay: open ? '200ms' : 0}}>
            <Tooltip title={'Abrir vídeo'}>
                <IconButton
                    sx={{
                        "&:hover": {opacity: 1, backgroundColor: 'red'},
                        opacity: 0.85,
                        width: 60,
                        height: 60,
                        background: 'red',
                        top: 'calc(50% - 30px)',
                        left: 'calc(50% - 30px)',
                        position: 'absolute'
                    }}
                    onClick={onClick}>
                    <PlayCircleOutlineIcon htmlColor={'white'} fontSize={'large'}/>
                </IconButton>
            </Tooltip>
        </Fade>
    </Box>
}