export default class FeatureRepository {
    constructor(endpoint) {
        this.endpoint = endpoint
    }

    getFeatures(groupId) {
        return $.ajax({type: "GET", dataType: "json", url: `${this.endpoint}/${groupId}`})
    }

    updateFeature(groupId, feature, activate, metadata) {
        return $.ajax({
            type: "PUT",
            contentType: "application/json",
            url: `${this.endpoint}/${groupId}`,
            data: JSON.stringify(updateRequestBody(feature, activate, metadata)),
        })
    }
}

const updateRequestBody = (feature, activate, metadata) => {
    return {
        data: {
            name: feature.attributes.name,
            options: {
                active: activate,
                metadata
            }
        }
    };
}