import classNames from "classnames";
import React from "react";

import { initSelects } from "../jquery_wrapper";

class Select extends React.Component {
  componentDidMount() {
    initSelects();
  }

  render() {
    return (
      <div
        className={classNames("input-field", "select", "select-input", {
          error: this.props.error,
          inactive: this.props.value === "",
        })}
        style={{ width: `${this.props.size}%` }}
      >
        <select
          test-name={this.props.test}
          onChange={this.props.onChange}
          value={this.props.value}
        >
          <option value="" disabled>
            {this.props.placeholder}
          </option>
          {this.props.options.map((el, idx) => (
            <option key={idx} value={el.value}>
              {el.label}
            </option>
          ))}
        </select>
        <label className="active">
          {this.props.label} {this.props.required && " *"}
        </label>
      </div>
    );
  }
}

Select.defaultProps = {
  error: false,
  test: "",
  size: 100,
  placeholder: "",
  onChange: Function,
  options: [],
  required: true,
  label: "",
  value: "",
};

export default Select;
