import React from "react";
import { Box, Divider, Stack, Tooltip, Typography } from "@mui/material";
import { parserCurrencyBRLValue } from "../../shared/helpers";
import { totalBy } from "./budgets_service";

const BudgetProgress = ({ theme, props }) => {
  const limit = Number(props.limit);
  const performed = Number(props.performed);
  const approving = Number(props.approving);
  const over = Number(props.over);

  const total = totalBy(limit, performed, approving, over);
  const limitWidth = (limit / total) * 100;
  const performedWidth = (performed / total) * 100;
  const approvingWidth = (approving / total) * 100;
  const overWidth = (over / total) * 100;

  return <>
    <Stack flexGrow={1}>
      <Box
        display={"flex"}
        alignItems={"center"}
        sx={{
          width: "100%",
          height: "1.5rem",
          backgroundColor: "lightgray",
          borderRadius: 10,
          overflow: "hidden",
          position: "relative",
        }}
      >
        {
          performed > 0 &&
          <Box
            sx={{
              height: "100%",
              width: `${over > 0 ? limitWidth : performedWidth}%`,
              backgroundColor: theme.palette.status.success.main,
            }}
          />
        }
        {
          ((performed > 0 && performed < limit && approving > 0) || approving > limit) &&
          <Divider
            orientation="vertical"
            sx={{
              position: "absolute",
              left: `${limitWidth}%`,
              height: '100%',
              borderRight: '3px dotted black',
            }}
          />
        }
        {
          over > 0 &&
          <Tooltip
            arrow
            placement="top"
            title={`Excedido: ${parserCurrencyBRLValue(props.over)}`}
          >
            <Box
              sx={{
                height: "100%",
                width: `${overWidth}%`,
                backgroundColor: theme.palette.status.error.main,
              }}
            />
          </Tooltip>
        }
        {
          approving > 0 &&
          <Tooltip
            arrow
            placement="top"
            title={`Em aprovação: ${parserCurrencyBRLValue(props.approving)}`}
          >
            <Box
              sx={{
                height: "100%",
                width: `${approvingWidth}%`,
                backgroundColor: theme.palette.status.warning.main,
              }}
            />
          </Tooltip>
        }
      </Box>
      <Box sx={{ width: `${limitWidth}%` }}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{ mt: 1 }}
        >
          <Typography
            color={theme.palette.success.main}
          >
            Realizado: {parserCurrencyBRLValue(props.performed)}
          </Typography>
          <Typography
            color={"GrayText"}
          >
            Limite: {parserCurrencyBRLValue(props.limit)}
          </Typography>
        </Stack>
      </Box>
    </Stack>
  </>
}

export default BudgetProgress;