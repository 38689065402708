import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,
    CardActions,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import {OnboardingThumbnail} from "./OnboardingThumbnail";

export const OnboardingStep = ({step, open, onClick, onOpen, completed}) => {
    const handleSummaryClick = (_, expanded) => onClick(expanded);

    const summaryColor = open || completed ? 'white' : '#F0EBF5';

    return <Accordion
        disableGutters
        elevation={0}
        expanded={open}
        onChange={handleSummaryClick}
        TransitionProps={{unmountOnExit: true}}
        sx={{borderBottom: '1px solid #dddddd', '&:before': {display: 'none'}}}>
        <AccordionSummary sx={{background: summaryColor}} expandIcon={<ExpandMoreIcon/>}>
            <CheckCircleIcon color={completed ? 'secondary' : 'disabled'}/>
            <Typography sx={{ml: 1}}>{step.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Card elevation={0}>
                <OnboardingThumbnail
                    open={open}
                    onClick={() => onOpen(step)}
                    youtubeId={step.youtubeId}/>
                <CardActions sx={{p: 0, justifyContent: 'flex-end'}}>
                    <Button
                        onClick={() => onOpen(step)}
                        size={'small'}
                        variant={'contained'}>
                        Configurar agora
                    </Button>
                </CardActions>
            </Card>
        </AccordionDetails>
    </Accordion>
}