import React from "react";

import { Box, Card, CardContent, Typography } from "@mui/material";

import UsersAddModal from "./UsersAddModal";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

const UserCardEmpty = () => {
  return (
    <Card>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "30px",
        }}
      >
        <Box
          sx={{ p: 0.5, display: "flex" }}
          style={{
            backgroundColor: "rgba(240, 235, 245, 1)",
            borderRadius: 0.1,
            color: "#3D0079",
            marginBottom: "20px",
          }}
        >
          <ManageAccountsIcon sx={{ fontSize: "40px", margin: "10px" }} />
        </Box>
        <Typography style={{ fontSize: "20px", margin: "10px" }}>
          Gerencie os usuários que tem acesso a conta digital!
        </Typography>
        <Typography
          style={{ color: "rgba(0, 0, 0, 0.6)", marginBottom: "10px" }}
        >
          Escolha os usuários autorizados a ativar cartões, garantindo uma
          gestão mais eficiente e confiável da sua conta digital.
        </Typography>
        <UsersAddModal />
      </CardContent>
    </Card>
  );
};

export default UserCardEmpty;
