import SettingsIcon from "@mui/icons-material/Settings";
import {Alert} from "@mui/material";
import React from "react";

export const OmieConnectionSteps = () => {
    return <>
        <p><b>Passo 1: </b>Acessar meus aplicativos e exibir Chave de Integração API</p>
        <ul>
            <li>
                <b>Meus aplicativos: </b>
                no aplicativo configurado clique em “<SettingsIcon fontSize={'small'}/>” e selecione
                a opção “Resumo do app”.
            </li>
            <li>
                <b>Resumo do aplicativo: </b>
                na secção de “Chaves de integração API” clique em “Exibir” ao lado de “App secret”.
            </li>
        </ul>
        <p><b>Passo 2: </b> Copiar as Chaves de Integração API e inserir nos campos disponíveis
            dentro do Espresso</p>
        <ul>
            <li>
                Com as chaves “App key” e App secret” visíveis copie e insira nos campos dentro do
                Espresso.
            </li>
        </ul>
        <Alert severity="info" sx={{mt: 1}}>
            A partir desses passos realizados será possível testar a conexão com o ERP
        </Alert>
    </>
}
