import React, { useState, useEffect } from 'react';
import { Stack, Typography, Grid, Snackbar } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';
import LoadingChart from "../../../../../assets/images/admin/shared/empty_space_despesas.svg";
import { expenses_chart_admin_home_path } from '../../../../routes';
import HomeExpensesChart from "./HomeExpensesChart";
import HomeCardSummaryItem from "../common/HomeCardSummaryItem";
import AttachMoneyIcon from "@mui/icons-material/Person";

const HomeExpensesCardContent = (props) => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(expenses_chart_admin_home_path());
        const result = await response.json();

        setChartData(result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setSnackbarMessage(`Ocorreu um erro ao carregar o gráfico de despesas mensais: ${error}`);
        setOpenSnackbar(true);
      }
    };

    fetchData();
  }, []); 

  return (
    <Stack data-testid="expenses-content">
      <Typography mb={1} variant="h6" style={{color: "#3D0079"}}>
        Gestão de despesas
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <HomeCardSummaryItem
              title={`${props.totalReports} relatórios enviados`}
              subtitle={"no último mês"}
              icon={<DescriptionIcon fontSize="large"/>}/>
        </Grid>

        <Grid item xs={12} sm={6}>
          <HomeCardSummaryItem
              title={`${props.totalActiveUsers} usuários ativos`}
              subtitle={"no último mês"}
              icon={<PersonIcon fontSize="large"/>}/>
        </Grid>
      </Grid>

      {loading && !props.advanceLegacy && <LoadingChart />}
      {!loading && !props.advanceLegacy && (
        <Grid item xs={12}>
          <Typography variant="subtitle1" sx={{ marginTop: '10px' }}>Despesas mensais</Typography>

          <HomeExpensesChart {...chartData} />
        </Grid>
      )}

      <Snackbar
        data-testid="snackbar"
        open={openSnackbar}
        autoHideDuration={6000}
        message={snackbarMessage}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
      />
    </Stack>
  );
}

export default HomeExpensesCardContent;