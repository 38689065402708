import {
    Avatar,
    Badge,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Chip,
    CircularProgress,
    Stack,
    Typography
} from "@mui/material";
import React, {useCallback, useMemo, useState} from "react";
import {templateTypes} from "../../Index";
import SettingsIcon from "@mui/icons-material/Settings";
import {
    admin_erp_integration_template_path,
    sync_admin_erp_integration_template_path
} from "../../../../../../routes";
import {post} from "@rails/request.js";
import CheckIcon from "@mui/icons-material/Check";
import SyncIcon from "@mui/icons-material/Sync";
import dayjs from "dayjs";

export const ErpTemplateResume = ({erp, integration, templateType, template}) => {
    const [syncing, setSyncing] = useState(false)
    const [succeed, setSucceed] = useState(false)

    const erpTemplateType = useMemo(() => {
        return templateTypes.find(type => type.identifier === templateType)
    }, [template]);

    const sync = useCallback(async () => {
        setSyncing(true)
        const response = await post(sync_admin_erp_integration_template_path(erp, templateType))
        setSucceed(response.ok)
        if (response.ok) {
            window.location.reload()
        }
        setSyncing(false)
    }, [template]);

    return <Card>
        <CardHeader
            title={'Configuração'}
            titleTypographyProps={{variant: 'body1'}}
            action={template && <TemplateStatus integration={integration} template={template}/>}
            avatar={
                <Avatar variant={'rounded'} sx={{bgcolor: '#F5F2F8'}}>
                    <SettingsIcon color={'primary'}/>
                </Avatar>
            }/>
        <CardContent sx={{py: 0}}>
            {template && (
                <Typography variant={'body1'}>
                    {integration.status !== 'connected' ? (
                        'A integração está inativa'
                    ) : template.active ? (
                        'Configurado com sucesso'
                    ) : (
                        'O template foi desativado'
                    )}
                </Typography>
            )}
            {template && (
                <Typography variant={'body2'} color={'gray'}>
                    Monitore todas as modificações realizadas no sistema, com informações sobre o
                    usuário responsável, o tipo de alteração e o momento exato em que ela foi feita.
                    Garantia de rastreabilidade e segurança em cada ação.
                </Typography>
            )}
            {!template && (
                <Typography variant={'body1'}>{erpTemplateType.about[erp]}</Typography>
            )}
            {!template && (
                <Typography variant={'body2'} color={'gray'}>
                    Após acontecer a integração o histórico estará disponível. Nele você pode
                    monitorar
                    todas as modificações realizadas no sistema, com informações sobre o usuário
                    responsável, o tipo de alteração e o momento exato em que ela foi feita.
                    Garantia de
                    rastreabilidade e segurança em cada ação.
                </Typography>
            )}
            {!template && (
                <Typography sx={{mt: 2}} variant={'body1'}>
                    Como configurar?
                </Typography>
            )}
            {!template && (
                <Typography variant={'body2'} color={'gray'}>
                    {erpTemplateType.howTo[erp]}
                </Typography>
            )}
            <Stack direction={'row'} spacing={2} sx={{mt: 2}}>
                {template?.integrationCount > 0 && <Chip
                    color={'success'}
                    variant={'outlined'}
                    label={`${template.integrationCount} usuário(s) integrado(s)`}/>}
                {template?.failureCount > 0 && <Chip
                    color={'error'}
                    variant={'outlined'}
                    label={`${template.failureCount} usuário(s) com erro`}/>}
            </Stack>
        </CardContent>
        <CardActions sx={{p: 2, justifyContent: 'flex-end'}}>
            {integration.status === 'connected' && !template && (
                <Button
                    href={admin_erp_integration_template_path(erp, templateType)}
                    variant={'contained'}>
                    Configurar
                </Button>
            )}
            {integration.status === 'connected' && template?.active && (
                <SyncButton template={template} syncing={syncing} succeed={succeed} onSync={sync}/>
            )}
        </CardActions>
    </Card>
}

const SyncButton = ({template, syncing, succeed, onSync}) => {
    const label = useMemo(() => {
        if (syncing) return 'Sincronizando...'
        if (succeed) return 'Sincronizado'
        if (template.syncEnabled) return 'Sincronizar agora'

        const enabledAt = dayjs(template.lastSyncAt).add(1, 'hour');
        const duration = enabledAt.diff(dayjs(), 'minutes')
        return `Sincronização disponível em ${duration} minutos`
    }, [template, syncing, succeed]);

    const icon = useMemo(() => {
        if (syncing) return <CircularProgress size={24} color={'disabled'}/>
        if (succeed) return <CheckIcon/>
        return <SyncIcon/>
    }, [syncing, succeed]);

    return <Button
        startIcon={icon}
        variant={'outlined'}
        onClick={succeed ? null : onSync}
        color={succeed ? 'success' : 'primary'}
        disabled={!template.syncEnabled || syncing}>
        {label}
    </Button>
}

const TemplateStatus = ({integration, template}) => {
    return <Stack direction={'row'} sx={{mr: 1}}>
        <Badge
            variant={'dot'}
            sx={{mt: 1.5, mr: 1}}
            color={(integration.status === 'connected' && template.active) ? 'success' : 'disabledLight'}/>
        <Typography variant={'body1'}>
            {(integration.status === 'connected' && template.active) ? 'Ativado' : 'Desativado'}
        </Typography>
    </Stack>
}
