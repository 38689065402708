import React from "react";
import classNames from "classnames";

import Stage from "../shared/Stage";
import TermsAndPrivacyPolicy from "./parsers/TermsAndPrivacyPolicy";
import LegalInfosForm from "./parsers/LegalInfosForm";
import HoldersForm from "./parsers/HoldersForm";
import Resume from "./parsers/Resume";

class NewWeb extends React.Component {
  state = { stage: 1, edit: false };

  componentDidMount() {
    for (const [key, value] of Object.entries(this.props.stage)) {
      if (value) {
        this.setState({ ...this.state, stage: key });
      }
    }
  }

  render() {
    return (
      <div className="container design-system account-new-web">
        <Stage
          list={[
            {
              label: "Tudo pronto para começar?",
              active: this.props.stage["1"],
              selectStage: this.state.stage === 1,
            },
            {
              label: "Dados da empresa",
              active: this.props.stage["2"] || this.state.stage === 2,
              selectStage: this.state.stage === 2,
            },
            {
              label: "Dados dos sócios",
              active: this.props.stage["3"],
              selectStage: this.state.stage === 3,
            },
            {
              label: "Revisão",
              active: this.props.stage["4"],
              selectStage: this.state.stage === 4,
            },
          ]}
          edit={this.state.edit}
        />
        <section
          className={classNames({
            "card-panel": ["1", "2", 1, 2].includes(this.state.stage),
          })}
        >
          {this[`render_stage_${this.state.stage}`]()}
        </section>
      </div>
    );
  }

  render_stage_1() {
    return (
      <TermsAndPrivacyPolicy
        setState={(props) => this.setState({ ...this.state, ...props })}
      />
    );
  }

  render_stage_2() {
    return (
      <LegalInfosForm
        company_id={this.props.company_id}
        endpoint={this.props.legal_infos_endpoint}
        legal_infos={this.props.company_legal_infos}
        address={this.props.company_legal_infos_address}
        has_legal_info={this.props.stage["2"]}
        edit={this.state.edit}
      />
    );
  }

  render_stage_3() {
    return (
      <HoldersForm
        company_id={this.props.company_id}
        endpoint={this.props.holders_endpoint}
        holders={this.props.holders}
        edit={this.state.edit}
      />
    );
  }

  render_stage_4() {
    return (
      <Resume
        setStage={(stage) => this.setState({ stage, edit: true })}
        legal_infos={this.props.company_legal_infos}
        address={this.props.company_legal_infos_address}
        holders={this.props.holders}
        endpoint={this.props.account_endpoint}
        legal_infos_img={this.props.legal_infos_img}
        holders_img={this.props.holders_img}
        create_account={this.props.create_account}
        company_id={this.props.company_id}
        edit_redirect_endpoint={this.props.edit_redirect_endpoint}
      />
    );
  }
}

NewWeb.defaultProps = {
  stage: { 1: true },
  legal_infos_endpoint: "",
  company_legal_infos: {},
  company_legal_infos_address: {},
  holders_endpoint: "",
  holders: [],
  account_endpoint: "",
  legal_infos_img: "",
  holders_img: "",
  create_account: true,
  company_id: "",
  edit_redirect_endpoint: "",
};

export default NewWeb;
