import React from "react";
import { Button } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const TwoFactorCopyCodeButton = ({ value }) => {
  const onCopy = () => {
    navigator.clipboard.writeText(value);
  }
  
  return <Button
    color="disabled"
    startIcon={<ContentCopyIcon/>}
    onClick={onCopy}
    data-testid="copy-code-button"
  >
    { value }
  </Button>
}

export default TwoFactorCopyCodeButton;