import React from 'react';
import { Button, Box } from '@mui/material';
import { openModal } from '../../../shared/jquery_wrapper';
import { new_admin_account_path } from "../../../../routes";

const ButtonAccountOpening = ({ text, isPlanFree, marginTop }) => {
  const style = {
    backgroundImage: 'linear-gradient(to right, #0F001E, #3D0079)',
    mt: marginTop,
  };

  const handleClick = () => {
    if (isPlanFree) {
      openModal('#show-plans');
    } else {
      window.location.href = new_admin_account_path();
    }
  };

  return (
    <Box sx={{ mt: style.mt, justifyContent: { xs: 'center', lg: 'flex-start' }, display: 'flex' }}>
      <Button variant="contained" sx={{backgroundImage: style.backgroundImage}} onClick={handleClick}>
        {text}
      </Button>
    </Box>
  );
};

export default ButtonAccountOpening;
