import React from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const BudgetableField = ({ sx, value, placeholder, error, disabled, helperText, onClick, onChange, onKeyDown }) => {
  return <>
    <TextField
      fullWidth
      sx={sx}
      label="Buscar"
      variant="outlined"
      size="medium"
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      data-testid={"budgetable-field"}
      onClick={onClick}
      onChange={onChange}
      onKeyDown={onKeyDown}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  </>
}

export default BudgetableField;