import React, { useState } from "react";
import TwoFactorFaqDrawer from "./TwoFactorFaqDrawer";
import {FaqDrawerButton} from "../../components/faq/FaqDrawerButton";

const TwoFactorFaqButton = () => {
  const [openFaqDrawer, setOpenFaqDrawer] = useState(false);
  return <>
    <FaqDrawerButton onClick={() => setOpenFaqDrawer(true)}/>
    <TwoFactorFaqDrawer
      open={openFaqDrawer}
      onClose={() => setOpenFaqDrawer(false)}
    />
  </>
}

export default TwoFactorFaqButton;