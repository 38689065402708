import React from "react";

import {Stack} from "@mui/material";
import Container from "@mui/material/Container";
import {hot} from "react-hot-loader";
import {OmieUserConfig} from "./components/omie/OmieUserConfig";
import {SankhyaUserConfig} from "./components/sankhya/SankhyaUserConfig";
import EspressoTemplate from "../../EspressoTemplate";
import {PageNavbar} from "../../components/PageNavbar";
import {admin_erp_integration_template_events_path} from "../../../../routes";

const Show = (props) => {
    const {erp, templateType, integration, template} = props;

    return <EspressoTemplate {...props}>
        <Stack>
            <PageNavbar
                title={'Configuração'}
                backTo={admin_erp_integration_template_events_path(erp, templateType)}/>
            <Container maxWidth={'lg'} sx={{my: 5}}>
                {erp === 'sankhya' && (
                    <SankhyaUserConfig template={template}/>
                )}
                {erp === 'omie' && (
                    <OmieUserConfig integration={integration} template={template}/>
                )}
            </Container>
        </Stack>
    </EspressoTemplate>
}


export default hot(module)(Show)