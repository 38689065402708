import React from "react";
import { hot } from "react-hot-loader";
import { EspressoTheme } from "../EspressoTemplate";
import BlockedAppBar from "./BlockedAppBar";

const BlockedTemplate = () => {
  return <EspressoTheme>
    <BlockedAppBar/>
  </EspressoTheme>
}

export default hot(module)(BlockedTemplate);
