import * as React from "react";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import DescriptionIcon from "@mui/icons-material/Description";
import PaymentsIcon from "@mui/icons-material/Payments";
import {
    Avatar,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Skeleton
} from "@mui/material";

export default function GeneralSearchResult({loading, results, onClickResult}) {
    if (loading) {
        return <LoadingSearchResults/>
    } else if (results.length === 0) {
        return <EmptySearchResults/>
    }
    return <SearchListResults results={results} onClickResult={onClickResult}/>
}

const SearchListResults = ({results, onClickResult}) => {
    return <List data-testid={"search-list-results"}>
        {results.map((result, index) => (
            <ListItemButton onClick={onClickResult} divider key={index} href={result.link}>
                <ListItemIcon><SearchResultAvatar result={result}/></ListItemIcon>
                <ListItemText primary={result.title} secondary={result.subtitle}/>
            </ListItemButton>
        ))}
    </List>
}

const LoadingSearchResults = () => {
    return <ListItem data-testid={"loading-search-results"}>
        <ListItemIcon>
            <Skeleton variant="circular" width={40} height={40} animation={"wave"}/>
        </ListItemIcon>
        <ListItemText
            primary={<Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>}
            secondary={<Skeleton animation="wave" height={10} width="40%"/>}/>
    </ListItem>
}

const EmptySearchResults = () => {
    return <ListItem data-testid={"empty-search-results"}>
        <ListItemIcon><Avatar><SearchOffIcon/></Avatar></ListItemIcon>
        <ListItemText
            primary={"Nenhum resultado encontrado"}
            secondary={"Revise o termo de busca e tente novamente"}/>
    </ListItem>
}


const SearchResultAvatar = ({result}) => {
    if (result.type === 'User') {
        return <Avatar src={result.avatar}/>
    } else if (result.type === 'Report') {
        return <Avatar><DescriptionIcon/></Avatar>
    }
    return <Avatar><PaymentsIcon/></Avatar>
}