import React, {useEffect, useMemo, useState} from "react";
import {admin_lightboxes_path} from "../../routes";
import dayjs from "dayjs";
import {ShadowTheme} from "./ShadowTemplate";
import {Lightbox} from "./lightboxes/Lightbox";
import {hot} from "react-hot-loader";

const LightboxManagerShadow = (props) => {
    return <ShadowTheme rootSelector={props.rootSelector}>
        <LightboxManager {...props}/>
    </ShadowTheme>;
};

const LightboxManager = ({lastSeenAt, csrfToken}) => {
    const [open, setOpen] = useState(true);
    const [lightbox, setLightbox] = useState(null);
    const [lastSeen, setLastSeen] = useState(lastSeenAt ? dayjs(lastSeenAt * 1000) : null);

    const currentLightbox = useMemo(() => {
        if (lightbox && (lastSeen == null || dayjs(lightbox.createdAt).isAfter(lastSeen))) {
            return lightbox;
        }
        return null;
    }, [lightbox, lastSeen])

    const updateLightboxSeenAt = () => {
        fetch(admin_lightboxes_path(), {
            method: 'PUT', headers: {
                "X-CSRF-Token": csrfToken, 'Content-Type': 'application/json'
            },
        });
    }

    const getLightbox = async () => {
        try {
            const response = await fetch(admin_lightboxes_path())
            if (response.ok) {
                const body = await response.json();
                return body.lightbox;
            }
            return null;
        } catch (_) {
            return null;
        }
    }

    useEffect(() => {
        getLightbox().then((data) => setLightbox(data))
    }, []);

    const handleClose = () => {
        updateLightboxSeenAt();
        setLastSeen(dayjs())
        setOpen(false);
    };

    return <>
        {currentLightbox && <Lightbox lightbox={currentLightbox} open={open} onClose={handleClose}/>}
    </>
}

export default hot(module)(LightboxManagerShadow)