import React from "react";

const Card = ({ mastercard_logo, espresso_card_logo }) => {
  return (
    <div className="d-flex direction-column justify-between virtual-card">
      <div>
        <img src={espresso_card_logo} />
      </div>
      <div className="d-flex justify-end">
        <img src={mastercard_logo} />
      </div>
    </div>
  );
};

export default Card;
