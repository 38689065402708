import React from "react";

const YouTubeEmbed = ({ embedId }) => {
    return (
        <iframe
            width="100%"
            style={{ aspectRatio: "16/9" }}
            src={`https://www.youtube.com/embed/${embedId}?rel=0&amp;controls=1&amp;showinfo=0`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            data-testid={"youtube-embed"}
        />
    );
}

export default YouTubeEmbed;