import {Alert, AlertTitle, Button} from "@mui/material";
import React, {useState} from "react";
import {ErpTemplateConfig} from "../ErpTemplateConfig";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {OmieUserConfigSteps} from "./OmieUserConfigSteps";
import {OmieUserConfigForm} from "./OmieUserConfigForm";
import {updateTemplate} from "../../erp_template_connector";

export const OmieUserConfig = ({integration, template}) => {
    const [tag, setTag] = useState(template?.settings?.tag ?? '')

    return <ErpTemplateConfig
        erp={'omie'}
        title={'Configuração de usuários'}
        validConfig={tag.length > 0}
        onConfigure={() => updateTemplate('omie', 'user', {tag})}
        info={<OmieWebhookInfo/>}
        steps={<OmieUserConfigSteps webhooksUrl={integration.webhooksUrl}/>}
        form={(loading, failed) => (
            <OmieUserConfigForm
                tag={tag}
                failed={failed}
                disabled={loading}
                onChangeTag={setTag}/>
        )}/>
}

const OmieWebhookInfo = () => {
    return <Alert
        severity={'info'}
        action={
            <Button
                size={'small'}
                color={'inherit'}
                variant={'outlined'}
                target={'_blank'}
                href={'https://developer.omie.com.br/my-apps/'}
                startIcon={<OpenInNewIcon/>}>
                Webhook
            </Button>
        }>
        <AlertTitle>
            Para configurar o webhook e concluir a integração, clique em “configurar
            webhook”.
        </AlertTitle>
        Você será direcionado para a página de configuração, onde poderá fazer os ajustes
        necessários de forma rápida e fácil.
    </Alert>
}
