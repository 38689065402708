import {ShadowTheme} from "../ShadowTemplate";
import {Button, ListItem, ListItemAvatar, ListItemText, Stack} from "@mui/material";
import BaristaAILogo from "../../../../assets/images/admin/barista_ai/barista_ai_logo.svg"
import {hot} from "react-hot-loader";
import React from "react";
import Paper from "@mui/material/Paper";
import {useTheme} from "@mui/material/styles";
import WarningIcon from "@mui/icons-material/Warning";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";

const AlertFilterShadow = ({rootSelector, enabled}) => {
    const actionUrl = new URL(window.location.href)
    if (enabled) {
        actionUrl.searchParams.delete('filter[alert]')
    } else {
        actionUrl.searchParams.set('filter[alert]', '1')
    }

    return <ShadowTheme rootSelector={rootSelector}>
        {enabled ? <AlertFilterEnabled actionUrl={actionUrl.href}/> : <AlertFilter actionUrl={actionUrl.href}/>}

    </ShadowTheme>
}

const AlertFilter = ({actionUrl}) => {
    return <AlertFilterBase
        title={'Atenção'}
        icon={<WarningIcon/>}
        subtitle={'Encontrei alertas nos relatórios!'}
        action={<Button
            variant={'contained'}
            href={actionUrl}
            edge="end">
            Ver relatórios com alertas
        </Button>}/>
}

const AlertFilterEnabled = ({actionUrl}) => {
    return <AlertFilterBase
        title={'Pronto!'}
        icon={<AutoAwesomeIcon/>}
        subtitle={'Aqui estão os relatórios com alertas:'}
        action={<Button
            variant={'outlined'}
            href={actionUrl}
            edge="end">
            Voltar para os todos relatórios
        </Button>}/>
}

const AlertFilterBase = ({title, icon, subtitle, action}) => {
    const theme = useTheme();

    return <Paper sx={{background: theme.palette.surfaceLight5.main, mx: 1.5, my: 3, border: '1px solid #D9A9FF'}}>
        <ListItem
            secondaryAction={action}>
            <ListItemAvatar>
                <BaristaAILogo style={{height: 40, width: 40}}/>
            </ListItemAvatar>
            <ListItemText
                primaryTypographyProps={{color: 'primary', fontWeight: 500}}
                secondaryTypographyProps={{color: 'primary', fontWeight: 400}}
                secondary={subtitle}>
                <Stack direction={'row'} spacing={1}>{icon}<span>{title}</span></Stack>
            </ListItemText>
        </ListItem>
    </Paper>
}

export default hot(module)(AlertFilterShadow);