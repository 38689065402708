import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import DialogContent from "@mui/material/DialogContent";

export const EventLogDialog = ({event, onClose}) => {
    return <Dialog maxWidth={'sm'} fullWidth open={true} onClose={onClose}>
        <DialogTitle>Registro de logs</DialogTitle>
        <DialogContent sx={{pr: 4}}>
            <ul>
                {event.archivedAt && <li>{formatDate(event.archivedAt)} - Arquivado</li>}
                {event.failedAt && <li>{formatDate(event.failedAt)} - Erro</li>}
                {event.completedAt && <li>{formatDate(event.completedAt)} - Concluído</li>}
                <li>{formatDate(event.createdAt)} - Pendente</li>
            </ul>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Fechar</Button>
        </DialogActions>
    </Dialog>
}

const formatDate = (date) => {
    return dayjs(date).format('DD/MM/YYYY[ às ]HH:mm')
}