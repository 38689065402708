import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const FaqDrawerHeader = ({ onClose }) => {
  return <Stack
    direction={"row"}
    justifyContent={"space-between"}
    alignItems={"center"}
  >
    <Typography variant="h5">Precisa de ajuda?</Typography>
    <IconButton onClick={onClose} data-testid={"close-drawer"}>
      <CloseIcon/>
    </IconButton>
  </Stack>
}

export default FaqDrawerHeader;