import {BistroTableCell, BistroTableColumn, BistroTableRow} from "../../../components/collection/table/EspressoTable";
import {Typography} from "@mui/material";
import React from "react";

export const statementColumns = [
    BistroTableColumn({id: 'company_name', padding: 'none', label: 'Empresa', filterable: true}),
    BistroTableColumn({id: 'cost', label: 'Valor'}),
    BistroTableColumn({
        id: 'performed_at',
        label: 'Data e hora',
        filterable: true,
        filterType: 'datetime'
    }),
    BistroTableColumn({id: 'origin', label: 'Conta de Origem'}),
    BistroTableColumn({
        id: 'status',
        label: 'Situação',
        filterable: true,
        filterType: 'select',
        values: [
            {value: 0, label: 'Recebido'},
            {value: 1, label: 'Transferindo da estrutura banking'},
            {value: 2, label: 'Aguardando transferência para conta'},
            {value: 3, label: 'Transferindo para a conta'},
            {value: 4, label: 'Transferência concluída'},
            {value: 5, label: 'Falha ao transferir da estrutura banking'},
            {value: 6, label: 'Falha ao transferir para conta'}
        ],
        cellBuilder: (cell, statement) => {
            return <Typography color={statusColors[statement.status]}>{cell.value}</Typography>
        }
    })
];

const statusColors = {
    0: '#607d8b',
    1: 'indigo',
    2: 'indigo',
    3: 'indigo',
    4: 'teal',
    5: 'red',
    6: 'red',
}

export const statementRows = (statements) => {
    return statements.map((statement) => BistroTableRow({
        id: statement.id,
        data: statement,
        cells: new Map([
            ['company_name', BistroTableCell({value: statement.companyName})],
            ['cost', BistroTableCell({value: statement.cost})],
            ['performed_at', BistroTableCell({value: statement.performedAt})],
            ['origin', BistroTableCell({value: statement.origin})],
            ['status', BistroTableCell({value: statement.statusName})],
        ])
    }));
}