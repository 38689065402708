import {
    add_bistro_company_user_workflows_path,
    bistro_company_user_workflows_path,
    remove_bistro_company_user_workflows_path, replace_bistro_company_user_workflows_path
} from "../../../../routes";

const headers = (csrfToken) => {
    return {"X-CSRF-Token": csrfToken, 'Accept': 'application/json'};
}

const fetchUrl = (companyId, userId, page, perPage, filter) => bistro_company_user_workflows_path(
    companyId,
    userId,
    {page, per_page: perPage, filter}
);

const addUrl = (companyId, userId, selectAll, memberIds, email, filter) => add_bistro_company_user_workflows_path(
    companyId,
    userId,
    {all_except: selectAll, member_ids: memberIds, email, filter}
);

const removeUrl = (companyId, userId, selectAll, memberIds, filter) => remove_bistro_company_user_workflows_path(
    companyId,
    userId,
    {all_except: selectAll, member_ids: memberIds, filter}
);

const replaceUrl = (companyId, userId, selectAll, memberIds, email, filter) => replace_bistro_company_user_workflows_path(
    companyId,
    userId,
    {all_except: selectAll, member_ids: memberIds, email, filter}
);

export const formatFilters = (filters) => {
    if (filters.length === 0) return undefined;

    return filters.reduce((a, v) => ({...a, [v.column]: v.value}), {});
}

export const addWorkflowMembers = async (csrfToken, companyId, userId, selectAll, memberIds, email, filters) => {
    return fetchJobId(() => fetch(
        addUrl(companyId, userId, selectAll, memberIds, email, formatFilters(filters)),
        {method: 'POST', headers: headers(csrfToken)}
    ));
}

export const removeWorkflowMembers = async (csrfToken, companyId, userId, selectAll, memberIds, filters) => {
    return fetchJobId(() => fetch(
        removeUrl(companyId, userId, selectAll, memberIds, formatFilters(filters)),
        {method: 'DELETE', headers: headers(csrfToken)}
    ));
}

export const replaceWorkflowMembers = async (csrfToken, companyId, userId, selectAll, memberIds, email, filters) => {
    return fetchJobId(() => fetch(
        replaceUrl(companyId, userId, selectAll, memberIds, email, formatFilters(filters)),
        {method: 'POST', headers: headers(csrfToken)}
    ));
}

export const fetchJobId = async (request) => {
    try {
        const response = await request();
        if (response.ok) {
            const data = await response.json();
            return data.jobId;
        }
        return null;
    } catch {
        return null;
    }
}

export const fetchWorkflowMembers = async (csrfToken, companyId, userId, page, perPage, filters) => {
    try {
        const response = await fetch(
            fetchUrl(companyId, userId, page, perPage, formatFilters(filters)),
            {method: 'GET', headers: headers(csrfToken)}
        )
        if (response.ok) {
            return response.json();
        }
        return {page, perPage, data: [], error: 'Não foi possível carregar a página'}
    } catch {
        return {page, perPage, data: [], error: 'Não foi possível carregar a página'}
    }
}