import React, {forwardRef, useImperativeHandle, useState} from "react";
import Paper from "@mui/material/Paper";
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Pagination,
    Skeleton,
    Stack
} from "@mui/material";

export const PaginatedList = forwardRef((props, ref) => {
    const {items, fetchPage, totalCount, perPage, itemBuilder, onEmpty} = props;

    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(items)
    const [totalRows, setTotalRows] = useState(totalCount)

    const emptyRows = page > 1 ? Math.max(0, perPage - data.length) : 0;

    const loadPage = async (page) => {
        setLoading(true);
        const pageData = await fetchPage(page);
        setData(pageData.data);
        setPage(pageData.currentPage);
        setTotalRows(pageData.totalCount);
        setLoading(false);
        if (page === 1 && pageData.data.length === 0) onEmpty()
    };

    const refresh = () => loadPage(page);
    useImperativeHandle(ref, () => ({refresh}), [page]);

    return <Paper>
        <Stack>
            <List>
                {[...data.map(event => (
                    loading ? (
                        <LoadingEventPlaceholder key={event.uuid}/>
                    ) : (
                        itemBuilder(event)
                    )
                ))]}
                {emptyRows > 0 && (
                    <Box sx={{height: 72 * emptyRows}}/>
                )}
            </List>
            <Pagination
                page={page}
                shape="rounded"
                count={Math.ceil(totalRows / perPage)}
                onChange={(_, page) => loadPage(page)}
                sx={{m: 2, alignSelf: 'flex-end'}}/>
        </Stack>
    </Paper>
})

export const LoadingEventPlaceholder = () => {
    return <ListItem>
        <ListItemAvatar>
            <Skeleton variant={'circular'} width={40} height={40}/>
        </ListItemAvatar>
        <ListItemText
            primary={<Skeleton variant="text" width={300}/>}
            secondary={<Skeleton variant="text" width={200}/>}/>
    </ListItem>
}