export default class BistroFeaturesRepository {
    constructor(endpoint) {
        this.endpoint = endpoint
    }

    getFeatures() {
        return $.ajax({type: "GET", dataType: "json", url: `${this.endpoint}`})
    }

    updateFeature(feature, alwaysActive) {
        return $.ajax({
            type: "PUT",
            contentType: "application/json",
            url: `${this.endpoint}/${feature.id}`,
            data: JSON.stringify({
                data: {
                    always_active: alwaysActive
                }
            }),
        })
    }

    enablePlan(feature, planLevel) {
        return $.ajax({
            type: "POST",
            contentType: "application/json",
            url: `${this.endpoint}/${feature.id}/enable`,
            data: JSON.stringify(enableRequestBody(planLevel, feature)),
        })
    }

    disablePlan(feature, planLevel) {
        return $.ajax({
            type: "POST",
            contentType: "application/json",
            url: `${this.endpoint}/${feature.id}/disable`,
            data: JSON.stringify({
                data: {
                    plan_level: planLevel
                }
            }),
        })
    }
}

const enableRequestBody = (planLevel, feature) => {
    return {
        data: {
            plan_level: planLevel,
            always_active: feature.always_active
        }
    };
}