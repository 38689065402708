import React from "react";

class SearchField extends React.Component {
  state = {};

  render() {
    return (
      <div className={`input-field col s4 ${this.props.customClass}`}>
        <input
          test-name="search-input"
          id="input_search"
          type="text"
          placeholder={this.props.placeholder}
          value={this.props.value}
          onChange={this.props.onChange}
          onKeyDown={this.props.onKeyDown}
        />
        <label
          className={`active ${this.props.labelGray ? "gray" : ""}`}
          htmlFor="input_search"
        >
          Buscar
        </label>
      </div>
    );
  }
}

SearchField.defaultProps = {
  placeholder: "",
  onChange: Function,
  onKeyDown: Function,
  value: "",
  customClass: "",
  labelGray: false,
};

export default SearchField;
