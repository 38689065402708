import {
    admin_onboarding_path,
    complete_admin_onboarding_path,
    skip_step_admin_onboarding_path,
    ignore_admin_onboarding_path
} from "../../../routes";

const fetchOnboarding = async (request) => {
    try {
        const response = await request();
        if (response.ok) {
            const body = await response.json();
            return body.onboarding;
        }
        return null;
    } catch (_) {
        return null;
    }
}

export const skipStep = async (csrfToken, onboardingId, step) => {
    return fetchOnboarding(() => fetch(
        skip_step_admin_onboarding_path(onboardingId, {step}),
        {method: 'POST', headers: {"X-CSRF-Token": csrfToken}}
    ));
}

export const completeOnboarding = async (csrfToken, onboardingId) => {
    return fetchOnboarding(() => fetch(
        complete_admin_onboarding_path(onboardingId),
        {method: 'POST', headers: {"X-CSRF-Token": csrfToken}}
    ));
}

export const ignoreOnboarding = async (csrfToken, onboardingId) => {
    return fetchOnboarding(() => fetch(
        ignore_admin_onboarding_path(onboardingId),
        {method: 'POST', headers: {"X-CSRF-Token": csrfToken}}
    ));
}

export const getOnboarding = async (identifier) => {
    return fetchOnboarding(() => fetch(admin_onboarding_path(identifier)));
}