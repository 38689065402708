import React, { useState } from "react";
import { ShadowTheme } from "../ShadowTemplate";

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";

import { admin_account_billing_path } from "../../../../../app/javascript/routes";

import { showSnackbar } from "../../shared/jquery_wrapper";
import {
  parserCurrencyBRLValue,
  parserDate,
  parserValueWithBrlCurrency,
} from "../../shared/helpers";
import moment from "moment";
import { hot } from "react-hot-loader";

const DepositAccount = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const [amount, setAmount] = useState("");

  const generateCopyBarcode = () => {
    setShowDialog(true);

    const tmpAmount = amount
      .replace("R$", "")
      .replace(/\./gm, "")
      .replace(",", ".");

    $.ajax({
      type: "POST",
      url: admin_account_billing_path(),
      data: { amount: Number(tmpAmount) },
    })
      .then((r) => {
        showSnackbar({
          content:
            "O código do boleto está sendo gerado e logo estará disponível!",
          style: "notice",
        });
        setShowDialog(false);
        window.location.reload(false);
      })
      .catch((e) => {
        setShowDialog(false);
        showSnackbar({
          content: "Não foi possivel gerar o boleto, tente novamente!",
          style: "alert",
        });
      });
  };

  const swapInfos = (tittle) => (
    <Grid container spacing={2} style={{ marginTop: "0px" }}>
      <Grid item xs={5}>
        <span style={{ fontWeight: "bold" }}>{tittle}</span>
        <p style={{ marginTop: "0" }}>
          Swap Meios de Pagamentos Instituição de Pagamentos S.A
        </p>
      </Grid>
      <Grid item xs={4}>
        <span style={{ fontWeight: "bold" }}>CNPJ</span>
        <p style={{ marginTop: "0" }}>31.680.151/0001-61</p>
      </Grid>
    </Grid>
  );

  const waitingDialog = () => {
    return (
      <Dialog open={showDialog}>
        <Paper>
          <DialogTitle>Gerando o código de barras!</DialogTitle>
          <DialogContent>
            <Typography>Leva apenas alguns instantes.</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              data-testid="close-dialog"
              /* istanbul ignore file */
              onClick={() => setShowDialog(false)}
            >
              fechar
            </Button>
            <Box display={"flex"} justifyContent={"center"} width={40}>
              <CircularProgress size={24} color="success" />
            </Box>
          </DialogActions>
        </Paper>
      </Dialog>
    );
  };

  const copyCode = (code) => {
    navigator.clipboard?.writeText(code);
    showSnackbar({
      content: "Código copiado com sucesso!",
      style: "notice",
    });
  };

  const parseBillingStatus = (status) => {
    const statusMap = {
      requested: "Processando",
      enqueued: "Processando",
      processing: "Processando",
      created: "Disponível para pagamento",
      processing_payment: "Processando pagamento",
      paid: "Pago",
    };

    return statusMap[status] || "Erro no boleto";
  };

  const showBillingCode = (status, created_at) =>
    ["processing", "created"].includes(status) &&
    dateMore30Days(created_at) >= moment();

  const dateMore30Days = (date) => moment(date).add(30, "days");

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      {waitingDialog()}
      <React.Fragment>
        <h4 style={{ fontSize: "34px", fontWeight: "400", margin: "0" }}>
          Pix
        </h4>
        Para adicionar saldo, basta copiar e colar a chave Pix e realizar o
        pagamento na instituição financeira da sua escolha.
        <ClientInfo legalName={props.legalName} document={props.document} />
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <TextField
              style={{ width: "100%" }}
              label="Chave PIX"
              variant="outlined"
              size="medium"
              value={props.pix}
              disabled
            />
            <FormHelperText size="small">
              O código deve ser utilizado no campo de "PIX copia e cola".
            </FormHelperText>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{
                backgroundColor: "#3D0079",
                color: "white",
                height: "56px",
                minWidth: "156px",
              }}
              data-testid="btn-copy-paste-pix"
              onClick={() => copyCode(props.pix)}
            >
              <ContentCopyIcon style={{ marginRight: "4px" }} />
              Copiar código
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
      <React.Fragment>
        <h4 style={{ fontSize: "34px", fontWeight: "400", marginBottom: "0" }}>
          Boleto
        </h4>
        Para adicionar saldo, defina o valor do depósito, copie o código de
        barras e realize o pagamento na instituição financeira da sua escolha. O
        Prazo para compensação do boleto e disponibilização do saldo em conta, é
        de até 3 dias úteis.
        {swapInfos("Favorecido")}
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <TextField
              style={{ width: "100%" }}
              data-testid={"amount-input"}
              label="Valor do depósito"
              variant="outlined"
              size="medium"
              value={amount}
              onChange={(e) =>
                setAmount(parserValueWithBrlCurrency(e.target.value))
              }
            />
            <FormHelperText size="small">
              O valor mínimo para depósito é de R$10,00.
            </FormHelperText>
          </Grid>
          <Grid item xs={2}>
            <Button
              style={{
                backgroundColor: "#3D0079",
                color: "white",
                height: "56px",
                minWidth: "156px",
              }}
              onClick={generateCopyBarcode}
              data-testid="btn-copy-paste-billing"
            >
              <HorizontalSplitIcon
                style={{ marginRight: "4px", transform: "rotate(90deg)" }}
              />
              Gerar boleto
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
      <React.Fragment>
        <h4 style={{ fontSize: "24px", fontWeight: "400", marginBottom: "0" }}>
          Controle de boletos
        </h4>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell style={{ minWidth: "100px" }}>Valor</TableCell>
              <TableCell>Vencimento</TableCell>
              <TableCell>Código do boleto</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.billings.map((billing, idx) => (
              <TableRow key={idx}>
                <TableCell>{parseBillingStatus(billing.status)}</TableCell>
                <TableCell>
                  {parserCurrencyBRLValue(Number(billing.cost))}
                </TableCell>
                <TableCell>
                  {parserDate(dateMore30Days(billing.createdAt))}
                </TableCell>
                <TableCell>
                  {showBillingCode(billing.status, billing.createdAt) &&
                    billing.digitableLine}
                </TableCell>
                <TableCell align="right">
                  {showBillingCode(billing.status, billing.createdAt) && (
                    <ContentCopyIcon
                      style={{ color: "#3D0079", cursor: "pointer" }}
                      onClick={() => copyCode(billing.digitableLine)}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </React.Fragment>
    </ShadowTheme>
  );
};

const ClientInfo = ({ legalName, document }) => {
  return (
    <Grid container spacing={2} style={{ marginTop: "0px" }}>
      <Grid item xs={5}>
        <span style={{ fontWeight: "bold" }}>Titular</span>
        <p style={{ marginTop: "0" }}>{legalName}</p>
      </Grid>
      <Grid item xs={4}>
        <span style={{ fontWeight: "bold" }}>CNPJ</span>
        <p style={{ marginTop: "0" }}>{document}</p>
      </Grid>
    </Grid>
  );
};

export default hot(module)(DepositAccount);
