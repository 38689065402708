import React from 'react';
import { ShadowTheme } from '../../ShadowTemplate';
import { hot } from 'react-hot-loader';
import PromotionalInfoBox from './PromotionalInfoBox'
import CarouselCard from './CarouselCard';
import HandRaising from './HandRaising';

const AccountOpening = (props) => {
  const textButton = () => {
    return props.planLevel > 2 ? 'Abrir conta digital gratuita' : 'Abrir conta digital';
  };

  const isPlanFree = () => {
    return props.planLevel < 3;
  }

  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <CarouselCard textButton={textButton()} isPlanFree={isPlanFree()} />
      <PromotionalInfoBox textButton={textButton()} isPlanFree={isPlanFree()} />
      <HandRaising isPlanFree={isPlanFree()} userId={props.userId} companyId={props.companyId}  />
    </ShadowTheme>
  );
};

export default hot(module)(AccountOpening);
