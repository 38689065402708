import React from "react";
import { Button, IconButton, Dialog, Stack, DialogContent, DialogActions, CardHeader } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import YouTubeEmbed from "../../shared/YouTubeEmbed";

const OnboardingStepModal = ({ open, onClose, onSkip, step, completed, planLevel, onUpgrade }) => {
    const requiredUpgrade = planLevel < step.minPlanLevel;
    const buttonProps = requiredUpgrade ? { onClick: () => onUpgrade() } : { href: step.actionPath }
    return (
        <Dialog open={open} maxWidth={"md"} fullWidth={true} disableEnforceFocus={true}>
            <CardHeader
                title={`Passo ${step.order + 1}: ${step.title}`}
                titleTypographyProps={{ sx: { mb: 1 } }}
                subheader={"É simples como passar um cafezinho!"}
                action={
                    <IconButton
                        onClick={onClose}
                        data-testid={"close-button"}>
                            <CloseIcon/>
                    </IconButton>
                }
            />                                                                                           
            <DialogContent sx={{ px: 2, py: 0 }}>
                <YouTubeEmbed embedId={step.youtubeId} />
                <Stack spacing={1} alignItems={"start"}>
                    <CardHeader
                        sx={{ p: 0 }}
                        title={step.subtitle}
                        titleTypographyProps={{ sx: { mb: 1 } }}
                        subheader={step.description}
                        subheaderTypographyProps={{ align: "justify" }}
                    />
                    <Button
                        target="_blank"
                        href={step.articleUrl}
                        sx={{ px: 0, textTransform: "none" }}
                    >
                            Ir para o material de apoio
                    </Button>
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
                {(step.skippable || requiredUpgrade) && !completed && <Button
                    onClick={onSkip}
                    data-testid={"skip-button"}>
                        Ignorar por enquanto
                </Button>}
                <Button
                    variant="contained"
                    target="_blank"
                    {...buttonProps}
                >
                    {requiredUpgrade ? 'FAZER UPGRADE DE PLANO' : step.actionLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default OnboardingStepModal;