import React, { useState, useEffect } from "react";
import { ShadowTheme } from "../../ShadowTemplate";
import BalanceForm from "./components/BalanceForm";
import SubAccountList from "./components/SubAccountList";
import { showSnackbar } from "../../../shared/jquery_wrapper";
import {
  parserCurrencyBRLValue,
  parserValueWithBrlCurrencyAllowZero,
} from "../../../shared/helpers";
import { hot } from "react-hot-loader";
import {
  parseAmount,
  calcNewBalance,
  postAdjuster,
} from "../../../shared/balance_service";
import AccountBulkBalanceAdjustmentChannel from "../../../../../assets/javascripts/channels/account/bulk_balance_adjustment";
import { v4 as uuidv4 } from "uuid";
import { Button, Card, CardActions } from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

const BulkBalance = ({
  balance: initialBalance,
  subAccounts: initialSubAccounts,
  currentUser,
  rootSelector,
}) => {
  const [balanceAccount, setBalanceAccount] = useState(initialBalance);
  const [list, setList] = useState(initialSubAccounts);
  const [progressData, setProgressData] = useState({});
  const [lastProcessedSubAccount, setLastProcessedSubAccount] = useState(null);
  const [updateAmount, setUpdateAmount] = useState(0);
  const [updateOperation, setUpdateOperation] = useState("");
  const [updateWithdrawAll, setUpdateWithdrawAll] = useState(false);
  const [balanceDeficit, setBalanceDeficit] = useState(0);
  const [webSocketUniqueKey] = useState(uuidv4());

  const [operation, setOperation] = useState("deposit");
  const [withdrawAll, setWithdrawAll] = useState(false);

  // submit
  const [disableConfirmSubmit, setDisableConfirmSubmit] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [amount, setAmount] = useState(parserValueWithBrlCurrencyAllowZero(0));

  const updateBalanceItems = (
    subAccountIds,
    updatedBalance,
    operation,
    withdrawAll
  ) => {
    const updatedList = [];
    let newBalanceAccount = Number(balanceAccount);

    list.forEach((subAccount) => {
      if (subAccountIds.includes(subAccount.id)) {
        updatedList.push({
          ...subAccount,
          balance: updateItem(operation, subAccount, updatedBalance),
        });

        newBalanceAccount += calcNewBalanceTermBySubAccount(
          subAccount,
          updatedBalance,
          operation,
          withdrawAll
        );
      } else {
        updatedList.push(subAccount);
      }
    });

    setBalanceAccount(Math.max(0, newBalanceAccount));

    return updatedList;
  };

  useEffect(() => {
    const subscription =
      connectBulkBalanceAdjustmentChannel(webSocketUniqueKey);

    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (
      progressData.sub_account_ids?.length &&
      progressData.status === "success"
    ) {
      setList(
        updateBalanceItems(
          progressData.sub_account_ids,
          updateAmount,
          updateOperation,
          updateWithdrawAll
        )
      );

      const lastSubAccountPosition = progressData.sub_account_ids.length - 1;
      const lastSubAccount = list.find(
        (subAccount) =>
          subAccount.id === progressData.sub_account_ids[lastSubAccountPosition]
      );

      setLastProcessedSubAccount(lastSubAccount);
    }
  }, [progressData.sub_account_ids]);

  useEffect(() => {
    if (list.some((subAccount) => subAccount.checked)) {
      setDisableConfirmSubmit(false);
    } else {
      setDisableConfirmSubmit(true);
    }
  }, [list]);

  // submit
  useEffect(() => {
    const isFinished =
      progressData.progress === -1 ||
      (progressData.total && progressData.progress >= progressData.total) ||
      balanceDeficit < 0;

    if (isFinished) {
      setIsSubmitting(false);
    }
  }, [progressData.progress]);

  const updateItem = (operation, subAccount, updatedBalance) => {
    const options = {
      adjust: updatedBalance,
      deposit: subAccount.balance + updatedBalance,
      withdraw: Math.max(0, subAccount.balance - updatedBalance),
    };
    return options[operation];
  };

  const calcNewBalanceTermBySubAccount = (
    subAccount,
    updatedBalance,
    operation,
    withdrawAll
  ) => {
    const withdrawSubtraction = withdrawAll
      ? subAccount.balance
      : updatedBalance;

    const termOperation = {
      adjust: subAccount.balance - updatedBalance,
      deposit: -updatedBalance,
      withdraw: withdrawSubtraction,
    };

    return termOperation[operation];
  };

  const postBalanceAdjuster = async (
    numberAmount,
    subAccountIds,
    operation
  ) => {
    try {
      await postAdjuster(
        numberAmount,
        subAccountIds,
        operation,
        webSocketUniqueKey
      );

      showSnackbar({
        content: "Saldo em processo de ajuste!",
        style: "notice",
      });
    } catch (e) {
      setList(initialSubAccounts);
      setProgressData({ progress: -1, status: "error" });
    }
  };

  /* istanbul ignore next */
  const handleUpdate = (amount, operation, withdrawAll = false) => {
    const numberAmount = withdrawAll
      ? list.reduce((acc, item) => acc + item.balance, 0)
      : parseAmount(amount);
    const filteredList = list.filter((e) => e.checked);
    const subAccountIds = filteredList.map((e) => e.id);
    const tmpBalance = calcNewBalance(
      operation,
      filteredList,
      numberAmount,
      balanceAccount,
      withdrawAll
    );

    setUpdateAmount(numberAmount);
    setUpdateOperation(operation);
    setUpdateWithdrawAll(withdrawAll);

    clearBalanceData();

    if (tmpBalance >= 0) {
      postBalanceAdjuster(numberAmount, subAccountIds, operation);
    } else {
      setBalanceDeficit(tmpBalance);
    }
  };

  const connectBulkBalanceAdjustmentChannel = (jobId) => {
    return AccountBulkBalanceAdjustmentChannel({
      userId: currentUser.id,
      key: jobId,
      onReceive: (data) => setProgressData(data),
      onConnected: () => {
        setDisableConfirmSubmit(false);
      },
    });
  };

  const clearBalanceData = () => {
    setBalanceDeficit(0);
    setProgressData({ progress: 0, total: null, status: "" });
    setLastProcessedSubAccount(null);
  };

  // submit
  const isProcessing = () =>
    progressData.progress > 0 && progressData.progress < progressData.total;

  const handleSubmit = () => {
    setIsSubmitting(true);

    handleUpdate(amount, operation, withdrawAll);

    setAmount(parserValueWithBrlCurrencyAllowZero(0));
  };

  return (
    <ShadowTheme rootSelector={rootSelector}>
      <Card style={{ padding: "16px" }}>
        <BalanceForm
          balance={parserCurrencyBRLValue(balanceAccount)}
          progressData={progressData}
          lastProcessedSubAccount={lastProcessedSubAccount}
          description={"Selecione na lista as subcontas e confirme o valor:"}
          balanceDeficit={balanceDeficit}
          amount={amount}
          setAmount={setAmount}
          operation={operation}
          setOperation={setOperation}
          withdrawAll={withdrawAll}
          setWithdrawAll={setWithdrawAll}
        />
        <SubAccountList list={list} setList={setList} />

        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button
            data-testid="confirm-submit"
            variant="contained"
            size="large"
            color="primary"
            startIcon={isSubmitting || isProcessing() ? null : <CheckIcon />}
            onClick={handleSubmit}
            disabled={disableConfirmSubmit || isSubmitting || isProcessing()}
          >
            {isSubmitting || isProcessing()
              ? "Enviando..."
              : `Confirmar (${list.filter((e) => e.checked).length})`}
          </Button>
        </CardActions>
      </Card>
    </ShadowTheme>
  );
};

export default hot(module)(BulkBalance);
