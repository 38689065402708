import React from "react";
import { ShadowTheme } from "../../../ShadowTemplate";

import UserCardEmpty from "./UserCardEmpty";
import UsersCardWithAlloweds from "./UsersCardWithAlloweds";

// last import
import { hot } from "react-hot-loader";

const Users = (props) => {
  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      {props.quantity === 0 ? (
        <UserCardEmpty />
      ) : (
        <UsersCardWithAlloweds quantity={props.quantity} />
      )}
    </ShadowTheme>
  );
};

export default hot(module)(Users);
