import React from "react";

import { Card, Stack, Typography } from "@mui/material";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const BudgetFeedback = ({ icon, title, titleColor, backgroundColor, smallScreen, testId }) => {
  return <>
    <Card
      data-testid={testId}
      elevation={0}
      sx={{ 
        mx: smallScreen ? "1rem" : "10rem",
        mb: 2, p: 2, backgroundColor: backgroundColor,
      }}
    >
      <Stack
        spacing={1}
        direction={"row"}
      >
        {icon}
        <Typography
          color={titleColor}
        >
          {title}
        </Typography>
      </Stack>
    </Card>
  </>
}

export const ClosedFeedback = ({ theme, smallScreen }) => {
  return <BudgetFeedback
    smallScreen={smallScreen}
    title={"O orçamento foi encerrado!"}
    titleColor={theme.palette.alert.info.main}
    backgroundColor={theme.palette.alert.info.background}
    icon={<InfoOutlinedIcon sx={{ color: theme.palette.info.main }}/>}
    testId={"closed-feedback"}
  />
}

export const RiskFeedback = ({ theme, smallScreen }) => {
  return <BudgetFeedback
    smallScreen={smallScreen}
    title={"Ao serem aprovadas as despesas que estão “Em aprovação”, o orçamento estipulado será ultrapassado!"}
    titleColor={theme.palette.alert.warning.main}
    backgroundColor={theme.palette.alert.warning.background}
    icon={<WarningAmberOutlinedIcon sx={{ color: theme.palette.warning.main }}/>}
    testId={"risk-feedback"}
  />
}

export const ExceededFeedback = ({ theme, smallScreen }) => {
  return <BudgetFeedback
    smallScreen={smallScreen}
    title={"O orçamento excedeu o limite estipulado!"}
    titleColor={theme.palette.alert.error.main}
    backgroundColor={theme.palette.alert.error.background}
    icon={<InfoOutlinedIcon sx={{ color: theme.palette.error.main }}/>}
    testId={"exceeded-feedback"}
  />
}