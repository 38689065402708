import React, { useEffect, useState } from "react";
import { Box, Dialog } from "@mui/material";
import TwoFactorConfigHeader from "./TwoFactorConfigHeader";
import TwoFactorConfigStepOne from "./TwoFactorConfigStepOne";
import TwoFactorConfigStepTwo from "./TwoFactorConfigStepTwo";
import TwoFactorConfigStepThree from "./TwoFactorConfigStepThree";

const titleByStep = (step) => {
  if (step == 1) {
    return "Ativação pelo Google Authenticator";
  } else if (step == 2) {
    return "Confirme o código gerado pelo Google Authenticator";
  }
  return "Duplo fator ativado!";
}

const TwoFactorConfigDialog = ({ open, onClose, secret, qrCode, csrfToken, smallScreen, mandatory }) => {
  const [step, setStep] = useState(1);
  const [backupCodes, setBackupCodes] = useState([]);

  useEffect(() => {
    if (backupCodes.length > 0) {
      setStep(3);
    }
  }, [backupCodes]);

  return <Dialog
    fullWidth
    open={open}
    maxWidth={"md"}
    data-testid="two-factor-config-dialog"
    closeAfterTransition={false}
  >
    <TwoFactorConfigHeader
      title={titleByStep(step)}
      onClose={onClose}
      canClose={step !== 3}
    />
    { step === 1 &&
      <TwoFactorConfigStepOne
        secret={secret}
        qrCode={qrCode}
        onNext={() => setStep(2)}
        smallScreen={smallScreen}
      />
    }
    {
      step === 2 &&
      <TwoFactorConfigStepTwo
        csrfToken={csrfToken}
        onNext={setBackupCodes}
        onBack={() => setStep(1)}
        smallScreen={smallScreen}
      />
    }
    {
      step === 3 &&
      <TwoFactorConfigStepThree
        backupCodes={backupCodes}
        mandatory={mandatory}
      />
    }
    <Box mt={1}/>
  </Dialog>
}

export default TwoFactorConfigDialog;