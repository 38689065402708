import {
    bistro_accounts_swap_funds_in_statement_index_path,
    retry_bistro_accounts_swap_funds_in_statement_path
} from "../../../../routes";
import {formatFilters} from "../../companies/users/workflow_service";

export const fetchStatements = async (csrfToken, page, perPage, filters = []) => {
    try {
        const url = bistro_accounts_swap_funds_in_statement_index_path({
            page,
            per_page: perPage,
            filter: formatFilters(filters)
        })
        const headers = {"X-CSRF-Token": csrfToken, 'Accept': 'application/json'};
        const response = await fetch(url, {method: 'GET', headers})
        if (response.ok) {
            return response.json();
        }
        return {page, perPage, data: [], error: 'Não foi possível carregar a página'}
    } catch {
        return {page, perPage, data: [], error: 'Não foi possível carregar a página'}
    }
}

export const retryTransfer = async (csrfToken, statementId) => {
    try {
        const url = retry_bistro_accounts_swap_funds_in_statement_path(statementId)
        const headers = {"X-CSRF-Token": csrfToken, 'Accept': 'application/json'};
        const response = await fetch(url, {method: 'POST', headers})
        return response.ok;
    } catch {
        return false;
    }
}