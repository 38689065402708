/* istanbul ignore file */

import React from "react";
import { loadPmaSummary } from "./parser/pmaStatement";

import { initModals, showSnackbar } from "../../shared/jquery_wrapper";
import { statement_syncer_bistro_accounts_pma_statement_index_path } from "../../../routes";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

class PmaSummary extends React.Component {
  state = {
    summary: {},
    interval: 1,
  };

  componentDidMount() {
    loadPmaSummary((response) => {
      this.setState({ summary: response.data.summary });
    });

    initModals();
  }

  render() {
    const { summary } = this.state;

    return (
      <div className="collection white pma-summary">
        {this.renderModalSyncStatement()}

        <h4 className="title">Saldo e taxas</h4>

        <div style={{ marginBottom: "25px" }}>
          <h3>Ações</h3>
          <div className="d-flex">
            <button
              className="btn modal-trigger"
              href="#modal-sync-pma-statement"
            >
              Sincronização do extrato
            </button>
          </div>
        </div>

        <div className="d-flex justify-between align-center">
          <div>
            <span>Taxas IOF's</span>
            <br />
            <h3 className="summary-info">{summary.iof_fee}</h3>
          </div>
          <div>
            <span>Taxas saques</span>
            <br />
            <h3 className="summary-info">{summary.withdrawal_fee}</h3>
          </div>
          <div>
            <span>
              Saldo pendente
              <i
                title="Valor pendente para entrar na PMA (Taxa de IOF e Taxa de Saques)"
                className="mdi mdi-information"
              ></i>
            </span>
            <br />
            <h3 className="summary-info pending-balance">
              {summary.pending_balance}
            </h3>
          </div>
          <div>
            <span>
              Saldo transitório
              <i
                title="Valor depositado pelo cliente e retirada da PMA"
                className="mdi mdi-information"
              ></i>
            </span>
            <br />
            <h3 className="summary-info transitory-balance">
              {summary.transitory_balance}
            </h3>
          </div>
          <div>
            <span>
              Saldo disponível PMA
              <i
                title="Somatório do saldo IOF/taxa de saques disponível PMA"
                className="mdi mdi-information"
              ></i>
            </span>
            <br />
            <h3 className="summary-info balance">{summary.balance}</h3>
          </div>
        </div>
      </div>
    );
  }

  renderModalSyncStatement() {
    return (
      <div id="modal-sync-pma-statement" className="modal modal-fixed">
        <div className="modal-content">
          <h5 className="tittle">
            <i className="mdi mdi-file-document icon" />
            Sincronização do extrato pma
          </h5>
          <div style={{ marginTop: "35px" }}>
            <p style={{ marginBottom: "25px" }}>
              Deve selecionar o intervalo de tempo que o extrato será
              sincronizado, e o extrato será sincronizado de forma assincrona de
              tal forma que é possivel continuar utilizando a plataforma
              enquanto o extrato é sincronizado em background.
            </p>
            <FormControl>
              <InputLabel id="sync-select-label">
                Intervalo em horas para sincronizar
              </InputLabel>
              <Select
                labelId="sync-select-label"
                id="sync-select"
                value={this.state.interval}
                label="Intervalo em horas para sincronizar"
                onChange={(e) => this.setState({ interval: e.target.value })}
                style={{ height: "37px", marginRight: "15px", width: "250px" }}
              >
                {[
                  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                  19, 20, 21, 22, 23, 24,
                ].map((n, i) => (
                  <MenuItem key={i} value={n}>
                    {n} {n === 1 ? "hora" : "horas"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <button className="btn" onClick={() => this.handleSync()}>
              Sincronizar
            </button>
          </div>
        </div>
        <div className="modal-footer">
          <button test-name="close-modal-button" className="btn modal-close">
            Fechar
          </button>
        </div>
      </div>
    );
  }

  handleSync() {
    $.ajax({
      type: "POST",
      dataType: "json",
      url: statement_syncer_bistro_accounts_pma_statement_index_path(),
      data: { interval: this.state.interval },
    })
      .then((r) => {
        showSnackbar({
          content: "Extrato está sendo sincronizado!",
          style: "notice",
        });
      })
      .catch((e) => {
        showSnackbar({
          content: "Não foi possivel solicitar a sincronização do extrato!",
          style: "alert",
        });
      });
  }
}

export default PmaSummary;
