import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import slide from '../../../../../../../app/assets/images/admin/account/account_opening/slide-2.webp';
import ButtonAccountOpening from '../ButtonAccountOpening';

const SecondStep = ({ textButton, isPlanFree }) => {
  return (
    <Container maxWidth="lg">
      <Grid container spacing={2} sx={{
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: {lg: '473px'}
      }}>
        <Grid item xs={12} lg={6} sx={{
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: { xs: 'center', lg: 'left' },
        }}>
          <Box p={2} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: { xs: 'center', lg: 'flex-start' },
            maxWidth: { xs: '324px', sm: '324px', md: '514px', lg: '527px' }
          }}>
            <Typography variant="h3" component="h2" gutterBottom fontWeight={900} color="#3D0079" sx={{
              fontSize: { xs: '24px', sm: '32px', md: '36px', lg: '40px' },
              textAlign: { xs: 'center', lg: 'left' },
              maxWidth: { xs: '229px', sm: '236px', md: '486px', lg: '325px' }
            }}>
              Diga adeus aos adiantamentos em dinheiro
            </Typography>
            <Typography sx={{
              fontSize: { xs: '14px', sm: '18px', md: '20px', lg: '20px' },
              fontWeight: 400,
              lineHeight: '35.2px',
              color: '#000000DE',
              textAlign: { xs: 'center', lg: 'left' },
              maxWidth: { xs: '203px', sm: '313px', md: '453px', lg: '527px' }
            }}>
              Com a conta digital, você deixa de fazer adiantamentos na conta do colaborador e passa a depositá-las em um cartão pré-pago da sua empresa. Assim, você evita riscos trabalhistas, reduz fraudes e acompanha os gastos em tempo real.
            </Typography>
            <ButtonAccountOpening text={textButton} isPlanFree={isPlanFree} marginTop={3} />
          </Box>
        </Grid>
        <Grid item lg={6} sx={{ 
            display: { lg: 'flex', xs: 'none' },
            justifyContent: 'center', 
            alignItems: 'center',
        }}>
          <Box sx={{ maxWidth: '100%', display: 'flex', justifyContent: 'center' }}>
            <img src={slide} alt="Descrição da imagem" style={{
              maxWidth: '100%',
              height: 'auto',
              maxHeight: '400px',
              objectFit: 'contain'
            }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SecondStep;
