import React from "react";
import { Avatar, Box, Divider, Stack, Typography } from "@mui/material";
import AccountBalanceProgress from "../common/AccountBalanceProgress";

const HomeSubscriptionItem = ({avatar, title, trailing, showDivider}) => {
  return <Stack data-testid="subscription-item">
    <Stack direction={"row"} alignItems={"center"} spacing={1.5}>
      <Avatar sx={{ width: 32, height: 32 }} src={avatar} />
      <Stack direction={"row"} justifyContent={"space-between"} sx={{ width: '100%' }}>
        <Typography>{title}</Typography>
        <Typography>{trailing}</Typography>
      </Stack>
    </Stack>
    {showDivider ? <Divider flexItem sx={{ my: 1 }} /> : <div/>}
  </Stack>
}

const HomeSubscriptionsCardContent = (props) => {
  return <>
    <AccountBalanceProgress {...props} />
    <Typography variant="subtitle1" sx={{ my: 1.2, mb: 2.2 }}>
      {props.lastEntries.length} últimos fornecedores pagos
    </Typography>
    <Box data-testid="subscriptions-content">
      {props.lastEntries.map((subscription, index) => 
        <HomeSubscriptionItem 
          key={index}
          avatar={subscription.avatar}
          title={subscription.title}
          trailing={subscription.trailing}
          showDivider={index !== props.lastEntries.length - 1} />)}
    </Box>
  </>
}

export default HomeSubscriptionsCardContent;