import React, { useState } from "react";

import { parserValueWithBrlCurrencyAllowZero } from "../../../../../../shared/helpers";
import { closeModal } from "../../../../../../shared/jquery_wrapper";

import {
  Card,
  CardContent,
  Checkbox,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  CardActions,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";

const BalanceForm = ({ balance, onUpdate }) => {
  const [operation, setOperation] = useState("deposit");
  const [withdrawAll, setWithdrawAll] = useState(false);
  const [amount, setAmount] = useState(parserValueWithBrlCurrencyAllowZero(0));
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAmountChange = (event) => {
    setAmount(parserValueWithBrlCurrencyAllowZero(event.target.value));
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    onUpdate(amount, operation, withdrawAll);
  };

  const handleOperationChange = (event) => {
    const newOperation = event.target.value;
    setOperation(newOperation);

    if (newOperation !== "withdraw") {
      setWithdrawAll(false);
    }
  };

  return (
    <Card sx={{ padding: 1 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          Saldo disponível para alocação
        </Typography>
        <Typography
          mb={3}
          variant="h5"
          component="div"
          style={{ color: "#3D0079" }}
        >
          {balance}
        </Typography>
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          Selecione uma das opções para definir o saldo:
        </Typography>
        <FormControl
          component="fieldset"
          sx={{ marginBottom: 3, marginLeft: 2, marginTop: 2 }}
        >
          <RadioGroup
            row
            aria-label="saldo"
            name="row-radio-buttons-group"
            value={operation}
            onChange={handleOperationChange}
          >
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="deposit"
              control={<Radio />}
              label="Adicionar saldo"
            />
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="adjust"
              control={<Radio />}
              label="Ajustar saldo"
            />
            <FormControlLabel
              sx={{ marginRight: 12 }}
              value="withdraw"
              control={<Radio />}
              label="Resgatar saldo"
            />
          </RadioGroup>
        </FormControl>

        <Typography sx={{ fontSize: 16 }} gutterBottom>
          Por favor, confirme o valor:
        </Typography>
        <TextField
          sx={{ mt: 1, width: "100%", maxWidth: "576px" }}
          label="Valor (R$)"
          variant="outlined"
          size="medium"
          value={amount}
          onChange={handleAmountChange}
          disabled={withdrawAll}
          placeholder="R$ 0,00"
        />
        {operation == "withdraw" && (
          <FormControlLabel
            sx={{ marginTop: 2, width: "100%" }}
            control={
              <Checkbox
                checked={withdrawAll}
                onChange={(e) => setWithdrawAll(e.target.checked)}
              />
            }
            label="Resgatar tudo"
          />
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "flex-end", mt: 2 }}>
        <Button
          variant="text"
          size="large"
          sx={{ color: "#000000" }}
          onClick={() =>
            closeModal("#js-modal-sub-account-change-balance.modal")
          }
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          size="large"
          color="primary"
          startIcon={isSubmitting ? null : <CheckIcon />}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Enviando..." : "Confirmar"}
        </Button>
      </CardActions>
    </Card>
  );
};

export default BalanceForm;
