import React from "react";
import { createTheme } from "@mui/material/styles";
import MailIcon from '@mui/icons-material/Mail';
import HelpIcon from '@mui/icons-material/Help';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EspressoLogo from "../../../../assets/images/logo_icon_outline.svg";
import {
    Button,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    ThemeProvider,
    Typography
} from "@mui/material";



const ContactButton = ({ icon, label, link }) => {
    return (
        <ListItem disablePadding>
            <ListItemButton href={link} target="_blank">
                <ListItemIcon sx={{ mr: -2.5 }}>
                    {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
            </ListItemButton>
        </ListItem>
    );
}

const SuccessfulOnboarding = ({ onBack }) => {
    const h4Theme = createTheme({
        typography: {
            h4: {
                fontSize: '1.60rem'
            }
        }
    });

    const links = {
        sendEmail: "mailto:suporte@espressoapp.com.br",
        supportHome: "https://suporte.espressoapp.com.br/support/home",
        suppportTicket: "https://suporte.espressoapp.com.br/support/tickets/new" 
    }

    return (
        <Stack height={'100%'} sx={{ m: 2 }} justifyContent={'space-between'}>
            <Stack>
                <Stack direction={"row"} alignItems={"center"}>
                    <EmojiEventsIcon sx={{ color: "#FFC30E", fontSize: 35, mr: 0.5 }}/>
                    <Typography variant="h4">Parabéns</Typography>
                </Stack>
                <Typography align="justify" sx={{ my: 2 }}>
                    Você acaba de concluir com sucesso o seu processo de onboarding.
                </Typography>
                <EspressoLogo fill={'#000'} style={{ height: 160 }} />
                <Typography align="justify" sx={{ mt: 2 }}>
                    Aproveite para tomar um cafezinho enquanto o Espresso te deseja boas-vindas!
                </Typography>
                <ThemeProvider theme={h4Theme}>
                    <Typography align="justify" variant="h5" sx={{ mt: 2 }}>
                        Precisa de Ajuda ou tem Dúvidas?
                    </Typography>
                </ThemeProvider>
                <Typography align="justify" sx={{ my: 1 }}>
                    Disponibilizamos vários canais de Suporte:
                </Typography>
                <ContactButton 
                    icon={<MailIcon />}
                    label={"E-mail"}
                    link={links.sendEmail} />
                <ContactButton 
                    icon={<HelpIcon />}
                    label={"Central de ajuda"}
                    link={links.supportHome} />
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
                <Button
                    color="inherit"
                    onClick={onBack}
                    startIcon={<ChevronLeftIcon />}>
                        VOLTAR
                </Button>
                <Button 
                    variant="contained"
                    startIcon={<SupportAgentIcon />}
                    href={links.suppportTicket}
                    target="_blank">
                        FALE CONOSCO
                </Button>
            </Stack>
        </Stack>
    );
}

export default SuccessfulOnboarding;