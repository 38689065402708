import React, {useEffect, useState} from 'react';
import {Box, Button, Dialog, DialogContent, Grid, IconButton, Stack} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {alpha, useTheme} from "@mui/material/styles";

export const Lightbox = ({open, onClose, lightbox}) => {
    const [step, setStep] = useState(0);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    const sortedSteps = () => {
        let steps = [...lightbox.steps];
        steps.sort((a, b) => a.order - b.order);
        return steps;
    }

    const steps = sortedSteps();

    const loadImage = step => new Promise((resolve, reject) => {
        const stepImage = new Image()
        stepImage.src = step.image ?? step.imageUrl;
        stepImage.onload = () =>  resolve();
        stepImage.onerror = reject;
    });

    useEffect(() => {
        Promise.all(steps.map(step => loadImage(step))).then(() => setImagesLoaded(true))
    }, [])

    return <>
        {imagesLoaded && (
            <LightboxDialog open={open} step={step} onClose={onClose}>
                {steps.map((item, index) => {
                    if (index === steps.length - 1) {
                        return <LightboxLastStep
                            key={index}
                            data={steps[steps.length - 1]}
                            onMainClick={onClose}
                            {...lightbox.buttons}/>
                    }
                    return <LightboxStep
                        key={index}
                        data={item}
                        onNextStep={() => setStep(index + 1)}/>
                })}
            </LightboxDialog>
        )}
    </>
}

const LightboxDialog = ({children, step, open, onClose}) => {
    return <Dialog
        data-testid={'lightbox-dialog'}
        open={open}
        onClose={onClose}
        maxWidth='md'>
        {children[step]}
    </Dialog>
}

const LightboxMainButton = ({label, href, target, onClick}) => {
    return <Button
        href={href}
        target={target}
        onClick={onClick}
        size={'large'}
        sx={{borderRadius: '24px'}}
        color={'secondary'}
        variant={'contained'}>
        {label}
    </Button>
}

const LightboxSecondaryButton = ({label, href, target, onClick}) => {
    return <Button
        href={href}
        target={target}
        onClick={onClick}
        size={'large'}
        sx={{borderRadius: '24px'}}
        color={'primary90'}
        variant={'outlined'}>
        {label}
    </Button>
}

const ForwardButton = ({variant, onClick}) => {
    return <StepButton variant={variant} onClick={onClick}>
        <ArrowForwardIcon/>
    </StepButton>;
}

const StepButton = ({children, variant, onClick}) => {
    const theme = useTheme();
    const color = variant === 'dark' ? '#FFF' : theme.palette.primary.main;

    return <IconButton
        onClick={onClick}
        aria-label={'Avançar'}
        sx={{
            color: color,
            background: alpha(color, 0.15),
            '&:hover': {
                background: alpha(color, 0.30)
            }
        }}>
        {children}
    </IconButton>
}

const LightboxStep = ({data, onNextStep}) => {
    return <LightboxStepBase
        onNextStep={onNextStep}
        variant={data.brightness}
        background={data.image ?? data.imageUrl}>
    </LightboxStepBase>
}

const LightboxLastStep = ({onMainClick, data, primary, secondary}) => {
    return <LightboxStepBase variant={data.brightness} background={data.image ?? data.imageUrl}>
        <Stack direction={'row'} spacing={2} sx={{mt: 4}}>
            <LightboxMainButton
                onClick={onMainClick}
                href={primary.url}
                target={'_blank'}
                label={primary.label}/>
            {secondary?.label?.length > 0 && secondary?.url?.length > 0 && (
                <LightboxSecondaryButton
                    href={secondary.url}
                    target={'_blank'}
                    label={secondary.label}/>
            )}
        </Stack>
    </LightboxStepBase>
}

const LightboxStepBase = ({children, background, variant, onNextStep, padding}) => {
    return <DialogContent sx={{px: 4, py: 2.5, backgroundSize: 'cover', backgroundImage: `url(${background})`}}>
        <Box
            width={576}
            height={onNextStep ? 320 : 360}
            display={'flex'}
            alignItems='center'
            justifyContent='center'
            sx={{p: padding}}>
            {children}
        </Box>
        {onNextStep && (
            <Grid container justifyContent='end'>
                <ForwardButton onClick={onNextStep} variant={variant}/>
            </Grid>
        )}
    </DialogContent>
}