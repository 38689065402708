import {
    Button,
    Card,
    CardActions,
    CardContent,
    CircularProgress,
    Stack,
    Typography
} from "@mui/material";
import React, {useCallback, useState} from "react";
import {ErpConnectionSteps} from "../../components/ErpConnectionSteps";
import {FaqDrawerButton} from "../../../components/faq/FaqDrawerButton";
import {admin_erp_integration_templates_path} from "../../../../../routes";
import CheckIcon from "@mui/icons-material/Check";

export const ErpTemplateConfig = (props) => {
    const {erp, title, onConfigure, validConfig, info, form, steps} = props;

    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);
    const [succeed, setSucceed] = useState(false);

    const onConfigureTemplate = useCallback(async () => {
        setLoading(true)
        const success = await onConfigure()
        setLoading(false)
        setFailed(!success)
        setSucceed(success)
        if (success) {
            window.location.assign(admin_erp_integration_templates_path(erp))
        }
    }, [onConfigure]);

    return <Stack spacing={2}>
        <Card>
            <CardContent>
                <Stack spacing={2}>
                    <Typography variant={'h6'}>{title}</Typography>
                    {info}
                    {form(loading, failed)}
                    <ErpConnectionSteps>{steps}</ErpConnectionSteps>
                </Stack>
            </CardContent>
            <CardActions sx={{p: 2, justifyContent: 'flex-end'}}>
                <Button
                    size={'large'}
                    variant={'contained'}
                    color={succeed ? 'success' : 'primary'}
                    onClick={succeed ? null : onConfigureTemplate}
                    startIcon={loading ? (
                        <CircularProgress size={24} color={'disabled'}/>
                    ) : succeed ? (
                        <CheckIcon/>
                    ) : null}
                    disabled={!validConfig || loading}>
                    {loading ? 'Configurando...' : succeed ? 'Configurado' : 'Configurar'}
                </Button>
            </CardActions>
        </Card>
        <FaqDrawerButton disabled={true}/>
    </Stack>
}