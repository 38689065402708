import { Chip, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from "@mui/material";
import React from "react";

const TwoFactorType = {
  app: "app",
  email: "email"
}

const TwoFactorTypeSelect = ({ smallScreen }) => {
  return <FormControl fullWidth>
    <RadioGroup defaultValue={TwoFactorType.app}>
      <Divider/>
      <FormControlLabel
        data-testid="two-factor-app-type"
        value={TwoFactorType.app}
        control={<Radio color="info"/>}
        label={
          <Stack my={1}>
            <Typography>Aplicativo autenticador</Typography>
            { 
              !smallScreen && <Typography variant="body2" color={"GrayText"}>
                A configuração do 2FA via aplicativo autenticador (como o Google Authenticator) gera códigos temporários para validar a identidade do usuário durante o login. Isso aumenta a segurança ao exigir algo que o usuário possui (o aplicativo) além de algo que ele sabe (a senha).
              </Typography>
            }
          </Stack>
        }
      />
      <Divider sx={{ mt: 1 }}/>
      <FormControlLabel
        disabled
        data-testid="two-factor-email-type"
        value={TwoFactorType.email}
        control={<Radio color="info"/>}
        label={
          <Stack my={1}>
            <Stack direction={"row"} alignItems={"center"}>
              <Typography mr={1}>E-mail</Typography>
              <Chip color="primary" label={"Em breve"} size="small" sx={{ fontSize: 12 }}/>
            </Stack>
            { 
              !smallScreen && <Typography variant="body2" color={"GrayText"}>
                A configuração do 2FA via e-mail envolve o envio de um código de verificação para o e-mail do usuário, que deve ser inserido para concluir a autenticação. Isso adiciona uma camada extra de segurança, exigindo acesso ao e-mail associado à conta.
              </Typography>
            }
          </Stack>
        }
      />
      <Divider sx={{ mt: 1 }}/>
    </RadioGroup>
  </FormControl>
}

export default TwoFactorTypeSelect;