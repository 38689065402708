import React from "react";

import { initModals, showSnackbar } from "../jquery_wrapper";

class SubAccountCancel extends React.Component {
  state = { canceling: false, id: "" };

  componentDidMount() {
    initModals();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.card !== this.props.card) {
      this.setState({ ...this.props.card });
    }
  }

  render() {
    return (
      <div
        id="modal-cancel-subscription"
        className="modal modal-fixed modal-subscription"
      >
        <div className="d-flex justify-between align-center modal-header">
          <h3>Cancelar cartão assinaturas</h3>
          <i className="mdi mdi-close modal-close" />
        </div>

        <div className="modal-content">
          <section className="d-flex align-center">
            <div className="icon-background d-flex align-center justify-center pointer">
              <i className="mdi mdi-delete icon-purple" />
            </div>
            O cancelamento do cartão é irreversível. Pense bem antes de
            prosseguir.
          </section>

          <div className="modal-footer">
            <button
              test-name="btn-cancel"
              className="btn cancel-card-btn"
              disabled={this.state.canceling}
              onClick={() => this.handleCancel()}
            >
              {this.state.canceling ? "cancelando..." : "cancelar cartão"}
            </button>
          </div>
        </div>
      </div>
    );
  }

  handleCancel() {
    this.setState({ canceling: true });

    $.ajax({
      type: "DELETE",
      url: this.props.endpoints.cancel.replace("sub_account_id", this.state.id),
    })
      .then((r) => {
        showSnackbar({ content: "Cartão cancelado!", style: "notice" });

        window.location.reload(false);
      })
      .catch((e) => {
        this.setState({ canceling: false });

        showSnackbar({
          content: "Não foi possivel cancelar o cartão!",
          style: "alert",
        });
      });
  }
}

SubAccountCancel.defaultProsp = {
  card: { id: "" },
  endpoints: {
    cancel: "",
  },
};

export default SubAccountCancel;
