import React, { useState } from "react";
import { Box, Button, Snackbar, Stack, Typography } from "@mui/material";
import { ShadowTheme } from "../../ShadowTemplate";
import { hot } from "react-hot-loader";
import { admin_users_two_factor_path } from "../../../../routes";
import TwoFactorDisableDialog from "./TwoFactorDisableDialog";

const TwoFactorManagementShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <TwoFactorManagement
      editable={props.editable}
      enabled={props.enabled}
      mandatory={props.mandatory}
      csrfToken={props.csrfToken}
    />
  </ShadowTheme>
}

const EnableButton = () => {
  const onEnable = () => {
    window.location.assign(admin_users_two_factor_path());
  }

  return <Button
    variant="contained"
    data-testid={"enable-two-factor-button"}
    onClick={onEnable}
  >
    CONFIGURAR
  </Button>
}

const DisableButton = ({ csrfToken, mandatory }) => {
  const [openDialog, setOpenDialog] = useState(false);
  return <>
    <Button
      variant="outlined"
      data-testid={"disable-two-factor-button"}
      onClick={() => setOpenDialog(true)}
    >
      DESCONFIGURAR
    </Button>
    <TwoFactorDisableDialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      mandatory={mandatory}
      csrfToken={csrfToken}
    />
  </>
}

const TwoFactorStatus = ({ enabled }) => {
  return <Typography
    color={"GrayText"}
    data-testid={"two-factor-status"}
  >
    { enabled ? 'ATIVADO' : 'DESATIVADO' }
  </Typography>
}

const TwoFactorManagement = ({ editable, enabled, mandatory, csrfToken }) => {
  return <Box
    border={1}
    borderRadius={1}
    borderColor={"grey.300"}
    p={2} mx={2}
  >
    <Stack>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h6">
          Duplo fator de autenticação
        </Typography>
        { !editable && <TwoFactorStatus enabled={enabled}/> }
        { editable && enabled && <DisableButton csrfToken={csrfToken} mandatory={mandatory}/> }
        { editable && !enabled && <EnableButton/> }
      </Stack>
      <Typography
        variant="body2"
        color={"grey.500"}
      >
        { enabled ? 'O Duplo Fator de Autenticação é uma configuração importante para a segurança dos seus dados, recomendamos manter ativado!'
          : 'Para reforçar a segurança do sistema, é recomendado a ativação do 2FA (Autenticador de Dois Fatores) na sua conta.' }
      </Typography>
    </Stack>
  </Box>
}

export default hot(module)(TwoFactorManagementShadow);