import React, { useState } from "react";
import HighchartsReact from "highcharts-react-official";

import { parserCurrencyBRLValue } from "../../../shared/helpers";

import {
  Alert,
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import BalanceAdjust from "./BalanceAdjust";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DonutLargeIcon from "@mui/icons-material/DonutLarge";
import Circle from "@mui/icons-material/Circle";

const BalanceCards = (props) => {
  const [avaliable, setAvaliable] = useState(
    props.balance - props.limitForToday
  );

  const [chartBalanceOptions, setChartBalanceOptions] = useState({
    credits: { enabled: false },
    tooltip: { enabled: false },
    colors: [
      avaliable > 0 || props.limitForToday === 0 ? "#D0B6AD" : "#EF5350",
      "#00CBA8",
    ],
    chart: { type: "pie", height: "120px" },
    title: { enabled: false, text: null },
    plotOptions: {
      pie: {
        dataLabels: { enabled: false },
        showInLegend: false,
      },
      series: { states: { hover: { enabled: false } } },
    },
    series: [
      {
        innerSize: "60%",
        data: [
          { name: "previsto para uso", y: Number(props.limitForToday) },
          {
            name: "disponível para uso",
            y: Number(avaliable > 0 ? avaliable : 0),
          },
        ],
      },
    ],
  });

  return (
    <React.Fragment>
      {avaliable <= 0 && props.limitForToday > 0 && (
        <Alert severity="error" sx={{ marginBottom: "24px" }}>
          {`Saldo insuficiente para o valor previsto para uso hoje, aloque mais
          ${parserCurrencyBRLValue(
            props.limitForToday - props.balance
          )} de saldo
          para cobrir os gastos previstos.`}
        </Alert>
      )}
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Card
          style={{
            width: "49%",
            minWidth: "500px",
            marginBottom: "25px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <CardHeader
            sx={{ fontSize: "20px" }}
            title={
              <Stack spacing={1.5} direction={"row"} alignItems={"center"}>
                <Box
                  sx={{ p: 0.5, display: "flex" }}
                  style={{
                    backgroundColor: "rgba(240, 235, 245, 1)",
                    borderRadius: 5.0,
                    color: "#3D0079",
                  }}
                >
                  <AttachMoneyIcon />
                </Box>
                <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
                  Saldo
                </Typography>
              </Stack>
            }
            action={<BalanceAdjust accountBalance={props.accountBalance} />}
          />
          <CardContent>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography sx={{ fontSize: "24px" }}>
                {parserCurrencyBRLValue(props.balance)}
              </Typography>
            </Grid>
          </CardContent>
        </Card>

        <Card style={{ width: "49%", minWidth: "500px", marginBottom: "25px" }}>
          <CardHeader
            sx={{ fontSize: "20px" }}
            title={
              <Stack spacing={1.5} direction={"row"} alignItems={"center"}>
                <Box
                  sx={{ p: 0.5, display: "flex" }}
                  style={{
                    backgroundColor: "rgba(240, 235, 245, 1)",
                    borderRadius: 5.0,
                    color: "#3D0079",
                  }}
                >
                  <DonutLargeIcon />
                </Box>
                <Typography variant="subtitle1" sx={{ fontSize: "20px" }}>
                  Distribuição de saldo
                </Typography>
              </Stack>
            }
          />
          <CardContent style={{ padding: 0 }}>
            <div style={{ display: "flex" }}>
              <Grid item style={{ width: "100px" }}>
                <HighchartsReact options={chartBalanceOptions} />
              </Grid>
              <List>
                <ListItem>
                  <ListItemAvatar style={{ minWidth: "32px" }}>
                    <Circle
                      sx={{
                        color:
                          avaliable > 0 || props.limitForToday === 0
                            ? "#D0B6AD"
                            : "#EF5350",
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText>
                    Saldo previsto para uso hoje:{" "}
                    {parserCurrencyBRLValue(props.limitForToday)}
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemAvatar style={{ minWidth: "32px" }}>
                    <Circle sx={{ color: "#00CBA8" }} />
                  </ListItemAvatar>
                  <ListItemText>
                    Disponível para uso:{" "}
                    {parserCurrencyBRLValue(avaliable > 0 ? avaliable : 0)}
                  </ListItemText>
                </ListItem>
              </List>
            </div>
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default BalanceCards;
