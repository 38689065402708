import React from "react";
import {Box, Divider, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography} from "@mui/material";
import HomeInsightsStepItemAvatar from "./HomeInsightsStepItemAvatar";
import HomeInsightsStepItemChip from "./HomeInsightsStepItemChip";

const HomeInsightsStep = ({title, data}) => {
    /* istanbul ignore next */
    const handleExpense = (expenseUrl) => {
        const $modal = $('#modal-base-expense');
        $modal.find('.js-load-expense').empty();
        $modal.modal('open');
        $modal.on('click', '.js-modal-close', function () {
            $modal.modal('close');
        });
        const $prevButton = $modal.find('.js-prev-expense');
        const $nextButton = $modal.find('.js-next-expense');
        $prevButton.addClass('disabled');
        $nextButton.addClass('disabled');

        getExpense(expenseUrl, $modal);
    }

    /* istanbul ignore next */
    const getExpense = (expenseUrl, $modal) => {
        const $preloader = $modal.find('.preloader-container');
        $preloader.show();

        $.ajax({
            type: 'GET',
            url: expenseUrl,
            complete: function () {
                $preloader.hide();

                $modal.find('.modal-footer-info').show();
                $modal.find('.modal-footer-form-buttons').hide();
            }
        });
    }
    return (
        <Box>
            <Typography variant="subtitle1" sx={{my: 2}}>{title}</Typography>
            {data.map((item, index) => <Stack key={index}>
                {item.link ?

                    <ListItemButton onClick={/* istanbul ignore next */() => handleExpense(item.link)}>
                        <HomeInsightItemContent item={item} index={index}/>
                    </ListItemButton>
                    : <ListItem>
                        <HomeInsightItemContent item={item} index={index}/>
                    </ListItem>}
                <Divider flexItem/>
            </Stack>)}
        </Box>
    );
}

const HomeInsightItemContent = ({item, index}) => {
    return <>
        <ListItemAvatar sx={{my: 1}}>
            <HomeInsightsStepItemAvatar avatar={item.avatar} number={index + 1}/>
        </ListItemAvatar>
        <ListItemText
            primary={item.title}
            primaryTypographyProps={{sx: {overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}}
            secondary={item.subtitle}
            secondaryTypographyProps={{
                sx: {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '400px'
                }
            }}/>
        <Stack direction={"row"} alignItems={"center"} justifyContent={'right'}>
            {item.trailingChip === undefined ? <div/> :
                <HomeInsightsStepItemChip label={item.trailingChip} link={item.trailingChipLink}/>}
            <Typography variant="subtitleGray" sx={{whiteSpace: 'nowrap'}}>{item.trailing}</Typography>
        </Stack>
    </>
}

export default HomeInsightsStep;
