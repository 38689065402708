import React from "react";

import AvatarAndDetail from "../shared/lists/AvatarAndDetail";
import SelectTab from "../shared/SelectTab";
import Pagination from "../shared/Pagination";
import Loading from "../shared/Loading";
import SearchField from "../shared/SearchField";

class List extends React.Component {
  state = {
    baseLinkToDetail: `${this.props.endpoint}/`,
    subAccounts: [],
    search: "",
    selectTab: "activated",
    forcePageOne: false,
    loading: true,
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      loading: true,
      forcePageOne: !this.state.forcePageOne,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectTab !== prevState.selectTab) {
      this.setState({
        ...this.state,
        loading: true,
        forcePageOne: !this.state.forcePageOne,
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="search-input form-design-on-surfaces show-on-medium-and-down hide-on-med-and-up">
          {this.renderSearchField()}
        </div>
        <SelectTab
          list={this.listTab()}
          customList={[
            <div className="search-input form-design-on-surfaces in-tab show-on-large hide-on-med-and-down">
              {this.renderSearchField()}
            </div>,
          ]}
        />
        <Loading show={this.state.loading} />
        <AvatarAndDetail
          list={this.state.subAccounts.map((e) => ({
            ...e,
            title: e.name,
            description: e.card_description,
            secondary: e.balance,
          }))}
          baseLinkToDetail={this.state.baseLinkToDetail}
        />
        {this.props.empty && this.state.subAccounts.length === 0 && (
          <div className="card-panel dashed design-system">
            <span>
              Esta conta ainda não possui subcontas! Entre em contato com o
              suporte e solicite suas primeiras subcontas!
            </span>
          </div>
        )}
        <Pagination
          forcePageOne={this.state.forcePageOne}
          url={this.props.endpoint}
          setExternalList={(list) =>
            this.setState({ ...this.state, loading: false, subAccounts: list })
          }
          params={{
            q: {
              scopes: [this.state.selectTab],
              name_or_card: this.state.search,
            },
          }}
        />
      </React.Fragment>
    );
  }

  renderSearchField() {
    return (
      <SearchField
        placeholder={"Busque por usuário ou número final do cartão"}
        value={this.state.search}
        onChange={(e) =>
          this.setState({
            ...this.state,
            search: e.target.value,
            loading: true,
            forcePageOne: !this.state.forcePageOne,
          })
        }
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            this.setState({
              ...this.state,
              loading: true,
              forcePageOne: !this.state.forcePageOne,
            });
          }
        }}
      />
    );
  }

  listTab() {
    let list = [
      {
        testName: "activated",
        href: "#activated",
        label: "ATIVAS",
        onClick: () => this.setState({ ...this.state, selectTab: "activated" }),
      },
      {
        testName: "to_active",
        href: "#to_active",
        label: "PARA ATIVAR",
        onClick: () => this.setState({ ...this.state, selectTab: "to_active" }),
      },
      {
        testName: "canceled",
        href: "#canceled",
        label: "DESATIVADAS",
        onClick: () => this.setState({ ...this.state, selectTab: "canceled" }),
      },
    ];

    if (!this.props.show_to_active) {
      list.splice(
        list.findIndex((e) => e.testName === "to_active"),
        1
      );
    }

    return list;
  }
}

List.defaultProps = {
  empty: undefined,
  show_to_active: true,
};

export default List;
