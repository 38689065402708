import {admin_platform_updates_path} from "../../../../routes";

export const getPlatformUpdates = async () => {
    const response = await fetch(admin_platform_updates_path())
    if (response.ok) {
        const body = await response.json();
        return body.data;
    }
    return []
}

export const updatePlatformUpdates = () => {
    const token = document.querySelector('[name=csrf-token]').content
    fetch(admin_platform_updates_path(), {method: 'PUT', headers: {"X-CSRF-TOKEN": token}})
}
