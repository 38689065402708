import React, { useState } from 'react';
import { Card, CardContent, Divider, Grid, Snackbar, Stack, Switch, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { ShadowTheme } from "../../ShadowTemplate";
import { hot } from "react-hot-loader";
import { admin_root_path, admin_security_mandatory_two_factor_path } from '../../../../routes';
import MandatoryTwoFactorExplanation from './MandatoryTwoFactorExplanation';
import MandatoryTwoFactorBanner from './MandatoryTwoFactorBanner';
import MandatoryTwoFactorSubtitle from './MandatoryTwoFactorSubtitle';
import MandatoryTwoFactorInactiveAlerts from './MandatoryTwoFactorInactiveAlerts';
import MandatoryTwoFactorActiveAlerts from './MandatoryTwoFactorActiveAlerts';

const MandatoryTwoFactorShadow = (props) => {
  return <ShadowTheme rootSelector={props.rootSelector}>
    <MandatoryTwoFactor {...props} />
  </ShadowTheme>
}

const MandatoryTwoFactor = (props) => {
  const theme = useTheme();

  const [switchChecked, setSwitchChecked] = useState(props.active);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hiddenSwitch] = useState(props.active && props.hasAccount);

  const handleSwitchChange = async () => {
    let state = switchChecked ? 'desativado' : 'ativado';
    let action = switchChecked ? 'desativar' : 'ativar';
    let redirect = switchChecked ? null : () => {
      window.location.assign(admin_root_path());
    }

    const response = await fetch(admin_security_mandatory_two_factor_path(), {
      method: 'PUT',
      headers: { "X-CSRF-Token": props.csrfToken, 'Content-Type': 'application/json' },
      body: JSON.stringify({ active: !switchChecked })
    });

    if (response.ok) {
      setSnackbarMessage(`2FA obrigatório ${state}!`);
      setOpenSnackbar(true);
      setSwitchChecked(!switchChecked);
      redirect?.call();
    } else {
      setSnackbarMessage(`Não foi possível ${action} o 2FA obrigatório`);
      setOpenSnackbar(true);
    }
  };

  return <>
    <Card sx={{ marginTop: '80px' }}>
      <CardContent>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
          2FA Obrigatório
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MandatoryTwoFactorBanner theme={theme} active={switchChecked}/>
          </Grid>
          <Grid item xs={12}>
            <MandatoryTwoFactorSubtitle active={switchChecked} hasAccount={props.hasAccount}/>
          </Grid>
          <Grid item xs={12}>
            { switchChecked ? <MandatoryTwoFactorActiveAlerts hasAccount={props.hasAccount}/>
              : <MandatoryTwoFactorInactiveAlerts hasAccount={props.hasAccount}/> }
          </Grid>
          { !hiddenSwitch &&
            <Grid item xs={2}>
              <Stack direction={'row'} alignItems={'center'}>
                <Typography variant='subtitle1'>
                  { switchChecked ? 'Ativado' : 'Desativado' }
                </Typography>
                <Switch
                  color='secondary'
                  data-testid='switch'
                  checked={switchChecked}
                  onChange={handleSwitchChange}
                  disabled={props.active && props.hasAccount}
                />
              </Stack>
            </Grid>
          }
          { !switchChecked && <Grid item xs={12}><Divider/></Grid> }
          { !switchChecked && <Grid item xs={12}><MandatoryTwoFactorExplanation/></Grid> }
        </Grid>
      </CardContent>
    </Card>
    <Snackbar
      data-testid="snackbar"
      open={openSnackbar}
      autoHideDuration={6000}
      message={snackbarMessage}
      anchorOrigin={{vertical: 'bottom', horizontal: 'center' }}
    />
  </>
}

export default hot(module)(MandatoryTwoFactorShadow);