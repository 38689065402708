import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Collapse, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const History = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <React.Fragment>
      <Typography variant="h6">
        Histórico de ações
      </Typography>
                
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" style={{ flex: 1 }}>
          Clique para ver mais detalhes do histórico
        </Typography>
        
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <IconButton data-testid="expand-button" onClick={handleExpandClick} size="small">
            {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </div>
      </div>

      <div style={{ display: 'flex', marginTop: '20px' }}>
        <Collapse in={expanded} style={{ width: '100%' }} data-testid="expanded-content">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ação</TableCell>
                  <TableCell>Usuário</TableCell>
                  <TableCell>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.history.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {row[0]}
                    </TableCell>
                    <TableCell>
                      {row[1]}
                    </TableCell>
                    <TableCell>
                      {row[2]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </div>
    </React.Fragment>
  );
};

export default History;