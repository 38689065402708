import {companies_path, new_user_session_path, thank_you_companies_path} from "../../../routes";

export class CompanySignUpDto {
    constructor(name, cnpj, activity) {
        this.name = name;
        this.cnpj = cnpj;
        this.activity = activity;
    }
}

export class UserSignUpDto {
    constructor(company, name, email, phone, password) {
        this.company = company;
        this.name = name;
        this.email = email;
        this.phone = phone;
        this.password = password;
    }
}

export const signUp = async (user) => {
    const token = document.querySelector('[name=csrf-token]')?.content
    const response = await fetch(companies_path(), {
        method: 'POST',
        credentials: "same-origin",
        redirect: 'follow',
        headers: {
            "X-CSRF-TOKEN": token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({user: user})
    });
    if (response.ok) {
        window.location.assign(thank_you_companies_path());
    }
    return null;
}
