import React from "react";
import { Button, Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
import { parserCurrencyBRLValue } from "../../shared/helpers";
import { budgetFrequencyName, budgetableTypeName } from "./budgets_service";
import { ClosedFeedback, ExceededFeedback, RiskFeedback } from "./BudgetFeedback";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReceiptIcon from '@mui/icons-material/Receipt';
import BudgetProgress from "./BudgetProgress";
import { expenses_admin_budget_path } from "../../../routes";

const BudgetInfo = ({ title, titleColor, value, valueColor }) => {
  return <>
    <Stack>
      <Typography
        color={titleColor || "GrayText"}
        variant="caption"
      >
        {title}
      </Typography>
      <Typography
        color={valueColor}
      >
        {value}
      </Typography>
    </Stack>
  </>
}

const BigBudgetInfos = ({ theme, props }) => {
  return <>
    <Grid container justifyContent={"space-between"}>
      <Grid item>
        <BudgetInfo
          title="Classificação"
          value={budgetableTypeName(props.classification, true)}
        />
      </Grid>
      <Grid item>
        <BudgetInfo
          title="Recorrência"
          value={budgetFrequencyName(props.frequency)}
        />
      </Grid>
      <Grid item>
        <BudgetInfo
          title="Início do orçamento"
          value={props.start}
        />
      </Grid>
      <Grid item>
        <BudgetInfo
          title="Fim do orçamento"
          value={props.end}
        />
      </Grid>
      <Grid item>
        <BudgetInfo
          title="Em aprovação"
          titleColor={theme.palette.alert.warning.main}
          value={parserCurrencyBRLValue(props.approving)}
          valueColor={theme.palette.warning.main}
        />
      </Grid>
      <Grid item>
        <BudgetInfo
          title="Excedido"
          titleColor={theme.palette.alert.error.main}
          value={parserCurrencyBRLValue(props.over)}
          valueColor={theme.palette.error.main}
        />
      </Grid>
    </Grid>
  </>
}

const SmallBudgetInfos = ({ theme, props }) => {
  return <>
    <Grid container spacing={2} sx={{ p: "1rem" }}>
      <Grid item xs={6}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Realizado"
            titleColor={theme.palette.alert.success.main}
            value={parserCurrencyBRLValue(props.performed)}
            valueColor={theme.palette.success.main}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Limite"
            value={parserCurrencyBRLValue(props.limit)}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Em aprovação"
            titleColor={theme.palette.alert.warning.main}
            value={parserCurrencyBRLValue(props.approving)}
            valueColor={theme.palette.warning.main}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Excedido"
            titleColor={theme.palette.alert.error.main}
            value={parserCurrencyBRLValue(props.over)}
            valueColor={theme.palette.error.main}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Classificação"
            value={budgetableTypeName(props.classification, true)}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Recorrência"
            value={budgetFrequencyName(props.frequency)}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Início do orçamento"
            value={props.start}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2 }}>
          <BudgetInfo
            title="Fim do orçamento"
            value={props.end}
          />
        </Card>
      </Grid>
    </Grid>
  </>
}

const BudgetResume = (props) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const limit = Number(props.limit);
  const performed = Number(props.performed);
  const approving = Number(props.approving);

  const exceeded = performed > limit;
  const risk = performed < limit && (performed + approving) > limit;

  return <>
    { 
      props.closed ? <ClosedFeedback theme={theme} smallScreen={smallScreen}/> :
      props.admin && exceeded ? <ExceededFeedback theme={theme} smallScreen={smallScreen}/> :
      props.admin && risk ? <RiskFeedback theme={theme} smallScreen={smallScreen}/> : <></>
    }
    <Card
      data-testid="budget-resume"
      sx={{ mx: smallScreen ? "1rem" : "10rem", p: 2 }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
      >
        <Typography variant={"h6"}>Resumo</Typography>
        {
          props.admin &&
          <Button
            startIcon={<ReceiptIcon/>}
            sx={{ color: "GrayText" }}
            href={expenses_admin_budget_path(props.id)}
          >
            Ver despesas
          </Button>
        }
      </Stack>
      <Stack
        sx={{ py: 2 }}
        direction={"row"}
        spacing={2}
        alignItems={"center"}
      >
        <Stack>
          <Typography>Orçado</Typography>
          <Typography variant="h6">{parserCurrencyBRLValue(props.limit)}</Typography>
        </Stack>
        { 
          !smallScreen &&
          <Divider
            flexItem
            orientation="vertical"
            sx={{ height: "5rem" }}
          />
        }
        { 
          !smallScreen &&
          <BudgetProgress theme={theme} props={props}/>
        }
      </Stack>
      { 
        !smallScreen &&
        <BigBudgetInfos theme={theme} props={props}/>
      }
    </Card>
    { 
      smallScreen &&
      <SmallBudgetInfos theme={theme} props={props}/>
    }
  </>
}

export default BudgetResume;