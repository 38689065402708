import React, {useEffect, useMemo, useState} from "react";
import {ShadowTheme} from "../../../admin/ShadowTemplate";
import {Chip, Snackbar, Stack} from "@mui/material";
import {hot} from "react-hot-loader";
import {bistro_company_financial_records_path} from "../../../../routes";

const ClassificationChips = ({rootSelector, companyId, classification, groups, active}) => {
    const [activeIds, setActiveIds] = useState(active);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const activeGroups = useMemo(() => {
        return groups.filter(group => activeIds.includes(group.id));
    }, [groups, activeIds]);

    useEffect(() => {
        document.addEventListener(`add_${classification}`, handleAdd);
        return () => window.removeEventListener(`add_${classification}`, handleAdd);
    }, []);

    const handleDelete = (groupId) => updateGroups(activeIds.filter(group => group !== groupId));

    const handleAdd = (event) => updateGroups(event.detail);

    const updateGroups = async (groupIds) => {
        try {
            const response = await updateRequest(groupIds);
            if (response.ok) {
                setActiveIds(groupIds);
                setSnackbarMessage('Classificação alterada com sucesso!');
                document.dispatchEvent(new CustomEvent(`${classification}_changed`, {detail: groupIds}));
            } else {
                setSnackbarMessage('Não foi possível fazer essa alteração');
            }
        } catch (e) {
            setSnackbarMessage('Não foi possível fazer essa alteração');
        }
    }

    const updateRequest = (groupIds) => {
        return fetch(
            bistro_company_financial_records_path(companyId),
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                },
                body: JSON.stringify({company: {[classification]: groupIds}})
            }
        )
    }

    return <ShadowTheme rootSelector={rootSelector}>
        <Stack direction="row" spacing={1} sx={{pt: 1}}>
            {activeGroups.map(group => (
                <Chip
                    key={`group_${group.id}`}
                    label={`${group.name} - ${group.code || 'Sem código'}`}
                    onDelete={() => handleDelete(group.id)}/>
            ))}
        </Stack>
        <Snackbar
            open={!!snackbarMessage}
            onClose={() => setSnackbarMessage('')}
            message={snackbarMessage}
            autoHideDuration={3000}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}/>
    </ShadowTheme>
}

export default hot(module)(ClassificationChips)