import React from "react";
import { ShadowTheme } from "../../../ShadowTemplate";

import { admin_account_sub_accounts_path } from "../../../../../routes";

import NavBarModal from "./NavBarModal";

import { Box, Toolbar, Typography } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// last import
import { hot } from "react-hot-loader";

const NavBar = (props) => {
  return (
    <ShadowTheme rootSelector={props.rootSelector}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#F0EBF5",
          marginBottom: "30px",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <a
            style={{ cursor: "pointer", marginRight: "20px", height: "20px" }}
            href={admin_account_sub_accounts_path()}
          >
            <ArrowBackIcon />
          </a>
          <Typography>Permissão para ativação de cartões</Typography>
        </Box>
        <NavBarModal />
      </Toolbar>
    </ShadowTheme>
  );
};

export default hot(module)(NavBar);
