import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Divider, Drawer, List, Stack, Typography } from "@mui/material";
import FaqDrawerHeader from "./FaqDrawerHeader";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FaqSupportButton from "./FaqSupportButton";

const FaqDrawer = ({ open, onClose, subtitle, data }) => {
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return <Drawer
    open={open}
    onClose={onClose}
    anchor={"right"}
    sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
    PaperProps={{ sx: { width: smallScreen ? "80%" : "20%" } }}
    data-testid={"faq-drawer"}
  >
    <Stack mt={2} pb={1} px={2} sx={{ height: "100%" }}>
      <FaqDrawerHeader onClose={onClose}/>
      <Typography my={1} color={"GrayText"}>
        {subtitle}
      </Typography>
      <List>
        { 
          data.map((item, index) =>
            <Accordion
              elevation={0}
              key={`faq-${index}`}
              sx={{ my: 1 }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ ml: 1 }}/>}>
                { item.question }
              </AccordionSummary>
              <AccordionDetails>
                { 
                  item.answer.split("\n").map((line, index) =>
                    <Typography mt={1} key={`line-${index}`}>
                      { line }
                    </Typography>
                  )
                }
              </AccordionDetails>
            </Accordion>
          )
        }
      </List>
      <Divider/>
      <FaqSupportButton/>
    </Stack>
  </Drawer>
}

export default FaqDrawer;