/* istanbul ignore file */

import React, { useEffect, useState } from "react";
import { admin_account_suppliers_users_path } from "../../../routes";

import MaskedInput from "../../shared/inputs/Masked";

import {
  Button,
  CardActions,
  Dialog,
  DialogContent,
  List,
  ListItem,
  Pagination,
  Typography,
} from "@mui/material";
import classNames from "classnames";

const UserMerchantModal = ({ edit, open, onClose, onSelect }) => {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedId, setSelectedId] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (open) {
      onSearchChange("", 1);
    }
  }, [open]);

  const onSearchChange = (search, page) => {
    setSearch(search);

    $.ajax({
      type: "GET",
      url: admin_account_suppliers_users_path(),
      data: { filters: { name_or_email: search }, page },
    }).then((r) => {
      setList(r.data.list);
      setPage(r.data.current_page);
      setTotal(r.data.total_count);
    });
  };

  const select = () => {
    onSelect(list.find((e) => e.id === selectedId));

    setSelectedId("");
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
      <DialogContent>
        <Typography>
          Selecione o usuário responsável pela prestação de contas das compras
          realizadas neste cartão.
        </Typography>
        <div
          className="form-design-on-surfaces masked-input"
          style={{ width: "80%" }}
        >
          <div className="input-field">
            <input
              placeholder="Busque pelo nome ou e-mail do usuário"
              onChange={(e) => onSearchChange(e.target.value, 1)}
              value={search}
            />
            <label className="active">Buscar</label>
          </div>
        </div>
        <p
          style={{
            marginBottom: "10px",
            marginTop: "-20px",
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "12px",
          }}
        >
          Se não for necessário a comprovação das compras realizadas nesse
          cartão vá para o próximo passo!
        </p>
        <List>
          {list.map((e, i) => (
            <ListItem
              key={i}
              onClick={() => setSelectedId(e.id)}
              style={{
                cursor: "pointer",
                backgroundColor: selectedId === e.id && "#F0EBF5",
              }}
            >
              <div className="d-flex align-center">
                <img
                  className="avatar d-flex-justify-center align-center circle"
                  style={{ width: "40px", marginRight: "16px" }}
                  src={e.avatar}
                />
                {`${e.name} (${e.email})`}
              </div>
            </ListItem>
          ))}
        </List>
        <Pagination
          shape="rounded"
          sx={{
            display: "flex",
            justifyContent: "right",
            backgroundColor: "white",
            boxShadow: "none",
          }}
          count={Math.ceil(total / 5)}
          page={page}
          onChange={(e, value) => onSearchChange(search, value)}
        />
      </DialogContent>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          className={classNames({ "modal-trigger": edit })}
          sx={{ color: "rgba(0, 0, 0, 0.38)" }}
          href="#modal-subscription"
          variant="text"
          color="primary"
          onClick={onClose}
        >
          cancelar
        </Button>
        <Button
          className={classNames({ "modal-trigger": edit })}
          href="#modal-subscription"
          onClick={select}
          sx={{ color: "#3D0079" }}
        >
          associar usuário
        </Button>
      </CardActions>
    </Dialog>
  );
};

export default UserMerchantModal;
