import React, {useState} from "react";
import {
    admin_user_path,
    back_user_masquerade_index_path,
    edit_admin_settings_company_path,
    user_masquerade_path
} from "../../../../routes";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import WorkIcon from "@mui/icons-material/Work";
import {
    Avatar,
    Box,
    Divider,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    useMediaQuery
} from "@mui/material";
import {logout} from "./profileMenuDataSource";

export default function ProfileMenu({user, copilotMode, canEditCompany, copilotEnable, copilots}) {
    const [profileTarget, setProfileTarget] = useState(null);
    const profileOpen = Boolean(profileTarget);
    const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    const handleProfileMenu = (event) => {
        setProfileTarget(event.currentTarget);
    };

    const handleCloseProfileMenu = () => {
        setProfileTarget(null);
    };

    return <Box sx={{flexGrow: 0}}>
        <ProfileMenuButton
            showText={largeScreen}
            copilotMode={copilotMode}
            user={user}
            onClick={handleProfileMenu}/>
        <Menu
            anchorEl={profileTarget}
            open={profileOpen}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            onClose={handleCloseProfileMenu}>
            <ProfileMenuContent
                user={user}
                onClickItem={handleCloseProfileMenu}
                showUserInfo={!largeScreen}
                copilots={copilots}
                copilotMode={copilotMode}
                copilotEnable={copilotEnable}
                canEditCompany={canEditCompany}/>
        </Menu>
    </Box>
}

const ProfileMenuButton = ({copilotMode, user, onClick, showText}) => {
    return <ListItemButton dense onClick={onClick}>
        <ListItemIcon><Avatar src={user.photoPath}/></ListItemIcon>
        {showText && (
            <ListItemText
                primary={copilotMode ? "Você está logado como:" : user.name}
                secondary={copilotMode ? user.name : user.email}
                secondaryTypographyProps={{color: "inherit"}}/>
        )}
        <ArrowDropDownIcon sx={{ml: 1}}/>
    </ListItemButton>
}

const ProfileMenuContent = (props) => {
    const {
        showUserInfo,
        copilotMode,
        user,
        canEditCompany,
        copilotEnable,
        copilots,
        onClickItem
    } = props;

    const showCopilots = copilotEnable && copilots.length > 0;

    const handleLogout = () => {
        logout().then(onClickItem())
    }

    return <>
        {showUserInfo && (
            <UserInfoMenuItem copilotMode={copilotMode} user={user}/>
        )}
        {copilotMode && (
            <MenuItem onClick={onClickItem} href={back_user_masquerade_index_path()} component="a">
                <ListItemIcon><PersonIcon/></ListItemIcon>
                <ListItemText>Voltar para sua conta</ListItemText>
            </MenuItem>
        )}
        {canEditCompany && (
            <MenuItem onClick={onClickItem} href={edit_admin_settings_company_path()} component="a">
                <ListItemIcon><WorkIcon/></ListItemIcon>
                <ListItemText>Dados da empresa</ListItemText>
            </MenuItem>
        )}
        {!copilotMode && (
            <MenuItem onClick={onClickItem} href={admin_user_path(user.employeeId)} component="a">
                <ListItemIcon><PersonIcon/></ListItemIcon>
                <ListItemText>Minha conta</ListItemText>
            </MenuItem>
        )}
        <Divider/>
        <MenuItem onClick={handleLogout}>
            <ListItemIcon><LogoutIcon/></ListItemIcon>
            <ListItemText>Sair</ListItemText>
        </MenuItem>
        {showCopilots && <CopilotListSection onClickItem={onClickItem} copilots={copilots}/>}
    </>
}

const UserInfoMenuItem = ({user, copilotMode}) => {
    return <>
        <ListItem>
            <ListItemText
                primary={copilotMode ? "Você está logado como:" : user.name}
                secondary={copilotMode ? user.name : user.email}/>
        </ListItem>
        <Divider/>
    </>
}

const CopilotListSection = ({copilots, onClickItem}) => {
    return <>
        <Divider/>
        <ListSubheader>Acessar como:</ListSubheader>
        {copilots.map((copilot, idx) => (
            <MenuItem
                onClick={onClickItem}
                key={idx}
                href={user_masquerade_path(copilot.id)}
                component="a">
                <ListItemIcon>
                    <Avatar sx={{width: 28, height: 28}} src={copilot.photoPath}/>
                </ListItemIcon>
                <ListItemText primary={copilot.name}/>
            </MenuItem>
        ))}
    </>
}