import React from "react";
import {closeModal, showSnackbar} from "../../../shared/jquery_wrapper";

export default class DistanceCostModal extends React.Component {

    constructor(props) {
        super(props);
        this.input = props.inputRef;
    }

    handleSubmit = () => {
        let value = this.input.current.value;
        if (value === '0,00' || value === "") {
            showSnackbar({
                content: "O valor deve ser maior que 0!",
                style: "alert",
                timeout: 3000
            })
        } else {
            closeModal('#edit-distance-cost-modal')
            this.props.onConfirm(value)
        }
    }

    render() {
        return (
            <div id="edit-distance-cost-modal" className="modal modal-form modal-fixed-footer">
                <div className="modal-content">
                    <h4>Valor em R$ por Km</h4>
                    <div className="modal-field">
                        <div className="input-field">
                            <div className="inset-new-value" style={{color: '#9e9e9e', fontSize: '12px'}}>
                                Insira o valor
                            </div>
                            <div className="prefix" style={{lineHeight: '70px', color: '#9e9e9e'}}>
                                R$
                            </div>
                            <input ref={this.input} type="text" className="js-value-mask" style={{fontSize: '28px'}}/>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <a className="modal-close grey-text btn-flat">Cancelar</a>
                    <a className="modal-action btn-flat" onClick={() => this.handleSubmit()}>
                        Salvar
                    </a>
                </div>
            </div>
        );
    }
}
