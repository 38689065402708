import {Badge, Button, IconButton, Snackbar, Tooltip} from "@mui/material";
import SecurtyIcon from "@mui/icons-material/Security";
import * as React from "react";
import {useState} from "react";
import {admin_user_passwords_path} from "../../../../routes";
import CloseIcon from "@mui/icons-material/Close";

const TwoFactorButton = ({employeeId}) => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const toggleSnackBar = () => setSnackbarOpen(!snackbarOpen);

    return <>
        <Tooltip title={"Alerta de segurança"}>
            <IconButton sx={{mx: 2}} color={"inherit"} onClick={toggleSnackBar}>
                <Badge color={"pink"} badgeContent={1}>
                    <SecurtyIcon/>
                </Badge>
            </IconButton>
        </Tooltip>
        <Snackbar
            sx={{mt: 6, mr: {xs: 16, lg: 36}}}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            open={snackbarOpen}
            action={<TwoFactorAction employeeId={employeeId} onClose={toggleSnackBar}/>}
            message={
                <>
                    Reforce sua segurança!<br/>
                    Ative a autenticação de dois fatores agora e proteja <br/>
                    sua conta com uma camada extra de segurança
                </>
            }/>
    </>
}

const TwoFactorAction = ({onClose, employeeId}) => {
    return <>
        <Button
            color={'secondary'}
            onClick={onClose}
            href={admin_user_passwords_path(employeeId)}>
            Ativar
        </Button>
        <Tooltip title={"Fechar"}>
            <IconButton size="small" color="inherit" onClick={onClose}>
                <CloseIcon fontSize="small"/>
            </IconButton>
        </Tooltip>
    </>
}

export default TwoFactorButton;